import React, { FC, useContext } from "react";
// import images
import FreedomBanner from "../../../assets/images/advantages/freedomBanner.png";
import Step1 from "../../../assets/images/advantages/Step1.png";
import Step2 from "../../../assets/images/advantages/step_2.png";
import step3 from "../../../assets/images/advantages/step_3.png";
import Step4 from "../../../assets/images/advantages/step_4.png";
import Step5 from "../../../assets/images/advantages/step_5.png";
import Step6 from "../../../assets/images/advantages/step_6.png";
import Step7 from "../../../assets/images/advantages/step__7.png";
import CalendarImage from "../../../assets/images/advantages/calendar.svg";

// import components
import { GlobalContext } from "../../../context/globalContext/globalContext";
import { getValue } from "../../../utils/getValue";
import InputTabs from "../../../shared/autoComplete";

interface FreedomOfChoiceInterface {}

const FreedomOfChoice: FC<FreedomOfChoiceInterface> = () => {
  const { state }: any = useContext(GlobalContext);
  console.log("getValue", getValue(state, 1, 0));
  return (
    <div>
      <div>
        <img src={FreedomBanner} alt="FreedomBanner" />
      </div>
      <p
        className="text-black text-[20px] py-4"
        dangerouslySetInnerHTML={{
          __html: getValue(state, 1, 189)?.props?.content,
        }}
      />

      <div className="flex flex-col lg:flex-row  px-8 pt-10">
        <div className="w-full lg:w-3/6 ">
          <p
            className="text-[#007DDF] text-sm py-1"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 53)?.props?.content,
            }}
          />
          <p
            className="text-[#374151] text-base "
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 55)?.props?.content,
            }}
          />
        </div>
        <div className="w-full lg:w-3/6 flex justify-center lg:py-0 py-4">
          <img src={Step1} alt="Step1" className="h-full lg:h-[200px]" />
        </div>
      </div>
      <div className="flex flex-col-reverse lg:flex-row px-8 pt-10">
        <div className="w-full lg:w-3/6 flex justify-center lg:py-0 py-4">
          <img src={Step2} alt="Step2" className="h-fit" />
        </div>
        <div className="w-full lg:w-3/6 ">
          <p
            className="text-[#007DDF] text-sm py-1"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 57)?.props?.content,
            }}
          />
          <p
            className="text-[#374151] text-base "
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 58)?.props?.content,
            }}
          />
        </div>
      </div>
      <div className="px-8 pt-10">
        <div className="w-full lg:w-3/6">
          <p
            className="text-[#007DDF] text-sm py-1"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 60)?.props?.content,
            }}
          />
          <p
            className="text-[#374151] text-base "
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 153)?.props?.content,
            }}
          />
        </div>
        <div className="w-full lg:w-3/6 flex justify-center lg:py-0 py-4">
          <img src={step3} alt="step3" className="h-fit" />
        </div>
      </div>
      <div className="flex flex-col lg:flex-row px-8 pt-10">
        <div className="w-full lg:w-3/6">
          <p
            className="text-[#007DDF] text-sm py-1"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 62)?.props?.content,
            }}
          />
          <p
            className="text-[#374151] text-base "
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 63)?.props?.content,
            }}
          />
        </div>
        <div className="w-full lg:w-3/6 flex justify-center lg:py-0 py-4 ">
          <img src={Step4} alt="Step4" className="h-fit" />
        </div>
      </div>
      <div className="flex flex-col-reverse lg:flex-row px-8 pt-10">
        <div className="w-full lg:w-3/6 flex justify-center lg:py-0 py-4 ">
          <img src={Step5} alt="Step5" className="h-fit" />
        </div>
        <div className="w-full lg:w-3/6">
          <p
            className="text-[#007DDF] text-sm py-1"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 65)?.props?.content,
            }}
          />
          <p
            className="text-[#374151] text-base "
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 134)?.props?.content,
            }}
          />
          {/* <p
            className="text-[#374151] text-base pt-1"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 23)?.props?.content,
            }}
          /> */}
        </div>
      </div>
      <div className="flex flex-col lg:flex-row px-8 pt-10">
        <div className="w-full lg:w-3/6">
          <p
            className="text-[#007DDF] text-sm py-1"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 136)?.props?.content,
            }}
          />
          <p
            className="text-[#374151] text-base "
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 137)?.props?.content,
            }}
          />
        </div>
        <div className="w-full lg:w-3/6 flex justify-center lg:py-0 py-4">
          <img src={Step6} alt="Step6" className="h-fit" />
        </div>
      </div>
      <div className="flex flex-col-reverse lg:flex-row px-8 pt-10">
        <div className="w-full lg:w-3/6 flex justify-center lg:py-0 py-4">
          <img src={Step7} alt="Step5" className="h-fit" />
        </div>
        <div className="w-full lg:w-3/6">
          <p
            className="text-[#007DDF] text-sm py-1"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 139)?.props?.content,
            }}
          />
          <p
            className="text-[#374151] text-base "
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 140)?.props?.content,
            }}
          />
        </div>
      </div>

      <div className="py-16 flex flex-col items-end">
        <div className="flex">
          <img src={CalendarImage} alt="Step6" className="w-[7%] mr-2" />
          <button className="bg-[#007DDF] text-white rounded px-6 py-3">
            {getValue(state, 1, 143)?.props?.content}
          </button>
        </div>
        {/* <div className="flex flex-col items-center py-4">
          
        </div> */}
        <p
          className="text-[#374151] text-base pt-4 "
          dangerouslySetInnerHTML={{
            __html: getValue(state, 1, 144)?.props?.content,
          }}
        />
      </div>

      <div className="bg-[#007DDF] rounded-[20px] p-[40px] mt-6 mb-14">
        <p
          className="text-white text-xl font-medium pb-6"
          dangerouslySetInnerHTML={{
            __html: getValue(state, 1, 145)?.props?.content,
          }}
        />
        <InputTabs showAutocomplete="renovation" />
      </div>
    </div>
  );
};
export default FreedomOfChoice;
