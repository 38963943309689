import React, { FC, useContext, useState } from "react";
import { Spin } from "antd";
// import images
import ArroRight from "../../../assets/images/faq/next.png";
import ArroDown from "../../../assets/images/faq/down-arrow.png";

// import components
import { GlobalContext } from "../../../context/globalContext/globalContext";
import { getValue } from "../../../utils/getValue";
import InputTabs from "../../../shared/autoComplete";
import AnimateHeight from "react-animate-height";
interface CarryOutInterface {
  currentFaqs?: any;
  faqLoader?: boolean;
}

const CarryOut: FC<CarryOutInterface> = ({ currentFaqs, faqLoader }) => {
  const { state }: any = useContext(GlobalContext);
  console.log("getValue", getValue(state, 5, 0));
  const [active, setActive]: any = useState(0);
  return (
    <div>
      {faqLoader === true ? (
        <div className="h-screen flex justify-center items-center">
          <Spin size="large" />
        </div>
      ) : (
        <div className="pt-6">
          {currentFaqs?.map((faq: any) => (
            <div
              aria-expanded={faq?.id !== 0}
              aria-controls="example-panel"
              onClick={() => {
                setActive(faq?.id === active ? 0 : faq?.id);
              }}
              className="border-b border-b-[#d9d9d9] py-4"
            >
              <div className="cursor-pointer flex justify-between">
                <p className="text-black text-sm font-semibold">{faq?.title}</p>
                {faq?.id === active ? (
                  <img src={ArroDown} alt="ArroDown" className="h-[20px]" />
                ) : (
                  <img src={ArroRight} alt="ArroRight" className="h-[20px]" />
                )}
              </div>
              <div>
                <AnimateHeight
                  id="example-panel"
                  duration={500}
                  height={faq?.id === active ? "auto" : 0}
                >
                  <p
                    className=" text-sm font-normal py-[21px]"
                    dangerouslySetInnerHTML={{
                      __html: faq?.description,
                    }}
                  />
                </AnimateHeight>
              </div>
            </div>
          ))}
          <div className="bg-[#007DDF] rounded-[20px] p-[40px] mt-6 mb-14">
            <p
              className="text-white text-base font-medium pb-6"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 5, 7)?.props?.content,
              }}
            />
            <InputTabs showAutocomplete="renovation" />
          </div>
        </div>
      )}
    </div>
  );
};
export default CarryOut;
