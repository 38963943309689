import React, { FC, useContext } from "react";
// import images
import TogetherPositive from "../../../assets/images/advantages/togetherpositive.png";
import UnderCity from "../../../assets/images/advantages/UndrawCity.svg";

// import components
import { GlobalContext } from "../../../context/globalContext/globalContext";
import { getValue } from "../../../utils/getValue";
import InputTabs from "../../../shared/autoComplete";

interface TogetherForPositiveInterface {}

const TogetherForPositive: FC<TogetherForPositiveInterface> = () => {
  const { state }: any = useContext(GlobalContext);
  console.log("getValue", getValue(state, 1, 0));
  return (
    <div>
      <div>
        <img src={TogetherPositive} alt="TogetherPositive" />
      </div>
      <p
        className="text-black text-[20px] py-4"
        dangerouslySetInnerHTML={{
          __html: getValue(state, 1, 92)?.props?.content,
        }}
      ></p>

      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-[85%] ">
          <div className="flex items-end">
            <p
              className="text-[40px] text-transparent bg-clip-text  bg-gradient-to-b from-[#007ddf] to-[#9fea61] font-medium"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 1, 193)?.props?.content,
              }}
            />
            <p
              className="text-base text-transparent font-semibold bg-clip-text bg-gradient-to-b from-[#007ddf] to-[#9fea61]"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 1, 160)?.props?.content,
              }}
            />
          </div>
          <p
            className="text-[#1F2937] text-xl font-medium py-4"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 94)?.props?.content,
            }}
          />
        </div>
        <div className="flex lg:flex-none justify-center ">
          <img src={UnderCity} alt="UnderCity" className="h-[80px]" />
        </div>
      </div>
      <div className="flex flex-col lg:flex-row space-x-3 justify-between  py-8">
        <div>
          <div>
            <p
              className="text-center text-[36px] text-transparent bg-clip-text  bg-gradient-to-b from-[#007ddf] to-[#9fea61] font-medium"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 1, 194)?.props?.content,
              }}
            ></p>
            <span
              className="text-[#6b7280] text-base font-semibold flex justify-center"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 1, 161)?.props?.content,
              }}
            ></span>
          </div>
          <p
            className="text-[#6B7280] text-center text-sm font-normal py-2"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 98)?.props?.content,
            }}
          />
        </div>
        <div>
          <div>
            <p
              className="text-center text-[36px] text-transparent bg-clip-text  bg-gradient-to-b from-[#007ddf] to-[#9fea61] font-medium"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 1, 195)?.props?.content,
              }}
            ></p>
            <span
              className="text-[#6b7280] text-base font-semibold flex justify-center"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 1, 162)?.props?.content,
              }}
            ></span>
          </div>
          <p
            className="text-[#6B7280] text-center text-sm font-normal py-2"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 98)?.props?.content,
            }}
          />
        </div>
        <div>
          <div>
            <p
              className="text-center text-[36px] text-transparent bg-clip-text  bg-gradient-to-b from-[#007ddf] to-[#9fea61] font-medium"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 1, 196)?.props?.content,
              }}
            ></p>
            <span
              className="text-[#6b7280] text-sm font-semibold flex justify-center"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 1, 163)?.props?.content,
              }}
            ></span>
          </div>
          <p
            className="text-[#6B7280] text-center text-base font-normal py-2"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 102)?.props?.content,
            }}
          />
        </div>
      </div>
      <p
        className="text-[#6B7280] text-base font-normal py-4 "
        dangerouslySetInnerHTML={{
          __html: getValue(state, 1, 105)?.props?.content,
        }}
      />

      <div className="bg-[#007DDF] rounded-[20px] p-[40px] mb-14">
        <p
          className="text-white text-xl font-medium pb-6"
          dangerouslySetInnerHTML={{
            __html: getValue(state, 1, 106)?.props?.content,
          }}
        />
        <InputTabs showAutocomplete="renovation" />
      </div>
    </div>
  );
};
export default TogetherForPositive;
