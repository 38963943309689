import {
  pythonLogic1,
  pythonLogicMinus1,
  pythonLogic1AfterCall2Call3,
  pythonLogicMinus1AfterCall2Call3,
} from "../../utils/renovations";

const soloSolarEpcIndValue = (
  currentEPCIndValue: any,
  unitPV: any,
  numberOfSolars: any
) => {
  // current situation epc_ind - # panels * 1,65 * 1,085 * (current situation epc_ind - unit PV scenario epc_ind)
  let solar_ind =
    currentEPCIndValue -
    numberOfSolars * 1.65 * 1.085 * (currentEPCIndValue - unitPV);
  return solar_ind;
};

// post processing logic for pack A
export const renovationPostProcessing = (
  allRecords: any,
  renovationRefinements: any,
  callZeroRecord?: any
) => {
  // to remove all records which contains epc 1
  let epcLabelWithout1 = [...allRecords];
  // logic for solar calculations
  let solar_co2_saving: any = solarPriceHandler(
    callZeroRecord?.data[0]
  ).solar_co2_saving;
  let solar_yearly_saving: any = solarPriceHandler(
    callZeroRecord?.data[0]
  ).solar_yearly_saving;
  let solar_invsetment_cost: any = solarPriceHandler(
    callZeroRecord?.data[0]
  ).solar_invsetment_cost;
  let solar_subsidy_cost: any = solarPriceHandler(
    callZeroRecord?.data[0]
  ).solar_subsidy_cost;
  let number_of_solars: any = solarPriceHandler(
    callZeroRecord?.data[0]
  ).solarPanels;
  let roof_area = callZeroRecord?.data[0].roof_area;
  // let roof_area = houseRenovations.roof_area;
  // scenario 1 variable for energy_score
  let new_ind: any;
  let solarUptilA: any;
  // scenario 2 variable for roof_area
  let new_ind2: any;
  let solarUptilA2: any;
  // scenario 3 variable for electricity_consumption
  let combocost: any;
  // variable to store current renovation and unit_pv
  let currentMinusPv: any;
  // duplicate to get single renovations record
  let duplicateSingleRenovations =
    allRecords && allRecords?.length !== 0 ? [...allRecords] : [];
  let singleRenovations = duplicateSingleRenovations.splice(1, 10);
  // subtracting current renovation - unit_pv
  currentMinusPv =
    parseFloat(epcLabelWithout1[0]?.epc_ind) -
    parseFloat(epcLabelWithout1[epcLabelWithout1?.length - 1]?.epc_ind);
  currentMinusPv = currentMinusPv * 1.65 * 1.085 * number_of_solars;
  console.log("currentMinusPv", currentMinusPv);
  // scenario 1 for energy_score
  epcLabelWithout1?.map((item: any, index: any) => {
    new_ind = Math.round(item?.epc_ind);
    solarUptilA = new_ind - currentMinusPv;
    solarUptilA = Math.round(solarUptilA);
    // filtering to compare and get actual investment cost
    let totalInvestment = 0;
    singleRenovations?.filter(
      (content: any) =>
        item?.renovation?.split("_")?.some((key: any) => {
          if (content?.renovation === key) {
            if (content?.investment_cost === null) {
              delete epcLabelWithout1[index];
            } else if (parseInt(content?.investment_cost) === 0) {
              delete epcLabelWithout1[index];
            } else {
              totalInvestment =
                totalInvestment + Math.round(content?.investment_cost);
            }
          }
        })
      // wall_roof => solo prices 0
    );
    return (combocost = 200 * solarUptilA + totalInvestment);
  });
  // filtering to remove empty objects
  let removingNullRenovations = epcLabelWithout1.filter((x) => x !== null);
  //python logic for call 1
  let python1Renovations: any;

  if (
    renovationRefinements?.length == 0 ||
    renovationRefinements == undefined
  ) {
    python1Renovations = pythonLogic1(
      callZeroRecord?.data[0],
      removingNullRenovations
    );
  } else {
    python1Renovations = pythonLogic1AfterCall2Call3(
      renovationRefinements,
      removingNullRenovations,
      callZeroRecord?.data[0]
    );
  }
  //
  // let pythonMinus1Renovations: any;
  // if (
  //   renovationRefinements?.length !== 0 &&
  //   renovationRefinements !== undefined
  // ) {
  //   pythonMinus1Renovations = pythonLogicMinus1AfterCall2Call3(
  //     renovationRefinements,
  //     python1Renovations,
  //     callZeroRecord?.data[0]
  //   );
  // } else {
  //   pythonMinus1Renovations = pythonLogicMinus1(
  //     callZeroRecord?.data[0],
  //     python1Renovations
  //   );
  // }

  let filteredRenovations = [];
  // scenario 2 roof_area
  let num_solar2: any = 1;
  let duplicate_glazing: any = [];
  filteredRenovations = python1Renovations?.filter((item: any) => {
    return item?.renovation !== "current situation";
  });
  filteredRenovations = filteredRenovations?.filter((item: any) => {
    return item?.renovation !== "unit_pv";
  });
  let deletedRenovations: any = [];
  filteredRenovations?.map((item: any, index: any) => {
    new_ind2 = Math.round(item?.epc_ind);
    solarUptilA2 = Math.round(new_ind2);
    let epc_indCombo = new_ind2 - currentMinusPv;
    if (epc_indCombo > 100) {
      deletedRenovations?.push(item);
    }
    // if (parseInt(totalSolarRoofArea) > roof_area) {
    //   deletedRenovations?.push(item);
    // }
    // remaing records compare with electricity
    else {
      // if (
      //   renovationRefinements?.solar?.number_panels &&
      //   parseInt(renovationRefinements?.solar?.number_panels) > 0
      // ) {
      //   console.log("without solar")
      // }
      // let pv_prod = 159;
      if (
        parseInt(item?.epc_ind) > 100 &&
        !parseInt(renovationRefinements?.solar?.number_panels)
      ) {
        if (item?.renovation.includes("windowsDoubleAndDoors")) {
          // creating new object and replacing windowsDoubleAndDoors name with glazing
          let singleRenovation: any = {
            ...item,
            // total yearly savings and co2 savings
            yearly_savings:
              Math.round(item?.yearly_savings) +
              Math.round(
                epcLabelWithout1[epcLabelWithout1?.length - 1]?.yearly_savings
              ) *
                solar_yearly_saving,
            co2_savings:
              Math.round(item?.co2_savings) +
              Math.round(
                epcLabelWithout1[epcLabelWithout1?.length - 1]?.co2_savings
              ) *
                solar_co2_saving,
            number_solar: number_of_solars,
            // solar values
            solar_subsidy: solar_subsidy_cost,
            solar_co2_saving: solar_co2_saving,
            solar_yearly_saving: solar_yearly_saving,
            solar_invsetment_cost: solar_invsetment_cost,
            epc_ind: epc_indCombo,
            //
            glazing_investment_cost: parseInt(item.window_investment_cost) / 2,
            renovation:
              item?.renovation.replace("windowsDoubleAndDoors", "glazing") +
              "_solar",
          };
          // creating new array
          duplicate_glazing?.push(
            { ...singleRenovation },
            {
              ...item,
              // total yearly savings and co2 savings
              yearly_savings:
                Math.round(item?.yearly_savings) +
                Math.round(
                  epcLabelWithout1[epcLabelWithout1?.length - 1]?.yearly_savings
                ) *
                  solar_yearly_saving,
              co2_savings:
                Math.round(item?.co2_savings) +
                Math.round(
                  epcLabelWithout1[epcLabelWithout1?.length - 1]?.co2_savings
                ) *
                  solar_co2_saving,
              number_solar: number_of_solars,
              // solar values
              solar_subsidy: solar_subsidy_cost,
              solar_co2_saving: solar_co2_saving,
              solar_yearly_saving: solar_yearly_saving,
              solar_invsetment_cost: solar_invsetment_cost,
              epc_ind: epc_indCombo,
              //
              renovation: item?.renovation + "_solar",
              glazing_investment_cost:
                parseInt(item.window_investment_cost) / 2,
            },
            {
              ...item,
              renovation: item?.renovation,
              glazing_investment_cost:
                parseInt(item.window_investment_cost) / 2,
              // solar values
              solar_subsidy: solar_subsidy_cost,
              solar_co2_saving: solar_co2_saving,
              solar_yearly_saving: solar_yearly_saving,
              solar_invsetment_cost: solar_invsetment_cost,
              epc_ind: epc_indCombo,
              //
            }
          );
        } else {
          let singleRenovation: any = {
            ...item,
            // total yearly savings and co2 savings
            yearly_savings:
              Math.round(item?.yearly_savings) +
              Math.round(
                epcLabelWithout1[epcLabelWithout1?.length - 1]?.yearly_savings
              ) *
                solar_yearly_saving,
            co2_savings:
              Math.round(item?.co2_savings) +
              Math.round(
                epcLabelWithout1[epcLabelWithout1?.length - 1]?.co2_savings
              ) *
                solar_co2_saving,
            number_solar: number_of_solars,
            // solar values
            solar_subsidy: solar_subsidy_cost,
            solar_co2_saving: solar_co2_saving,
            solar_yearly_saving: solar_yearly_saving,
            solar_invsetment_cost: solar_invsetment_cost,
            epc_ind: epc_indCombo,
            //
            glazing_investment_cost: parseInt(item.window_investment_cost) / 2,
            renovation: item?.renovation + "_solar",
          };
          // creating new array
          duplicate_glazing?.push(
            { ...singleRenovation },
            {
              ...item,
              number_solar: number_of_solars,
              // solar values
              solar_subsidy: solar_subsidy_cost,
              solar_co2_saving: solar_co2_saving,
              solar_yearly_saving: solar_yearly_saving,
              solar_invsetment_cost: solar_invsetment_cost,
              //
              glazing_investment_cost:
                parseInt(item.window_investment_cost) / 2,
              renovation: item?.renovation,
              epc_ind: epc_indCombo,
            }
          );
        }
      } else if (parseInt(item?.epc_ind) < 100) {
        // creating new array
        duplicate_glazing?.push({
          ...item,
          glazing_investment_cost: parseInt(item.window_investment_cost) / 2,
          renovation: item?.renovation,
          // solar values
          solar_subsidy: solar_subsidy_cost,
          solar_co2_saving: solar_co2_saving,
          solar_yearly_saving: solar_yearly_saving,
          solar_invsetment_cost: solar_invsetment_cost,
          //
        });
      }
    }
  });
  let allRenovations: any = [...duplicate_glazing];

  //   removing null objects
  let removedNullRenovation = allRenovations.filter(function (el: any) {
    return el != null;
  });

  // sort to get lowest investment cost
  let sortedGlazingSolarRenovations = removedNullRenovation?.sort(
    (a: any, b: any): any => {
      return (
        parseInt(a?.investment_cost) -
        parseInt(a?.door_investment_cost) -
        parseInt(a?.total_subsidy) -
        (parseInt(b?.investment_cost) -
          parseInt(b?.door_investment_cost) -
          parseInt(b?.total_subsidy))
      );
    }
  );
  // applying logic now for <= 100
  // to remove all records which contains epc 1
  let epcLabelWith1 = [...sortedGlazingSolarRenovations].filter(
    (content: any) => {
      return parseInt(content?.epc_ind) <= 100;
    }
  );
  let glazingRenovation: any = [];
  epcLabelWith1?.map((item: any) => {
    if (item?.renovation.includes("windowsDoubleAndDoors")) {
      let singleRenovation: any;
      // creating new object and replacing windowsDoubleAndDoors name with glazing
      singleRenovation = {
        ...item,
        glazing_investment_cost: parseInt(item.window_investment_cost) / 2,
        renovation: item?.renovation.replace(
          "windowsDoubleAndDoors",
          "glazing"
        ),
      };
      // creating new array
      glazingRenovation?.push(singleRenovation);
    } else {
      glazingRenovation?.push(item);
    }
  });
  // pv_production * 1,65 * 190/175
  // 	co2 savings unit pv * 190/175 * 1,65
  // 	yearly_savings * 190/175
  let sortedGlazingRenovations = glazingRenovation?.sort(
    (a: any, b: any): any => {
      return (
        parseInt(a?.investment_cost) -
        parseInt(a?.door_investment_cost) -
        parseInt(a?.total_subsidy) -
        (parseInt(b?.investment_cost) -
          parseInt(b?.door_investment_cost) -
          parseInt(b?.total_subsidy))
      );
    }
  );

  let newCombonation = [
    sortedGlazingSolarRenovations[0],
    sortedGlazingRenovations[0],
  ];
  let comboinationToPackA = newCombonation?.sort((a: any, b: any): any => {
    return (
      parseInt(a?.investment_cost) -
      parseInt(a?.door_investment_cost) -
      parseInt(a?.total_subsidy) -
      (parseInt(b?.investment_cost) -
        parseInt(b?.door_investment_cost) -
        parseInt(b?.total_subsidy))
    );
  });
  if (comboinationToPackA[0]) {
    return comboinationToPackA;
  }
  return [];
};

// pack B
export const renovationPostProcessingForPackB = (
  allRecords: any,
  renovationRefinements: any,
  callZeroRecord?: any
) => {
  // to remove all records which contains epc 1
  let epcLabelWithout1 =
    allRecords && allRecords?.length !== 0 ? [...allRecords] : [];
  // solar subsidy calculations
  // logic for solar calculations
  let solar_co2_saving: any = solarPriceHandler(
    callZeroRecord?.data[0]
  ).solar_co2_saving;
  let solar_yearly_saving: any = solarPriceHandler(
    callZeroRecord?.data[0]
  ).solar_yearly_saving;
  let solar_invsetment_cost: any = solarPriceHandler(
    callZeroRecord?.data[0]
  ).solar_invsetment_cost;
  let solar_subsidy_cost: any = solarPriceHandler(
    callZeroRecord?.data[0]
  ).solar_subsidy_cost;
  let number_of_solars: any = solarPriceHandler(
    callZeroRecord?.data[0]
  ).solarPanels;

  let roof_area = callZeroRecord?.data[0].roof_area;
  // let roof_area = houseRenovations.roof_area;
  //   let roof_area = houseRenovations[0]?.data?.roof_area;
  // scenario 1 variable for energy_score
  let new_ind: any;
  let solarUptilA: any;
  // scenario 2 variable for roof_area
  let new_ind2: any;
  let solarUptilA2: any;
  // scenario 3 variable for electricity_consumption
  let combocost: any;
  // variable to store current renovation and unit_pv
  let currentMinusPv: any;
  // duplicate to get single renovations record
  let duplicateSingleRenovations =
    allRecords && allRecords?.length !== 0 ? [...allRecords] : [];
  let singleRenovations = duplicateSingleRenovations.splice(1, 10);
  // subtracting current renovation - unit_pv

  currentMinusPv =
    parseFloat(epcLabelWithout1[0]?.epc_ind) -
    parseFloat(epcLabelWithout1[epcLabelWithout1?.length - 1]?.epc_ind);
  currentMinusPv = currentMinusPv * 1.65 * 1.085 * number_of_solars;

  // scenario 1 for energy_score
  epcLabelWithout1?.map((item: any, index: any) => {
    new_ind = Math.round(item?.epc_ind);
    solarUptilA = new_ind - currentMinusPv;
    solarUptilA = Math.round(solarUptilA);
    // filtering to compare and get actual investment cost
    let totalInvestment = 0;
    singleRenovations?.filter(
      (content: any) =>
        item?.renovation?.split("_")?.some((key: any) => {
          if (content?.renovation === key) {
            if (content?.investment_cost === null) {
              delete epcLabelWithout1[index];
            } else if (parseInt(content?.investment_cost) === 0) {
              delete epcLabelWithout1[index];
            } else {
              totalInvestment =
                totalInvestment + Math.round(content?.investment_cost);
            }
          }
        })
      // wall_roof => solo prices 0
    );
    return (combocost = 200 * solarUptilA + totalInvestment);
  });
  // filtering to remove empty objects
  let removingNullRenovations = epcLabelWithout1.filter((x) => x !== null);
  // let removing less than 100
  // let removingLessThan = removingNullRenovations?.filter((item: any) => {
  //   return parseInt(item?.epc_ind) > 200;
  // });
  // call 1
  // let python1Renovations = pythonLogic1(
  //   callZeroRecord?.data[0],
  //   removingLessThan
  // );
  let python1Renovations: any;
  if (
    renovationRefinements?.length == 0 ||
    renovationRefinements == undefined
  ) {
    python1Renovations = pythonLogic1(
      callZeroRecord?.data[0],
      removingNullRenovations
    );
  } else {
    python1Renovations = pythonLogic1AfterCall2Call3(
      renovationRefinements,
      removingNullRenovations,
      callZeroRecord?.data[0]
    );
  }

  let filteredRenovations = [];
  // scenario 2 roof_area
  let num_solar2: any = 1;
  let duplicate_glazing: any = [];
  filteredRenovations = python1Renovations?.filter((item: any) => {
    return item?.renovation !== "current situation";
  });

  filteredRenovations = filteredRenovations?.filter((item: any) => {
    return item?.renovation !== "unit_pv";
  });
  let deletedRenovations: any = [];
  filteredRenovations?.map((item: any, index: any) => {
    new_ind2 = Math.round(item?.epc_ind);
    solarUptilA2 = parseInt(new_ind2);
    if (solarUptilA2 > 200) {
      // for (let i = 1; solarUptilA2 >= 200; i++) {
      //   solarUptilA2 = solarUptilA2 - currentMinusPv;
      //   num_solar2 = i;
      // }
    }
    // else {
    //   num_solar2 = 1;
    // }
    // solarUptilA2 is greater than roof_area (130) remove renovation
    // let totalSolarRoofArea: any = num_solar2 * 1.65;
    if (new_ind2 - currentMinusPv > 200) {
      deletedRenovations?.push(item);
    }
    // remaing records compare with electricity
    else {
      if (
        parseInt(item?.epc_ind) > 200 &&
        !parseInt(renovationRefinements?.solar?.number_panels)
      ) {
        if (item?.renovation.includes("windowsDoubleAndDoors")) {
          // creating new object and replacing windowsDoubleAndDoors name with glazing
          let singleRenovation: any = {
            ...item,
            // total yearly savings and co2 savings
            yearly_savings:
              Math.round(item?.yearly_savings) +
              Math.round(
                epcLabelWithout1[epcLabelWithout1?.length - 1]?.yearly_savings
              ) *
                solar_yearly_saving,
            co2_savings:
              Math.round(item?.co2_savings) +
              Math.round(
                epcLabelWithout1[epcLabelWithout1?.length - 1]?.co2_savings
              ) *
                solar_co2_saving,
            //
            // solar values
            solar_subsidy: solar_subsidy_cost,
            solar_co2_saving: solar_co2_saving,
            solar_yearly_saving: solar_yearly_saving,
            solar_invsetment_cost: solar_invsetment_cost,
            //
            glazing_investment_cost: parseInt(item.window_investment_cost) / 2,
            renovation:
              item?.renovation.replace("windowsDoubleAndDoors", "glazing") +
              "_solar",
          };
          // creating new array wibow-
          // 1 window => glazing_wall_roof_solar
          // 2 wall_roof_solar
          // 3 window_wall_roof_solar
          //
          duplicate_glazing?.push(
            { ...singleRenovation },
            {
              ...item,
              // total yearly savings and co2 savings
              yearly_savings:
                Math.round(item?.yearly_savings) +
                Math.round(
                  epcLabelWithout1[epcLabelWithout1?.length - 1]?.yearly_savings
                ) *
                  solar_yearly_saving,
              co2_savings:
                Math.round(item?.co2_savings) +
                Math.round(
                  epcLabelWithout1[epcLabelWithout1?.length - 1]?.co2_savings
                ) *
                  solar_co2_saving,
              number_solar: number_of_solars,
              //
              renovation: item?.renovation + "_solar",
              glazing_investment_cost:
                parseInt(item.window_investment_cost) / 2,
              // solar values
              solar_subsidy: solar_subsidy_cost,
              solar_co2_saving: solar_co2_saving,
              solar_yearly_saving: solar_yearly_saving,
              solar_invsetment_cost: solar_invsetment_cost,
              //
            },
            {
              ...item,
              renovation: item?.renovation,
              glazing_investment_cost:
                parseInt(item.window_investment_cost) / 2,
              // solar values
              solar_subsidy: solar_subsidy_cost,
              solar_co2_saving: solar_co2_saving,
              solar_yearly_saving: solar_yearly_saving,
              solar_invsetment_cost: solar_invsetment_cost,
              //
            }
          );
        } else {
          let singleRenovation: any = {
            ...item,
            // total yearly savings and co2 savings
            yearly_savings:
              Math.round(item?.yearly_savings) +
              Math.round(
                epcLabelWithout1[epcLabelWithout1?.length - 1]?.yearly_savings
              ) *
                solar_yearly_saving,
            co2_savings:
              Math.round(item?.co2_savings) +
              Math.round(
                epcLabelWithout1[epcLabelWithout1?.length - 1]?.co2_savings
              ) *
                solar_co2_saving,
            number_solar: number_of_solars,
            //
            // solar values
            solar_subsidy: solar_subsidy_cost,
            solar_co2_saving: solar_co2_saving,
            solar_yearly_saving: solar_yearly_saving,
            solar_invsetment_cost: solar_invsetment_cost,
            //
            glazing_investment_cost: parseInt(item.window_investment_cost) / 2,
            renovation: item?.renovation + "_solar",
          };
          // creating new array
          duplicate_glazing?.push(
            { ...singleRenovation },
            {
              ...item,
              // solar values
              solar_subsidy: solar_subsidy_cost,
              solar_co2_saving: solar_co2_saving,
              solar_yearly_saving: solar_yearly_saving,
              solar_invsetment_cost: solar_invsetment_cost,
              //
              glazing_investment_cost:
                parseInt(item.window_investment_cost) / 2,
              renovation: item?.renovation,
            }
          );
        }
      } else if (
        parseInt(item?.epc_ind) < 200 &&
        parseInt(item?.epc_ind) > 100
      ) {
        duplicate_glazing?.push({
          ...item,
          glazing_investment_cost: parseInt(item.window_investment_cost) / 2,
          renovation: item?.renovation,
          // solar values
          solar_subsidy: solar_subsidy_cost,
          solar_co2_saving: solar_co2_saving,
          solar_yearly_saving: solar_yearly_saving,
          solar_invsetment_cost: solar_invsetment_cost,
          //
        });
      }
    }
  });

  let allRenovations: any = [...duplicate_glazing];
  //   removing null objects
  let removedNullRenovation = allRenovations.filter(function (el: any) {
    return el != null;
  });
  // sort to get lowest investment cost
  let sortedGlazingSolarRenovations = removedNullRenovation?.sort(
    (a: any, b: any): any => {
      return (
        parseInt(a?.investment_cost) -
        parseInt(a?.door_investment_cost) -
        parseInt(a?.total_subsidy) -
        (parseInt(b?.investment_cost) -
          parseInt(b?.door_investment_cost) -
          parseInt(b?.total_subsidy))
      );
    }
  );
  // applying logic now for <= 100
  // to remove all records which contains epc 1
  let epcLabelWith1 = [...sortedGlazingSolarRenovations].filter(
    (content: any) => {
      return (
        parseInt(content?.epc_ind) >= 100 && parseInt(content?.epc_ind) < 200
      );
    }
  );
  let glazingRenovation: any = [];
  epcLabelWith1?.map((item: any) => {
    if (item?.renovation.includes("windowsDoubleAndDoors")) {
      let singleRenovation: any;
      // creating new object and replacing windowsDoubleAndDoors name with glazing
      singleRenovation = {
        ...item,
        glazing_investment_cost: parseInt(item.window_investment_cost) / 2,
        renovation: item?.renovation.replace(
          "windowsDoubleAndDoors",
          "glazing"
        ),
      };
      // creating new array
      glazingRenovation?.push(singleRenovation);
    } else {
      glazingRenovation?.push(item);
    }
  });
  // pv_production * 1,65 * 190/175
  // 	co2 savings unit pv * 190/175 * 1,65
  // 	yearly_savings * 190/175
  let sortedGlazingRenovations = glazingRenovation?.sort(
    (a: any, b: any): any => {
      return (
        parseInt(a?.investment_cost) -
        parseInt(a?.door_investment_cost) -
        parseInt(a?.total_subsidy) -
        (parseInt(b?.investment_cost) -
          parseInt(b?.door_investment_cost) -
          parseInt(b?.total_subsidy))
      );
    }
  );

  let newCombonation = [
    sortedGlazingSolarRenovations[0],
    sortedGlazingRenovations[0],
  ];
  let comboinationToPackA = newCombonation?.sort((a: any, b: any): any => {
    return (
      parseInt(a?.investment_cost) -
      parseInt(a?.door_investment_cost) -
      parseInt(a?.total_subsidy) -
      (parseInt(b?.investment_cost) -
        parseInt(b?.door_investment_cost) -
        parseInt(b?.total_subsidy))
    );
  });

  return comboinationToPackA;
};

// method for to set investment prices of selected combination
export const renovationsInvestmentPrice = (records: any, keys: any) => {
  records?.map((item: any, index: any) => {
    if (item?.renovation === "windowsDoubleAndDoors") {
      return (records[index].investment_cost = keys?.window_investment_cost);
    }
    if (item?.renovation === "wallCav") {
      return (records[index].investment_cost = keys?.wall_investment_cost);
    }
    if (item?.renovation === "wallExt") {
      return (records[index].investment_cost = keys?.wall_investment_cost);
    }
    if (item?.renovation === "roofAttic") {
      return (records[index].investment_cost = keys?.roof_investment_cost);
    }
    if (item?.renovation === "roofIn") {
      return (records[index].investment_cost = keys?.roof_investment_cost);
    }
    if (item?.renovation === "roofExt") {
      return (records[index].investment_cost = keys?.roof_investment_cost);
    }
    if (item?.renovation === "ventC+") {
      return (records[index].investment_cost =
        keys?.ventilation_investment_cost);
    }
    if (item?.renovation === "ventD") {
      return (records[index].investment_cost =
        keys?.ventilation_investment_cost);
    }
    if (item?.renovation === "condGas") {
      return (records[index].investment_cost = keys?.heating_investment_cost);
    }
    if (item?.renovation === "heatPump") {
      return (records[index].investment_cost = keys?.heating_investment_cost);
    }
    if (item?.renovation === "solar") {
      return (records[index].investment_cost = keys?.solar_invsetment_cost);
    }
    if (item?.renovation === "glazing") {
      return (records[index].investment_cost = keys?.glazing_investment_cost);
    }
  });
  return records;
};

// post processing logic for pack A
export const customSolarRenovationPostProcessing = (
  allRecords: any,
  renovationRefinements: any,
  callZeroRecord?: any
) => {
  // to remove all records which contains epc 1
  let epcLabelWithout1 = [...allRecords];
  // solar subsidy calculations
  // logic for solar calculations
  let solar_co2_saving: any = solarPriceHandler(
    callZeroRecord?.data[0]
  ).solar_co2_saving;
  let solar_yearly_saving: any = solarPriceHandler(
    callZeroRecord?.data[0]
  ).solar_yearly_saving;
  let solar_invsetment_cost: any = solarPriceHandler(
    callZeroRecord?.data[0]
  ).solar_invsetment_cost;
  let solar_subsidy_cost: any = solarPriceHandler(
    callZeroRecord?.data[0]
  ).solar_subsidy_cost;
  let number_of_solars: any = solarPriceHandler(
    callZeroRecord?.data[0]
  ).solarPanels;

  let roof_area = callZeroRecord?.data[0]?.roof_area;
  //   let roof_area = houseRenovations[0]?.data?.roof_area;
  // scenario 1 variable for energy_score
  let new_ind: any;
  let solarUptilA: any;
  // scenario 2 variable for roof_area
  let new_ind2: any;
  let solarUptilA2: any;
  // scenario 3 variable for electricity_consumption
  let combocost: any;
  // variable to store current renovation and unit_pv
  let currentMinusPv: any;
  // duplicate to get single renovations record
  let duplicateSingleRenovations =
    allRecords && allRecords?.length !== 0 ? [...allRecords] : [];
  let singleRenovations = duplicateSingleRenovations.splice(1, 10);
  // subtracting current renovation - unit_pv
  currentMinusPv =
    parseFloat(epcLabelWithout1[0]?.epc_ind) -
    parseFloat(epcLabelWithout1[epcLabelWithout1?.length - 1]?.epc_ind);
  currentMinusPv = currentMinusPv * 1.65 * 1.085 * number_of_solars;
  // scenario 1 for energy_score
  epcLabelWithout1?.map((item: any, index: any) => {
    new_ind = Math.round(item?.epc_ind);
    solarUptilA = new_ind - currentMinusPv;
    solarUptilA = Math.round(solarUptilA);
    // filtering to compare and get actual investment cost
    let totalInvestment = 0;
    singleRenovations?.filter(
      (content: any) =>
        item?.renovation?.split("_")?.some((key: any) => {
          if (content?.renovation === key) {
            if (content?.investment_cost === null) {
              delete epcLabelWithout1[index];
            } else if (parseInt(content?.investment_cost) === 0) {
              delete epcLabelWithout1[index];
            } else {
              totalInvestment =
                totalInvestment + Math.round(content?.investment_cost);
            }
          }
        })
      // wall_roof => solo prices 0
    );
    return (combocost = 200 * solarUptilA + totalInvestment);
  });
  // filtering to remove empty objects
  let removingNullRenovations = epcLabelWithout1.filter((x) => x !== null);
  // call 1
  let python1Renovations: any;
  let duplicateRenovationWithout1 = [...removingNullRenovations];
  if (
    renovationRefinements?.length == 0 ||
    renovationRefinements == undefined
  ) {
    python1Renovations = pythonLogic1(
      callZeroRecord?.data[0],
      duplicateRenovationWithout1
    );
  } else {
    python1Renovations = pythonLogic1AfterCall2Call3(
      renovationRefinements,
      duplicateRenovationWithout1,
      callZeroRecord?.data[0]
    );
  }
  // let pythonMinus1Renovations = pythonLogicMinus1(
  //   callZeroRecord?.data[0],
  //   python1Renovations
  // );

  let filteredRenovations = [];
  // let filteredRenovations = pythonMinus1Renovations;
  // scenario 2 roof_area
  let num_solar2: any = 1;
  let duplicate_glazing: any = [];
  let withoutSolarArray: any = [];

  filteredRenovations = removingNullRenovations?.filter((item: any) => {
    return item?.renovation !== "current situation";
  });
  filteredRenovations = filteredRenovations?.filter((item: any) => {
    return item?.renovation !== "unit_pv";
  });
  let solar_ind = soloSolarEpcIndValue(
    parseFloat(epcLabelWithout1[0]?.epc_ind),
    parseFloat(epcLabelWithout1[epcLabelWithout1?.length - 1]?.epc_ind),
    number_of_solars
  );
  let deletedRenovations: any = [];
  filteredRenovations?.map((item: any, index: any) => {
    new_ind2 = Math.round(item?.epc_ind);
    solarUptilA2 = Math.round(new_ind2);
    // if (solarUptilA2 > 100) {
    //   for (let i = 1; solarUptilA2 >= 100; i++) {
    //     solarUptilA2 = solarUptilA2 - currentMinusPv;
    //     num_solar2 = i;
    //   }
    // } else {
    //   num_solar2 = 1;
    // }

    // solarUptilA2 is greater than roof_area (130) remove renovation
    // let totalSolarRoofArea: any = num_solar2 * 1.65;
    // if (parseInt(totalSolarRoofArea) > roof_area) {
    //   withoutSolarArray?.push({
    //     ...item,
    //     renovation: item?.renovation,
    //     solar_co2_saving:
    //       Math.round(
    //         epcLabelWithout1[epcLabelWithout1?.length - 1]?.co2_savings
    //       ) *
    //       num_solar2 *
    //       1.65 *
    //       1.085,
    //     glazing_investment_cost: parseInt(item.window_investment_cost) / 2,
    //     solar_yearly_saving:
    //       Math.round(
    //         epcLabelWithout1[epcLabelWithout1?.length - 1]?.yearly_savings
    //       ) *
    //       num_solar2 *
    //       1.65 *
    //       1.085,
    //     solar_invsetment_cost: num_solar2 * 200 * 1.65,
    //     solar_subsidy: solar_subsidy_cost,
    //   });
    // }
    let epc_indCombo = new_ind2 - currentMinusPv;
    if (epc_indCombo > 100) {
      let solarCombination = {
        ...item,
        renovation: item?.renovation + "_solar",
        glazing_investment_cost: parseInt(item.window_investment_cost) / 2,
        solar_subsidy: solar_subsidy_cost,
        solar_co2_saving: solar_co2_saving,
        solar_yearly_saving: solar_yearly_saving,
        solar_invsetment_cost: solar_invsetment_cost,
        epc_ind: epc_indCombo,
        yearly_savings:
          Math.round(item?.yearly_savings) +
          Math.round(
            epcLabelWithout1[epcLabelWithout1?.length - 1]?.yearly_savings
          ) *
            solar_yearly_saving,
        co2_savings:
          Math.round(item?.co2_savings) +
          Math.round(
            epcLabelWithout1[epcLabelWithout1?.length - 1]?.co2_savings
          ) *
            solar_co2_saving,
        number_of_solars: number_of_solars,
      };
      withoutSolarArray?.push(
        { ...solarCombination },
        {
          ...item,
          renovation: item?.renovation,
          glazing_investment_cost: parseInt(item.window_investment_cost) / 2,
          solar_subsidy: solar_subsidy_cost,
          solar_co2_saving: solar_co2_saving,
          solar_yearly_saving: solar_yearly_saving,
          solar_invsetment_cost: solar_invsetment_cost,
          // epc_ind: epc_indCombo,
          yearly_savings:
            Math.round(item?.yearly_savings) +
            Math.round(
              epcLabelWithout1[epcLabelWithout1?.length - 1]?.yearly_savings
            ) *
              solar_yearly_saving,
          co2_savings:
            Math.round(item?.co2_savings) +
            Math.round(
              epcLabelWithout1[epcLabelWithout1?.length - 1]?.co2_savings
            ) *
              solar_co2_saving,
          number_of_solars: number_of_solars,
        }
      );
    }
    // remaing records compare with electricity
    else {
      let pv_prod = 159;
      /*let totalElectricity_consumptionValue =
       num_solar2 * pv_prod * 1.65 * 1.085;
        // remove record if value is greater then electricity_consumption
        if (totalElectricity_consumptionValue > item?.electricity_consumption) {
          delete filteredRenovations[index];
        } else {*/
      // wall_roof_solar
      // wall_roof
      if (
        parseInt(item?.epc_ind) > 100 &&
        !parseInt(renovationRefinements?.solar?.number_panels)
      ) {
        if (item?.renovation.includes("windowsDoubleAndDoors")) {
          // creating new object and replacing windowsDoubleAndDoors name with glazing
          let singleRenovation: any = {
            ...item,
            // total yearly savings and co2 savings
            // total yearly savings and co2 savings
            yearly_savings:
              Math.round(item?.yearly_savings) +
              Math.round(
                epcLabelWithout1[epcLabelWithout1?.length - 1]?.yearly_savings
              ) *
                solar_yearly_saving,
            co2_savings:
              Math.round(item?.co2_savings) +
              Math.round(
                epcLabelWithout1[epcLabelWithout1?.length - 1]?.co2_savings
              ) *
                solar_co2_saving,
            number_solar: number_of_solars,
            epc_ind: epc_indCombo,
            // epc_label: "1",
            //
            // solar values
            solar_subsidy: solar_subsidy_cost,
            solar_co2_saving: solar_co2_saving,
            solar_yearly_saving: solar_yearly_saving,
            solar_invsetment_cost: solar_invsetment_cost,
            //
            glazing_investment_cost: parseInt(item.window_investment_cost) / 2,
            renovation:
              item?.renovation.replace("windowsDoubleAndDoors", "glazing") +
              "_solar",
          };
          // creating new array
          duplicate_glazing?.push(
            { ...singleRenovation },
            {
              ...item,
              // total yearly savings and co2 savings
              yearly_savings:
                Math.round(item?.yearly_savings) +
                Math.round(
                  epcLabelWithout1[epcLabelWithout1?.length - 1]?.yearly_savings
                ) *
                  solar_yearly_saving,
              co2_savings:
                Math.round(item?.co2_savings) +
                Math.round(
                  epcLabelWithout1[epcLabelWithout1?.length - 1]?.co2_savings
                ) *
                  solar_co2_saving,
              number_solar: number_of_solars,
              epc_ind: epc_indCombo,
              //
              renovation: item?.renovation + "_solar",
              glazing_investment_cost:
                parseInt(item.window_investment_cost) / 2,
              // solar values
              solar_subsidy: solar_subsidy_cost,
              solar_co2_saving: solar_co2_saving,
              solar_yearly_saving: solar_yearly_saving,
              solar_invsetment_cost: solar_invsetment_cost,
              //
            },
            {
              ...item,
              renovation: item?.renovation,
              glazing_investment_cost:
                parseInt(item.window_investment_cost) / 2,
              // solar values
              solar_subsidy: solar_subsidy_cost,
              solar_co2_saving: solar_co2_saving,
              solar_yearly_saving: solar_yearly_saving,
              solar_invsetment_cost: solar_invsetment_cost,
              // epc_ind: epc_indCombo,
              //
            },
            {
              ...item,
              // total yearly savings and co2 savings
              yearly_savings:
                Math.round(
                  epcLabelWithout1[epcLabelWithout1?.length - 1]?.yearly_savings
                ) * solar_yearly_saving,
              co2_savings:
                Math.round(
                  epcLabelWithout1[epcLabelWithout1?.length - 1]?.co2_savings
                ) * solar_co2_saving,
              number_solar: number_of_solars,
              epc_ind: solar_ind,
              //
              renovation: "solar",
              glazing_investment_cost:
                parseInt(item.window_investment_cost) / 2,
              // solar values
              solar_subsidy: solar_subsidy_cost,
              solar_co2_saving: solar_co2_saving,
              solar_yearly_saving: solar_yearly_saving,
              solar_invsetment_cost: solar_invsetment_cost,
              //
            }
          );
        } else {
          let singleRenovation: any = {
            ...item,
            // total yearly savings and co2 savings
            yearly_savings:
              Math.round(item?.yearly_savings) +
              Math.round(
                epcLabelWithout1[epcLabelWithout1?.length - 1]?.yearly_savings
              ) *
                solar_yearly_saving,
            co2_savings:
              Math.round(item?.co2_savings) +
              Math.round(
                epcLabelWithout1[epcLabelWithout1?.length - 1]?.co2_savings
              ) *
                solar_co2_saving,
            number_solar: number_of_solars,
            epc_ind: epc_indCombo,
            // epc_label: "1",
            // solar values
            solar_subsidy: solar_subsidy_cost,
            solar_co2_saving: solar_co2_saving,
            solar_yearly_saving: solar_yearly_saving,
            solar_invsetment_cost: solar_invsetment_cost,
            //
            glazing_investment_cost: parseInt(item.window_investment_cost) / 2,
            renovation: item?.renovation + "_solar",
          };
          // creating new array
          duplicate_glazing?.push(
            { ...singleRenovation },
            {
              ...item,
              glazing_investment_cost:
                parseInt(item.window_investment_cost) / 2,
              renovation: item?.renovation,
              // epc_ind: epc_indCombo,
              // solar values
              solar_subsidy: solar_subsidy_cost,
              solar_co2_saving: solar_co2_saving,
              solar_yearly_saving: solar_yearly_saving,
              solar_invsetment_cost: solar_invsetment_cost,
              //
            },
            {
              ...item,
              // total yearly savings and co2 savings
              yearly_savings:
                Math.round(
                  epcLabelWithout1[epcLabelWithout1?.length - 1]?.yearly_savings
                ) * solar_yearly_saving,
              co2_savings:
                Math.round(
                  epcLabelWithout1[epcLabelWithout1?.length - 1]?.co2_savings
                ) * solar_co2_saving,
              number_solar: number_of_solars,
              epc_ind: solar_ind,
              // epc_label: "1",
              //
              // solar values
              solar_subsidy: solar_subsidy_cost,
              solar_co2_saving: solar_co2_saving,
              solar_yearly_saving: solar_yearly_saving,
              solar_invsetment_cost: solar_invsetment_cost,
              //
              glazing_investment_cost:
                parseInt(item.window_investment_cost) / 2,
              renovation: "solar",
            }
          );
        }
      } else if (parseInt(item?.epc_ind) < 100) {
        duplicate_glazing?.push({
          ...item,
          glazing_investment_cost: parseInt(item.window_investment_cost) / 2,
          renovation: item?.renovation,
          solar_subsidy: solar_subsidy_cost,
          solar_co2_saving: solar_co2_saving,
          solar_yearly_saving: solar_yearly_saving,
          solar_invsetment_cost: solar_invsetment_cost,
        });
      }
    }
  });

  let allRenovations: any = [...duplicate_glazing];
  //   removing null objects
  let removedNullRenovation = allRenovations.filter(function (el: any) {
    return el != null;
  });
  // sort to get lowest investment cost
  let sortedGlazingSolarRenovations = removedNullRenovation?.sort(
    (a: any, b: any): any => {
      return (
        parseInt(a?.investment_cost) -
        parseInt(a?.door_investment_cost) -
        parseInt(a?.total_subsidy) -
        (parseInt(b?.investment_cost) -
          parseInt(b?.door_investment_cost) -
          parseInt(b?.total_subsidy))
      );
    }
  );
  // applying logic now for <= 100
  // to remove all records which contains epc 1
  // let epcLabelWith1 = [...withoutSolarArray];
  let epcLabelWith1 = [...sortedGlazingSolarRenovations, ...withoutSolarArray];
  // let epcLabelWith1 = [...sortedGlazingSolarRenovations].filter(
  //   (content: any) => {
  //     return parseInt(content?.epc_ind) <= 100;
  //   }
  // );
  let glazingRenovation: any = [];
  epcLabelWith1?.map((item: any) => {
    if (item?.renovation.includes("windowsDoubleAndDoors")) {
      let singleRenovation: any;
      // creating new object and replacing windowsDoubleAndDoors name with glazing
      singleRenovation = {
        ...item,
        glazing_investment_cost: parseInt(item.window_investment_cost) / 2,
        renovation: item?.renovation.replace(
          "windowsDoubleAndDoors",
          "glazing"
        ),
      };
      // creating new array
      glazingRenovation?.push(singleRenovation);
    } else {
      glazingRenovation?.push(item);
    }
  });
  // pv_production * 1,65 * 190/175
  // 	co2 savings unit pv * 190/175 * 1,65
  // 	yearly_savings * 190/175
  let sortedGlazingRenovations = glazingRenovation?.sort(
    (a: any, b: any): any => {
      return (
        parseInt(a?.investment_cost) -
        parseInt(a?.door_investment_cost) -
        parseInt(a?.total_subsidy) -
        (parseInt(b?.investment_cost) -
          parseInt(b?.door_investment_cost) -
          parseInt(b?.total_subsidy))
      );
    }
  );

  let newCombonation = [
    ...sortedGlazingSolarRenovations,
    ...sortedGlazingRenovations,
  ];
  let comboinationToPackA = newCombonation?.sort((a: any, b: any): any => {
    return (
      parseInt(a?.investment_cost) -
      parseInt(a?.door_investment_cost) -
      parseInt(a?.total_subsidy) -
      (parseInt(b?.investment_cost) -
        parseInt(b?.door_investment_cost) -
        parseInt(b?.total_subsidy))
    );
  });
  // let finalCombination: any = [];
  // to check solar if number of panels is greter than 0 => remove solar
  // wall_condgas
  // if (
  //   renovationRefinements?.solar?.number_panels &&
  //   parseInt(renovationRefinements?.solar?.number_panels) > 0
  // ) {
  //   comboinationToPackA?.map((item: any) => {
  //     if (item?.renovation.includes("_solar")) {
  //       item?.renovation.replace("_solar", "");
  //       return finalCombination?.push({
  //         ...item,
  //         renovation: item?.renovation.replace("_solar", ""),
  //       });
  //     } else {
  //       return finalCombination?.push(item);
  //     }
  //   });
  // }
  // // if number of solars is 0
  // else {
  //   comboinationToPackA?.map((item: any) => {
  //     return finalCombination?.push(item);
  //   });
  // }
  // return finalCombination;
  return {
    solarPlusGlazingScenario: comboinationToPackA,
    // glazingScenario: sortedGlazingRenovations,
    withoutSolarArray: withoutSolarArray,
    python1Renovations: python1Renovations,
  };
};

// method to GetExists  renovation of user selected
export const existRenovationHandler = (
  allRecords: any,
  exitRenovation: any
) => {
  allRecords?.map((item: any) => {
    if (item.renovation === "windowsDoubleAndDoors") {
      exitRenovation?.push(item);
    }
    if (item.renovation === "wallExt") {
      exitRenovation?.push(item);
    }
    if (item.renovation === "wallCav") {
      exitRenovation?.push(item);
    }
    if (item.renovation === "roofExt") {
      exitRenovation?.push(item);
    }
    if (item.renovation === "roofIn") {
      exitRenovation?.push(item);
    }
    if (item.renovation === "roofAttic") {
      exitRenovation?.push(item);
    }
    if (item.renovation === "ventD") {
      exitRenovation?.push(item);
    }
    if (item.renovation === "ventC+") {
      exitRenovation?.push(item);
    }
    if (item.renovation === "heatPump") {
      exitRenovation?.push(item);
    }
    if (item.renovation === "condGas") {
      exitRenovation?.push(item);
    }
  });
  return exitRenovation;
};

export const solarPriceHandler = (houseDetails: any) => {
  let solarPanels = Math.round((houseDetails.roof_area * 40) / 100 / 1.65);
  if (solarPanels > 28) {
    solarPanels = 28;
  }
  const solar_co2_saving = Math.round(solarPanels * 1.65 * 1.085);
  const solar_yearly_saving = Math.round(solarPanels * 1.65 * 1.085);
  const solar_invsetment_cost = Math.round(solarPanels * 200 * 1.65);
  const kwp = Math.round(solarPanels * 1.65 * 0.19);
  let solar_subsidy_cost = 0;
  let difference_kwp = 0;
  let difference_total = 0;
  if (kwp <= 4) {
    solar_subsidy_cost = kwp * 300;
  } else {
    solar_subsidy_cost = 4 * 300;
    difference_kwp = kwp - 4;

    difference_total = difference_kwp * 150;
    solar_subsidy_cost = solar_subsidy_cost + difference_total;
    if (solar_subsidy_cost >= 1500) {
      solar_subsidy_cost = 1500;
    }
  }
  // const solar_subsidy_cost=
  return {
    solar_co2_saving: solar_co2_saving,
    solar_yearly_saving: solar_yearly_saving,
    solar_invsetment_cost: solar_invsetment_cost,
    solar_subsidy_cost: solar_subsidy_cost,
    solarPanels: solarPanels,
  };
};

export const pricesDotHandler = (price: any) => {
  console.log("price", price);
  return price === 0 || price === ""
    ? 0
    : price &&
        price
          .toFixed()
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};

/**
 * remove dot from the string
 * @param price is string value.
 * @returns if price exit return int value else retrun empty string
 */
export const removeDotHandler = (price: any) => {
  if (price) return parseInt(price.replace(".", ""));
  else return "";
};
