import React, { FC, useContext } from "react";
// import images
import Window from "../../../assets/images/renovates/window.jpg";
import Solar from "../../../assets/images/renovates/solarpannel.png";
import Daki from "../../../assets/images/renovates/daki.jpg";
import Murri from "../../../assets/images/renovates/murri.jpg";
import Warming from "../../../assets/images/renovates/warming.jpg";
import Venti from "../../../assets/images/renovates/ventilationdesign1.jpg";
import { getValue } from "../../../utils/getValue";
import { GlobalContext } from "../../../context/globalContext/globalContext";
// import components
interface RenovatesSideNavInterface {
  activeTab?: any;
  setActiveTab?: any;
}

const RenovatesSideNav: FC<RenovatesSideNavInterface> = ({
  activeTab,
  setActiveTab,
}) => {
  // global state
  const { state }: any = useContext(GlobalContext);

  const SideMap = [
    {
      id: 1,
      images: Window,
      title: getValue(state, 3, 140)?.props?.content,
    },
    {
      id: 2,
      images: Solar,
      title: getValue(state, 3, 141)?.props?.content,
    },
    {
      id: 3,
      images: Daki,
      title: getValue(state, 3, 142)?.props?.content,
    },
    {
      id: 4,
      images: Murri,
      title: getValue(state, 3, 143)?.props?.content,
    },
    {
      id: 5,
      images: Warming,
      title: getValue(state, 3, 144)?.props?.content,
    },
    {
      id: 6,
      images: Venti,
      title: getValue(state, 3, 145)?.props?.content,
    },
  ];

  return (
    <div className="boxes ">
      <div className=" space-x-10 lg:space-x-0 flex  lg:flex-col flex-row justify-between w-full py-4 lg:py-0">
        {SideMap.map((single) => (
          <div
            onClick={() => setActiveTab(single.id)}
            className={` ${
              single.id === activeTab
                ? "shadow-lg bg-white w-fit  text-[#435159] "
                : "text-[#c2c6c8]  hover:text-[#435159] "
            }hover:bg-white hover:rounded-xl hover:shadow-lg  w-full lg:w-[80%]   flex items-center justify-center lg:justify-start  cursor-pointer rounded-xl px-8 py-4 lg:px-4  lg:my-4`}
          >
            <img
              src={single.images}
              alt="images"
              className="h-[24px] lg:h-[80px]"
            />
            <p
              className="text-xs lg:text-base px-0 lg:px-4"
              dangerouslySetInnerHTML={{
                __html: single.title,
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
export default RenovatesSideNav;
