import {
  SetUserProfileStatusByService,
  GetSingleUserBuildingStatusByService,
  // GetUserBuildingStatusByService,
} from "../services/ResultPage";
import { get_construction_date } from "./buildingDetailsHandler";

export const deleteHandler = (array1: any, array2: any) => {
  return array1?.filter(
    (content: any) =>
      !array2?.some((key: any) => content.renovation === key.renovation)
  );
};
const wallInsulationv2 = (key: any) => {
  let construction_date = get_construction_date(key);
  if (parseInt(construction_date) < 1960) {
    return "3-6 cm";
  } else if (
    parseInt(construction_date) >= 1960 &&
    parseInt(construction_date) < 2006
  ) {
    return "7-9 cm";
  } else if (parseInt(construction_date) >= 2006) {
    return "10 cm+";
  }
};
const roofInsulationv2 = (key: any) => {
  //construction_date = 2009;
  let construction_date = get_construction_date(key);
  if (parseInt(construction_date) < 1960) {
    return "3-6 cm";
  } else if (
    parseInt(construction_date) >= 1960 &&
    parseInt(construction_date) < 1985
  ) {
    return "8-12 cm";
  } else if (
    parseInt(construction_date) >= 1986 &&
    parseInt(construction_date) < 2006
  ) {
    return "14-18 cm";
  } else if (parseInt(construction_date) >= 2006) {
    return "20 cm+";
  }
};
export const defaultRenovations = (response: any, allRenovations: any) => {
  // glazing
  if (response?.window_type === "triple") {
    allRenovations = allRenovations?.filter(
      (item: any) => item?.renovation !== "windowsDoubleAndDoors"
    );
    allRenovations = allRenovations?.filter(
      (item: any) => item?.renovation !== "glazing"
    );
  }
  // Roof
  if (response?.u_value_roof < 0.25) {
    allRenovations = allRenovations?.filter(
      (item: any) => item?.renovation !== "roofIn"
    );
    allRenovations = allRenovations?.filter(
      (item: any) => item?.renovation !== "roofExt"
    );
    allRenovations = allRenovations?.filter(
      (item: any) => item?.renovation !== "roofAttic"
    );
  }
  // heat pump
  if (response?.heating_type === "heat_pump") {
    allRenovations = allRenovations?.filter(
      (item: any) => item?.renovation !== "heatPump"
    );
  }
  // Boiler
  if (
    response?.heating_type === "boiler" &&
    response?.subtype === "condensing"
  ) {
    allRenovations = allRenovations?.filter(
      (item: any) => item?.renovation !== "condGas"
    );
  }
  // cavity wall
  if (
    (response?.u_value_wall < 0.35 &&
      response?.has_cavity &&
      response?.construction_year > 1950) ||
    (response?.u_value_wall < 0.25 && !response?.has_cavity)
  ) {
    allRenovations = allRenovations?.filter(
      (item: any) => item?.renovation !== "wallCav"
    );
    allRenovations = allRenovations?.filter(
      (item: any) => item?.renovation !== "wallExt"
    );
  }
  // Ventillation
  if (
    response?.mechanical_system_type === "system_cplus" ||
    response?.mechanical_system_type === "system_d"
  ) {
    allRenovations = allRenovations?.filter(
      (item: any) => item?.renovation !== "ventD"
    );
    allRenovations = allRenovations?.filter(
      (item: any) => item?.renovation !== "ventC+"
    );
  }
  // Attic Floor
  // if (response?.attic_storage === 1) {
  //   allRenovations = allRenovations?.filter(
  //     (item: any) => item?.renovation !== "roofAttic"
  //   );
  // }
  return allRenovations;
};

// python logic after call 2 and 3
export const pythonLogic1AfterCall2Call3 = (
  response: any,
  allRenovations: any,
  callZeroResponse: any
) => {
  // glazing
  if (
    response?.window_type === "triple" ||
    response?.window_type === "hr_double_after_2000"
  ) {
    allRenovations = allRenovations?.filter(
      (item: any) => !item?.renovation.includes("windowsDoubleAndDoors")
    );
  }
  // Roof
  if (response?.roof?.u_value < 0.25) {
    allRenovations = allRenovations?.filter(
      (item: any) => !item?.renovation.includes("roof")
    );
  }
  // heat pump
  if (response?.heating?.heating_type === "heat_pump") {
    allRenovations = allRenovations?.filter(
      (item: any) => !item?.renovation.includes("heatPump")
    );
  }
  // Boiler
  if (
    response?.heating?.heating_type === "boiler" &&
    response?.heating?.subtype === "condensing"
  ) {
    allRenovations = allRenovations?.filter(
      (item: any) => !item?.renovation.includes("condGas")
    );
  }
  // cavity wall
  if (
    (response?.wall?.u_value < 0.6 && response?.wall?.has_cavity) ||
    // &&
    // callZeroResponse?.construction_year > 1950
    (response?.wall?.u_value < 0.25 && !response?.wall?.has_cavity)
  ) {
    allRenovations = allRenovations?.filter(
      (item: any) => !item?.renovation.includes("wall")
    );
  }
  // Ventillation
  if (
    response?.mechanical_system_type === "system_cplus" ||
    response?.mechanical_system_type === "system_d"
  ) {
    allRenovations = allRenovations?.filter(
      (item: any) => !item?.renovation.includes("vent")
    );
  }
  return allRenovations;
};

export const pythonLogicMinus1AfterCall2Call3 = (
  response: any,
  allRenovations: any,
  callZeroResponse: any
) => {
  // glazing
  if (response?.window_type === "single") {
    allRenovations = allRenovations?.filter((item: any) =>
      item?.renovation.includes("windowsDoubleAndDoors")
    );
  }
  // Roof
  if (response?.roof?.u_value > 0.85) {
    allRenovations = allRenovations?.filter((item: any) =>
      item?.renovation.includes("roof")
    );
  }
  // Boiler
  if (
    response?.heating?.heating_type === "boiler" &&
    response?.heating?.subtype === "standard"
  ) {
    allRenovations = allRenovations?.filter((item: any) =>
      item?.renovation.includes("heatPump")
    );
  }
  // wall
  if (
    (response?.wall?.u_value > 0.65 &&
      callZeroResponse?.construction_year > 1950 &&
      response?.wall?.has_cavity) ||
    (response?.wall?.u_value > 0.45 && !response.wall?.has_cavity)
  ) {
    allRenovations = allRenovations?.filter((item: any) =>
      item?.renovation.includes("wall")
    );
  }
  // Ventillation
  if (response?.mechanical_system_type === "") {
    allRenovations = allRenovations?.filter((item: any) =>
      item?.renovation.includes("vent")
    );
  }
  return allRenovations;
};

// python logic for call 0
// for all renovations for which configuration is 1
export const pythonLogic1 = (response: any, allRenovations: any) => {
  // glazing
  if (
    response?.window_type === "triple" ||
    response?.window_type === "hr_double_after_2000"
  ) {
    allRenovations = allRenovations?.filter(
      (item: any) => !item?.renovation.includes("windowsDoubleAndDoors")
    );
  }
  // Roof
  if (response?.u_value_roof < 0.25) {
    allRenovations = allRenovations?.filter(
      (item: any) => !item?.renovation.includes("roof")
    );
  }
  // heat pump
  if (response?.heating_type === "heat_pump") {
    allRenovations = allRenovations?.filter(
      (item: any) => !item?.renovation.includes("heatPump")
    );
  }
  // Boiler
  if (
    response?.heating_type === "boiler" &&
    response?.heating_sub_type === "condensing"
  ) {
    allRenovations = allRenovations?.filter(
      (item: any) => !item?.renovation.includes("condGas")
    );
    // allRenovations = allRenovations?.filter(
    //   (item: any) => !item?.renovation.includes("heatPump")
    // );
  }
  // cavity wall
  if (
    (response?.u_value_wall < 0.6 && response?.has_cavity) ||
    // &&
    // response?.construction_year > 1950
    (response?.u_value_wall < 0.25 && !response?.has_cavity)
  ) {
    allRenovations = allRenovations?.filter(
      (item: any) => !item?.renovation.includes("wall")
    );
  }
  // Ventillation
  if (
    response?.mechanical_system_type === "system_cplus" ||
    response?.mechanical_system_type === "system_d"
  ) {
    allRenovations = allRenovations?.filter(
      (item: any) => !item?.renovation.includes("vent")
    );
  }
  return allRenovations;
};
// for all renovations for which configuration is -1
export const pythonLogicMinus1 = (response: any, allRenovations: any) => {
  // glazing
  if (response?.window_type === "single") {
    allRenovations = allRenovations?.filter((item: any) =>
      item?.renovation.includes("windowsDoubleAndDoors")
    );
  }
  // Roof
  if (response?.u_value_roof > 0.85) {
    allRenovations = allRenovations?.filter((item: any) =>
      item?.renovation.includes("roof")
    );
  }
  // Boiler
  if (
    response?.heating_type === "boiler" &&
    response?.heating_sub_type === "standard"
  ) {
    allRenovations = allRenovations?.filter((item: any) =>
      item?.renovation.includes("heatPump")
    );
  }
  // wall
  if (
    (response?.u_value_wall > 0.65 &&
      response?.construction_year > 1950 &&
      response?.has_cavity) ||
    (response?.u_value_wall > 0.45 && !response.has_cavity)
  ) {
    allRenovations = allRenovations?.filter((item: any) =>
      item?.renovation.includes("wall")
    );
  }
  // Ventillation
  if (response?.mechanical_system_type === "") {
    allRenovations = allRenovations?.filter((item: any) =>
      item?.renovation.includes("vent")
    );
  }
  return allRenovations;
};

// method to check user status if status not exist to store status
export const GetUserStatus = (
  userRecord: any,
  response?: any,
  houseRenovations?: any,
  payload?: any,
  setUserStatus?: any,
  baseOffer?: any
) => {
  if (userRecord?.id) {
    GetSingleUserBuildingStatusByService(
      userRecord?.id,
      houseRenovations.data[0].id
    )
      .then((statusResponse: any) => {
        if (
          userRecord &&
          (statusResponse?.data === null ||
            statusResponse?.data?.length === 0 ||
            statusResponse?.data?.status === null ||
            statusResponse?.data?.status === 0)
        ) {
          // api call to store status for new user if they don't have it.
          SetUserProfileStatusByService(payload)
            .then((profileResponse: any) => {
              setUserStatus(profileResponse.data.data);
            })
            .catch((error: any) => {});
        }
        setUserStatus(statusResponse);
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

export const currentComfort = (response: any, total: any) => {
  // agc -v2
  if (response?.glazing === "S") {
    total = total + 0;
  } else if (response?.glazing === "D") {
    total = total + 2;
  } else if (response?.glazing === "T" || response?.glazing === "DR") {
    total = total + 4;
  } else {
    total = total + 0;
  }
  let roofValuecurrent = response?.roof_insulation;

  if (roofValuecurrent !== "" && roofValuecurrent !== undefined) {
    if (roofValuecurrent === "Probable") {
      total = total + 1;
    } else if (roofValuecurrent === "OUI") {
      let roofcalculatedvalue = roofInsulationv2(response?.construction_date);
      if (roofcalculatedvalue === "3-6 cm") {
        total = total + 1;
      } else if (roofcalculatedvalue === "8-12 cm") {
        total = total + 2;
      } else if (roofcalculatedvalue === "14-18 cm") {
        total = total + 3;
      } else if (roofcalculatedvalue === "20 cm+") {
        total = total + 3;
      }
    } else {
      total = total + 0;
    }
  }
  let WallValuecurrent = response?.facade_insulation_exterior;

  if (WallValuecurrent !== "" && WallValuecurrent !== undefined) {
    if (WallValuecurrent === "Probable") {
      total = total + 1;
    } else if (WallValuecurrent === "OUI") {
      let wallcalculatedvalue = wallInsulationv2(response?.construction_date);
      if (wallcalculatedvalue === "3-6 cm") {
        total = total + 1;
      } else if (wallcalculatedvalue === "7-9 cm") {
        total = total + 1;
      } else if (wallcalculatedvalue === "10 cm+") {
        total = total + 2;
      }
    } else {
      total = total + 0;
    }
  }

  // console.log("total value after all logcs - value , after ",total , (total / 14) * 100)
  return (total / 14) * 100;
};

const roofInsulationv2value = (value: any) => {
  let answere = "";

  if (parseInt(value) >= 3 && parseInt(value) <= 6) {
    answere = "3-7 cm";
  }
  // 8 - 12 cm
  else if (parseInt(value) >= 8 && parseInt(value) <= 12) {
    answere = "8-13 cm";
  }
  // 14 - 18 cm
  else if (parseInt(value) >= 14 && parseInt(value) <= 18) {
    answere = "14-19 cm";
  }
  // 20 cm +
  else if (parseInt(value) > 18) {
    answere = "20 cm+";
  }
  return answere;
};

const wallInsulationv2value = (value: any) => {
  let answere = "";

  if (parseInt(value) >= 3 && parseInt(value) <= 6) {
    answere = "3-6 cm";
  }
  // 7-9 cm
  else if (parseInt(value) >= 7 && parseInt(value) <= 9) {
    answere = "7-9 cm";
  }
  // 10 cm+
  else if (parseInt(value) >= 10) {
    answere = "10 cm+";
  }

  return answere;
};

export const futureComfort = (response: any, total: any) => {
  // agc -v2
  if (response?.glazing === "S") {
    total = total + 0;
  } else if (response?.glazing === "D") {
    total = total + 2;
  } else if (response?.glazing === "T" || response?.glazing === "DR") {
    total = total + 4;
  } else {
    total = total + 0;
  }

  // roof
  let roofValue = response?.roof_insulation_key;

  if (roofValue !== "" && roofValue !== undefined) {
    if (roofValue === "Probable") {
      total = total + 1;
    } else if (roofValue === "OUI") {
      let roofcalculatedvalue = roofInsulationv2value(
        response?.roof_insulation_value
      );
      //console.log("roofcalculatedvalue", roofcalculatedvalue);
      if (roofcalculatedvalue === "3-7 cm") {
        total = total + 1;
      } else if (roofcalculatedvalue === "8-13 cm") {
        total = total + 2;
      } else if (roofcalculatedvalue === "14-19 cm") {
        total = total + 3;
      } else if (roofcalculatedvalue === "20 cm+") {
        total = total + 3;
      }
    }
  }
  // wall
  let wallValue = response?.wall_insulation_key;
  if (wallValue !== "" && wallValue !== undefined) {
    if (wallValue === "Probable") {
      total = total + 1;
    } else if (wallValue === "OUI") {
      let wallcalculatedvalue = wallInsulationv2value(
        response?.wall_insulation_value
      );
      if (wallcalculatedvalue === "3-6 cm") {
        total = total + 1;
      } else if (wallcalculatedvalue === "7-9 cm") {
        total = total + 1;
      } else if (wallcalculatedvalue === "10 cm+") {
        total = total + 2;
      }
    }
  }

  // heat pump
  let heating = response?.heating_type;
  if (heating === "non-condesing2" || heating === "non-condesing1") {
    total = total + 0;
  } else if (heating === "electric-heating" || heating === "condesing1") {
    total = total + 0;
  } else if (heating === "condesing2" || heating === "heatpump") {
    total = total + 1;
  } else {
    total = total + 0;
  }

  // Ventillation
  if (response?.ventilation_type === null) {
    total = total + 0;
  } else if (response?.ventilation_type === "1") {
    total = total + 0;
  } else if (response?.ventilation_type === "2") {
    total = total + 1;
  } else if (response?.ventilation_type === "3") {
    total = total + 2;
  } else if (response?.ventilation_type === "4") {
    total = total + 4;
  }

  console.log("total value after all logcs - value , after ", total, response);
  return (total / 14) * 100;
};
