import React, { FC, useContext, useEffect } from "react";
import { Modal } from "antd";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { getValue } from "../../utils/getValue";

interface UserRegisterationModalInterface {
  visible?: boolean;
  setVisible?: any;
}
const UserRegisterationModal: FC<UserRegisterationModalInterface> = ({
  visible,
  setVisible,
}) => {
  // global state
  const { state, dispatch }: any = useContext(GlobalContext);
  console.log("state", state);
  return (
    <div className="flex justify-center">
      <div>
        <Modal
          open={visible}
          onCancel={() => setVisible(false)}
          // onOk={() => console.log("Saved!")}
          closable={false}
          footer={null}
          className="!w-[90%] lg:!w-[70%] xl:!w-[50%]"
          maskClosable={false}
        >
          <h2
            className="text-[#007DDF] text-center text-lg md:text-[20px] lg:text-[38px] font-bold font-poppins p-8"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 0, 149)?.props?.content,
            }}
          >
            {/* {state?.language?.signupModalTitle} */}
          </h2>
          <div className="px-6 md:px-24 lg:px-[130px] pb-6 ">
            <p
              className="text-center text-sm text-[#455259]  font-normal  font-poppins  pb-5"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 0, 150)?.props?.content,
              }}
            ></p>
            {/* form div */}
          </div>
          <div className="border border-[#F0F0F0] "></div>
          <div className="flex justify-between p-5 items-center">
            <div>
              <button
                onClick={() => setVisible(false)}
                className="text-base font-poppins text-[#989898] bg-white underline"
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: getValue(state, 0, 152)?.props?.content,
                  }}
                ></span>
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};
export default UserRegisterationModal;
