import React, { FC, useContext } from "react";
// import images
import FaqCity1 from "../../../assets/images/faq/faqCity4.svg";
import FaqCity2 from "../../../assets/images/faq/faqCity2.svg";
import FaqCity3 from "../../../assets/images/faq/faqCity4.svg";
import FaqCity4 from "../../../assets/images/faq/faqCity3.svg";
import { getValue } from "../../../utils/getValue";
import { GlobalContext } from "../../../context/globalContext/globalContext";
// import components
interface FaqSideNavInterface {
  activeTab?: any;
  setActiveTab?: any;
  faqsDataHandler?:any;
}

const FaqSideNav: FC<FaqSideNavInterface> = ({ activeTab, setActiveTab,faqsDataHandler }) => {
  // global state
  const { state }: any = useContext(GlobalContext);
  console.log("getValue", getValue(state, 5, 0));

  const SideMap = [
    {
      id: 1,
      images: FaqCity1,
      title: getValue(state, 5, 0)?.props?.content,
    },
    {
      id: 2,
      images: FaqCity2,
      title: getValue(state, 5, 1)?.props?.content,
    },
    {
      id: 3,
      images: FaqCity3,
      title: getValue(state, 5, 2)?.props?.content,
    },
    {
      id: 4,
      images: FaqCity4,
      title: getValue(state, 5, 3)?.props?.content,
    },
  ];

  return (
    <div className="boxes-faq">
      <div className=" space-x-10 lg:space-x-0 flex  lg:flex-col flex-row justify-between w-full py-4 lg:py-0">
        {SideMap.map((single) => (
          <div
            onClick={() => {
              faqsDataHandler(single.id)
              setActiveTab(single.id)
            }}
            className={` ${
              single.id === activeTab
                ? "shadow-lg bg-white text-[#435159]"
                : "text-[#c2c6c8]  hover:text-[#435159]"
            }hover:bg-white hover:rounded-xl hover:shadow-lg   w-full lg:w-[80%]  flex items-center justify-center lg:justify-start  cursor-pointer rounded-xl  px-8 py-4 lg:px-4  lg:my-4`}
          >
            <img
              src={single.images}
              alt="images"
              className="h-[24px] lg:h-[80px]"
            />
            <p
              className="text-xs lg:text-base px-0 lg:px-4"
              dangerouslySetInnerHTML={{
                __html: single.title,
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
export default FaqSideNav;
