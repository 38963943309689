import React, { FC, useContext, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// import components
import { GlobalContext } from "../../context/globalContext/globalContext";
import { getValue } from "../../utils/getValue";

interface YourNextStepInterface {
  getResultContent?: any;
}

const YourNextStep: FC<YourNextStepInterface> = ({ getResultContent }) => {
  //  global state
  const { state }: any = useContext(GlobalContext);

  const responsive = {
    desktop: {
      breakpoint: { max: 1023, min: 768 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 768, min: 430 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 430, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="border border-[#E3E7EB] rounded-[10px] py-[22px] px-[12px]">
      {/* home_div   */}
      <div className="pb-[22px] px-[7px]">
        <h2 className=" text-[22px]  text-[#222222]  text-left font-semibold ">
          {getResultContent && getResultContent(62)}
        </h2>
        <p className="text-[#455259] text-base  pt-[9px] font-normal">
          {getResultContent && getResultContent(63)}
        </p>
      </div>
      {/* boxes_div */}
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        // autoPlay={this.props.deviceType !== "mobile" ? true : false}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
        // deviceType={this.props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px block lg:hidden"
      >
        <div className="next-step-boxes-main-div-responsive relative bgimg1 h-[212px] mx-2 shadow-sm rounded-2xl ">
          <div className="next-step-overlay">
            <p className="text-sm text-white text-center font-bold p-2 ">
              {getResultContent && getResultContent(64)}
            </p>
            <p className="text-white text-center">
              {getResultContent && getResultContent(66)}
            </p>
          </div>
          <p className="text-sm text-white text-center font-bold p-2 ">
            {getResultContent && getResultContent(64)}
          </p>

          <div className="bg-white rounded-tr-[55px] rounded-br-[55px] w-[80px] ">
            <p className="text-[#222222] text-center text-[32px] font-semibold p-5">
              01
            </p>
          </div>
        </div>
        <div className="next-step-boxes-main-div-responsive relative bgimg2  h-[212px] mx-2 shadow-sm rounded-2xl  ">
          <div className="next-step-overlay">
            <p className="text-sm text-white text-center font-bold p-2 ">
              {getResultContent && getResultContent(67)}
            </p>
            <p className="text-white text-center">
              {getResultContent && getResultContent(69)}
            </p>
          </div>
          <p className="text-sm text-white text-center font-bold p-2 ">
            {getResultContent && getResultContent(67)}
          </p>

          <div className="bg-white rounded-tr-[55px] rounded-br-[55px] w-[80px]">
            <p className="text-[#222222] text-center text-[32px] font-semibold p-5">
              02
            </p>
          </div>
        </div>
        <div className="next-step-boxes-main-div-responsive relative bgimg3  h-[212px] mx-2 shadow-sm rounded-2xl ">
          <div className="next-step-overlay">
            <p className="text-sm text-white text-center font-bold p-2 ">
              {getResultContent && getResultContent(70)}
            </p>

            <p className="text-white text-center">
              {getResultContent && getResultContent(72)}
            </p>
          </div>
          <p className="text-sm text-white text-center font-bold p-2 ">
            {getResultContent && getResultContent(70)}
          </p>

          <div className="bg-white rounded-tr-[55px] rounded-br-[55px] w-[80px]">
            <p className="text-[#222222] text-center text-[32px] font-semibold p-5">
              03
            </p>
          </div>
        </div>
        <div className="next-step-boxes-main-div-responsive relative bgimg4  h-[212px] mx-2 shadow-sm rounded-2xl ">
          <div className="next-step-overlay">
            <p className="text-sm text-white text-center font-bold p-2 ">
              {getResultContent && getResultContent(73)}
            </p>
            <p className="text-white text-center">
              {getResultContent && getResultContent(82)}
            </p>
          </div>
          <p className="text-sm text-white text-center font-bold p-2 ">
            {getResultContent && getResultContent(73)}
          </p>

          <div className="bg-white rounded-tr-[55px] rounded-br-[55px] w-[80px]">
            <p className="text-[#222222] text-center text-[32px] font-semibold p-5">
              04
            </p>
          </div>
        </div>
        <div className="next-step-boxes-main-div-responsive relative bgimg5 h-[212px] mx-2 shadow-sm rounded-2xl ">
          <div className="next-step-overlay">
            <p className="text-sm text-white text-center font-bold p-2 ">
              {getResultContent && getResultContent(303)}
            </p>
            <p className="text-white text-center">
              {getResultContent && getResultContent(305)}
            </p>
          </div>
          <p className="text-sm text-white text-center font-bold p-2 ">
            {getResultContent && getResultContent(303)}
          </p>

          <div className="bg-white rounded-tr-[55px] rounded-br-[55px] w-[80px]">
            <p className="text-[#222222] text-center text-[32px] font-semibold p-5">
              05
            </p>
          </div>
        </div>
      </Carousel>
      <div className="hidden lg:block">
        <div className="flex justify-between">
          <div className="next-step-boxes-main-div relative bgimg1  lg:h-[200px] lg:w-[200px] xl:h-[212px] xl:w-[230px]  shadow-lg rounded-2xl ">
            <div className="next-step-overlay opacity-[0.14]">
              <p className="text-sm text-white text-left font-bold px-[10px] py-[12px] ">
                {" "}
                {getResultContent && getResultContent(64)}
              </p>
              <div className="flex justify-end">
                <div className="w-[75%] px-1 pb-1">
                  <p className="text-white text-left font-normal text-[10px] opacity-overly-text">
                    {getResultContent && getResultContent(66)}
                  </p>
                </div>
              </div>
            </div>
            <p className="text-sm text-white text-left font-bold px-[10px] py-[12px] ">
              {" "}
              {getResultContent && getResultContent(64)}
            </p>
            <div className="bg-white rounded-tr-[55px] rounded-br-[55px] w-[80px] absolute  top-[60px] number-box py-4 pl-1 pr-2">
              <p className="text-[#222222] text-center text-[32px] font-semibold ">
                01
              </p>
            </div>
          </div>
          <div className=" next-step-boxes-main-div relative bgimg2 lg:h-[200px] lg:w-[200px] xl:h-[212px] xl:w-[230px]  shadow-lg rounded-2xl ">
            <div className="next-step-overlay opacity-[0.14]">
              <p className="text-sm text-white text-left font-bold px-[10px] py-[12px]">
                {getResultContent && getResultContent(67)}
              </p>
              <div className="flex justify-end">
                <div className="w-[75%] px-1 pb-1">
                  <p className="text-white text-left font-normal text-[10px] opacity-overly-text">
                    {getResultContent && getResultContent(69)}
                  </p>
                </div>
              </div>
            </div>
            <p className="text-sm text-white text-left font-bold px-[10px] py-[12px]">
              {getResultContent && getResultContent(67)}
            </p>

            <div className="bg-white rounded-tr-[55px] rounded-br-[55px] w-[80px]  absolute  top-[60px] number-box py-4 pl-1 pr-2">
              <p className="text-[#222222] text-center text-[32px] font-semibold ">
                02
              </p>
            </div>
          </div>
          <div className="next-step-boxes-main-div relative bgimg3 lg:h-[200px] lg:w-[200px] xl:h-[212px] xl:w-[230px] shadow-lg rounded-2xl ">
            <div className="next-step-overlay opacity-[0.14]">
              <p className="text-sm text-white text-left font-bold px-[10px] py-[12px] w-[192px]">
                {getResultContent && getResultContent(70)}
              </p>
              <div className="flex justify-end">
                <div className="w-[75%] px-1 pb-1">
                  <p className="text-white text-left font-normal text-[10px] opacity-overly-text">
                    {getResultContent && getResultContent(72)}
                  </p>
                </div>
              </div>
            </div>
            <p className="text-sm text-white text-left font-bold px-[10px] py-[12px] w-[192px]">
              {getResultContent && getResultContent(70)}
            </p>

            <div className="bg-white rounded-tr-[55px] rounded-br-[55px] w-[80px]  absolute  top-[60px] number-box py-4 pl-1 pr-2">
              <p className="text-[#222222] text-center text-[32px] font-semibold ">
                03
              </p>
            </div>
          </div>
          <div className="next-step-boxes-main-div shadow-lg rounded-2xl  relative bgimg4 lg:h-[200px] lg:w-[200px] xl:h-[212px] xl:w-[230px]">
            <div className="next-step-overlay opacity-[0.14]">
              <p className="text-sm text-white text-left font-bold px-[10px] py-[12px] w-[192px]">
                {getResultContent && getResultContent(73)}
              </p>
              <div className="flex justify-end">
                <div className="w-[75%] px-1 pb-1">
                  <p className="text-white text-left font-normal text-[10px] opacity-overly-text">
                    {getResultContent && getResultContent(82)}
                  </p>
                </div>
              </div>
            </div>
            <p className="text-sm text-white text-left font-bold px-[10px] py-[12px] w-[192px]">
              {getResultContent && getResultContent(73)}
            </p>

            <div className="bg-white rounded-tr-[55px] rounded-br-[55px] w-[80px]  absolute  top-[60px] number-box py-4 pl-1 pr-2">
              <p className="text-[#222222] text-center text-[32px] font-semibold">
                04
              </p>
            </div>
          </div>
          <div className="next-step-boxes-main-div  shadow-lg rounded-2xl  relative bgimg5 lg:h-[200px] lg:w-[200px] xl:h-[212px] xl:w-[230px] ">
            <div className="next-step-overlay opacity-[0.14]">
              <p className="text-sm text-white text-left font-bold px-[10px] py-[12px] w-[192px]">
                {getResultContent && getResultContent(303)}
              </p>
              <div className="flex justify-end">
                <div className="w-[75%] px-1 pb-1">
                  <p className="text-white text-left font-normal text-[10px] opacity-overly-text">
                    {getResultContent && getResultContent(305)}
                  </p>
                </div>
              </div>
            </div>
            <p className="text-sm text-white text-left font-bold px-[10px] py-[12px] w-[192px]">
              {getResultContent && getResultContent(303)}
            </p>

            <div className="bg-white rounded-tr-[55px] rounded-br-[55px] w-[80px]  absolute  top-[60px] number-box py-4 pl-1 pr-2">
              <p className="text-[#222222] text-center text-[32px] font-semibold ">
                05
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default YourNextStep;
