import React, { FC, useContext, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { PostAPIService, GetAPIService } from "../context/services";
// pages
import Home from "../containers/chooseProject";
import ComingSoon from "../containers/comingSoon";
import CookiePage from "../containers/cookie";
import HomePage from "../containers/homePage";
import Renovates from "../containers/renovates";
import Benefits from "../containers/benefits";
import Process from "../containers/process";
import WhoWeAre from "../containers/whoWeAre";
import ContactUs from "../containers/contactUs";
import FAQ from "../containers/faq";
import ResultPage from "../containers/result/Results";
import ResultPageSub from "../containers/result/Results";
import ResultSubTest from "../containers/resultSub/Results";
import Subtest from "../containers/subTest";
// Layout
import Layout from "../shared/layout";
import Policy from "../components/policy/policy";
import { GlobalContext } from "../context/globalContext/globalContext";
import { setLanguageHandler } from "../context/actions/language";
// languages
import French from "../languages/fr";
import Dutch from "../languages/nl";

interface RoutesInterface {}

/**
 * routes page to define all pages routes here
 */
const PagesRoutes: FC<RoutesInterface> = () => {
  const { state, dispatch }: any = useContext(GlobalContext);
  const location = useLocation();

  useEffect(() => {
    document.documentElement.lang = state?.language?.language.toLowerCase();
    // eslint-disable-next-line
  }, []);

  // useEffect for reload to show content for cookies and fr page
  useEffect(() => {
    if (location?.pathname?.includes("nl")) {
      setLanguageHandler(Dutch, dispatch);
    } else if (location?.pathname?.includes("fr")) {
      setLanguageHandler(French, dispatch);
    }
    // eslint-disable-next-line
  }, [location?.pathname]);

  // API call to get content
  useEffect(() => {
    const payload = {
      city_id: 37,
      datatype: "menu",
    };
    PostAPIService("/getCityDataByType", payload)
      .then((response) => {
        console.log("response", response);
        dispatch({
          type: "SET_HOMEPAGE_DATA",
          payload: response?.data?.data,
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let AllAddresses: any = [];
    GetAPIService("/agc/v2/addresses")
      .then((response: any) => {
        response?.data?.map((addresses: any) => {
          AllAddresses.push({
            ...addresses,
            house_address: addresses?.street + " " + addresses?.street_num,
          });
        });
        // to remove duplicate data
        let addressesWithoutDuplicate = AllAddresses.filter(
          (ele: any, ind: any) =>
            ind ===
            AllAddresses.findIndex(
              (elem: any) => elem.house_address === ele.house_address
            )
        );
        if (addressesWithoutDuplicate) {
          dispatch({
            type: "SET_ALL_ADDRESSES",
            payload: addressesWithoutDuplicate,
          });
        }
      })
      .catch((error: any) => {
        console.log("res", error);
      });
  }, []);

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/nl" element={<HomePage />} />
        <Route path="/fr" element={<HomePage />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
        <Route
          path={`/cookie/${state?.language?.language.toLowerCase()}`}
          element={<CookiePage />}
        />
        <Route
          path={`/privacy/${state?.language?.language.toLowerCase()}`}
          element={<Policy />}
        />
        <Route path="/home" element={<HomePage />} />
        <Route path="/renovates" element={<Renovates />} />
        <Route path="/benefits" element={<Benefits />} />
        <Route path="/process" element={<Process />} />
        <Route path="/who-we-are" element={<WhoWeAre />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/listing-renovate-detail/:id" element={<ResultPage />} />
        {/*  subsidy and Investment test page */}
        {/* <Route path="/subTest" element={<Subtest />} /> */}
        <Route path="/result/:id" element={<ResultSubTest />} />
      </Routes>
    </Layout>
  );
};
export default PagesRoutes;
