import React, { FC, useContext } from "react";
// import images
import Together from "../../../assets/images/advantages/together.png";
import Check from "../../../assets/images/advantages/check.svg";

// import components
import { GlobalContext } from "../../../context/globalContext/globalContext";
import { getValue } from "../../../utils/getValue";
import InputTabs from "../../../shared/autoComplete";

interface StrongerTogetherInterface {}

const StrongerTogether: FC<StrongerTogetherInterface> = () => {
  const { state }: any = useContext(GlobalContext);
  return (
    <div>
      <div>
        <img src={Together} alt="Together" />
      </div>
      <p
        className="text-black text-[20px] py-4"
        dangerouslySetInnerHTML={{
          __html: getValue(state, 1, 17)?.props?.content,
        }}
      ></p>

      <div className="flex flex-col lg:flex-row justify-between lg:space-x-3 py-8">
        <div className="w-full lg:w-2/6">
          <div className="flex ">
            <img src={Check} alt="check" className="h-[30px]" />
            <p
              className="text-[#6B7280] text-lg font-semibold px-2"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 1, 18)?.props?.content,
              }}
            />
          </div>
          <p
            className="text-[#6B7280] text-base font-light py-4"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 21)?.props?.content,
            }}
          />
        </div>
        <div className="w-full lg:w-2/6">
          <div className="flex ">
            <img src={Check} alt="check" className="h-[30px]" />
            <p
              className="text-[#6B7280] text-lg font-semibold px-2"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 1, 19)?.props?.content,
              }}
            />
          </div>
          <p
            className="text-[#6B7280] text-base font-light py-4"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 22)?.props?.content,
            }}
          />
        </div>
        <div className="w-full lg:w-2/6">
          <div className="flex ">
            <img src={Check} alt="check" className="h-[30px]" />
            <p
              className="text-[#6B7280] text-lg font-semibold px-2"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 1, 20)?.props?.content,
              }}
            />
          </div>
          <p
            className="text-[#6B7280] text-base font-light py-4"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 23)?.props?.content,
            }}
          />
        </div>
      </div>

      <div className="bg-[#007DDF] rounded-[20px] p-[40px] mb-14">
        <p
          className="text-white text-xl font-medium pb-6"
          dangerouslySetInnerHTML={{
            __html: getValue(state, 1, 109)?.props?.content,
          }}
        />
        <InputTabs showAutocomplete="renovation" />
      </div>
    </div>
  );
};
export default StrongerTogether;
