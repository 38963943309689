import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
// import ButtonClose from "shared/ButtonClose/ButtonClose";
import { Menu, Button } from "antd";
import {CloseOutlined} from "@ant-design/icons";
// import Avatar from "shared/Avatar/Avatar";
// import User from "images/resultPage/userDetail/user.svg";
// import Mail from "images/resultPage/userDetail/mail.svg";
// import Phone from "images/resultPage/userDetail/phone.svg";

const Modal = (props: any) => {
    let { isOpenModal, closeModal, getValue } = props;
    const [current, setCurrent] = useState("signup");
    const handleClick = (e: any) => {
        setCurrent(e.key);
    };
    return (
        <Transition appear show={isOpenModal} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-50 overflow-y-auto"
                onClose={closeModal}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block py-8 w-full">
                            <div className="inline-flex flex-col w-full lg:w-1/2 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                                <div className="relative flex justify-center bg-neutral-100 flex-shrink-0 px-6 pb-4 border-b border-neutral-200 text-center">
                                <h3
                                    className="text-md m-0 text-gray-600 pt-4"
                                        id="headlessui-dialog-title-70"
                                    >
                                        {getValue(275)}
                                    </h3>
                                    <span className="absolute right-4 pt-3 text-black text-lg font-bold">
                    <CloseOutlined onClick={closeModal}/>
                  </span>
                                </div>
                                <div className="px-4 py-8">
                                    <div className="flex justify-between py-4 pr-4">
                                        <div className="flex items-center space-x-3 w-1/3">
                                            {/* <Avatar
                                                sizeClass="h-10 w-10"
                                                radius=""
                                                imgUrl={User}
                                            /> */}
                                            <div className="flex flex-col">
                                                <span className="text-md text-gray-500">
                                                    {getValue(256)}
                                                    {/* Enegy Auditer */}
                                                    </span>
                                                <span className="text-md py-1 text-gray-500">
                                                    {getValue(257)}
                                                    {/* Maggy Dewitte */}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex items-center space-x-3 w-1/3">
                                            {/* <Avatar
                                                sizeClass="h-10 w-10"
                                                radius=""
                                                imgUrl={Mail}
                                            /> */}
                                            <div className="flex flex-col">
                                                <span className="text-md text-gray-500">
                                                    {getValue(258)}
                                                    {/* Email */}
                                                    </span>
                                                <span className="text-md py-1 text-gray-500">
                                                    {getValue(259)}
                                                    {/* maggy@renocity.be */}
                                                    </span>
                                            </div>
                                        </div>
                                        <div className="flex items-center space-x-3 w-1/3">
                                            {/* <Avatar
                                                sizeClass="h-10 w-10"
                                                radius=""
                                                imgUrl={Phone}
                                            /> */}
                                            <div className="flex flex-col">
                                                <span className="text-md text-gray-500">
                                                    {getValue(260)}
                                                    {/* Phone Number */}
                                                    </span>
                                                <span className="text-md py-1 text-gray-500">
                                                    {getValue(261)}
                                                    {/* 0475/23.45.89 */}
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-6 py-4 flex justify-end border-b border-neutral-100 bg-neutral-100 dark:border-neutral-800 text-center">
                                    <Button
                                        type="primary"
                                        className="rounded-4xl"
                                        size={"large"}
                                        onClick={closeModal} >
                                        Annuleer
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};
export default Modal;
