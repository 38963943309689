import React, { FC } from "react";
import ComingSoon from "../../components/comingSoon/comingSoon"
interface ComingSoonInterface { }

const ComingSoonPage: FC<ComingSoonInterface> = () => {
    return (
        <div className=" bg-home">
            <ComingSoon />
        </div>
    );
};

export default ComingSoonPage;
