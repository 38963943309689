import React, { useEffect, useState } from "react";
import ReactStreetview from "react-streetview";
const GSMap = ({ lat, lng, address }) => {

  const googleMapsApiKey = process.env.REACT_APP_GOOGLEMAPS_API_KEY;

  const [panoId, setPanoId] = useState("");
  const [headingId, setHeadingId] = useState("");
  const google = window.google;
  // useEffect to get panoid from address 
  useEffect(() => {
    // check if lat lng and address is comming 
    if (lat && lng && address) {
      const geocoder = new google.maps.Geocoder();
      const sv = new google.maps.StreetViewService();

      // Geocode the address to obtain the lat/lng coordinates
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === "OK" && results[0]?.geometry?.location) {
          const geocodeLocation = results[0].geometry.location;

          // Use the lat/lng coordinates to fetch the pano ID
          sv.getPanorama({ location: geocodeLocation }, (data, status) => {
            if (status === "OK") {
              setPanoId(data.location.pano);
              setHeadingId(google.maps.geometry.spherical.computeHeading(data.location.latLng, results[0].geometry.location));
            } else {
              console.error("Error fetching pano ID:", status);
            }
          });
        } else {
          console.error("Error geocoding address:", status);
        }
      });
    }
  }, [lat, lng, address]);

  console.log("headingid", headingId)

  const streetViewPanoramaOptions = {
    pano: panoId, // Use the obtained pano ID
    pov: { heading: headingId, pitch: 0 },
    zoom: 1,
    addressControl: false,
    showRoadLabels: true,
    motionTracking: false,
    motionTrackingControl: false,
    disableDefaultUI: true,
    disableDoubleClickZoom: false,
    panControl: true,
    zoomControl: false,
  };
  console.log("streetViewPanoramaOptions", streetViewPanoramaOptions);
  return (
    <div className="w-full lg:h-[318px] rounded">
      {panoId && (
        <ReactStreetview
          apiKey={googleMapsApiKey}
          streetViewPanoramaOptions={streetViewPanoramaOptions}
        />
      )}
    </div>
  );
};
export default GSMap;
