import React, { FC, useContext } from "react";
import { Modal } from "antd";
import { GlobalContext } from "../../context/globalContext/globalContext";

interface ComingSoonModalInterface {
  visible?: boolean;
  setVisible?: any;
}
const ComingSoonModal: FC<ComingSoonModalInterface> = ({
  visible,
  setVisible,
}) => {
  // global state
  const { state }: any = useContext(GlobalContext);
  return (
    <div className="flex justify-center">
      <div>
        <Modal
          visible={visible}
          onCancel={() => setVisible(false)}
          onOk={() => console.log("Saved!")}
          closable={false}
          footer={null}
          className="!w-[90%] lg:!w-[50%]"
          maskClosable={false}
        >
          <div className="flex justify-center pt-[30px] p-7">
            <h2 className="text-[#007DDF] text-center text-lg md:text-[20px] lg:text-[22px] font-bold font-popins">
              {state?.language?.comingSoonModalTitle}
            </h2>
          </div>
          <div className="border border-[#F0F0F0]  "></div>
          <div className="text-center text-xs text-[#455259] px-6 md:px-24 lg:px-[120px] font-normal py-6 font-popins ">
            <p>{state?.language?.comingSoonModalContent1}</p>
            <p className="py-6"> {state?.language?.comingSoonModalContent2}</p>
            <p> {state?.language?.comingSoonModalContent3}</p>
            <p className="py-6">{state?.language?.comingSoonModalContent4}</p>
            <p>{state?.language?.comingSoonModalContent5}</p>
          </div>
          <div className="border border-[#F0F0F0] "></div>
          <div className="flex justify-end py-5 pr-5">
            <button
              onClick={() => setVisible(false)}
              className="text-base font-popins text-white bg-[#007DDF] border rounded py-2.5 px-7"
            >
              {state?.language?.comingSoonModalButton}
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
};
export default ComingSoonModal;
