import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
// import ButtonClose from "shared/ButtonClose/ButtonClose";
// import Avatar from "shared/Avatar/Avatar";
import Boiler from "../../assets/images/result/renovations_images/boiler_gas.png";
import WindowDouble from "../../assets/images/result/renovations_images/window_double.png";
import Glazing from "../../assets/images/result/resultPage/renovations/WM_38_RW_A_01.gif";
import SolarPanel from "../../assets/images/result/resultPage/renovations/solar_panels@2x.png";
import HeatPump from "../../assets/images/result/resultPage/renovations/heatpump@2x.png";
import Roof_Attic from "../../assets/images/result/resultPage/renovations/roof_attic@2x.png";
import Roof_Exterior from "../../assets/images/result/renovations_images/roof_exterior.png";
import Roof_Interior from "../../assets/images/result/renovations_images/roof_interior.png";
import VentilationC from "../../assets/images/result/resultPage/renovations/ventilation_c@2x.png";
import VentilationD from "../../assets/images/result/renovations_images/ventilation_d_2.png";
import Wall_Cavity from "../../assets/images/result/renovations_images/wall_cavity.png";
import Wall_Exterior from "../../assets/images/result/renovations_images/wall_exterior.png";
import { CloseOutlined } from "@ant-design/icons";

const Modal = (props: any) => {
  let {
    isOpenModal,
    closeModal,
    renovationModalContent,
    activeRenovationToolTip,
    getValue,
  } = props;

  const renovationsModalTitle = (key: any) => {
    switch (key) {
      case "window":
        return getValue(188);
      case "glazing":
        return getValue(187);
      case "wall":
        return getValue(199);
      // case "wallExt":
      //   return getValue(199);
      case "roof":
        return getValue(210);
      // case "roofIn":
      //   return getValue(206);
      // case "roofExt":
      //   return getValue(210);
      case "ventc+":
        return getValue(214);
      case "ventd":
        return getValue(218);
      case "condgas":
        return getValue(222);
      case "heatpump":
        return getValue(226);
      case "solar":
        return getValue(276);
      default:
        return "";
    }
  };

  const renovationsDescription = (key: any) => {
    switch (key) {
      case "window":
        return getValue(192);
      case "glazing":
        return getValue(193);
      // case "wallCav":
      //   return getValue(197);
      case "wall":
        return getValue(201);
      // case "roofAttic":
      // //   return getValue(205);
      // case "roofIn":
      //   return getValue(209);
      case "roof":
        return getValue(213);
      case "ventc+":
        return getValue(217);
      case "ventd":
        return getValue(221);
      case "condgas":
        return getValue(225);
      case "heatpump":
        return getValue(229);
      case "solar":
        return getValue(278);
      default:
        return "";
    }
  };

  const getImages = (key: any) => {
    switch (key) {
      case "window":
        return WindowDouble;
      // case "wallCav":
      //   return Wall_Cavity;
      case "wall":
        return Wall_Exterior;
      // case "roofAttic":
      //   return Roof_Attic;
      // case "roofIn":
      //   return Roof_Interior;
      case "roof":
        return Roof_Exterior;
      case "ventc+":
        return VentilationC;
      case "ventd":
        return VentilationD;
      case "condgas":
        return Boiler;
      case "heatpump":
        return HeatPump;
      case "glazing":
        return Glazing;
      case "solar":
        return SolarPanel;
      default:
        return "";
    }
  };

  return (
    <Transition appear show={isOpenModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center result_common_model_container">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block py-8 w-full">
              <div className="inline-flex flex-col w-full lg:w-1/2 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                <div className="relative flex justify-center bg-neutral-100 flex-shrink-0 px-6 pb-4 border-b border-neutral-200 text-center">
                  <h3
                    className="text-md m-0 text-gray-600 pt-4"
                    id="headlessui-dialog-title-70"
                  >
                    {renovationsModalTitle(activeRenovationToolTip)}
                  </h3>
                  <span className="absolute right-4 pt-3 text-black text-lg font-bold">
                    <CloseOutlined onClick={closeModal} />
                  </span>
                </div>
                <div className="px-8 py-8 flex items-center">
                  {/* left side  */}
                  <div className="w_20p">
                    {activeRenovationToolTip ? (
                      <img
                        src={
                          activeRenovationToolTip
                            ? getImages(activeRenovationToolTip)
                            : ""
                        }
                        alt=""
                        className="w-full"
                      />
                    ) : (
                      // <Avatar
                      //   radius="rounded-full"
                      // />
                      <div>Avatar</div>
                    )}
                  </div>
                  {/* right side */}
                  <div className="px-4 w_80p">
                    <span className="text-center font-medium text-sm text-gray-500">
                      {renovationsDescription(activeRenovationToolTip)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
export default Modal;
