import RenovationInformationModal from "./RenovationInformation";
import RefineHouseInformationModal from "./RefineNewHouseInformationModal";
import RefineHouseInformation2Modal from "./RefineNewHouseInformationModal2";
import BookAnAudit from "./BookAnAudit";
import PackModel from "./PackModel";
import SubsidyModel from "./subsideModel";
import SubsidiesMainModel from "./SubsidiesMainModel";
import LoanModel from "./Loan";

import TotalBudgetModal from "./TotalBudgetModal";
import AuditWaiting from "./AuditWaiting";
import ContactInfo from "./ContactInfo";
// import LoginModal from "./LoginModal";
// import ForgetPasswordModal from "./ForgetPasswordModal";
// import { useState } from "react";
// import { withRouter } from "react-router";

const Modal = (props: any) => {
  let {
    openModal,
    setActiveModal,
    isOpenModal,
    closeModal,
    activeModal,
    renovationModalContent,
    callTwo,
    callThree,
    userBuildingTemPId,
    activeRenovationToolTip,
    getValue,
    allRenovationRecord,
    userData,
    houseDetails,
    setRefinementCall3Record,
    refinementCall3Record,
    setRefinementCall2Record,
    setLoader,
    refinementCall2Record,
    setHouseCallRecordUpdated,
    houseCallRecordUpdated,
    setUserStatus,
    //
    setAllUserSelectedData,
    setEpcA,
    renovationsInvestmentPrice,
    defaultRenovations,
    setRenovations,
    userModalRenovations,
    setLogin,
    setFutureEpc,
    setPackLabel,
    setUserExistRenovations,
    setIsOpenModal,
    getResultContent,
    modelContent,
    setSelectedUserCombination,
    setSelectedPack,
    setCustomStars,
    setIsCallWaiting,
    setDownloadedRecord,
    getModalKeysHandler,
    setAllPacksCombinations,
    setRenovationLoader,
    renovationPackHandler,
    pythonLogicHandler,
    setGlazing,
    glazing,
    setAllCustomCombination
  } = props;

  // const [forgetPassword, setForgetPassword] = useState(false);

  const modalHandler = (modal: string) => {
    switch (modal) {
      case "renovation-information":
        return (
          <RenovationInformationModal
            activeRenovationToolTip={activeRenovationToolTip}
            renovationModalContent={renovationModalContent}
            closeModal={closeModal}
            isOpenModal={isOpenModal}
            getValue={getValue}
          />
        );
      case "refine-information":
        return (
          <RefineHouseInformationModal
            callThree={callThree}
            setActiveModal={setActiveModal}
            userBuildingTemPId={userBuildingTemPId}
            closeModal={closeModal}
            isOpenModal={isOpenModal}
            allRenovationRecord={
              refinementCall3Record &&
              Object.keys(refinementCall3Record)?.length !== 0 &&
              refinementCall3Record !== null &&
              refinementCall3Record !== undefined
                ? refinementCall3Record
                : houseDetails
            }
            houseDetails={houseDetails}
            houseCallRecordUpdated={houseCallRecordUpdated}
            setHouseCallRecordUpdated={setHouseCallRecordUpdated}
            setLoader={setLoader}
            userData={userData}
            setRefinementCall3Record={setRefinementCall3Record}
            //
            setAllUserSelectedData={setAllUserSelectedData}
            setEpcA={setEpcA}
            renovationsInvestmentPrice={renovationsInvestmentPrice}
            defaultRenovations={defaultRenovations}
            setRenovations={setRenovations}
            userModalRenovations={userModalRenovations}
            setUserExistRenovations={setUserExistRenovations}
            openModal={openModal}
            setIsOpenModal={setIsOpenModal}
            setSelectedUserCombination={setSelectedUserCombination}
            setFutureEpc={setFutureEpc}
            setSelectedPack={setSelectedPack}
            setCustomStars={setCustomStars}
            setIsCallWaiting={setIsCallWaiting}
            setDownloadedRecord={setDownloadedRecord}
            getValue={getValue}
            getModalKeysHandler={getModalKeysHandler}
            setAllPacksCombinations={setAllPacksCombinations}
            setRenovationLoader={setRenovationLoader}
            renovationPackHandler={renovationPackHandler}
            pythonLogicHandler={pythonLogicHandler}
            glazing={glazing}
            setAllCustomCombination={setAllCustomCombination}
          />
        );
      case "refine-information2":
        return (
          <RefineHouseInformation2Modal
            callTwo={callTwo}
            setActiveModal={setActiveModal}
            userBuildingTemPId={userBuildingTemPId}
            refinementCall2Record={refinementCall2Record}
            closeModal={closeModal}
            userData={userData}
            isOpenModal={isOpenModal}
            allRenovationRecord={houseDetails}
            setLoader={setLoader}
            setRefinementCall2Record={setRefinementCall2Record}
            houseCallRecordUpdated={houseCallRecordUpdated}
            setHouseCallRecordUpdated={setHouseCallRecordUpdated}
            // method to set renovations
            setAllUserSelectedData={setAllUserSelectedData}
            setEpcA={setEpcA}
            renovationsInvestmentPrice={renovationsInvestmentPrice}
            defaultRenovations={defaultRenovations}
            setRenovations={setRenovations}
            userModalRenovations={userModalRenovations}
            setUserExistRenovations={setUserExistRenovations}
            openModal={openModal}
            setIsOpenModal={setIsOpenModal}
            setSelectedUserCombination={setSelectedUserCombination}
            setFutureEpc={setFutureEpc}
            setSelectedPack={setSelectedPack}
            setCustomStars={setCustomStars}
            setIsCallWaiting={setIsCallWaiting}
            setDownloadedRecord={setDownloadedRecord}
            getValue={getValue}
            getModalKeysHandler={getModalKeysHandler}
            setAllPacksCombinations={setAllPacksCombinations}
            setRenovationLoader={setRenovationLoader}
            renovationPackHandler={renovationPackHandler}
            pythonLogicHandler={pythonLogicHandler}
            setGlazing={setGlazing}
            setAllCustomCombination={setAllCustomCombination}
          />
        );
      case "book-an-audit":
        return (
          <BookAnAudit
            getValue={getValue}
            closeModal={closeModal}
            isOpenModal={isOpenModal}
            //
            allRenovationRecord={allRenovationRecord}
            setUserStatus={setUserStatus}
            userData={userData}
            houseDetails={houseDetails}
          />
        );
      // case "login":
      //   return (
      //     <LoginModal
      //       setFutureEpc={setFutureEpc}
      //       setPackLabel={setPackLabel}
      //       setLogin={setLogin}
      //       closeModal={closeModal}
      //       isOpenModal={isOpenModal}
      //       openModal={openModal}
      //       setForgetPassword={setForgetPassword}
      //       forgetPassword={forgetPassword}
      //       setResultPageLoader={setLoader}
      //     />
      //   );
      case "pack-info":
        return (
          <PackModel
            closeModal={closeModal}
            isOpenModal={isOpenModal}
            getResultContent={getResultContent}
          />
        );
      case "subsidy-info":
        return (
          <SubsidiesMainModel
            closeModal={closeModal}
            isOpenModal={isOpenModal}
            getResultContent={getResultContent}
          />
        );
      case "total-modal":
        return (
          <TotalBudgetModal
            closeModal={closeModal}
            isOpenModal={isOpenModal}
            getResultContent={getResultContent}
            renovationModalContent={renovationModalContent}
          />
        );
      case "audit-waiting":
        return (
          <AuditWaiting
            getValue={getValue}
            closeModal={closeModal}
            isOpenModal={isOpenModal}
            allRenovationRecord={allRenovationRecord}
            setUserStatus={setUserStatus}
            userData={userData}
          />
        );

      case "contact-info":
        return (
          <ContactInfo
            getValue={getValue}
            closeModal={closeModal}
            isOpenModal={isOpenModal}
            allRenovationRecord={allRenovationRecord}
            setUserStatus={setUserStatus}
            userData={userData}
          />
        );
      case "loan":
        return (
          <LoanModel
            closeModal={closeModal}
            isOpenModal={isOpenModal}
            getResultContent={getResultContent}
          />
        );
      // case "terms-condition":
      //   return (
      //     <TermsAndConditions
      //       getValue={getValue}
      //       closeModal={closeModal}
      //       isOpenModal={isOpenModal}
      //     />
      //   );
      default:
        return "";
    }
  };
  return (
    <div>
      {/* {forgetPassword && (
        <ForgetPasswordModal setForgetPassword={setForgetPassword} />
      )} */}
      {modalHandler(activeModal)}
    </div>
  );
};
export default Modal;
