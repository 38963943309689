import React, { FC, useState } from "react";
import AdvantagesSideNav from "../../components/benefits/sideNav";
import LowerEnergy from "../../components/benefits/benefitsComponents/lowerEnergy";
import StrongerTogether from "../../components/benefits/benefitsComponents/strongerTogether";
import HelpWithApplying from "../../components/benefits/benefitsComponents/helpWithApplying";
import TogetherForPositive from "../../components/benefits/benefitsComponents/togetherForPositive";
import EasyProcess from "../../components/benefits/benefitsComponents/easyProcess";
import FreedomOfChoice from "../../components/benefits/benefitsComponents/freedomOfChoice";

interface AdvantagesInterface {}

const Advantages: FC<AdvantagesInterface> = () => {
  const [activeTab, setActiveTab] = useState(1);

  const ActiveTabHandler = (active: number) => {
    switch (active) {
      case 1:
        return <LowerEnergy />;
      case 2:
        return <StrongerTogether />;
      case 3:
        return <HelpWithApplying />;
      case 4:
        return <TogetherForPositive />;
      case 5:
        return <EasyProcess/>;
      case 6:
        return <FreedomOfChoice/>;
      default:
        return <LowerEnergy />;
    }
  };

  return (
    <div className="mx-[5%] lg:mx-[10%] flex flex-col lg:flex-row py-4 ">
      <div className="w-full lg:w-[33%]">
        <AdvantagesSideNav activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
      <div className="w-full lg:w-[67%]">{ActiveTabHandler(activeTab)}</div>
    </div>
  );
};
export default Advantages;
