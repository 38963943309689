import React, { FC, useContext, useMemo, useState } from "react";
import { Tooltip } from "antd";

// import Images
import Bg from "../../assets/images/home/bg.png";
import Calendar from "../../assets/images/home/calendar.png";
import Check from "../../assets/images/home/check.svg";
import Info from "../../assets/images/home/info.png";
// import components
import { GlobalContext } from "../../context/globalContext/globalContext";
import AutoComplete from "../../shared/autoComplete";
import { getValue } from "../../utils/getValue";
// import SelectorLanguage from "../../shared/languageSelector";
// import SideNav from "../../shared/layout/sidenav";
interface HomeInterface {
  setVisible?: any;
}

const Home: FC<HomeInterface> = ({ setVisible }) => {
  // global state
  const { state }: any = useContext(GlobalContext);

  const text = (
    <span
      dangerouslySetInnerHTML={{
        __html: getValue(state, 0, 143)?.props?.content,
      }}
    />
  );

  const [arrow, setArrow] = useState("Show");

  const mergedArrow = useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }

    if (arrow === "Show") {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  return (
    <div>
      {/* Side_nav */}
      {/* <SideNav /> */}
      {/* banner_content_div */}
      <div className="w-full  lg:flex">
        {/* left_sdie_div */}
        <div className="  w-full lg:w-3/6 px-6 md:px-[60px] lg:px-3 ">
          <div className="background-image">
            <div className="mx-0 lg:mx-[10%] py-5 lg:py-14">
              <div>
                <h2
                  className="text-black text-3xl lg:text-[38px] font-medium lg:font-semibold leading-[3rem]"
                  dangerouslySetInnerHTML={{
                    __html: getValue(state, 0, 0)?.props?.content,
                  }}
                />

                <p
                  className="text-xs lg:text-sm text-[#455259] font-normal py-[25px]"
                  dangerouslySetInnerHTML={{
                    __html: getValue(state, 0, 1)?.props?.content,
                  }}
                />
                <div className="flex justify-center items-center">
                  <p
                    className="text-[#007DDF] text-center text-sm"
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 0, 116)?.props?.content,
                    }}
                  />
                  <img
                    src={Info}
                    alt="Info"
                    className="h-[15px] pl-1 cursor-pointer"
                    onClick={() => setVisible(true)}
                  />
                </div>
                <div className="flex flex-col lg:flex-row  lg:justify-between pt-3 pb-5">
                  <div className="bg-white rounded-[11px] boxShaodw-homepage py-3 px-3 w-full lg:w-[42%] ">
                    <p
                      className="text-center text-3xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-[#77DB89] to-[#36A7B8] "
                      dangerouslySetInnerHTML={{
                        __html: getValue(state, 0, 118)?.props?.content,
                      }}
                    />
                    <p
                      className="text-center text-[10px] font-normal text-[#455259] pt-2"
                      dangerouslySetInnerHTML={{
                        __html: getValue(state, 0, 117)?.props?.content,
                      }}
                    />
                  </div>
                  <div className="flex justify-center w-full flex justify-center items-center w-full lg:w-[6%]">
                    <p
                      className="text-[22px] font-bold text-[#007DDF] "
                      dangerouslySetInnerHTML={{
                        // __html: getValue(state, 0, 120)?.props?.content,
                        __html: getValue(state, 0, 121)?.props?.content,
                      }}
                    />
                  </div>
                  <div className="bg-white rounded-[11px] boxShaodw-homepage py-3 px-3 w-full lg:w-[42%]">
                    <div>
                      <p
                        className="text-center text-3xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-[#77DB89] to-[#36A7B8] "
                        dangerouslySetInnerHTML={{
                          __html: getValue(state, 0, 120)?.props?.content,
                        }}
                      />
                    </div>
                    <p
                      className="text-center text-[10px] font-normal text-[#455259] pt-2 "
                      dangerouslySetInnerHTML={{
                        __html: getValue(state, 0, 119)?.props?.content,
                      }}
                    />
                  </div>
                </div>
                <div className="pb-5">
                  <div className="bg-white rounded-[11px] boxShaodw-homepage py-4 px-4 flex items-center justify-between">
                    <p
                      className="text-[#007DDF] text-[17px] font-bold whitespace-nowrap pr-2"
                      dangerouslySetInnerHTML={{
                        __html: getValue(state, 0, 122)?.props?.content,
                      }}
                    />
                    <p
                      className="text-[#455259] text-xs font-normal"
                      dangerouslySetInnerHTML={{
                        // __html: getValue(state, 0, 123)?.props?.content,
                        __html: getValue(state, 0, 123)?.props?.content,
                      }}
                    />
                  </div>
                </div>
                <div>
                  <p
                    className="text-[#455259] text-[14px] pb-5"
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 0, 124)?.props?.content,
                    }}
                  />
                </div>
              </div>
              <div className="">
                <AutoComplete showAutocomplete={"banner"} />
                <div className="py-3 flex justify-end">
                  <p className="text-sm text-[#8e9fa8] font-medium underline cursor-pointer">
                    {getValue(state, 0, 39)?.props?.content}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* right_side_div */}
        <div className="flex w-full lg:w-3/6 ">
          <div className="w-full h-full lg:h-[904px]">
            <img src={Bg} alt="bg" className="w-full h-full" />
          </div>
          {/* boxes_div */}
          <div>
            <div className="absolute h-fit md:w-[40%] lg:w-[26%] right-0  md:top-[970px] lg:top-[180px] top-control1">
              <div className=" bg-white border rounded-l-[10px] flex items-center py-[5px] px-2.5">
                <p className="text-[30px] lg:text-[40px] text-[#77DB89] font-semibold">
                  {getValue(state, 0, 40)?.props?.content}
                </p>
                <p
                  className="text-[#455259] text-xs lg:text-[14px]  font-normal px-2.5"
                  dangerouslySetInnerHTML={{
                    __html: getValue(state, 0, 41)?.props?.content,
                  }}
                />
              </div>
            </div>
            <div className="absolute h-fit  md:w-[34%] lg:w-[26%] xl:w-[24%] right-0  md:top-[1050px] lg:top-[280px] top-control2">
              <div className=" bg-white border rounded-l-[10px] flex items-center py-[5px] px-2.5 ">
                <p className="text-[30px] lg:text-[40px] text-[#007DDF] font-semibold">
                  {getValue(state, 0, 42)?.props?.content}
                </p>
                <p
                  className="text-[#455259] text-xs lg:text-sm  font-normal px-2.5 lg:px-1 2xl:px-4 "
                  dangerouslySetInnerHTML={{
                    __html: getValue(state, 0, 43)?.props?.content,
                  }}
                />
              </div>
            </div>
            <div className="absolute h-fit  md:w-[34%] lg:w-[26%] xl:w-[22%] 2xl:w-[18%] right-0  md:top-[1130px] lg:top-[360px] 2xl:top-[361px] top-control3 ">
              <div className=" bg-white border rounded-l-[10px] flex items-center p-2.5 ">
                <div className="flex items-center w-4/12">
                  {/* <div className="w-6/12">
                    <img src={Calendar} alt="calendar" />
                  </div> */}
                  <div className="w-6/12">
                    <p
                      className="text-[#455259] text-xs lg:text-sm  font-normal px-2"
                      dangerouslySetInnerHTML={{
                        __html: getValue(state, 0, 113)?.props?.content,
                      }}
                    />
                  </div>
                </div>
                <span
                  className="pl-2 pr-1 w-8/12 text-[14px]"
                  dangerouslySetInnerHTML={{
                    __html: getValue(state, 0, 112)?.props?.content,
                  }}
                ></span>
              </div>
            </div>
            <div className="absolute h-fit  md:w-[34%] lg:w-[26%] xl:w-[22%] 2xl:w-[18%] right-0  md:top-[1210px] lg:top-[430px] xl:top-[435px] 2xl:top-[440px] top-control4">
              <div className=" bg-white border rounded-l-[10px] flex items-center p-2.5 ">
                <div className="flex items-center w-4/12">
                  {/* <div className="w-6/12">
                    <img src={Calendar} alt="calendar" />
                  </div> */}
                  <div className="w-6/12">
                    <p
                      className="text-[#455259] text-xs lg:text-sm  font-normal px-2"
                      dangerouslySetInnerHTML={{
                        __html: getValue(state, 0, 115)?.props?.content,
                      }}
                    />
                  </div>
                </div>
                <span
                  className="pl-2 pr-1 w-8/12 text-[14px]"
                  dangerouslySetInnerHTML={{
                    __html: getValue(state, 0, 114)?.props?.content,
                  }}
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* images_text_div */}
      <div className="bg-[#F0F0F0] py-5">
        <div className=" flex flex-col lg:flex-row  justify-center items-start lg:items-center">
          <div className="flex items-center px-3 lg:px-16 py-2.5 lg:py-0">
            <img src={Check} alt="chek" />
            <p
              className="text-[#455259] text-sm font-bold pl-2.5"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 0, 3)?.props?.content,
              }}
            />
          </div>
          <div className="flex items-center px-3 lg:px-16 py-2.5 lg:py-0">
            <img src={Check} alt="chek" />
            <p
              className="text-[#455259] text-sm font-bold pl-2.5"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 0, 4)?.props?.content,
              }}
            />
          </div>
          <div className="flex items-center px-3 lg:px-16 py-2.5 lg:py-0">
            <img src={Check} alt="chek" />
            <p
              className="text-[#455259] text-sm font-bold pl-2.5"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 0, 5)?.props?.content,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
