import React, { useState, useEffect, useContext } from "react";
import { Input, AutoComplete } from "antd";
import { SelectProps } from "antd/es/select";
import UserRegisterModal from "../../shared/homePageModals/userRegisterationModal";
// import { getAddressesByService } from "../../services/homepage";
import { useNavigate } from "react-router";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { getValue } from "../../utils/getValue";
// import AddressModal from "shared/Modal/AddressesModal";
import InputBanner from "./inputBanner";
import InputRenovation from "./inputRenovation";
import InputHeader from "./inputHeader";

interface SearchedData {
  value: string;
  className?: string;
  label?: any;
  modelText?: any;
  showAutocomplete?: any;
}

const AutoCompleteSearch = (props: any) => {
  const {
    history,
    className,
    bottomText,
    searchText,
    modelText,
    showAutocomplete,
  } = props;
  const [addresses, setAddresses]: any = useState([]);
  const [options, setOptions] = useState<SelectProps<object>["options"]>([]);
  const [selectedAddress, setSelectedAddress]: any = useState();
  const [isSearch, setIsSearch]: any = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const data: any = [];
  const navigate = useNavigate();
  const { state } = useContext(GlobalContext);
  /**
   * Sort addresses by house address
   * @param addresses list of house addresses.
   * @returns Sorted list
   */
  const sortedAddress = (addresses: any) => {
    return addresses?.sort((a: any, b: any) => {
      //  return a.street_number - b.street_number
      if (a.house_address < b.house_address) {
        return -1;
      } else if (a.house_address > b.house_address) {
        return 1;
      } else {
        // nothing to split them
        return 0;
      }
    });
  };

  useEffect(() => {
    if (state?.getAllAdresses) {
      setAddresses(sortedAddress(state?.getAllAdresses));
    }
  }, [state?.getAllAdresses]);

  const searchResult = (query: string): void => {
    const filter = addresses
      ? addresses.filter((adresses: any) => {
          return (
            adresses.house_address
              .toLowerCase()
              .indexOf(query.toLowerCase()) !== -1
          );
        })
      : [];

    if (filter && query !== "") {
      filter
        // .splice(0, 5)
        .map((address: any, idx: any) => {
          return data.push({
            value: address?.house_address,
            label: address?.house_address,
          });
        });
      setOptions(data);
    }
  };

  const showAllAddresses = () => {
    addresses.map((address: any, idx: any) => {
      return data.push({
        value: address?.house_address,
        label: address?.house_address,
      });
    });
    setOptions(data);
  };

  const handleSearch = (value: string) => {
    if (!value) {
      setIsSearch(false);
      showAllAddresses();
    } else {
      setIsSearch(true);
      searchResult(value);
      let searchedRecord = addresses?.filter(
        (item: any) => item.house_address === value
      );
      setSelectedAddress(searchedRecord);
    }
  };
  const onSelect = (value: string, option: any) => {
    let searchedRecord = addresses?.filter(
      (item: any) => item.house_address === value
    );
    setSelectedAddress(searchedRecord);
  };
  const selectAddressHandler = () => {
    // if (selectedAddress[0]?.building_id > 69101) {
    //   setIsOpenModal(true);
    // } else if (selectedAddress?.length !== 0) {
    // history.push(
    //   `/listing-renovate-detail/${
    //     selectedAddress[0]?.userdataurl?.split("/")[4] +
    //     "?gmlid=GRB_OIDN_" +
    //     selectedAddress[0]?.GRB_OIDN_
    //   }`
    // );
    // }
    // agc v2 addresses redirect on select
    if (selectedAddress && selectedAddress?.length !== 0) {
      //console.log("ismodel close");
      let link_id =
        selectedAddress && selectedAddress[0]?.link_id.replace(/\//g, "-");
      navigate(`/result/${link_id}`);
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  // inputHandler
  const inputHandler = (value: any) => {
    switch (value) {
      case "banner":
        return (
          <InputBanner
            options={options}
            dropdownClassName={`${isSearch && "list-container"}`}
            onSelect={onSelect}
            onSearch={handleSearch}
            notFoundContent={"Pas d’adresse correspondante"}
            onFocus={showAllAddresses}
            selectAddressHandler={selectAddressHandler}
            placeholder={getValue(state, 0, 153)?.props?.content}
            buttonText={getValue(state, 0, 154)?.props?.content}
          />
        );
      case "renovation":
        return (
          <InputRenovation
            options={options}
            dropdownClassName={`${isSearch && "list-container"}`}
            onSelect={onSelect}
            onSearch={handleSearch}
            notFoundContent={"Pas d’adresse correspondante"}
            onFocus={showAllAddresses}
            selectAddressHandler={selectAddressHandler}
            placeholder={getValue(state, 0, 153)?.props?.content}
            buttonText={getValue(state, 0, 154)?.props?.content}
          />
        );
      case "header":
        return (
          <InputHeader
            options={options}
            dropdownClassName={`${isSearch && "list-container"}`}
            onSelect={onSelect}
            onSearch={handleSearch}
            notFoundContent={"Pas d’adresse correspondante"}
            onFocus={showAllAddresses}
            selectAddressHandler={selectAddressHandler}
            placeholder={getValue(state, 0, 153)?.props?.content}
            buttonText={getValue(state, 0, 154)?.props?.content}
          />
        );
      default:
        return (
          <InputHeader
            options={options}
            dropdownClassName={`${isSearch && "list-container"}`}
            onSelect={onSelect}
            onSearch={handleSearch}
            notFoundContent={"Pas d’adresse correspondante"}
            onFocus={showAllAddresses}
            selectAddressHandler={selectAddressHandler}
            placeholder={getValue(state, 0, 153)?.props?.content}
            buttonText={getValue(state, 0, 154)?.props?.content}
          />
        );
    }
  };
  return (
    <>
      {isOpenModal && (
        <UserRegisterModal visible={isOpenModal} setVisible={setIsOpenModal} />
      )}
      {inputHandler(showAutocomplete)}
    </>
  );
};
export default AutoCompleteSearch;
