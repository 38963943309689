import React, { FC, useContext } from "react";
// import images
import Static from "../../../assets/images/advantages/stat.png";
import Check from "../../../assets/images/advantages/check.svg";

// import components
import { GlobalContext } from "../../../context/globalContext/globalContext";
import { getValue } from "../../../utils/getValue";
import InputTabs from "../../../shared/autoComplete";

interface HelpWithApplyingInterface {}

const HelpWithApplying: FC<HelpWithApplyingInterface> = () => {
  const { state }: any = useContext(GlobalContext);
  return (
    <div>
      <div>
        <img src={Static} alt="Static" />
      </div>
      <p
        className="text-black text-[20px] py-4"
        dangerouslySetInnerHTML={{
          __html: getValue(state, 1, 36)?.props?.content,
        }}
      ></p>

      <p
        className="text-[#6B7280] text-base font-normal pb-4 "
        dangerouslySetInnerHTML={{
          __html: getValue(state, 1, 37)?.props?.content,
        }}
      />
      <div className="flex flex-col lg:flex-row space-x-3 justify-between  py-8">
        <div className="w-full lg:w-3/6">
          <p
            className="text-[40px] text-transparent bg-clip-text  bg-gradient-to-b from-[#007ddf] to-[#9fea61] font-medium"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 39)?.props?.content,
            }}
          />

          <p
            className="text-[#6B7280] text-base font-light py-2"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 40)?.props?.content,
            }}
          />
        </div>
        <div className="w-full lg:w-3/6">
          <p
            className="text-[40px] text-transparent bg-clip-text  bg-gradient-to-b from-[#007ddf] to-[#9fea61] font-medium"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 42)?.props?.content,
            }}
          />
          <p
            className="text-[#6B7280] text-base font-light py-2"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 43)?.props?.content,
            }}
          />
        </div>
      </div>
      <div>
        <p
          className="text-black text-base font-normal pb-2 "
          dangerouslySetInnerHTML={{
            __html: getValue(state, 1, 47)?.props?.content,
          }}
        />
        <p
          className="text-[#6B7280] text-base font-medium "
          dangerouslySetInnerHTML={{
            __html: getValue(state, 1, 48)?.props?.content,
          }}
        />
        <div className=" flex items-center space-x-3 py-2">
          <img src={Check} alt="check" className="h-4" />
          <p
            className="text-[#6B7280] text-base font-normal  "
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 164)?.props?.content,
            }}
          />
        </div>
        <div className=" flex items-center space-x-3 ">
          <img src={Check} alt="check" className="h-4" />
          <p
            className="text-[#6B7280] text-base font-normal  "
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 165)?.props?.content,
            }}
          />
        </div>
      </div>
      {/* table div */}
      {/*
      <div className="py-8 flex lg:flex-row flex-col  lg:space-x-6 justify-between">
        <div className="w-full lg:w-[70%] overflow-x-auto">
          <table className="help-with-apply-table">
            <tr>
              <th
                className="px-2  text-black text-base font-normal  "
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 1, 49)?.props?.content,
                }}
              />
              <th
                className="px-2  text-black text-base font-normal  "
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 1, 50)?.props?.content,
                }}
              />
              <th
                className="px-2  text-black text-base font-normal  "
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 1, 51)?.props?.content,
                }}
              />
              <th
                className="px-2  text-black text-base font-normal  "
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 1, 166)?.props?.content,
                }}
              />
            </tr>
            <tr>
              <td
                className="px-2  text-black text-sm font-normal  "
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 1, 121)?.props?.content,
                }}
              />
              <td
                className="px-2  text-black text-sm font-normal  "
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 1, 122)?.props?.content,
                }}
              />
              <td
                className="px-2  text-black text-sm font-normal  "
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 1, 123)?.props?.content,
                }}
              />
              <td
                className="px-2  text-black text-sm font-normal  "
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 1, 173)?.props?.content,
                }}
              />
            </tr>
            <tr>
              <td
                className="px-2  text-black text-sm font-normal  "
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 1, 125)?.props?.content,
                }}
              />
              <td
                className="px-2  text-black text-sm font-normal  "
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 1, 122)?.props?.content,
                }}
              />
              <td
                className="px-2  text-black text-sm font-normal  "
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 1, 126)?.props?.content,
                }}
              />
              <td
                className="px-2  text-black text-sm font-normal  "
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 1, 178)?.props?.content,
                }}
              />
            </tr>
            <tr>
              <td
                className="px-2  text-black text-sm font-normal  "
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 1, 190)?.props?.content,
                }}
              />
              <td
                className="px-2  text-black text-sm font-normal  "
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 1, 191)?.props?.content,
                }}
              />
              <td
                className="px-2  text-black text-sm font-normal  "
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 1, 192)?.props?.content,
                }}
              />
              <td
                className="px-2  text-black text-sm font-normal  "
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 1, 183)?.props?.content,
                }}
              />
            </tr>
          </table>
        </div>
        <div className="overflow-x-auto w-full lg:w-[30%]">
          <div className="flex  ">
            <div className="w-full lg:w-fit">
              <p
                className="text-center  text-white text-base font-normal  !bg-[#c4d500]"
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 1, 167)?.props?.content,
                }}
              />
              <div className="p-2 flex justify-between lg:items-center w-full !bg-[#f1f5c7]">
                <p
                  className=" text-black text-base font-normal "
                  dangerouslySetInnerHTML={{
                    __html: getValue(state, 1, 168)?.props?.content,
                  }}
                />
                <p
                  className="  text-black text-base font-normal "
                  dangerouslySetInnerHTML={{
                    __html: getValue(state, 1, 169)?.props?.content,
                  }}
                />
              </div>
              <div className="flex justify-around p-2 space-x-8">
                <div className="space-y-8">
                  <p
                    className="text-black text-base font-normal"
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 1, 174)?.props?.content,
                    }}
                  />
                  <p
                    className="text-black text-base font-normal "
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 1, 179)?.props?.content,
                    }}
                  />
                </div>
                <div className="space-y-8">
                  <p
                    className="text-black text-base font-normal"
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 1, 175)?.props?.content,
                    }}
                  />
                  <p
                    className="text-black text-base font-normal "
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 1, 180)?.props?.content,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-full lg:w-fit">
              <p
                className="text-center  text-white text-base font-normal  !bg-[#50aa37]"
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 1, 167)?.props?.content,
                }}
              />
              <div className="p-2 flex justify-around lg:items-center w-full !bg-[#cfead0]">
                <p
                  className=" text-black text-base font-normal "
                  dangerouslySetInnerHTML={{
                    __html: getValue(state, 1, 168)?.props?.content,
                  }}
                />
                <p
                  className="  text-black text-base font-normal "
                  dangerouslySetInnerHTML={{
                    __html: getValue(state, 1, 169)?.props?.content,
                  }}
                />
              </div>
              <div className="flex justify-around p-2 space-x-8">
                <div className="space-y-8">
                  <p
                    className="text-black text-base font-normal"
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 1, 176)?.props?.content,
                    }}
                  />
                  <p
                    className="text-black text-base font-normal "
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 1, 181)?.props?.content,
                    }}
                  />
                </div>
                <div className="space-y-8">
                  <p
                    className="text-black text-base font-normal"
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 1, 177)?.props?.content,
                    }}
                  />
                  <p
                    className="text-black text-base font-normal "
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 1, 182)?.props?.content,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
                  */}
      <div className="bg-[#007DDF] rounded-[20px] p-[40px] my-14">
        <p
          className="text-white text-xl font-medium pb-6"
          dangerouslySetInnerHTML={{
            __html: getValue(state, 1, 127)?.props?.content,
          }}
        />
        <InputTabs showAutocomplete="renovation" />
      </div>
    </div>
  );
};
export default HelpWithApplying;
