import React, { FC, useContext } from "react";
// import images
import Furniture from "../../../assets/images/renovates/furniture.jpg";
import WindowDouble from "../../../assets/images/renovates/windowdouble.png";
import Glazing from "../../../assets/images/renovates/Glazing.gif";

// import components
import { GlobalContext } from "../../../context/globalContext/globalContext";
import InputTabs from "../../../shared/autoComplete";
import { getValue } from "../../../utils/getValue";

interface WindowsRenovationsInterface {}

const WindowsRenovations: FC<WindowsRenovationsInterface> = () => {
  const { state }: any = useContext(GlobalContext);
  console.log("getValue", getValue(state, 3, 0));
  return (
    <div>
      <div>
        <img src={Furniture} alt="furniture" />
      </div>
      <p
        className="text-black text-[20px] py-4"
        dangerouslySetInnerHTML={{
          __html: getValue(state, 3, 1)?.props?.content,
        }}
      ></p>
      <p
        className="text-[#6B7280] text-base py-4 "
        dangerouslySetInnerHTML={{
          __html: getValue(state, 3, 27)?.props?.content,
        }}
      />
      <div className="flex flex-col lg:flex-row  py-8 space-x-0 lg:space-x-5">
        {/* left */}
        <div className="w-full lg:w-[50%]">
          <p
            className="text-[#6B7280] text-lg py-2 font-semibold"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 3, 5)?.props?.content,
            }}
          />
          <p
            className="text-[#6B7280] text-base font-normal py-2"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 3, 29)?.props?.content,
            }}
          />
        </div>
        {/* right */}
        <div className="w-full lg:w-[50%]  flex flex-row items-center justify-center lg:justify-between pt-3 lg:pt-0">
          <div>
            <img src={WindowDouble} alt="windowdouble" className="h-40" />
            <p
              className="text-[#6B7280] text-base font-normal text-center py-4"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 3, 31)?.props?.content,
              }}
            />
          </div>
          <div>
            <img src={Glazing} alt="Glazing" className="h-40" />
            <p
              className="text-[#6B7280] text-base font-normal text-center py-4"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 3, 33)?.props?.content,
              }}
            />
          </div>
        </div>
      </div>
      <div className="bg-[#007DDF] rounded-[20px] p-[40px] mb-14">
        <p
          className="text-white text-xl font-medium pb-6"
          dangerouslySetInnerHTML={{
            __html: getValue(state, 3, 37)?.props?.content,
          }}
        />

        <InputTabs showAutocomplete="renovation" />
      </div>
    </div>
  );
};
export default WindowsRenovations;
