// get building type agc-v2
export const getbuilding_type = (value: any) => {
  let total_facade: any = parseInt(value);
  // console.log("total_facade", total_facade);

  switch (total_facade) {
    case 1:
      return 2;
    case 2:
      return 2;
    case 4:
      return 4;
    case 3:
      return 3;
    default:
      return "";
  }
};

export const get_construction_date = (const_date: any) => {
  //de 1850 jusqu'à 1874
  // const_date = "de 1850 jusqu'à 1874";
  let formatted_date =
    typeof const_date !== "number" && const_date?.includes("de")
      ? const_date.split(" ")[1]
      : const_date;

  if (parseInt(formatted_date) < 1900) {
    return 1900;
  } else {
    return formatted_date;
  }
  // console.log("total_facade", total_facade);
};

export const windowAndDoorsv2 = (value: any, getValue: any) => {
  switch (value) {
    case "":
      return getValue && getValue(86)?.props?.content
        ? getValue(86).props.content
        : getValue(86);
    case "S":
      return getValue && getValue(87)?.props?.content
        ? getValue(87).props.content
        : getValue(87);
    case "ordinary_double":
      return (
        getValue &&
        `${
          getValue(88)?.props?.content
            ? getValue(88).props.content
            : getValue(88)
        } "<2000"`
      );
    case "D":
      return (
        getValue &&
        `${
          getValue(88)?.props?.content
            ? getValue(88).props.content
            : getValue(88)
        } "<2000"`
      );
    case "DR":
      return (
        getValue &&
        `${
          getValue(89)?.props?.content
            ? getValue(89).props.content
            : getValue(89)
        } ">2000"`
      );
    case "T":
      return (
        getValue &&
        `${
          getValue(90)?.props?.content
            ? getValue(90).props.content
            : getValue(90)
        }`
      );
    default:
      return "";
  }
};

export const wallInsulationv2 = (construction_date: any) => {
  //    console.log("wallInsulationv2", construction_date);
  if (parseInt(construction_date) < 1960) {
    return "6";
  } else if (
    parseInt(construction_date) >= 1960 &&
    parseInt(construction_date) < 2006
  ) {
    return "7";
  } else if (parseInt(construction_date) >= 2006) {
    return "10";
  }
};

export const roofInsulationv2 = (construction_date: any) => {
  //construction_date = 2009;
  if (parseInt(construction_date) < 1960) {
    return "6";
  } else if (
    parseInt(construction_date) >= 1960 &&
    parseInt(construction_date) < 1985
  ) {
    return "12";
  } else if (
    parseInt(construction_date) >= 1986 &&
    parseInt(construction_date) < 2006
  ) {
    return "18";
  } else if (parseInt(construction_date) >= 2006) {
    return "20";
  }
};
