import React, { FC, useState } from "react";
import Home from "../../components/homePage/home";
import OurClient from "../../components/homePage/ourClients";
import YourSide from "../../components/homePage/yourSide";
import PositivePoint from "../../components/homePage/positivePoints";
import Process from "../../components/homePage/process";
import HomepageModal from "../../shared/homePageModals/homepageBannerModel";

interface HomeInterface {}

const HomePage: FC<HomeInterface> = () => {
  const [visible, setVisible] = useState(false);
  return (
    <div>
      {/* model */}
      <HomepageModal setVisible={setVisible} visible={visible} />
      {/* compoennts */}
      <Home setVisible={setVisible} />
      <OurClient />
      <YourSide />
      <PositivePoint />
      <Process />
    </div>
  );
};

export default HomePage;
