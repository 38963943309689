import React, { FC, useState, useMemo, useEffect, Fragment } from "react";
import { Button, Input, Select, Slider, Tooltip } from "antd";
import Avatar from "../../shared/Avatar/Avatar";
import informationImg from "../../assets/images/resultPage2/information.png";
import {
  pricesDotHandler,
  removeDotHandler,
} from "../../containers/result/constants";
import { InfoCircleOutlined } from "@ant-design/icons";

export interface SectionRenovationLoanType {
  className?: string;
  getResultContent?: any;
  totalValue?: any;
  setRenovationModalContent?: any;
  openModal?: any;
  renovationLoader?: any;
}

const SectionRenovationLoan: FC<SectionRenovationLoanType> = ({
  className,
  getResultContent,
  totalValue,
  setRenovationModalContent,
  openModal,
  renovationLoader,
}) => {
  const [formData, setFormData] = useState({
    intrest: 2.2,
    month: 12,
    loan: 0,
  });
  let marks2: any = {};
  useEffect(() => {
    setFormData({
      intrest: 2.2,
      month: 60,
      loan: totalValue,
    });
    calculateLoan(2.2, 60, totalValue);
  }, [totalValue]);

  const { intrest, month, loan } = formData;
  const [monthlyInstallment, setMonthlyInstallment]: any = useState(0);

  const handleIntrestRateChange = (value: any) => {
    setFormData({ ...formData, ["intrest"]: value });
    calculateLoan(value, formData.month, formData.loan);
  };

  const handleMonthsChange = (value: any) => {
    setFormData({ ...formData, ["month"]: value });
    calculateLoan(formData.intrest, value, formData.loan);
  };

  const handleChange = (e: any) => {
    if (removeDotHandler(e.target.value) <= totalValue) {
      setFormData({
        ...formData,
        [e.target.name]: removeDotHandler(e.target.value),
      });
      calculateLoan(
        formData.intrest,
        formData.month,
        removeDotHandler(e.target.value) ? removeDotHandler(e.target.value) : 0
      );
    }
  };

  const calculateLoan = (intrestRate: any, months: any, paidAmount: any) => {
    if (intrestRate) {
      let amnt: any = Math.pow(1 + intrestRate / 100, 0.083) - 1;
      amnt = amnt.toFixed(4) * 100;
      const amount: any = paidAmount;
      let amt: any = amount * (amnt / 100);
      amt = amt.toFixed(2);
      let pow: any = Math.pow(1 + amnt / 100, months);
      let nomenator: any = amt * pow;
      let denomenator: any = Math.pow(1 + amnt / 100, months) - 1;
      const total: any = nomenator / denomenator;
      if (Math.round(total)) {
        setMonthlyInstallment(Math.round(total));
      } else {
        setMonthlyInstallment(0);
      }
    } else {
      let total: any = paidAmount / months;
      setMonthlyInstallment(Math.round(total));
    }
  };

  const renovationPricesHandler = (value: any) => {
    let price = pricesDotHandler(value);
    return price;
  };

  // slider
  const marks = {
    12: "12",
    120: `${120}`,
  };

  return (
    <div className="listingSection__wrap sectionLoad_container py-[22px]  !space-y-6 border border-[#E3E7EB] rounded-[10px]">
      <div className="flex items-center">
        <h2 className="fs_22 m-0 px-4 text-[22px] text-black font-bold">
          {getResultContent(57)}
        </h2>
        <span
          className="px-3 flex justify-center cursor-pointer"
          onClick={() => {
            openModal("loan");
            setRenovationModalContent("total");
          }}
        >
          <InfoCircleOutlined className="text-[#007DDF] text-[16px]" />
        </span>
      </div>
      <div>
        <div className="flex flex-col justify-between lg:flex-row">
          <div className="px-4 w-full lg:w-1/2">
            <span className="block mt-2 text-[#455259] text-base font-bold pb-8">
              {getResultContent(58)}
            </span>
            {/* <Tooltip title={intrest}>
              <span className="text-white">.</span> */}
            <Slider
              // tooltipVisible={false}
              tooltip={{ open: renovationLoader ? false : true }}
              max={5}
              min={0}
              value={intrest}
              onChange={handleIntrestRateChange}
              step={0.1}
              marks={{ 0: "0", 5: "5" }}
              tooltipPrefixCls="slider-active-tooltip"
            />
            {/* <div className="flex justify-center">
              <div className="w-32">
                <Input className="text-center " disabled value={intrest} />
              </div>
            </div> */}
            {/* </Tooltip> */}
            <div className="flex result_common_model_container items-center">
              {intrest == 0 && (
                <div className="flex items-center">
                  <span className="block pr-2 text-green-400">
                    {getResultContent(293)}
                    {/* Green Loan */}
                  </span>
                  <Tooltip title={getResultContent(262)} color={"#000"} key={1}>
                    <div className="tool_tip_button flex items-end">
                      <Avatar
                        imgUrl={informationImg}
                        sizeClass="h-4 w-4"
                        radius="rounded-full cursor-pointer"
                      />
                    </div>
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
          <div className="px-4 w-full lg:w-1/2">
            <span className="block mt-2 text-[#455259] text-base font-bold pb-8">
              {getResultContent(59)}
            </span>
            {/* <Tooltip title={month}>
              <span className="text-white">.</span> */}
            <Slider
              // tooltipVisible={false}
              tooltip={{ open: renovationLoader ? false : true}}
              marks={marks}
              value={month}
              onChange={handleMonthsChange}
              max={120}
              min={12}
              tooltipPrefixCls="slider-active-tooltip"
            />
            {/* <div className="flex justify-center">
              <div className="w-32">
                <Input className="text-center" disabled value={month} />
              </div>
            </div> */}
            {/* </Tooltip> */}
          </div>
        </div>
        <div className="flex flex-col justify-between lg:flex-row pt-8">
          <div className="px-4 w-full lg:w-1/2">
            <span className="block my-2 text-[#455259] text-base font-bold">
              {getResultContent(60)}
            </span>
            <Input
              type="number"
              onChange={handleChange}
              name="loan"
              value={loan && renovationPricesHandler(loan)}
              max={88}
              min={0}
              className="text-[#222222] font-normal text-base border border-[#CCCCCC] rounded-[5px]"
            />
          </div>
          <div className="px-4 w-full  lg:w-1/2 mt-2 lg:mt-0">
            <div className="py-4 rounded-[10px] px-8 bg-[#F4F4F6]">
              <h2 className="m-0 text-[22px] text-[#007DDF] font-bold">
                {renovationPricesHandler(parseInt(monthlyInstallment))} €
                <span className="text-[#455259] text-base font-normal">
                  /mois
                </span>
              </h2>
              <span className="block text-sm mt-2 text-[#A1A7AB] font-normal dark:text-neutral-400">
                {getResultContent(80)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionRenovationLoan;
