import React, { FC, useState } from "react";
import { Button } from "antd";
// import HandImg from "../../images/hands/hand_3.png";
import { InfoCircleOutlined } from "@ant-design/icons";
export interface SectionHeroProps {
  className?: string;
  subsidies: number;
  getResultContent: any;
  setSubsidies: any;
  userStatus: any;
  openModal?: any;
}

const SectionSubsidies: FC<SectionHeroProps> = ({
  className,
  getResultContent,
  setSubsidies,
  subsidies,
  openModal,
  userStatus,
}) => {
  return (
    <div className="listingSection__wrap packs_container !space-y-6 shadow-[0_0_20px_#0000001A] border border-[#F0F0F0] py-10 md:px-36 rounded-md px-10">
      <div className="flex justify-center items-center">
        <p className="text-[22px] font_semibold m-0">
          {" "}
          {getResultContent && getResultContent(295)}
        </p>
        <span
          className="px-3 flex justify-center cursor-pointer"
          onClick={() => {
            openModal("subsidy-info");
          }}
        >
          <InfoCircleOutlined className="ml-5 text-[#007DDF]" />
        </span>
      </div>
      <div className="flex justify-center">
        <p className="text-center text-[14px]">
          {getResultContent && getResultContent(296)}
        </p>
      </div>
      <div className="flex justify-center flex-wrap">
        {userStatus?.data &&
        userStatus?.data &&
        userStatus?.data?.status &&
        parseInt(userStatus?.data?.status) > 4 ? (
          ""
        ) : (
          <>
            <div className="px-2 py-2">
              <button
                className={`
                  ${
                    subsidies === 1
                      ? "bg-[#007DDF] text-white border border-[#007DDF2B]"
                      : "bg-white text-[#007DDF] border border-[#007DDF2B]"
                  }
                 rounded-[4px] px-[14px] py-2 lg:px-7 lg:py-2.5 text-[16px]`}
                onClick={() => {
                  setSubsidies(1);
                }}
              >
                {" "}
                {getResultContent && getResultContent(298)}
              </button>
            </div>
            <div className="px-2 py-2">
              <button
                className={`
                  ${
                    subsidies === 2
                      ? "bg-[#007DDF] text-white border border-[#007DDF2B]"
                      : "bg-white text-[#007DDF] border border-[#007DDF2B]"
                  }
                 rounded-[4px] px-[14px] py-2 lg:px-7 lg:py-2.5 text-[16px]`}
                onClick={() => {
                  setSubsidies(2);
                }}
              >
                {" "}
                {getResultContent && getResultContent(299)}
              </button>
            </div>
          </>
        )}
      </div>
      {/* </> */}
      {/* )} */}
    </div>
    // </div>
  );
};

export default SectionSubsidies;
