import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
// import ButtonClose from "shared/ButtonClose/ButtonClose";
import {
  Slider,
  Checkbox,
  Radio,
  Button,
  Tooltip,
  Input,
  Space,
  Col,
  InputNumber,
  Row,
} from "antd";
import {
  getbuilding_type,
  get_construction_date,
} from "../../utils/buildingDetailsHandler";
import {
  UpdateBuildingHouseParams,
  GetUserPacksData,
} from "../../services/ResultPage";
import { GetContentByService } from "../../services/homepage";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { updateRenovations } from "./constant";
import { renovationPostProcessingForPackB } from "../../containers/result/constants";
import { Markup } from "interweave";
import { CloseOutlined } from "@ant-design/icons";

const RadioGroup = Radio.Group;
const CheckboxGroup = Checkbox.Group;

const Modal = (props: any) => {
  let {
    isOpenModal,
    closeModal,
    allRenovationRecord,
    userData,
    setActiveModal,
    setIsOpenModal,
    setRefinementCall3Record,
    setLoader,
    setHouseCallRecordUpdated,
    houseCallRecordUpdated,
    //
    setAllUserSelectedData,
    setEpcA,
    renovationsInvestmentPrice,
    defaultRenovations,
    setRenovations,
    userModalRenovations,
    houseDetails,
    setUserExistRenovations,
    openModal,
    setSelectedUserCombination,
    setSelectedPack,
    setFutureEpc,
    setCustomStars,
    setIsCallWaiting,
    setDownloadedRecord,
    getValue,
    getModalKeysHandler,
    setAllPacksCombinations,
    setRenovationLoader,
    renovationPackHandler,
    pythonLogicHandler,
    glazing,
    setAllCustomCombination
  } = props;

  const [inputValue, setInputValue] = useState(1);

  const onChange = (newValue: number) => {
    setInputValue(newValue);
  };

  const marks = {
    1900: "<1900",
    2021: "2023",
  };
  const marks2 = {
    0: "1",
    25: "2",
    50: "3",
    75: "4",
    100: "5+",
  };

  const convertValueToSteps = (value: any) => {
    if (value === 0) {
      return 1;
    }
    if (value === 25) {
      return 2;
    }
    if (value === 50) {
      return 3;
    }
    if (value === 75) {
      return 4;
    }
    if (value === 100) {
      return 5;
    }
  };
  // const [sectionContent, setSectionContent]: any = useState([]);
  // useEffect(() => {
  //   let cityId: any = { id: 197 };
  //   GetContentByService(cityId)
  //     .then((response) => {
  //       setSectionContent(response?.data?.data?.page_contents);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  // Method to return value through index.
  // const getValue = (index: number): any => {
  //   return sectionContent?.length !== 0 && sectionContent[index]?.value ? (
  //     <Markup content={sectionContent[index]?.value} />
  //   ) : (
  //     ""
  //   );
  // };
  const [activeConstructionYear, setActiveConstructionYear]: any =
    useState(false);

  // get building type agc-v2
  // const getbuilding_type = (value: any) => {
  //   let total_facade: any = parseInt(value) + 1;
  //   // console.log("total_facade", total_facade);

  //   switch (total_facade) {
  //     case 1:
  //       return 2;
  //     case 2:
  //       return 2;
  //     case 4:
  //       return 4;
  //     case 3:
  //       return 3;
  //     default:
  //       return "";
  //   }
  // };
  // const get_construction_date = (const_date: any) => {
  //   //de 1850 jusqu'à 1874
  //   // console.log("const_date", const_date);
  //   // const_date = "de 1850 jusqu'à 1874";
  //   let formatted_date =
  //     typeof const_date !== "number" && const_date?.includes("de")
  //       ? const_date.split(" ")[1]
  //       : const_date;

  //   if (parseInt(formatted_date) < 1900) {
  //     return 1900;
  //   } else {
  //     return formatted_date;
  //   }
  //   // console.log("total_facade", total_facade);
  // };

  console.log("refinementCall3Record-allRenovationRecord", allRenovationRecord);

  const [buildingValues, setBuildingValues]: any = useState({
    building_type:
      allRenovationRecord && allRenovationRecord?.building_type !== undefined
        ? allRenovationRecord?.building_type
        : allRenovationRecord?.builiding_type !== null
        ? allRenovationRecord?.builiding_type
        : "",
    construction_date:
      allRenovationRecord?.construction_date !== null
        ? get_construction_date(allRenovationRecord?.construction_date)
        : 0,
    floors:
      allRenovationRecord?.num_floors !== null
        ? (Number(allRenovationRecord?.num_floors) === 1 && "1") ||
          (Number(allRenovationRecord?.num_floors) < 3 && "2") ||
          (Number(allRenovationRecord?.num_floors) >= 3 && "3")
        : 0,
    residents:
      allRenovationRecord?.num_hob !== undefined
        ? (Number(allRenovationRecord?.num_hob) === 1 && 1) ||
          (Number(allRenovationRecord?.num_hob) === 2 && 2) ||
          (Number(allRenovationRecord?.num_hob) === 3 && 3) ||
          (Number(allRenovationRecord?.num_hob) === 4 && 4) ||
          (Number(allRenovationRecord?.num_hob) >= 5 && 5)
        : // default
        allRenovationRecord?.num_hob !== null
        ? (Number(allRenovationRecord?.num_hob) === 1 && 1) ||
          (Number(allRenovationRecord?.num_hob) === 2 && 2) ||
          (Number(allRenovationRecord?.num_hob) === 3 && 3) ||
          (Number(allRenovationRecord?.num_hob) === 4 && 4) ||
          (Number(allRenovationRecord?.num_hob) >= 5 && 5)
        : 0,
    has_attic:
      allRenovationRecord?.has_attic === null ||
      allRenovationRecord?.has_attic === undefined ||
      allRenovationRecord?.has_attic === 1
        ? 1
        : 0,
    energyConsumer:
      allRenovationRecord?.energyConsumer !== null &&
      allRenovationRecord?.energyConsumer !== undefined
        ? allRenovationRecord?.energyConsumer
        : [],
    cave:
      allRenovationRecord?.cave !== null &&
      (allRenovationRecord?.cave === "OUI" || allRenovationRecord?.cave === 1)
        ? 1
        : 0,
    facades:
      allRenovationRecord?.num_facades !== null
        ? (getbuilding_type(Number(allRenovationRecord?.num_facades)) === 2 &&
            "2") ||
          (getbuilding_type(Number(allRenovationRecord?.num_facades)) === 3 &&
            "3") ||
          (getbuilding_type(Number(allRenovationRecord?.num_facades)) === 4 &&
            "4")
        : 0,
    building_id: allRenovationRecord?.id
      ? allRenovationRecord?.id
      : allRenovationRecord?.building_id,
  });

  let userd_local_data = localStorage.getItem("user_temporary_id");

  const submitRenovationData = () => {
    setIsCallWaiting(true);
    setRenovationLoader(true);
    let house_payload: any = {
      building_type: buildingValues?.building_type,
      construction_date: activeConstructionYear
        ? "Je ne sais pas"
        : buildingValues?.construction_date,
      floors: buildingValues?.floors,
      facades: parseInt(buildingValues?.facades),
      residents: buildingValues?.residents,
      energyConsumer: buildingValues?.energyConsumer,
      cave: buildingValues?.cave,
      has_attic: buildingValues?.has_attic,
      building_id: buildingValues?.building_id,
      userId: userd_local_data,
    };

    // if (userData?.id != undefined) {
    //   house_payload.userId = userData.id;
    // } else {
    //   const temp: any = localStorage.getItem("user_temporary_id");
    //   const local_storage: any = JSON.parse(temp);
    //   house_payload.user_temporary_id =
    //     local_storage[allRenovationRecord?.building_id]["user_temporary_id"];
    // }

    UpdateBuildingHouseParams(house_payload)
      .then((response: any) => {
        setIsCallWaiting(false);
        // setActiveModal("call2&3");
        // to update the state to get current data from response and to show inside form  using this state condition
        let duplicate = { ...houseCallRecordUpdated };
        duplicate.leftSide = "updated";
        setHouseCallRecordUpdated(duplicate);
        setRefinementCall3Record(response?.data?.data?.building_refinements);
        // params 3 =>
        // 1 = to get keys on basis for updated options ,
        // 2 = to send updated object
        // 3 = right model object should selected from state
        let payloadforquest: any = {
          building_id: house_payload?.building_id,
          userId: userd_local_data,
          ...getModalKeysHandler(
            "",
            {
              ...house_payload,
              num_floors: house_payload?.floors,
              num_facades: parseInt(house_payload?.facades),
            },
            {}
          ),
        };
        // to get packs data from api
        // GetUserPacksData(payloadforquest)
        //   .then((response_packs: any) => {
        //     console.log("response_packs", response_packs);
        //     setAllPacksCombinations(response_packs?.data);
        //     setRenovationLoader(false);
        //     renovationPackHandler("b", response_packs?.data);
        //   })
        //   .catch((error) => {
        //     setRenovationLoader(false);
        //     console.log("error", error);
        //   });
        // to get packs data from api
        GetUserPacksData(payloadforquest)
          .then((response_packs: any) => {
            // pythonLogicHandler,glazing
            setAllCustomCombination(response_packs?.data);
            let pythonData = pythonLogicHandler(response_packs?.data, {
              glazing: glazing,
            });
            if (
              parseInt(payloadforquest?.q26) === 75 ||
              parseInt(payloadforquest?.q26) === 76
            ) {
              const filteredData = pythonData?.filter((item: any) => {
                return (
                  !item?.combo.includes("ventc+") &&
                  !item?.combo.includes("ventd")
                );
              });
              setAllPacksCombinations(filteredData);
              setRenovationLoader(false);
              renovationPackHandler("b", filteredData);
            } else {
              setAllPacksCombinations(pythonData);
              setRenovationLoader(false);
              renovationPackHandler("b", pythonData);
            }
          })
          .catch((error) => {
            setRenovationLoader(false);
            console.log("error", error);
          });
      })

      .catch((err: any) => {
        setIsCallWaiting(false);
        setRenovationLoader(false);
      });
    closeModal();
  };

  const options = [
    { label: getValue(144), value: "pool" },
    { label: getValue(145), value: "electric" },
    { label: getValue(146), value: "sauna" },
  ];

  console.log("allRenovationRecord?.num_facades", allRenovationRecord?.id);
  return (
    <div>
      <Transition appear show={isOpenModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto result_common_model_container"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 w-full refinement_house_modal_one_container">
                <div className="inline-flex flex-col w-full lg:w-1/2 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex border-b border-[#d8d6d6] border-l-0 border-r-0  justify-center bg-whte flex-shrink-0 px-6 py-4 text-center">
                    <h3
                      className="text-[22px] font-bold  text-[#007DDF] "
                      id="headlessui-dialog-title-70"
                    >
                      {getValue(134)}
                    </h3>
                  </div>
                  <div className="px-8 py-3 w-full">
                    <span className="block  text-xs  text-[#455259] dark:text-neutral-400">
                      {getValue(135)}
                    </span>
                  </div>
                  {/* radio-button */}
                  {/* <div>
                    <div className="bg-[#F0F0F0] px-8 py-2">
                      <p className="text-xs text-[#455259] font-bold">
                        {getValue(136)}
                      </p>
                    </div>
                    <RadioGroup
                      onChange={(e) => {
                        let duplicate = { ...buildingValues };
                        duplicate.building_type = e.target.value;
                        setBuildingValues(duplicate);
                      }}
                      value={"terraced_building"}
                      defaultValue={"terraced_building"}
                    >
                      <div className="px-8 py-6 flex flex-col">
                        <Radio
                          className="textsm text-[#455259]"
                          value="detached_building"
                        >
                          {getValue(137)}
                        </Radio>
                        <Radio
                          className="textsm text-[#455259] py-2"
                          value="semi_detached_building"
                        >
                          {getValue(138)}
                        </Radio>
                        <Radio
                          className="textsm text-[#455259] "
                          value={"terraced_building"}
                        >
                          {getValue(139)}
                        </Radio>
                      </div>
                    </RadioGroup>
                  </div> */}
                  {/* slider-radio-button */}
                  <div className="slider-radio-button">
                    <div className="bg-[#F0F0F0] px-8 py-2">
                      <p className="text-xs text-[#455259] font-bold">
                        De quand date la construction de votre habitation?
                      </p>
                    </div>
                    <div className="px-8 py-6 w-full flex flex-col justify-center">
                      <Row className="slider-input w-full flex flex-col justify-between items-center">
                        <div className="w-full flex justify-center input-disabled">
                          <InputNumber
                            min={1}
                            max={20}
                            disabled
                            style={{ margin: "0 16px" }}
                            className="text-[center]"
                            value={
                              buildingValues?.construction_date !==
                                "Je ne sais pas" &&
                              buildingValues?.construction_date
                            }
                          />
                        </div>
                        <div className="w-full">
                          <Slider
                            marks={marks}
                            min={1900}
                            max={2023}
                            value={parseInt(buildingValues?.construction_date)}
                            onChange={(e: any) => {
                              let duplicate = { ...buildingValues };
                              duplicate.construction_date = e;
                              setBuildingValues(duplicate);
                            }}
                            defaultValue={parseInt(
                              buildingValues?.construction_date
                            )}
                            disabled={activeConstructionYear}
                          />
                        </div>
                      </Row>

                      {/* <Checkbox
                        className="textsm text-[#455259] pt-4 radio-to-checkbox"
                        value={
                          buildingValues?.construction_date === "Je ne sais pas"
                        }
                        onChange={(e: any) => {
                          console.log("e->", e.target.checked);
                          setActiveConstructionYear(e.target.checked);
                        }}

                      >
                        {getValue(307)}
                      </Checkbox> */}
                    </div>
                  </div>
                  {/* radio-button */}
                  <div>
                    <div className="bg-[#F0F0F0] px-8 py-2">
                      <p className="text-xs text-[#455259] font-bold">
                        {getValue(316)}
                      </p>
                    </div>
                    <RadioGroup
                      onChange={(e) => {
                        let duplicate = { ...buildingValues };
                        duplicate.facades = e.target.value;
                        setBuildingValues(duplicate);
                      }}
                      value={buildingValues?.facades}
                      defaultValue={buildingValues?.facades}
                    >
                      <div className="px-8 py-6 flex flex-col">
                        <Radio className="textsm text-[#455259] " value="2">
                          {getValue(317)}
                        </Radio>
                        <Radio className="textsm text-[#455259] py-2" value="3">
                          {getValue(318)}
                        </Radio>
                        <Radio className="textsm text-[#455259] " value="4">
                          {getValue(319)}
                        </Radio>
                      </div>
                    </RadioGroup>
                  </div>
                  {/* radio-button */}
                  <div>
                    <div className="bg-[#F0F0F0] px-8 py-2">
                      <p className="text-xs text-[#455259] font-bold">
                        {getValue(313)}
                      </p>
                    </div>
                    <RadioGroup
                      onChange={(e) => {
                        let duplicate = { ...buildingValues };
                        duplicate.floors = e.target.value;
                        setBuildingValues(duplicate);
                      }}
                      value={buildingValues?.floors}
                      defaultValue={buildingValues?.floors}
                    >
                      <div className="px-8 py-6 flex flex-col">
                        <Radio className="textsm text-[#455259] " value="1">
                          {getValue(314)}
                        </Radio>
                        <Radio className="textsm text-[#455259] pt-2" value="2">
                          {getValue(315)}
                        </Radio>
                        <Radio className="textsm text-[#455259] pt-2" value="3">
                          {getValue(393)}
                        </Radio>
                      </div>
                    </RadioGroup>
                  </div>
                  {/* radio-button */}
                  <div>
                    <div className="bg-[#F0F0F0] px-8 py-2">
                      <p className="text-xs text-[#455259] font-bold">
                        {getValue(142)}
                      </p>
                    </div>
                    <RadioGroup
                      onChange={(e) => {
                        let duplicate = { ...buildingValues };
                        duplicate.residents = e.target.value;
                        setBuildingValues(duplicate);
                      }}
                      value={
                        buildingValues?.residents &&
                        parseInt(buildingValues?.residents)
                      }
                    >
                      <div className="px-8 py-6 flex flex-col">
                        <Radio className="textsm text-[#455259] " value={1}>
                          {getValue(308)}
                        </Radio>
                        <Radio
                          className="textsm text-[#455259] pt-2 "
                          value={2}
                        >
                          {getValue(309)}
                        </Radio>
                        <Radio
                          className="textsm text-[#455259] pt-2 "
                          value={3}
                        >
                          {getValue(310)}
                        </Radio>
                        <Radio className="textsm text-[#455259] pt-2" value={4}>
                          {getValue(311)}
                        </Radio>
                        <Radio className="textsm text-[#455259] pt-2" value={5}>
                          {getValue(312)}
                        </Radio>
                      </div>
                    </RadioGroup>
                  </div>
                  {/* check-box */}
                  <div className="modal-check-box">
                    <div className="bg-[#F0F0F0] px-8 py-2">
                      <p className="text-xs text-[#455259] font-bold">
                        {getValue(143)}
                      </p>
                    </div>
                    <div className="px-8 py-6 flex flex-col">
                      <CheckboxGroup
                        options={options}
                        className="flex flex-col m-0 checkbox-group-custom"
                        value={buildingValues?.energyConsumer}
                        onChange={(e: any) => {
                          let duplicate: any = { ...buildingValues };
                          duplicate.energyConsumer = e;
                          setBuildingValues(duplicate);
                        }}
                      ></CheckboxGroup>
                    </div>
                  </div>
                  {/* radio-button */}
                  <div>
                    <div className="bg-[#F0F0F0] px-8 py-2">
                      <p className="text-xs text-[#455259] font-bold">
                        {getValue(147)}
                      </p>
                    </div>
                    <RadioGroup
                      onChange={(e: any) => {
                        let duplicate: any = { ...buildingValues };
                        duplicate.cave = e.target.value;
                        setBuildingValues(duplicate);
                      }}
                      value={buildingValues?.cave}
                    >
                      <div className="px-8 py-6 flex flex-col">
                        <Radio className="textsm text-[#455259]" value={1}>
                          {getValue(148)}
                        </Radio>
                        <Radio className="textsm text-[#455259] py-2" value={0}>
                          {getValue(149)}
                        </Radio>
                      </div>
                    </RadioGroup>
                  </div>
                  {/* radio-button */}
                  <div>
                    <div className="bg-[#F0F0F0] px-8 py-2">
                      <p className="text-xs text-[#455259] font-bold">
                        {getValue(150)}
                      </p>
                    </div>
                    <RadioGroup
                      value={buildingValues?.has_attic}
                      onChange={(e: any) => {
                        let duplicate = { ...buildingValues };
                        duplicate.has_attic = e.target.value;
                        setBuildingValues(duplicate);
                      }}
                    >
                      <div className="px-8 py-6 flex flex-col">
                        <Radio className="textsm text-[#455259] " value={1}>
                          {getValue(151)}
                        </Radio>
                        <Radio className="textsm text-[#455259] py-2" value={0}>
                          {getValue(152)}
                        </Radio>
                      </div>
                    </RadioGroup>
                  </div>
                  <div className="relative bg-white border border-[#F0F0F0] flex justify-between items-center py-2 px-8">
                    <h3
                      className="text-base m-0 text-[#989898] underline cursor-pointer"
                      id="headlessui-dialog-title-70"
                      onClick={closeModal}
                    >
                      {getValue(394)}
                    </h3>
                    <span className="bg-[#007DDF] rounded py-2.5 px-7">
                      <button
                        className="text-white text-base "
                        onClick={submitRenovationData}
                      >
                        {" "}
                        {getValue(153)}
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
export default Modal;
