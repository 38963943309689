import React, { FC, useContext } from "react";
import { getValue } from "../../utils/getValue";
import { GlobalContext } from "../../context/globalContext/globalContext";
// import images
import Lalouiver from "../../assets/images/comingSoon/lalou-logo-grey.svg";
import AGC from "../../assets/images/whoAreWe/agc.svg";
import Group from "../../assets/images/whoAreWe/who_we_are.png";
import InputTabs from "../../shared/autoComplete";
interface WhoInterface {}

const Who: FC<WhoInterface> = () => {
  const { state }: any = useContext(GlobalContext);
  console.log("getValue", getValue(state, 4, 0));
  return (
    <div>
      <div className="bg-who-we-are h-[250px]">
        <div className="h-full flex justify-center items-center">
          <p
            className="text-[40px] font-semibold text-black text-center px-1"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 4, 0)?.props?.content,
            }}
          />
        </div>
      </div>
      <div className="mx-[5%] lg:mx-[10%] py-6">
        <p
          className="text-[#6B7280] text-base font-normal  text-center"
          dangerouslySetInnerHTML={{
            __html: getValue(state, 4, 1)?.props?.content,
          }}
        />
        <div className="flex items-center justify-center lg:flex-row py-6">
          <div>
            <img src={Lalouiver} alt="Lalouiver" className="h-[50px]" />
          </div>
          <div className="px-2">
            <img src={AGC} alt="Lalouiver" className="h-[130px]" />
          </div>
        </div>
        <div className="py-8">
          <img src={Group} alt="group" />
        </div>
        <div className="flex justify-center">
          <div className="bg-[#007DDF] rounded-[20px] p-[40px] my-14 w-full lg:w-[60%]">
            <p
              className="text-white text-xl font-medium pb-6"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 4, 6)?.props?.content,
              }}
            />
            <InputTabs showAutocomplete="renovation" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Who;
