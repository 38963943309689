import React, { FC, useContext, useEffect } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { getValue } from "../../utils/getValue";
// import { useHubspotForm } from "react-use-hubspot-form";
import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form";

interface ContactUsInterface {}

const ContactUs: FC<ContactUsInterface> = () => {
  // global state
  const { state }: any = useContext(GlobalContext);

  // hubspot form //
  useHubspotForm({
    portalId: "25186371",
    formId: "ad8a0ec1-d331-402c-9285-e66e71897999",
    // portalId: "25186371",
    // formId: "4540b61c-5bb3-4961-848b-76221dbd8fa4",
    target: "#my-hubspot-form",
  });

  return (
    <div>
      <div className="bg-contactus h-[250px]">
        <div className="h-full flex items-center justify-center md:justify-start mx-[5%] lg:mx-[10%]">
          <p
            className="text-[30px] lg:text-[40px] font-semibold text-black"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 6, 19)?.props?.content,
            }}
          />
        </div>
      </div>
      {/* content_div */}
      <div className="mx-[5%] lg:mx-[10%]">
        <div className="flex flex-col lg:flex-row lg:justify-between py-8">
          {/* left */}
          <div className="w-full lg:w-9/12 ">
            <div className="w-full lg:w-[40%]">
              <div className="toll-free mb-10 p-[32px] text-center text-white text-[18px] font-medium">
                <p
                  dangerouslySetInnerHTML={{
                    __html: getValue(state, 6, 26)?.props?.content,
                  }}
                ></p>
              </div>
            </div>
            <div className="flex py-8">
              <div className="w-[6px] h-[initial] border-l bg-gradient-to-b from-[#77DB89] to-[#007DDF] pt-11"></div>
              <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-8 w-full">
                <div className="pl-3 w-full lg:w-[40%]">
                  <p
                    className="text-[18px] font-medium text-black"
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 6, 0)?.props?.content,
                    }}
                  />
                  <div className="py-2">
                    <p
                      className="text-sm font-normal text-black"
                      dangerouslySetInnerHTML={{
                        __html: getValue(state, 6, 1)?.props?.content,
                      }}
                    />
                  </div>
                  <p
                    className="text-sm font-normal text-black py-4"
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 6, 2)?.props?.content,
                    }}
                  />
                </div>
                <div className="w-full lg:w-[40%] pl-3 py-2 lg:py-0 lg:pl-0">
                  <p
                    className="text-sm font-normal text-[#374151] pb-2"
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 6, 21)?.props?.content,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex ">
              <div className="w-[6px] h-[initial] border-l bg-gradient-to-b from-[#77DB89] to-[#007DDF] pt-11"></div>
              <div className="flex flex-col lg:flex-row w-full space-x-0 lg:space-x-8 ">
                <div className="pl-3 w-full lg:w-[40%] ">
                  <p
                    className="text-[18px] font-medium text-black"
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 6, 3)?.props?.content,
                    }}
                  />
                  {/* <div className="py-4"></div>
                  <div className="py-4"></div> */}
                  <p
                    className="text-sm font-normal text-[#374151] py-2"
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 6, 4)?.props?.content,
                    }}
                  />
                  <p
                    className="text-sm font-normal text-[#374151] py-2"
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 6, 5)?.props?.content,
                    }}
                  />
                  <p
                    className="text-sm font-normal text-[#374151] py-2"
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 6, 6)?.props?.content,
                    }}
                  />
                </div>
                <div className="w-ful lg:w-[40%] pl-3 py-2 lg:py-0 lg:pl-0">
                  <p
                    className="text-sm font-normal text-[#374151] pb-2"
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 6, 22)?.props?.content,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* rigt */}
          <div className="w-full lg:w-[40%] mt-[32px] lg:-mt-[180px] ">
            <div className="bg-white rounded-[10px] py-6 px-[37px] shadow-xl">
              <div id="my-hubspot-form"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
