import HandImg from "../../images/hands/hand_1.png";

const Modal = (props: any) => {
  let { getValue, closeModal, loader } = props;
  return (
    <div>
      <div className="absolute w-full z-60 top-0">
        <div className="h-full fixed w-full background_rgba flex justify-center refinement_house_modal_one_container">
          <div className="overflow_y_scroll py-8 w-full flex justify-center">
            <div className="inline-flex h-fit-content flex-col w-full lg:w-1/2 text-left align-middle transition-all transform rounded-2xl bg-white dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl">
              <div className="relative flex justify-center bg-neutral-100 flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                <h3
                  className="text-sm m-0 text-left text-gray-500"
                  id="headlessui-dialog-title-70"
                >
                  {/* {getValue && getValue(283)} */}
                </h3>
              </div>
              <div className="px-8 py-8 flex flex-col items-center">
                <div className="px-4 text-center">
                  <p className="text-center whitespace_prewrap text-blue-500">
                    {getValue && getValue(283)}
                  </p>
                </div>
                <div className="py-4">
                  {/* <img src={HandImg} className="h-32" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Modal;
