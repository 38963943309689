import React, { FC } from "react";
import ContactUs from "../../components/contactUs/contactUs";

interface ContactUsPageInterface {}

const ContactUsPage: FC<ContactUsPageInterface> = () => {
  return (
    <div>
      <ContactUs />
    </div>
  );
};
export default ContactUsPage;
