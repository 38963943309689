import React, { FC, useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "./footer";
import { useLocation } from "react-router-dom";
import Header from "./header";
import BookAnAudit from "../homePageModals/bookAnAudit";
import { GlobalContext } from "../../context/globalContext/globalContext";

interface LayoutInterface {
  children: JSX.Element;
}

const Layout: FC<LayoutInterface> = ({ children }) => {
  const Location = useLocation();
  // global state
  const { state }: any = useContext(GlobalContext);
  const [zoom, setZoom] = useState(1);
  const [innerWidth, setInnerWidth]: any = useState("");
  // Method to show/hide footer for different pages
  // const footerHandler = () => {
  //   if (Location?.pathname === "/" ) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };
  // Method to show/hide header for different pages
  const headerHandler = () => {
    if (
      //Location?.pathname === "/" ||
      Location?.pathname === "/coming-soon" ||
      Location?.pathname === "/fr" ||
      Location?.pathname === "/nl"
    ) {
      return false;
    } else {
      return true;
    }
  };

  // useEffect to fix ResizeObserver loop limit exceeded issue
  // useEffect(() => {
  //   window.addEventListener("error", (e) => {
  //     if (e.message === "ResizeObserver loop limit exceeded") {
  //       const resizeObserverErrDiv = document.getElementById(
  //         "webpack-dev-server-client-overlay-div"
  //       );
  //       const resizeObserverErr = document.getElementById(
  //         "webpack-dev-server-client-overlay"
  //       );
  //       if (resizeObserverErr) {
  //         resizeObserverErr.setAttribute("style", "display: none");
  //       }
  //       if (resizeObserverErrDiv) {
  //         resizeObserverErrDiv.setAttribute("style", "display: none");
  //       }
  //     }
  //   });
  // }, []);

  useEffect(() => {
    setInnerWidth(window.screen.availWidth);
  }, []);
  return (
    <div
      style={{
        transform: `scale(1)`,
        transformOrigin: "top center",
        maxWidth: innerWidth ? `${innerWidth}px` : `100%`,
      }}
    >
      <Helmet>
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-eu1.hs-scripts.com/25186371.js"
        ></script>
      </Helmet>
      {/* book an audit modal */}
      {state?.bookAnAuditModal && (
        <BookAnAudit visible={state?.bookAnAuditModal} />
      )}
      <Header />
      {/* {headerHandler() === true && <Header />} */}
      {children}
      <Footer />
    </div>
  );
};
export default Layout;
