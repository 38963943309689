import React, { FC, useContext } from "react";
// import images
import Work from "../../../assets/images/advantages/Work.png";
import HomeSearch from "../../../assets/images/advantages/homesearch.svg";
import Agenda from "../../../assets/images/advantages/agenda.svg";
import Check from "../../../assets/images/advantages/check.svg";
import Meeting from "../../../assets/images/advantages/meeting.svg";
import Graph from "../../../assets/images/advantages/graph.svg";
import CityReno from "../../../assets/images/advantages/cityreno.svg";

// import components
import { GlobalContext } from "../../../context/globalContext/globalContext";
import { getValue } from "../../../utils/getValue";
import InputTabs from "../../../shared/autoComplete";

interface EasyProcessInterface {}

const EasyProcess: FC<EasyProcessInterface> = () => {
  const { state }: any = useContext(GlobalContext);
  console.log("getValue", getValue(state, 1, 0));
  return (
    <div>
      <div>
        <img src={Work} alt="Work" />
      </div>
      <p
        className="text-black text-[20px] py-4"
        dangerouslySetInnerHTML={{
          __html: getValue(state, 1, 188)?.props?.content,
        }}
      />
      <div className="snake-bg ">
        <div className="flex flex-col lg:flex-row space-x-10 px-8 pt-10">
          <div className="w-full lg:w-3/6 ">
            <p
              className="text-[#007DDF] text-sm py-1"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 1, 66)?.props?.content,
              }}
            />
            <p
              className="text-[#374151] text-sm "
              dangerouslySetInnerHTML={{
                __html: getValue(state, 1, 67)?.props?.content,
              }}
            />
          </div>
          <div className="w-full lg:w-3/6 flex justify-center lg:py-0 py-4">
            <img src={HomeSearch} alt="HomeSearch" className="h-[160px]" />
          </div>
        </div>
        <div className="flex flex-col-reverse lg:flex-row space-x-10 px-8 pt-10">
          <div className="w-full lg:w-3/6 flex justify-center lg:justify-start lg:py-0 py-4 ">
            <img src={Agenda} alt="HomeSearch" className="h-[160px]" />
          </div>
          <div className="w-full lg:w-3/6 ">
            <p
              className="text-[#007DDF] text-sm py-1"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 1, 69)?.props?.content,
              }}
            />
            <p
              className="text-[#374151] text-sm "
              dangerouslySetInnerHTML={{
                __html: getValue(state, 1, 70)?.props?.content,
              }}
            />
            <div className="flex py-4">
              <img src={Check} alt="check" className="h-[22px]" />
              <p
                className="text-[#374151] text-sm px-2"
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 1, 71)?.props?.content,
                }}
              />
            </div>
            <div className="flex py-4">
              <img src={Check} alt="check" className="h-[22px]" />
              <p
                className="text-[#374151] text-sm px-2"
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 1, 72)?.props?.content,
                }}
              />
            </div>
            <div className="flex py-4">
              <img src={Check} alt="check" className="h-[22px]" />
              <p
                className="text-[#374151] text-sm px-2"
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 1, 73)?.props?.content,
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex  flex-col lg:flex-row space-x-10 px-8 pt-10">
          <div className="w-full lg:w-3/6">
            <p
              className="text-[#007DDF] text-sm py-1"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 1, 75)?.props?.content,
              }}
            />
            <p
              className="text-[#374151] text-sm "
              dangerouslySetInnerHTML={{
                __html: getValue(state, 1, 76)?.props?.content,
              }}
            />
          </div>
          <div className="w-full lg:w-3/6 flex justify-center lg:py-0 py-4">
            <img src={Meeting} alt="Meeting" className="h-[160px]" />
          </div>
        </div>
        <div className="flex flex-col-reverse lg:flex-row space-x-10 px-8 pt-10">
          <div className="w-full lg:w-3/6 flex justify-center lg:py-0 py-4">
            <img src={Graph} alt="Graph" className="h-[160px]" />
          </div>
          <div className="w-full lg:w-3/6">
            <p
              className="text-[#007DDF] text-sm py-1"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 1, 78)?.props?.content,
              }}
            />
            <p
              className="text-[#374151] text-sm "
              dangerouslySetInnerHTML={{
                __html: getValue(state, 1, 79)?.props?.content,
              }}
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row space-x-10 px-8 pt-10">
          <div className="w-full lg:w-3/6 ">
            <p
              className="text-[#007DDF] text-sm py-1"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 1, 81)?.props?.content,
              }}
            />
            <p
              className="text-[#374151] text-sm "
              dangerouslySetInnerHTML={{
                __html: getValue(state, 1, 82)?.props?.content,
              }}
            />
            {/* <p
              className="text-[#374151] text-sm pt-1"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 1, 23)?.props?.content,
              }}
            /> */}
          </div>
          <div className="w-full lg:w-3/6 flex justify-center lg:py-0 py-4">
            <img src={CityReno} alt="CityReno" className="h-[160px]" />
          </div>
        </div>
      </div>
      <div className="bg-[#007DDF] rounded-[20px] p-[40px] mt-6 mb-14">
        <p
          className="text-white text-xl font-medium pb-6"
          dangerouslySetInnerHTML={{
            __html: getValue(state, 1, 84)?.props?.content,
          }}
        />
        <InputTabs showAutocomplete="renovation" />
      </div>
    </div>
  );
};
export default EasyProcess;
