import React, { FC, useContext } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { getValue } from "../../utils/getValue";
// import images
import HomeSearch from "../../assets/images/advantages/homesearch.svg";
import Agenda from "../../assets/images/advantages/agenda.svg";
import Check from "../../assets/images/advantages/check.svg";
import Meeting from "../../assets/images/advantages/meeting.svg";
import Graph from "../../assets/images/advantages/graph.svg";
import CityReno from "../../assets/images/advantages/cityreno.svg";
import InputTabs from "../../shared/autoComplete";

interface TheProcessInterface {}

const TheProcess: FC<TheProcessInterface> = () => {
  const { state }: any = useContext(GlobalContext);
  console.log("getValue", getValue(state, 2, 0));
  return (
    <div>
      <div className="bg-process h-[250px]">
        <div className="h-full flex justify-center items-center text-center">
          <p
            className="text-[40px] font-semibold text-black"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 2, 1)?.props?.content,
            }}
          />
        </div>
      </div>
      <div className="mx-0 lg:mx-[10%]">
        <div className="snake-bg-process">
          <div className="flex flex-col lg:flex-row  px-8 pt-10">
            <div className="w-full lg:w-3/6 ">
              <p
                className="text-[#007DDF] text-sm py-1"
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 2, 2)?.props?.content,
                }}
              />
              <p
                className="text-[#374151] text-base "
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 2, 3)?.props?.content,
                }}
              />
              <p
                className="text-[#374151] text-base py-4"
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 2, 4)?.props?.content,
                }}
              />
            </div>
            <div className="w-full lg:w-3/6 flex justify-center lg:py-0 py-4">
              <img src={HomeSearch} alt="HomeSearch" className="h-[160px]" />
            </div>
          </div>
          <div className="flex flex-col-reverse lg:flex-row px-8 pt-10">
            <div className="w-full lg:w-3/6 flex justify-center lg:py-0 py-4 ">
              <img src={Agenda} alt="HomeSearch" className="h-[160px]" />
            </div>
            <div className="w-full lg:w-3/6 ">
              <p
                className="text-[#007DDF] text-sm py-1"
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 2, 6)?.props?.content,
                }}
              />
              <p
                className="text-[#374151] text-base "
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 2, 7)?.props?.content,
                }}
              />
              <p
                className="text-[#374151] text-base "
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 2, 8)?.props?.content,
                }}
              />
              <div className="flex py-4">
                <img src={Check} alt="check" className="h-[22px]" />
                <p
                  className="text-[#374151] text-base px-2"
                  dangerouslySetInnerHTML={{
                    __html: getValue(state, 2, 10)?.props?.content,
                  }}
                />
              </div>
              <div className="flex py-4">
                <img src={Check} alt="check" className="h-[22px]" />
                <p
                  className="text-[#374151] text-base px-2"
                  dangerouslySetInnerHTML={{
                    __html: getValue(state, 2, 11)?.props?.content,
                  }}
                />
              </div>
              <div className="flex py-4">
                <img src={Check} alt="check" className="h-[22px]" />
                <p
                  className="text-[#374151] text-base px-2"
                  dangerouslySetInnerHTML={{
                    __html: getValue(state, 2, 12)?.props?.content,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex  flex-col lg:flex-row px-8 pt-10">
            <div className="w-full lg:w-3/6">
              <p
                className="text-[#007DDF] text-sm py-1"
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 2, 13)?.props?.content,
                }}
              />
              <p
                className="text-[#374151] text-base "
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 2, 14)?.props?.content,
                }}
              />
              <p
                className="text-[#374151] text-base py-4 "
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 2, 15)?.props?.content,
                }}
              />
            </div>
            <div className="w-full lg:w-3/6 flex justify-center lg:py-0 py-4">
              <img src={Meeting} alt="Meeting" className="h-[160px]" />
            </div>
          </div>
          <div className="flex flex-col-reverse lg:flex-row px-8 pt-10">
            <div className="w-full lg:w-3/6 flex justify-center lg:py-0 py-4">
              <img src={Graph} alt="Graph" className="h-[160px]" />
            </div>
            <div className="w-full lg:w-3/6">
              <p
                className="text-[#007DDF] text-sm py-1"
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 2, 17)?.props?.content,
                }}
              />
              <p
                className="text-[#374151] text-base "
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 2, 18)?.props?.content,
                }}
              />
              <p
                className="text-[#374151] text-base py-6"
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 2, 19)?.props?.content,
                }}
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row px-8 pt-10">
            <div className="w-full lg:w-3/6 ">
              <p
                className="text-[#007DDF] text-sm py-1"
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 2, 21)?.props?.content,
                }}
              />
              <p
                className="text-[#374151] text-base "
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 2, 22)?.props?.content,
                }}
              />
              <p
                className="text-[#374151] text-base pt-1"
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 2, 23)?.props?.content,
                }}
              />
            </div>
            <div className="w-full lg:w-3/6 flex justify-center lg:py-0 py-4">
              <img src={CityReno} alt="CityReno" className="h-[160px]" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="bg-[#007DDF] rounded-[20px] p-[40px] my-14 w-[90%] lg:w-[60%]">
          <p
            className="text-white text-xl font-medium pb-6"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 2, 24)?.props?.content,
            }}
          />
          <InputTabs showAutocomplete="renovation" />
        </div>
      </div>
    </div>
  );
};
export default TheProcess;
