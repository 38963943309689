import React, { FC, useContext } from "react";
import "react-multi-carousel/lib/styles.css";
// import Images
import PersonLeft from "../../assets/images/home/person2.jpeg";
import PersonRight from "../../assets/images/home/person1.jpeg";

// import components
import { GlobalContext } from "../../context/globalContext/globalContext";
import { getValue } from "../../utils/getValue";
interface OurClientInterface {}

const OurClient: FC<OurClientInterface> = () => {
  //  global state
  const { state }: any = useContext(GlobalContext);
  return (
    <div>
      <div className=" line-img  w-full our-clients-container">
        {/* home_div   */}
        <div className="mx-[5%] lg:mx-[10%]">
          <div className="flex flex-col lg:flex-row py-8 px-2 lg:px-8 ">
            {/* left_side_div */}
            <div className="block lg:hidden">
              <div className="  w-full    ">
                <h2
                  className="text-[38px] text-[#222222] font-semibold"
                  dangerouslySetInnerHTML={{
                    __html: getValue(state, 0, 125)?.props?.content,
                  }}
                ></h2>
                <p
                  className="text-sm text-[#455259] font-normal py-4"
                  dangerouslySetInnerHTML={{
                    __html: getValue(state, 0, 126)?.props?.content,
                  }}
                />
              </div>
            </div>
            <div>
              <div className="md:h-[525px] lg:h-[670px] margin-left-right">
                <div className=" relative w-[308px] lg:w-[340px] md:top-[40px]  lg:left-[-100px] top- lg:top-[160px] z-50 same-card">
                  <div className="house-bg w-full h-[471px]">
                    <div className="flex flex-col justify-center w-full h-full  px-6">
                      <div className="flex">
                        <div className="h-[50px] w-[50px] rounded-full border border-white personBgLeft"></div>
                        {/* <img src={PersonLeft} alt="person" /> */}
                        <div className="pl-2.5">
                          <p
                            className="text-[#007DDF] font-bold text-sm pb-[2px] "
                            dangerouslySetInnerHTML={{
                              __html: getValue(state, 0, 127)?.props?.content,
                            }}
                          />
                          <p
                            className="text-white font-normal text-sm"
                            dangerouslySetInnerHTML={{
                              __html: getValue(state, 0, 135)?.props?.content,
                            }}
                          />
                        </div>
                      </div>
                      <div className="py-6">
                        <p
                          className="text-xs font-normal text-black"
                          dangerouslySetInnerHTML={{
                            __html: getValue(state, 0, 128)?.props?.content,
                          }}
                        />
                        {/* <p className="text-xs font-normal text-black py-5">
                          {state?.language?.ourClientstext2}
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" relative z-50  w-[308px] md:w-[340px]  md:left-[370px] md:-top-[444px] lg:left-[260px] lg:-top-[450px] same-card">
                  <div className="house-bg w-full h-[471px]">
                    <div className="flex flex-col w-full  h-full justify-center px-6">
                      <div className="flex ">
                        <div className="h-[50px] w-[50px] rounded-full border border-white personBgRight"></div>
                        {/* <img
                          src={PersonRight}
                          alt="person"
                          className="h-[50px] w-[50px] rounded-full border border-white"
                        /> */}
                        <div className="pl-2.5">
                          <p
                            className="text-[#007DDF] font-bold text-sm pb-[2px] "
                            dangerouslySetInnerHTML={{
                              __html: getValue(state, 0, 129)?.props?.content,
                            }}
                          />
                          <p
                            className="text-white font-normal text-sm"
                            dangerouslySetInnerHTML={{
                              __html: getValue(state, 0, 136)?.props?.content,
                            }}
                          />
                        </div>
                      </div>
                      <div className="py-6">
                        <p
                          className="text-xs font-normal text-black"
                          dangerouslySetInnerHTML={{
                            __html: getValue(state, 0, 130)?.props?.content,
                          }}
                        />
                        {/* <p className="text-xs font-normal text-black py-5">
                          {state?.language?.ourClientstext4}
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* right_side_div */}
            <div className="lg:h-full">
              <div className="hidden lg:block">
                <div className="lg:flex lg:flex-col w-3/6 lg:w-[64%] relative md:top-[0px] lg:top-[130px] md:left-0 lg:left-[280px] ">
                  <h2
                    className="text-[38px] text-[#222222] font-semibold"
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 0, 125)?.props?.content,
                    }}
                  ></h2>
                  <p
                    className="text-sm text-[#455259] font-normal py-4"
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 0, 126)?.props?.content,
                    }}
                  />
                </div>
              </div>
              <div className="hidden lg:block">
                <div className="flex items-start lg:items-center flex-col md:flex-row md:justify-start lg:justify-center">
                  <div className=" relative  z-50 w-[186px] sm  md:-top-[30px] lg:top-[135px] left-[60px] md:left-[30px] lg:left-[90px] ">
                    <div className="house-bg1  w-full">
                      <div className="flex flex-col justify-start items-center w-full h-full pb-[55px] pt-[60px] px-[6px] h-[300px]">
                        <div className="text-center">
                          <p
                            className="text-[#007DDF] font-bold text-sm pb-[2px] pt-2"
                            dangerouslySetInnerHTML={{
                              __html: getValue(state, 0, 131)?.props?.content,
                            }}
                          />
                          <p
                            className="text-white font-normal text-sm"
                            dangerouslySetInnerHTML={{
                              __html: getValue(state, 0, 137)?.props?.content,
                            }}
                          />
                        </div>
                        <div className="py-1">
                          <p
                            className="text-xs font-normal text-black text-center"
                            dangerouslySetInnerHTML={{
                              __html: getValue(state, 0, 132)?.props?.content,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" relative  z-50  w-[186px] md:-top-[30px]  lg:top-[135px] left-[60px] md:left-[200px] lg:left-[120px] ">
                    <div className="house-bg1  w-full ">
                      <div className="flex flex-col  justify-start items-center pb-[55px] pt-[60px] px-[6px] h-[300px]">
                        <div className="text-center">
                          <p
                            className="text-[#007DDF] font-bold text-sm pb-[2px] pt-2"
                            dangerouslySetInnerHTML={{
                              __html: getValue(state, 0, 133)?.props?.content,
                            }}
                          />
                          <p
                            className="text-white font-normal text-sm"
                            dangerouslySetInnerHTML={{
                              __html: getValue(state, 0, 138)?.props?.content,
                            }}
                          />
                        </div>
                        <div className="py-1">
                          <p
                            className="text-xs font-normal text-black text-center"
                            dangerouslySetInnerHTML={{
                              __html: getValue(state, 0, 134)?.props?.content,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="block lg:hidden">
                <div className="md:h-[525px] lg:h-[860px] margin-left-right">
                  <div className=" relative w-[308px] lg:w-[340px] md:top-[40px] md:left-[10px] lg:left-[-100px] top- lg:top-[230px] z-50 same-card">
                    <div className="house-bg w-full">
                      <div className="flex flex-col w-full  py-[160px] px-6">
                        <div className="flex flex-col items-center justify-center">
                          <p
                            className="text-[#007DDF] font-bold text-sm pb-[2px] "
                            dangerouslySetInnerHTML={{
                              __html: getValue(state, 0, 131)?.props?.content,
                            }}
                          />
                          <p
                            className="text-white font-normal text-sm"
                            dangerouslySetInnerHTML={{
                              __html: getValue(state, 0, 137)?.props?.content,
                            }}
                          />
                        </div>
                        <div className="py-6">
                          <p
                            className="text-xs font-normal text-black"
                            dangerouslySetInnerHTML={{
                              __html: getValue(state, 0, 132)?.props?.content,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" relative z-50  w-[308px] md:w-[340px]  md:left-[370px] md:-top-[444px] lg:left-[260px] lg:-top-[345px] same-card">
                    <div className="house-bg w-full">
                      <div className="flex flex-col w-full  py-[160px] px-6">
                        <div className="flex flex-col items-center justify-center">
                          <p
                            className="text-[#007DDF] font-bold text-sm pb-[2px] "
                            dangerouslySetInnerHTML={{
                              __html: getValue(state, 0, 133)?.props?.content,
                            }}
                          />
                          <p
                            className="text-white font-normal text-sm"
                            dangerouslySetInnerHTML={{
                              __html: getValue(state, 0, 138)?.props?.content,
                            }}
                          />
                        </div>
                        <div className="py-6">
                          <p
                            className="text-xs font-normal text-black"
                            dangerouslySetInnerHTML={{
                              __html: getValue(state, 0, 134)?.props?.content,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OurClient;
