import React, { FC, useState, useEffect } from "react";
// import Packs from "components/Packs/Packs";
import { GetContentByService } from "../../services/ResultPage";
import {
  GetAllRenovationsByService,
  GenerateTempIdAgainsUserService,
  GenerateTempIdAgainsBuildingService,
  getUserSelectedDataService,
} from "../../services/ResultPage";
//
import SectionHouse from "../../components/result/sectionHouse";
import SectionDiagnosis from "../../components/result/sectionDianosis";
import Packs from "../../components/result/packs";
import Subsides from "../../components/result/SubSides";
import SectionOurRecomendation from "../../components/result/sectionOurRecomendation";
import SectionFixedSideBar from "../../components/result/sectionFixedSideBar";
import YourNextStep from "../../components/result/youNextStep";
// import ResultBanner from "../../components/result/resultBanner";
// import ChooseRenovationComponent from "../../components/result/chooseRenovation";
// import OurReacommended from "../../components/result/ourRecommended";
// import SectionHouse from "components/SectionHouse/SectionHouse";
// import SectionDiagnosis from "components/SectionOurDiagnosis/SectionOurDiagnosis";
// import SectionOurRecomendation from "components/SectionOurRecomendation/SectionOurRecomendation";
// import SectionResultDescription from "components/SectionResultDescription/SectionResultDescription";
// import SectionFixedSideBar from "components/SectionFixedSideBar/SectionFixedSideBar";
import SectionRenovationLoan from "../../components/result/SectionRenovationLoan";
import Modal from "../../shared/Modal/index";
// import SectionRenovationSteps from "components/SectionRenovationSteps/SectionRenovationSteps";
import { GetUserStatus } from "../../utils/renovations";
import { defaultRenovations } from "../../utils/renovations";
// import { withRouter } from "react-router-dom";
import cookie from "react-cookies";
import jwt_decode from "jwt-decode";
// import ResultHeader from "shared/Header/ResultHeader";
import { Helmet } from "react-helmet";
import {
  renovationPostProcessing,
  renovationPostProcessingForPackB,
  renovationsInvestmentPrice,
  customSolarRenovationPostProcessing,
  existRenovationHandler,
  solarPriceHandler,
} from "./constants";
import CallZeroModal from "../../shared/Modal/CallZeroModal";
import { Markup } from "interweave";
import Call2And3Modal from "../../shared/Modal/Call2And3Modal";
import { useParams } from "react-router-dom";

export interface ListingStayDetailPageProps {
  // isPreviewMode?: boolean;
  // history?: any;
  // match?: any;
}

const ListingStayDetailPage = ({}: // isPreviewMode,
// history,
// match,
any) => {
  const params = useParams();
  let match = {
    params: {
      id: params?.id,
    },
  };
  console.log("params", match);
  const [loader, setLoader] = useState(true);
  const [isCallWaitng, setIsCallWaiting] = useState(false);
  const [isGenerateTempId, setIsGenerateTempId] = useState(false);
  const [houseCallRecordUpdated, setHouseCallRecordUpdated] = useState({
    leftSide: "",
    rightSide: "",
  });
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [activeModal, setActiveModal] = useState("callZero");
  const [renovationModalContent, setRenovationModalContent] = useState({});
  const [resultPageContent, setResultPageContent]: any = useState([]);
  console.log("resultPageContent",resultPageContent)
  // state to store userData
  const [userData, setUserData] = useState();
  // state to store data of houseDetails of left and right side of section1
  const [houseDetails, setHouseDetails]: any = useState();
  // state to store userCurrentStatus
  const [userStatus, setUserStatus]: any = useState([]);
  // state to store api repose data
  const [allRenovationRecord, setAllRenovationsRecord]: any = useState();
  // state to show all renovations records (pack label a, b and custom)
  const [renovations, setRenovations]: any = useState([]);
  const [userSelectedData, setAllUserSelectedData]: any = useState([]);
  const [futureEpc, setFutureEpc] = useState("1");
  const [custom, setCustom] = useState(false);
  const [packLabel, setPackLabel] = useState(false);
  // state to add epc a through index
  const [epcA, setEpcA] = useState([]);
  // state to store user login info
  const [loggedInUser, setLoggedInUser]: any = useState();
  const [callTwoTemplate, setCallTwoTemplate] = useState({});
  const [callThreeTemplate, setCallThreeTemplate] = useState({});
  const [userBuildingId, setUserBuildingId] = useState({});
  // state to show active tool tip record
  const [activeRenovationToolTip, setActiveRenovationToolTip] = useState();
  // state to store data after refinement of left and right side modal
  const [refinementCall3Record, setRefinementCall3Record]: any = useState();
  const [refinementCall2Record, setRefinementCall2Record]: any = useState();
  // states for model
  const [userModalRenovations, setUserRenovationsModal] = useState([]);
  // state to store data for call 2 and 3
  const [userExistRenovations, setUserExistRenovations] = useState([]);
  // state to store total value for loan
  const [totalValue, setTotalValue] = useState();
  const [selectedInd, setSelectedInd] = useState(0);
  // state to show five stars if custom is selected
  const [customStars, setCustomStars]: any = useState(0);
  const [customPackSelected, setCustomPackSelected]: any = useState();
  // state to show selectedPack
  const [selectedPack, setSelectedPack] = useState("packB");
  // state to save selectedrecords to ssave it with user
  const [selectedUserCombination, setSelectedUserCombination]: any = useState();
  // state to store data after refinement
  const [downloadRecord, setDownloadedRecord]: any = useState();

  const [isAdminView, setIsAdminView]: any = useState(false);

  const [subsidies, setSubsidies]: any = useState(1);

  const generateTempIdAgainsUser = (userId: any, buildingId: any) => {
    if (buildingId) {
      let requestBody: any;
      if (userId) {
        requestBody = {
          userId: userId ? userId : null,
          building_id: buildingId,
        };
      } else {
        // const local_storage = localStorage.getItem(buildingId)
        const temp: any = localStorage.getItem("user_temporary_id");
        let local_storage: any;
        if (temp != null) {
          local_storage = JSON.parse(temp);
        }
        if (local_storage && local_storage[buildingId]) {
          requestBody = {
            user_temporary_id: local_storage[buildingId]["user_temporary_id"],
            building_id: local_storage[buildingId]["building_id"],
          };
        } else {
          requestBody = {
            user_temporary_id: "00000000-0000-0000-0000-000000000000",
            building_id: buildingId,
          };
        }
      }

      if (
        requestBody.user_temporary_id ==
          "00000000-0000-0000-0000-000000000000" ||
        userId
      ) {
        GenerateTempIdAgainsUserService(requestBody)
          .then((response: any) => {
            setIsGenerateTempId(false);
            let local_storage: any;
            const temp = localStorage.getItem("user_temporary_id");
            if (temp != null) {
              local_storage = JSON.parse(temp);
              if (!local_storage[buildingId]) {
                local_storage = {
                  ...local_storage,
                  [buildingId]: {
                    building_id: buildingId,
                    user_temporary_id: response.data.user_temporary_id,
                  },
                };
                if (response.data.user_temporary_id) {
                  localStorage.setItem(
                    "user_temporary_id",
                    JSON.stringify(local_storage)
                  );
                }
              }
            } else {
              local_storage = {
                ...local_storage,
                [buildingId]: {
                  building_id: buildingId,
                  user_temporary_id: response.data.user_temporary_id,
                },
              };
              if (response.data.user_temporary_id) {
                localStorage.setItem(
                  "user_temporary_id",
                  JSON.stringify(local_storage)
                );
              }
            }

            setLoader(false);
            if (response.data.api_request_2) {
              setCallTwoTemplate(JSON.parse(response.data.api_request_2));
            }
            if (response.data.api_request_3) {
              setCallThreeTemplate(JSON.parse(response.data.api_request_3));
            }
            const ids = {
              userId: userId ? userId : null,
              buildingId: buildingId,
              tempId: response.data.api_request_2
                ? JSON.parse(response.data.api_request_2).building.id
                : null,
            };
            setUserBuildingId(ids);
            generateTempIdAgainstBuilding(buildingId);
          })
          .catch((error) => {
            setLoader(false);
            setIsGenerateTempId(false);
          });
      }
    }
  };

  const generateTempIdAgainstBuilding = (buildingId: any) => {
    const requestBody = {
      building_id: buildingId,
    };

    GenerateTempIdAgainsBuildingService(requestBody)
      .then((response: any) => {})
      .catch((error) => {});
  };

  // user renovations record on preload
  const userRenovations = (
    response: any,
    userRecord: any,
    houseRenovations: any,
    userId: any,
    callZeroRenovations: any,
    // user refinement data after call 2
    renovationRefinements?: any
  ) => {
    let allRecords: any = response?.data[0]?.user_selected_data
      ? JSON.parse(response?.data[0]?.user_selected_data)
      : [];
    if (
      response?.data[0]?.renovation_destroy?.length < 1 ||
      response?.data[0]?.renovation_destroy?.length == undefined
    ) {
      callZeroRenovations.map((ele: any) => {
        if (
          ele.renovation.includes("condGas") &&
          (parseInt(ele.heating_investment_cost) == 0 ||
            ele.heating_investment_cost == null)
        ) {
          ele.heating_investment_cost = 4200;
          ele.investment_cost = ele.investment_cost
            ? parseInt(ele.investment_cost) + 4200
            : 4200;
        }
      });
      allRecords?.map((ele: any) => {
        if (
          ele.renovation.includes("condGas") &&
          (parseInt(ele.heating_investment_cost) == 0 ||
            ele.heating_investment_cost == null)
        ) {
          ele.heating_investment_cost = 4200;
          ele.investment_cost = ele.investment_cost
            ? parseInt(ele.investment_cost) + 4200
            : 4200;
        }
      });
    }
    setUserRenovationsModal(callZeroRenovations);
    if (
      response?.data[0]?.renovation_destroy?.length < 1 ||
      response?.data[0]?.renovation_destroy?.length == undefined
    ) {
      generateTempIdAgainsUser(
        userRecord?.id,
        houseRenovations?.data[0]?.building_id
      );
    }

    // if (
    //  ( response?.data[0] !== "no data yet" ||
    //  renovations?.data == "no temporary id created yet"
    //  ) &&
    //   response?.data[0]?.user_selected_data
    // )
    // {
    // to convert string of data into json
    setAllRenovationsRecord(response?.data[0]);
    // api call to check if user have building status or not
    let payload = {
      userId: userRecord?.id ? userRecord?.id : null,
      userDataId: response?.data[0]?.id,
      status: 1,
    };
    let baseOffer: any = [];
    // getuser status common method
    if (userRecord?.id) {
      GetUserStatus(
        userRecord,
        response,
        houseRenovations,
        payload,
        setUserStatus,
        baseOffer
      );
    }
    // pack label a logics start from here
    // let allRecords: any = response?.data[0]?.user_selected_data
    //   ? JSON.parse(response?.data[0]?.user_selected_data)
    //   : [];

    let selected: any;

    if (allRecords?.length !== 0) {
      // if (allRecords) {
      // method for allRenovation
      let exitRenovation: any = [];
      selected = existRenovationHandler(allRecords, exitRenovation);
      setUserExistRenovations(selected);
      // }
      setAllUserSelectedData(allRecords);
      // pack A
      let packA: any;

      packA = renovationPostProcessingForPackB(
        allRecords,
        renovationRefinements,
        houseRenovations
      );

      // to get and store pack label a records
      if (packA?.length > 0) {
        let newArray: any = [];
        let duplicateCall0: any = [...callZeroRenovations];
        // let solarValues = solarPriceHandler(houseDetails);
        let addingNewRenovation: any = [
          {
            ...duplicateCall0[1],
            renovation: "glazing",
          },
          {
            ...duplicateCall0[1],
            renovation: "solar",
            total_subsidy: packA[0]?.solar_subsidy,
            investment_price: packA[0]?.solar_invsetment_cost,
          },
        ];
        // new array with solar and glazing
        let allCombinedRenovations = [...addingNewRenovation, ...selected];

        let record: any = [...allCombinedRenovations]?.filter((content: any) =>
          packA[0]?.renovation
            ?.split("_")
            ?.some((key: any) => content.renovation === key)
        );
        record?.map((renovations: any) =>
          newArray.push({
            ...renovations,
            active: 1,
          })
        );
        setEpcA(packA[0]?.renovation);
        if (packA[0]) {
          setSelectedInd(101);
        }
        // method to show pack label renovations values of combinations
        if (newArray) {
          newArray = renovationsInvestmentPrice(newArray, packA[0]);
        }
        // newArray = defaultRenovations(response?.data, newArray);
        // remove records if null
        let removingNullRenovation = newArray?.filter(
          (renovation: any) => renovation?.investment_cost !== null
        );
        // remove records if price is 0
        let remaingRenovations = removingNullRenovation?.filter(
          (renovation: any) => parseInt(renovation?.investment_cost) !== 0
        );
        if (
          allRecords[0]?.epc_label &&
          parseInt(allRecords[0]?.epc_label) === 2
        ) {
          setSelectedUserCombination({});
          setFutureEpc("150");
          setCustomStars(4);
          setRenovations([]);
        } else {
          setSelectedUserCombination(packA[0]);
          setFutureEpc("150");
          setCustomStars(4);
          setRenovations(remaingRenovations);
        }
        setLoader(false);
      }
    }
  };

  const baseOfferRenovation = () => {
    let packA = userStatus?.data?.base_offer
      ? JSON.parse(userStatus?.data?.base_offer)
      : [];
    // to get and store pack label a records
    if (packA?.length > 0) {
      let newArray: any = [];
      let duplicateCall0: any = [...userModalRenovations];
      let addingNewRenovation: any = [
        {
          ...duplicateCall0[1],
          renovation: "glazing",
        },
        {
          ...duplicateCall0[1],
          renovation: "solar",
          total_subsidy: packA[0]?.solar_subsidy,
          investment_price: packA[0]?.solar_invsetment_cost,
        },
      ];
      // new array with solar and glazing
      let allCombinedRenovations = [
        ...addingNewRenovation,
        ...userExistRenovations,
      ];
      let record: any = [...allCombinedRenovations]?.filter((content: any) =>
        packA[0]?.renovation
          ?.split("_")
          ?.some((key: any) => content.renovation === key)
      );
      record?.map((renovations: any) =>
        newArray.push({
          ...renovations,
          active: 1,
        })
      );
      setEpcA(packA[0]?.renovation);
      if (packA[0]) {
        setSelectedInd(packA[0]?.epc_ind);
        setSelectedUserCombination(packA[0]);
      }
      if (newArray) {
        newArray = renovationsInvestmentPrice(newArray, packA[0]);
      }
      // remove records if null
      let removingNullRenovation = newArray?.filter(
        (renovation: any) => renovation?.investment_cost !== null
      );
      // remove records if price is 0
      let remaingRenovations = removingNullRenovation?.filter(
        (renovation: any) => parseInt(renovation?.investment_cost) !== 0
      );
      if (userStatus?.data?.status && parseInt(userStatus?.data?.status) > 3) {
        setRenovations(remaingRenovations);
        setSelectedPack("baseOffer");
        if (parseInt(packA[0]?.epc_ind) < 150) {
          setFutureEpc("99");
          setCustomStars(5);
        } else {
          setFutureEpc(packA[0]?.epc_ind);
        }
      }
    }
  };

  useEffect(() => {
    setRefinementCall2Record(null);
    setRefinementCall3Record(null);
    // homepage content api call for testimonial section
    let cityId: any = { id: 1 };
    // token is commented because they are not using any login functionality
    let userRecord: any = "";
    // let token = cookie.load("token");
    // if (token) {
    //   let decoded: any = jwt_decode(token);
    //   if (decoded) {
    //     userRecord = decoded;
    //     setLoggedInUser(decoded);
    //     setIsAdminView(false);
    //   }
    // } else {
    //   setLoggedInUser(null);
    //   if (window.location.href.split("=")[2]) {
    //     setIsAdminView(true);
    //   }
    // }
    // this was commented in old project
    // GetContentByService(cityId)
    //   .then((response) => {
    //     setSectionContent(response?.data?.data?.page_contents);
    //   })
    //   .catch((error) => {
    //     console.log("error 1");
    //   });
    // result page content api call
    let resultId: any = { id: 193 };
    // let resultId: any = { id: 167 };
    GetContentByService(resultId)
      .then((response) => {
        setResultPageContent(response?.data?.data?.page_contents);
        console.log(response?.data?.data?.page_contents);
      })
      .catch((error) => {
        console.log("error");
      });
    // api call to get all renovations list of records
    let userId = {
      user_id: match?.params?.id?.split("&")
        ? match?.params?.id?.split("&")[0]
        : match?.params?.id,
    };
    // api call to get only renovation house record
    GetAllRenovationsByService(userId)
      .then((houseRenovations: any) => {
        setHouseDetails(houseRenovations?.data[0]);
        let callZeroRenovations: any = houseRenovations?.data[0]
          ?.user_selected_data
          ? JSON.parse(houseRenovations?.data[0]?.user_selected_data)
          : [];

        // to show users renovations if login else show all logout renovations
        let payload: any = {
          building_id: houseRenovations?.data[0]?.building_id,
          // userId: userRecord?.id,
        };
        let local_storage: any;
        let temp: any = localStorage?.getItem("user_temporary_id");
        if (
          localStorage?.getItem("user_temporary_id") &&
          localStorage?.getItem("user_temporary_id") != null
        ) {
          local_storage = JSON.parse(temp);
        }
        if (userRecord || window?.location?.href?.split("=")[2]) {
          payload.userId = userRecord?.id
            ? userRecord?.id
            : parseInt(atob(window.location.href.split("=")[2]));
        } else if (
          local_storage &&
          local_storage[houseRenovations?.data[0]?.building_id]
        ) {
          payload.user_temporary_id =
            local_storage[houseRenovations?.data[0]?.building_id][
              "user_temporary_id"
            ];
        }

        if (
          (local_storage &&
            local_storage[houseRenovations?.data[0]?.building_id]) ||
          userRecord
        ) {
          setLoader(true);
          setUserData(userRecord);
          setActiveModal("callZero");

          getUserSelectedDataService(payload)
            .then((renovations: any) => {
              // setActiveModal("");
              setLoader(false);
              let buildingRefinements: any = renovations?.data
                ?.building_refinements
                ? JSON.parse(renovations?.data?.building_refinements)
                : [];

              let renovationRefinements: any = renovations?.data
                ?.renovation_refinements
                ? JSON.parse(renovations?.data?.renovation_refinements)
                : [];

              if (
                renovations?.data !== "no data yet" &&
                buildingRefinements &&
                houseCallRecordUpdated?.leftSide === ""
              ) {
                setRefinementCall3Record(buildingRefinements);
              }
              if (
                renovationRefinements &&
                houseCallRecordUpdated?.rightSide === ""
              ) {
                setRefinementCall2Record(renovationRefinements);
              }
              let data = {
                response: {
                  data: [renovations.data],
                },
              };

              let data1 =
                renovations?.data == "no data yet" ||
                renovations?.data == "no temporary id created yet"
                  ? houseRenovations
                  : data?.response;

              userRenovations(
                data1,
                userRecord,
                houseRenovations,
                userId,
                callZeroRenovations,
                renovationRefinements
              );
            })
            .catch((error) => {
              console.log(
                "🚀 ~ file: Results.tsx ~ line 654 ~ .then ~ error",
                error
              );
              // setActiveModal("");
              setLoader(false);
            });
        } else {
          // setActiveModal("");
          setLoader(false);
          userRenovations(
            houseRenovations,
            userRecord,
            houseRenovations,
            userId,
            callZeroRenovations
          );
        }
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: Results.tsx ~ line 671 ~ useEffect ~ error",
          error
        );
      });
  }, [cookie.load("token")]);

  useEffect(() => {
    baseOfferRenovation();
  }, [userStatus]);

  // method to change pack label using switch button
  const onChange = (checked: any) => {
    if (userModalRenovations && checked === true) {
      // pack A
      let Building_data = { data: [houseDetails] };
      let packA: any = renovationPostProcessingForPackB(
        userSelectedData,
        refinementCall2Record,
        Building_data
      );
      // to get and store pack label a records
      if (packA?.length > 0) {
        let newArray: any = [];
        let duplicateCall0: any = [...userModalRenovations];
        let addingNewRenovation: any = [
          {
            ...duplicateCall0[1],
            renovation: "glazing",
          },
          {
            ...duplicateCall0[1],
            renovation: "solar",
            total_subsidy: packA[0]?.solar_subsidy,
            investment_price: packA[0]?.solar_invsetment_cost,
          },
        ];
        // new array with solar and glazing
        let allCombinedRenovations = [
          ...addingNewRenovation,
          ...userExistRenovations,
        ];
        let record: any = [...allCombinedRenovations]?.filter((content: any) =>
          packA[0]?.renovation
            ?.split("_")
            ?.some((key: any) => content.renovation === key)
        );
        record?.map((renovations: any) =>
          newArray.push({
            ...renovations,
            active: 1,
          })
        );
        setEpcA(packA[0]?.renovation);
        if (packA[0]) {
          setSelectedInd(101);
        }
        if (newArray) {
          newArray = renovationsInvestmentPrice(newArray, packA[0]);
        }
        // newArray = defaultRenovations(allRenovationRecord, newArray);
        // remove records if null
        let removingNullRenovation = newArray?.filter(
          (renovation: any) => renovation?.investment_cost !== null
        );
        // remove records if price is 0
        let remaingRenovations = removingNullRenovation?.filter(
          (renovation: any) => parseInt(renovation?.investment_cost) !== 0
        );
        if (
          userSelectedData[0]?.epc_label &&
          parseInt(userSelectedData[0]?.epc_label) === 2
        ) {
          setSelectedUserCombination({});
          setFutureEpc("150");
          setCustomStars(4);
          setRenovations([]);
        } else {
          setSelectedUserCombination(packA[0]);
          setFutureEpc("150");
          setCustomStars(4);
          setRenovations(remaingRenovations);
        }
        setSelectedPack("packB");
        setPackLabel(true);
      }
    } else {
      let Building_data = { data: [houseDetails] };
      // pack A
      let packA: any = renovationPostProcessing(
        userSelectedData,
        refinementCall2Record,
        Building_data
      );
      // to get and store pack label a records
      if (packA?.length > 0) {
        let newArray: any = [];
        let duplicateCall0: any = [...userModalRenovations];
        let addingNewRenovation: any = [
          {
            ...duplicateCall0[1],
            renovation: "glazing",
          },
          {
            ...duplicateCall0[1],
            renovation: "solar",
            total_subsidy: packA[0]?.solar_subsidy,
            investment_price: packA[0]?.solar_invsetment_cost,
          },
        ];
        // new array with solar and glazing
        let allCombinedRenovations = [
          ...addingNewRenovation,
          ...userExistRenovations,
        ];
        let record: any = [...allCombinedRenovations]?.filter((content: any) =>
          packA[0]?.renovation
            ?.split("_")
            ?.some((key: any) => content.renovation === key)
        );
        record?.map((renovations: any) =>
          newArray.push({
            ...renovations,
            active: 1,
          })
        );
        setEpcA(packA[0]?.renovation);
        if (packA[0]) {
          setSelectedInd(99);
        }
        if (newArray) {
          newArray = renovationsInvestmentPrice(newArray, packA[0]);
        }
        // newArray = defaultRenovations(allRenovationRecord, newArray);
        // remove records if null
        let removingNullRenovation = newArray?.filter(
          (renovation: any) => renovation?.investment_cost !== null
        );
        // remove records if price is 0
        let remaingRenovations = removingNullRenovation?.filter(
          (renovation: any) => parseInt(renovation?.investment_cost) !== 0
        );
        if (
          userSelectedData[0]?.epc_label &&
          parseInt(userSelectedData[0]?.epc_label) === 1
        ) {
          setSelectedUserCombination({});
          setFutureEpc("99");
          setCustomStars(5);
          setRenovations([]);
        } else {
          setSelectedUserCombination(packA[0]);
          setFutureEpc("99");
          setCustomStars(5);
          setRenovations(remaingRenovations);
        }
        // setRenovations(remaingRenovations);
        // setFutureEpc("1");
        // setCustomStars(5);
        setSelectedPack("packA");
        setPackLabel(false);
      }
    }
  };

  // Method to return result page value through index.
  const getResultContent = (index: number): any => {
    console.log("resultPageContent[index]?.value",resultPageContent[83]?.value)
    return resultPageContent?.length !== 0 &&
      resultPageContent[index]?.value ? (
      <Markup content={resultPageContent[index]?.value} />
    ) : (
      ""
    );
  };
  console.log("resultPageContent",resultPageContent)
  const sortOrder = [
    "windowsDoubleAndDoors",
    "glazing",
    "wallExt",
    "wallCav",
    "roofExt",
    "roofIn",
    "roofAttic",
    "ventD",
    "ventC+",
    "heatPump",
    "condGas",
    "solar",
  ];

  const applyCustomOrder = (arr: any, desiredOrder: any, sortedArray: any) => {
    let array: any = [];
    arr?.split("_").map((item: any) => {
      array.push(item);
    });
    const orderForIndexVals = desiredOrder.slice(0).reverse();
    return (sortedArray = array.sort((a: any, b: any) => {
      const aIndex = -orderForIndexVals.indexOf(a);
      const bIndex = -orderForIndexVals.indexOf(b);
      return aIndex - bIndex;
    }));
  };

  const sortComboForHeatPump = (renovation: any) => {
    // method to sort array
    let sortedArray: any = [];
    let comboArray = "";
    // let comboArray = "";
    let sortedCombination = applyCustomOrder(
      renovation,
      sortOrder,
      sortedArray
    );
    if (sortedCombination) {
      sortedCombination?.map((item: any) => {
        comboArray = comboArray ? comboArray + "_" + item : item;
      });
    }
    return comboArray;
  };

  // heatPump logic
  const custumCumboArray = (
    lowestYearly_savings: any,
    comboArray: any,
    solarAndGlazingRenovations: any
  ) => {
    if (!lowestYearly_savings[0]?.renovation?.includes("condGas")) {
      if (lowestYearly_savings[0]?.renovation?.includes("heatPump")) {
        // if total investment cost is not null
        if (
          parseInt(lowestYearly_savings[0]?.investment_cost) !== null &&
          parseInt(lowestYearly_savings[0]?.heating_investment_cost) !==
            100000000 &&
          parseInt(lowestYearly_savings[0]?.investment_cost) > 0
        ) {
          comboArray = { ...lowestYearly_savings[0] };
          let selectedHeatpump = lowestYearly_savings && {
            ...lowestYearly_savings[0],
          };
          return {
            comboArray: comboArray,
            heatPump: "exist",
            default_heatpump: "selected",
            heatPump_price: [],
          };
        }
        // if total investment is null
        else {
          // -
          let newComboArray = lowestYearly_savings[0]?.renovation?.replace(
            "_heatPump",
            ""
          );
          newComboArray = sortComboForHeatPump(newComboArray);
          let allRenovatesDuplicates: any = [...solarAndGlazingRenovations];
          let filteredArray = allRenovatesDuplicates?.filter((sin: any) => {
            return sin.renovation === newComboArray;
          });
          comboArray = { ...filteredArray[0] };
          return {
            comboArray: comboArray,
            heatPump: "remove",
            default_heatpump: "not-selected",
            heatPump_price: [],
          };
        }
      }
      // if heatpump doesn't exist
      else {
        // to get combination without heatPump and return to save price
        let withoutHeatPump = lowestYearly_savings[0]?.renovation;
        let singleRenovationWithoutHeatPump: any = [
          ...solarAndGlazingRenovations,
        ]?.filter((content: any) => {
          return content?.renovation === withoutHeatPump;
        });
        // - wallExt
        let addRenovation = lowestYearly_savings[0]?.renovation?.includes("_")
          ? // &&
            // lowestYearly_savings[0]?.renovation?.split("_") > 0
            lowestYearly_savings[0]?.renovation + "_heatPump"
          : "heatPump";
        let sortedComboArray: any;
        if (lowestYearly_savings[0]?.renovation?.split("_") > 0) {
          sortedComboArray = sortComboForHeatPump(addRenovation);
        } else {
          sortedComboArray = addRenovation;
        }
        let getSingleRenovation: any = [...solarAndGlazingRenovations]?.filter(
          (content: any) => {
            return content?.renovation === sortedComboArray;
          }
        );

        if (
          parseInt(getSingleRenovation[0]?.investment_cost) !== null &&
          parseInt(lowestYearly_savings[0]?.heating_investment_cost) !==
            100000000 &&
          parseInt(getSingleRenovation[0]?.investment_cost) > 0
        ) {
          comboArray = { ...getSingleRenovation[0] };
          return {
            //
            comboArray: { ...singleRenovationWithoutHeatPump[0] },
            // comboArray: comboArray,
            heatPump: "exist",
            default_heatpump: "appended-but-not-selected",
            // to get heatPump value from here
            heatPump_price: comboArray,
          };
        } else {
          let newComboArray = lowestYearly_savings[0]?.renovation.replace(
            "_heatPump",
            ""
          );
          newComboArray = sortComboForHeatPump(newComboArray);
          let allRenovatesDuplicates: any = [...solarAndGlazingRenovations];
          let filteredArray = allRenovatesDuplicates?.filter((sin: any) => {
            return sin.renovation === newComboArray;
          });
          comboArray = { ...filteredArray[0] };
          return {
            comboArray: comboArray,
            heatPump: "remove",
            array: "exist",
            default_heatpump: "not-selected",
            heatPump_price: [],
          };
        }
      }
    } else {
      // to check heatPump
      let heatPumpComboArray: any;
      heatPumpComboArray = lowestYearly_savings[0]?.renovation?.includes(
        "condGas"
      )
        ? lowestYearly_savings[0]?.renovation?.replace("condGas", "heatPump")
        : "";
      let heatPumpRenovation: any = [...solarAndGlazingRenovations]?.filter(
        (content: any) => {
          return content?.renovation === heatPumpComboArray;
        }
      );
      let selectedHeatpump: any = [];
      if (
        parseInt(heatPumpRenovation[0]?.investment_cost) !== null &&
        parseInt(heatPumpRenovation[0]?.heating_investment_cost) !==
          100000000 &&
        parseInt(heatPumpRenovation[0]?.investment_cost) > 0
      ) {
        selectedHeatpump = heatPumpRenovation && { ...heatPumpRenovation[0] };
      }
      // to return values
      return {
        comboArray: { ...lowestYearly_savings[0] },
        heatPump: Object.keys(selectedHeatpump).length > 0 ? "exist" : "remove",
        default_heatpump:
          Object.keys(selectedHeatpump).length > 0
            ? "appended-but-not-selected"
            : "not-selected",
        heatPump_price:
          Object.keys(selectedHeatpump).length > 0 ? selectedHeatpump : [],
      };
    }
  };

  const updateSoloRenovationPrices = (renovation: any) => {
    renovation?.map((item: any) => {
      // if (item?.active !== 1) {
      if (item?.renovation === "windowsDoubleAndDoors") {
        return (item.investment_cost = item?.window_investment_cost);
      }
      // if (item?.renovation === "wallCav") {
      //   return (item.investment_cost = item?.wall_investment_cost);
      // }
      // if (item?.renovation === "wallExt") {
      //   return (item.investment_cost = item?.wall_investment_cost);
      // }
      // if (item?.renovation === "roofAttic") {
      //   return (item.investment_cost = item?.roof_investment_cost);
      // }
      // if (item?.renovation === "roofIn") {
      //   return (item.investment_cost = item?.roof_investment_cost);
      // }
      // if (item?.renovation === "roofExt") {
      //   return (item.investment_cost = item?.roof_investment_cost);
      // }
      // if (item?.renovation === "ventC+") {
      //   return (item.investment_cost = item?.ventilation_investment_cost);
      // }
      // if (item?.renovation === "ventD") {
      //   return (item.investment_cost = item?.ventilation_investment_cost);
      // }
      // if (item?.renovation === "condGas") {
      //   return (item.investment_cost = item?.heating_investment_cost);
      // }
      // if (item?.renovation === "heatPump") {
      //   return (item.investment_cost = item?.heating_investment_cost);
      // }
      // if (item?.renovation === "solar") {
      //   return (item.investment_cost = item?.solar_invsetment_cost);
      // }
      if (item?.renovation === "glazing") {
        return (item.investment_cost = item?.window_investment_cost / 2);
      }
      // }
    });
    return renovation;
  };

  // method to show all renovations
  const showAllRenovations = (value: any) => {
    // method to get all renovations after solar and glazing logics
    let Building_data = { data: [houseDetails] };
    let postProcessingRenovations = customSolarRenovationPostProcessing(
      userSelectedData,
      refinementCall2Record,
      Building_data
    );
    let solarAndGlazingRenovations = [
      ...postProcessingRenovations?.solarPlusGlazingScenario,
      ...postProcessingRenovations?.withoutSolarArray,
    ];
    let existSoloRenovations: any = [];
    let AllSoloCombinationsFromPython = existRenovationHandler(
      postProcessingRenovations?.python1Renovations,
      existSoloRenovations
    );
    // pack A
    let packA: any = renovationPostProcessing(
      userSelectedData,
      refinementCall2Record,
      Building_data
    );

    let duplicateCall0: any = [...userModalRenovations];
    let addingNewRenovation: any = [
      {
        ...duplicateCall0[1],
        renovation: "glazing",
        investment_cost: duplicateCall0[1]?.investment_cost / 2,
      },
      {
        ...duplicateCall0[1],
        renovation: "solar",
        ...solarPriceHandler(houseDetails),
        total_subsidy: solarPriceHandler(houseDetails)?.solar_subsidy_cost,
      },
    ];
    // new array with solar and glazing
    let allCombinedRenovations = [
      ...addingNewRenovation,
      ...AllSoloCombinationsFromPython,
      // ...userModalRenovations,
      // ...userExistRenovations,
    ];
    if (packA?.length > 0) {
      let newArray: any = [];
      let heatPumpComboArray: any;
      let newHeatPumpArray: any = [];
      let heatPumpSelectedObject = custumCumboArray(
        packA,
        heatPumpComboArray,
        solarAndGlazingRenovations
      );
      // comboArray: comboArray, heatPump
      packA = [heatPumpSelectedObject?.comboArray];
      let record: any = [...allCombinedRenovations]?.filter((content: any) =>
        packA[0]?.renovation
          ?.split("_")
          ?.some((key: any) => content.renovation === key)
      );
      if (
        heatPumpSelectedObject?.default_heatpump === "appended-but-not-selected"
      ) {
        let heatPumpRecord: any = [...allCombinedRenovations]?.filter(
          (content: any) => {
            return content.renovation === "heatPump";
          }
        );
        newHeatPumpArray.push({
          ...heatPumpRecord[0],
          investment_cost:
            heatPumpSelectedObject?.heatPump_price?.heating_investment_cost,
        });
      }
      record?.map((renovations: any) =>
        newArray.push({
          ...renovations,
          active: 1,
        })
      );
      setEpcA(packA[0]?.renovation);
      if (packA[0]) {
        setSelectedInd(99);
        setSelectedUserCombination(packA[0]);
      }

      if (newArray) {
        newArray = renovationsInvestmentPrice(newArray, packA[0]);
      }

      let combinedCombinations = [...newArray, ...newHeatPumpArray];
      if (combinedCombinations) {
        let duplicateData: any = [...allCombinedRenovations];
        let remaingRecord: any = [...duplicateData]
          // ?.splice(0, 13)
          ?.filter(
            (content: any) =>
              !combinedCombinations?.some(
                (key: any) => content.renovation === key.renovation
              )
          );
        updateSoloRenovationPrices(remaingRecord);
        //
        let allTenRenovationsRecord = [
          ...combinedCombinations,
          ...remaingRecord,
        ];
        allTenRenovationsRecord = defaultRenovations(
          allRenovationRecord,
          allTenRenovationsRecord
        );

        if (
          !allTenRenovationsRecord?.find(
            (item: any) => item.renovation === "windowsDoubleAndDoors"
          )
        ) {
          let glazingRemoved = allTenRenovationsRecord?.filter(
            (renovation: any) => renovation?.renovation !== "glazing"
          );
          allTenRenovationsRecord = glazingRemoved;
        }

        // remove records if null
        let removingNullRenovation = allTenRenovationsRecord?.filter(
          (renovation: any) => {
            // if(renovation?.renovation === 'condGas' &&
            // (renovation?.investment_cost == null || renovation?.investment_cost == 0)){
            //   renovation.investment_cost = 4200
            //   return renovation;
            // }
            if (renovation?.investment_cost !== null) {
              return renovation;
            }
          }
        );
        // remove records if price is 0 and to check value of heatPump if value is
        let removingZeroRenovation = removingNullRenovation?.filter(
          (renovation: any) => {
            // if(renovation?.renovation === 'condGas' &&
            // (renovation?.investment_cost == null || renovation?.investment_cost == 0)){
            //   renovation.investment_cost = 4200
            //   return renovation;
            // }
            if (parseInt(renovation?.investment_cost) !== 0) {
              return renovation;
            }
          }
        );
        // removing heatPump
        let remaingRenovations: any;
        if (
          heatPumpSelectedObject?.heatPump === "remove" ||
          removingZeroRenovation?.find(
            (item: any) => item.investment_cost === "100000000.0"
          )
        ) {
          remaingRenovations = removingZeroRenovation?.filter(
            (renovation: any) => {
              return renovation?.renovation !== "heatPump";
            }
          );
        } else {
          remaingRenovations = removingZeroRenovation;
        }
        let sortedRenovations = sortedRenovation(remaingRenovations);
        let AllFinalRenovations: any;
        if (
          refinementCall2Record?.solar?.number_panels &&
          parseInt(refinementCall2Record?.solar?.number_panels) > 0
        ) {
          AllFinalRenovations = sortedRenovations?.filter(
            (item: any) => item?.renovation !== "solar"
          );
        } else {
          AllFinalRenovations = sortedRenovations;
        }
        setRenovations(AllFinalRenovations);
        setCustom(true);
      }
      setFutureEpc("99");
      setCustomStars(5);
      setCustomPackSelected(true);
    }
    // } else {
    //   setCustom(false);
    //   onChange(packLabel);
    // }
  };

  const openModal = (activeModal: any) => {
    setIsOpenModal(true);
    setActiveModal(activeModal);
  };

  const closeModal = (e: any) => {
    if (e == false) {
    } else {
      setIsOpenModal(false);
      setActiveModal("");
    }
  };

  const renovationsReverseLogics = (renovations: any, card: any) => {
    // wall
    if (card.renovation === "wallCav") {
      renovations?.map((item: any, index: any) => {
        if (item?.renovation === "wallExt") {
          return (renovations[index].active = 0);
        }
      });
    }
    if (card.renovation === "wallExt") {
      renovations?.map((item: any, index: any) => {
        if (item?.renovation === "wallCav") {
          return (renovations[index].active = 0);
        }
      });
    }
    // roof
    if (card.renovation === "roofAttic") {
      renovations?.map((item: any, index: any) => {
        if (item?.renovation === "roofIn") {
          return (renovations[index].active = 0);
        }
        if (item?.renovation === "roofExt") {
          return (renovations[index].active = 0);
        }
      });
    }
    if (card.renovation === "roofIn") {
      renovations?.map((item: any, index: any) => {
        if (item?.renovation === "roofAttic") {
          return (renovations[index].active = 0);
        }
        if (item?.renovation === "roofExt") {
          return (renovations[index].active = 0);
        }
      });
    }
    if (card.renovation === "roofExt") {
      renovations?.map((item: any, index: any) => {
        if (item?.renovation === "roofAttic") {
          return (renovations[index].active = 0);
        }
        if (item?.renovation === "roofIn") {
          return (renovations[index].active = 0);
        }
      });
    }
    // ventillation
    if (card.renovation === "ventC+") {
      renovations?.map((item: any, index: any) => {
        if (item?.renovation === "ventD") {
          return (renovations[index].active = 0);
        }
      });
    }
    if (card.renovation === "ventD") {
      renovations?.map((item: any, index: any) => {
        if (item?.renovation === "ventC+") {
          return (renovations[index].active = 0);
        }
      });
    }
    //  condGas
    if (card.renovation === "condGas") {
      renovations?.map((item: any, index: any) => {
        if (item?.renovation === "heatPump") {
          return (renovations[index].active = 0);
        }
      });
    }
    if (card.renovation === "heatPump") {
      renovations?.map((item: any, index: any) => {
        if (item?.renovation === "condGas") {
          return (renovations[index].active = 0);
        }
      });
    }
    //  glazing
    if (card.renovation === "glazing") {
      renovations?.map((item: any, index: any) => {
        if (item?.renovation === "windowsDoubleAndDoors") {
          return (renovations[index].active = 0);
        }
      });
    }
    if (card.renovation === "windowsDoubleAndDoors") {
      renovations?.map((item: any, index: any) => {
        if (item?.renovation === "glazing") {
          return (renovations[index].active = 0);
        }
      });
    }
  };

  const sortedRenovation = (renovations: any) => {
    // method to sort array
    let sortedArray: any = [];
    const orderForIndexVals = sortOrder.slice(0).reverse();
    return (sortedArray = renovations.sort((a: any, b: any) => {
      const aIndex = -orderForIndexVals.indexOf(a.renovation);
      const bIndex = -orderForIndexVals.indexOf(b.renovation);
      return aIndex - bIndex;
    }));
  };

  const postProcessingSolar = (
    packA: any,
    userModalRenovations: any,
    solarAndGlazingRenovations: any,
    heatPumpSelectedObject: any,
    AllSoloCombinationsFromPython?: any
  ) => {
    let duplicateCall0: any = [...userModalRenovations];
    let addingNewRenovation: any = [
      {
        ...duplicateCall0[1],
        renovation: "glazing",
        investment_cost: packA[0]?.renovation?.includes(
          "windowsDoubleAndDoors_"
        )
          ? packA[0]?.window_investment_cost / 2
          : duplicateCall0[1]?.investment_cost / 2,
      },
      {
        ...duplicateCall0[1],
        renovation: "solar",
        ...solarPriceHandler(houseDetails),
        total_subsidy: solarPriceHandler(houseDetails)?.solar_subsidy_cost,
        investment_cost: solarPriceHandler(houseDetails)?.solar_invsetment_cost,
      },
    ];
    // new array with solar and glazing
    let allCombinedRenovations = [
      ...addingNewRenovation,
      ...AllSoloCombinationsFromPython,
      // ...userModalRenovations,
      // ...userExistRenovations,
    ];
    if (packA?.length > 0) {
      let newArray: any = [];
      let newHeatPumpArray: any = [];
      let record: any = [...allCombinedRenovations]?.filter((content: any) =>
        packA[0]?.renovation
          ?.split("_")
          ?.some((key: any) => content.renovation === key)
      );
      if (
        heatPumpSelectedObject?.default_heatpump === "appended-but-not-selected"
      ) {
        let heatPumpRecord: any = [...allCombinedRenovations]?.filter(
          (content: any) => {
            return content.renovation === "heatPump";
          }
        );

        newHeatPumpArray.push({
          ...heatPumpRecord[0],
          investment_cost:
            heatPumpSelectedObject?.heatPump_price?.heating_investment_cost,
        });
      }
      record?.map((renovations: any) => {
        newArray.push({
          ...renovations,
          active: 1,
        });
      });
      setEpcA(packA[0]?.renovation);
      if (packA[0]) {
        setSelectedInd(packA[0]?.epc_ind);
        // uncomment after testing.
        setSelectedUserCombination(packA[0]);
      }
      if (newArray) {
        newArray = renovationsInvestmentPrice(newArray, packA[0]);
      }

      let combinedCombinations = [...newArray, ...newHeatPumpArray];
      if (combinedCombinations) {
        combinedCombinations = defaultRenovations(
          allRenovationRecord,
          combinedCombinations
        );
        let duplicateData: any = [...allCombinedRenovations];
        let remaingRecord = [...duplicateData]
          // ?.splice(0, 13)
          ?.filter(
            (content: any) =>
              !combinedCombinations?.some(
                (key: any) => content.renovation === key.renovation
              )
          );
        updateSoloRenovationPrices(remaingRecord);
        let allTenRenovationsRecord = [
          ...combinedCombinations,
          ...remaingRecord,
        ];
        // remove record if windowsDoubleAndDoors exits
        if (
          !allTenRenovationsRecord?.find(
            (item: any) => item.renovation === "windowsDoubleAndDoors"
          )
        ) {
          let glazingRemoved = allTenRenovationsRecord?.filter(
            (renovation: any) => renovation?.renovation !== "glazing"
          );
          allTenRenovationsRecord = glazingRemoved;
        }
        // remove records if null
        let removingNullRenovation = allTenRenovationsRecord?.filter(
          (renovation: any) => {
            if (renovation?.investment_cost !== null) {
              return renovation;
            }
          }
        );
        // remove records if price is 0 and to check value of heatPump if value is
        let removingZeroRenovation = removingNullRenovation?.filter(
          (renovation: any) => {
            if (parseInt(renovation?.investment_cost) !== 0) {
              return renovation;
            }
          }
        );
        let remaingRenovations: any;
        if (
          heatPumpSelectedObject?.heatPump === "remove" ||
          removingZeroRenovation?.find(
            (item: any) => item.investment_cost === "100000000.0"
          )
        ) {
          remaingRenovations = removingZeroRenovation?.filter(
            (renovation: any) => {
              return renovation?.renovation !== "heatPump";
            }
          );
        } else {
          remaingRenovations = removingZeroRenovation;
        }
        let sortedRenovations = sortedRenovation(remaingRenovations);
        let AllFinalRenovations: any;
        if (
          refinementCall2Record?.solar?.number_panels &&
          parseInt(refinementCall2Record?.solar?.number_panels) > 0
        ) {
          AllFinalRenovations = sortedRenovations?.filter(
            (item: any) => item?.renovation !== "solar"
          );
        } else {
          AllFinalRenovations = sortedRenovations;
        }
        setRenovations(AllFinalRenovations);
        setCustom(true);
      }
      console.log("selected Pack", packA[0]);
      setFutureEpc(packA[0]?.epc_ind);
    }
    setSelectedPack("customPack");
    setCustomStars(0);
    setCustomPackSelected(false);
  };

  // method to select Card
  const selectCardHandler = (card: any, index: any, key: any) => {
    let duplicateRenovations = [...renovations];
    // method for recursive renovations logics
    renovationsReverseLogics(duplicateRenovations, card);

    duplicateRenovations[index] = { ...card, active: key };
    let selectedCombination: any = "";
    duplicateRenovations?.map((item: any) => {
      if (item?.active === 1) {
        selectedCombination = selectedCombination
          ? selectedCombination + "_" + item?.renovation
          : item?.renovation;
      }
    });
    // method to sort array
    let sortedArray: any = [];
    let comboArray: any = "";
    let sortedCombination = applyCustomOrder(
      selectedCombination,
      sortOrder,
      sortedArray
    );
    // creating comboArray
    sortedCombination?.map((item: any) => {
      comboArray = comboArray ? comboArray + "_" + item : item;
    });

    let Building_data = { data: [houseDetails] };
    let postProcessingRenovations = customSolarRenovationPostProcessing(
      userSelectedData,
      refinementCall2Record,
      Building_data
    );
    let solarAndGlazingRenovations = [
      ...postProcessingRenovations?.solarPlusGlazingScenario,
      // ...postProcessingRenovations?.glazingScenario,
      ...postProcessingRenovations?.withoutSolarArray,
    ];
    console.log("solarAndGlazingRenovations", solarAndGlazingRenovations);
    let existSoloRenovations: any = [];
    let AllSoloCombinationsFromPython = existRenovationHandler(
      postProcessingRenovations?.python1Renovations,
      existSoloRenovations
    );

    let solarRenovation = solarAndGlazingRenovations?.filter(
      (item: any) => item.renovation === comboArray
    );
    let finalComboArray: any = "";
    let heatPumpComboArray: any;
    if (comboArray.includes("solar")) {
      let newRenovation: any;
      // if solar exist comboaray _solar
      if (solarRenovation?.length !== 0) {
        finalComboArray = comboArray;
        // heatPump logic
        let heatPumpSelectedObject = custumCumboArray(
          solarRenovation,
          heatPumpComboArray,
          solarAndGlazingRenovations
        );
        let renovationSelected = [
          {
            ...heatPumpSelectedObject?.comboArray,
            ...solarPriceHandler(houseDetails),
          },
        ];
        if (Object.keys(heatPumpSelectedObject?.comboArray).length > 0) {
          postProcessingSolar(
            renovationSelected,
            userModalRenovations,
            solarAndGlazingRenovations,
            heatPumpSelectedObject,
            AllSoloCombinationsFromPython
          );
        } else {
          let removeSolar =
            finalComboArray?.split("_").length > 1
              ? comboArray.replace("_solar", "")
              : comboArray.replace("solar", "");
          let newRenovationWithoutSolar = solarAndGlazingRenovations?.filter(
            (item: any) => item.renovation === removeSolar
          );
          if (newRenovationWithoutSolar?.length !== 0) {
            // heatPump logic
            let heatPumpSelectedObject = custumCumboArray(
              newRenovationWithoutSolar,
              heatPumpComboArray,
              solarAndGlazingRenovations
            );
            let renovationSelected = [
              {
                ...heatPumpSelectedObject?.comboArray,
                ...solarPriceHandler(houseDetails),
              },
            ];
            //method to pass renovation
            postProcessingSolar(
              renovationSelected,
              userModalRenovations,
              solarAndGlazingRenovations,
              heatPumpSelectedObject,
              AllSoloCombinationsFromPython
            );
          }
        }
      }
      // if solar exist but renovation doesn't found
      else {
        let removeSolar =
          comboArray?.split("_").length > 1
            ? comboArray.replace("_solar", "")
            : comboArray.replace("solar", "");
        newRenovation = solarAndGlazingRenovations?.filter(
          (item: any) => item.renovation === removeSolar
        );
        // if record found
        if (newRenovation?.length !== 0) {
          finalComboArray = newRenovation[0]?.renovation;
          // heatPump logic
          let heatPumpSelectedObject = custumCumboArray(
            newRenovation,
            heatPumpComboArray,
            solarAndGlazingRenovations
          );
          // filter comboArray to get values from renovation
          // let selectedCombination = solarAndGlazingRenovations?.filter(
          //   (item: any) =>
          //     item?.renovation === heatPumpSelectedObject?.comboArray
          // );
          let renovationSelected = [
            {
              ...heatPumpSelectedObject?.comboArray,
              renovation: finalComboArray + "_solar",
              // ...solarPriceHandler(houseDetails),
              // yearly_savings:
              //   parseInt(selectedCombination[0]?.yearly_savings) +
              //   solarPriceHandler(houseDetails).solar_yearly_saving,
              // co2_savings:
              //   parseInt(selectedCombination[0]?.co2_savings) +
              //   solarPriceHandler(houseDetails).solar_co2_saving,
            },
          ];
          //method to pass renovation
          postProcessingSolar(
            renovationSelected,
            userModalRenovations,
            solarAndGlazingRenovations,
            heatPumpSelectedObject,
            AllSoloCombinationsFromPython
          );
        }
        // to check if solar record not found then to replace glazing with windowDoubleAndDoors
        else {
          let removeGlazing =
            removeSolar?.split("_").length > 1
              ? removeSolar.replace("glazing_", "windowsDoubleAndDoors_")
              : removeSolar.replace("glazing", "windowsDoubleAndDoors");
          let newRenovation = solarAndGlazingRenovations?.filter(
            (item: any) => item.renovation === removeGlazing
          );
          if (
            newRenovation?.length !== 0 &&
            newRenovation?.length !== undefined
          ) {
            finalComboArray =
              newRenovation[0]?.renovation?.split("_").length > 1
                ? newRenovation[0]?.renovation.replace(
                    "windowsDoubleAndDoors_",
                    "glazing_"
                  ) + "_solar"
                : newRenovation[0]?.renovation.replace(
                    "windowsDoubleAndDoors",
                    "glazing"
                  ) + "_solar";
            // heatPump logic
            let heatPumpSelectedObject = custumCumboArray(
              newRenovation,
              heatPumpComboArray,
              solarAndGlazingRenovations
            );
            let renovationSelected = [
              {
                ...heatPumpSelectedObject?.comboArray,
                renovation: finalComboArray,
                ...solarPriceHandler(houseDetails),
              },
            ];
            //method to pass renovation
            postProcessingSolar(
              renovationSelected,
              userModalRenovations,
              solarAndGlazingRenovations,
              heatPumpSelectedObject,
              AllSoloCombinationsFromPython
            );
          }
        }
      }
    }
    // for rest of combinations
    else if (!comboArray?.includes("solar")) {
      // call 0, 2, 3 => renovation =>
      let allCombination: any = userModalRenovations?.filter(
        (item: any) => item.renovation === comboArray
      );
      let selectedRenovation = solarAndGlazingRenovations?.filter(
        (item: any) => item.renovation === comboArray
      );
      // to
      if (
        selectedRenovation?.length !== 0 &&
        selectedRenovation !== undefined
      ) {
        let heatPumpSelectedObject = custumCumboArray(
          selectedRenovation,
          heatPumpComboArray,
          solarAndGlazingRenovations
        );
        let renovationSelected = [heatPumpSelectedObject?.comboArray];
        postProcessingSolar(
          renovationSelected,
          userModalRenovations,
          solarAndGlazingRenovations,
          heatPumpSelectedObject,
          AllSoloCombinationsFromPython
        );
      }
      //to check with call state
      else if (allCombination?.length !== 0 && allCombination !== undefined) {
        // else if (allCombination?.length !== 0 && allCombination !== undefined) {
        let heatPumpSelectedObject = custumCumboArray(
          allCombination,
          heatPumpComboArray,
          userModalRenovations
          // solarAndGlazingRenovations
        );
        let renovationSelected = [heatPumpSelectedObject?.comboArray];
        postProcessingSolar(
          renovationSelected,
          userModalRenovations,
          solarAndGlazingRenovations,
          heatPumpSelectedObject,
          AllSoloCombinationsFromPython
        );
      }
      // if not found and glazing is selected so we will replace it with window
      else {
        let replaceGlazing =
          comboArray?.split("_").length > 1
            ? comboArray.replace("glazing_", "windowsDoubleAndDoors_")
            : comboArray.replace("glazing", "windowsDoubleAndDoors");
        let newRenovation: any = userModalRenovations?.filter(
          (item: any) => item.renovation === replaceGlazing
        );
        // combination =>
        let finalComboArray: any =
          newRenovation[0]?.renovation?.split("_").length > 1
            ? newRenovation[0]?.renovation.replace(
                "windowsDoubleAndDoors_",
                "glazing_"
              )
            : newRenovation[0]?.renovation.replace(
                "windowsDoubleAndDoors",
                "glazing"
              );
        // heatPump logic
        let heatPumpSelectedObject = custumCumboArray(
          newRenovation,
          heatPumpComboArray,
          // userModalRenovations
          solarAndGlazingRenovations
        );
        let renovationSelected = [
          {
            ...heatPumpSelectedObject?.comboArray,
            renovation: finalComboArray,
            glazing_investment_cost:
              parseInt(newRenovation[0]?.window_investment_cost) / 2,
          },
        ];
        //method to pass renovation
        postProcessingSolar(
          renovationSelected,
          userModalRenovations,
          solarAndGlazingRenovations,
          heatPumpSelectedObject,
          AllSoloCombinationsFromPython
        );
      }
    }
  };

  useEffect(() => {
    if (activeModal === "call2&3") {
      const timer = setTimeout(() => {
        setActiveModal("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [activeModal]);

  const addressHandler = (name: any) => {
    const house = name?.split(/(\d+)/);
    const street = name?.split(/[0-9A-Z]/)[0];
    let firstAddress: any = "";
    let lastElement: any = "";
    house?.map((item: any) => {
      if (firstAddress === "" && item === street) {
        firstAddress = street;
        lastElement = house[house?.length - 2] + " " + house[house?.length - 1];
        // house[house?.length - 3] = ",";
        house[house?.length - 2] = "";
        house[house?.length - 1] = "";
      } else {
        firstAddress = firstAddress + item;
      }
    });
    firstAddress = firstAddress?.replace(" -", ", ");
    return {
      firstAddress: firstAddress?.replace(" ,", ", "),
      lastAddress: lastElement,
    };
  };

  return (
    <div className="result_page_main_container">
      <Helmet>
        <link
          rel="stylesheet"
          href="https://js.arcgis.com/4.21/esri/themes/light/main.css"
        />
      </Helmet>
      {/* Call2And3Modal */}
      {activeModal === "call2&3" && (
        <Call2And3Modal getValue={getResultContent} />
      )}
      {/* CallZeroModal */}
      {activeModal === "callZero" && (
        <div>
          <CallZeroModal
            loader={loader}
            getResultContent={getResultContent}
            closeModal={closeModal}
          />
        </div>
      )}
      {/* Header */}
      {/* <ResultHeader
        openModal={openModal}
        closeModal={closeModal}
        getResultContent={getResultContent}
        userStatus={userStatus}
        setLoggedInUser={setLoggedInUser}
        setRefinementCall2Record={setRefinementCall2Record}
        setRefinementCall3Record={setRefinementCall3Record}
        setFutureEpc={setFutureEpc}
        setPackLabel={setPackLabel}
        setLoader={setLoader}
      /> */}
      <div
        className={`nc-ListingStayDetailPage`}
        data-nc-id="ListingStayDetailPage"
      >
        <Modal
          setIsOpenModal={setIsOpenModal}
          openModal={openModal}
          setActiveModal={setActiveModal}
          activeRenovationToolTip={activeRenovationToolTip}
          activeModal={activeModal}
          closeModal={closeModal}
          isOpenModal={isOpenModal}
          callTwo={callTwoTemplate}
          callThree={callThreeTemplate}
          userBuildingTemPId={userBuildingId}
          getValue={getResultContent}
          allRenovationRecord={allRenovationRecord}
          renovationModalContent={renovationModalContent}
          userData={userData}
          setUserStatus={setUserStatus}
          setLoader={setLoader}
          houseCallRecordUpdated={houseCallRecordUpdated}
          setHouseCallRecordUpdated={setHouseCallRecordUpdated}
          // state to pass user data if exits
          refinementCall3Record={refinementCall3Record}
          setRefinementCall2Record={setRefinementCall2Record}
          refinementCall2Record={refinementCall2Record}
          setRefinementCall3Record={setRefinementCall3Record}
          // state for left and right side model of section 1
          houseDetails={houseDetails}
          // states for method
          setAllUserSelectedData={setAllUserSelectedData}
          setEpcA={setEpcA}
          renovationsInvestmentPrice={renovationsInvestmentPrice}
          defaultRenovations={defaultRenovations}
          setRenovations={setRenovations}
          userModalRenovations={userModalRenovations}
          //
          setFutureEpc={setFutureEpc}
          setPackLabel={setPackLabel}
          //
          setUserExistRenovations={setUserExistRenovations}
          getResultContent={getResultContent}
          //
          setSelectedUserCombination={setSelectedUserCombination}
          //
          setSelectedPack={setSelectedPack}
          setCustomStars={setCustomStars}
          setIsCallWaiting={setIsCallWaiting}
          setDownloadedRecord={setDownloadedRecord}
        />
        {/* Steps */}
        {/* <SectionRenovationSteps
          userStatus={userStatus}
          getResultContent={getResultContent}
        /> */}
        {/* <ResultBanner /> */}
        {/* <ChooseRenovationComponent />
        <OurReacommended /> */}
        {/* SectionHouse 2 house data*/}
        <div className="mx-[5%] lg:mx-[10%]">
          <div className="w-full">
            {/* heading-div */}
            <div className="flex flex-col lg:flex-row items-center py-8">
              <p className="text-[38px] lg:text-[38px] font-semibold text-[#222222] ">
                {getResultContent(2)}
              </p>
              <p className="text-lg lg:text-[22px] font-bold text-[#007DDF] pl-[14px] pt-1">
                {addressHandler(houseDetails?.formatted_address)?.firstAddress +
                  " " +
                  addressHandler(houseDetails?.formatted_address)?.lastAddress}
              </p>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row">
            {/* left-side */}
            <SectionHouse
              allRenovationRecord={houseDetails}
              refinementCall3Record={refinementCall3Record}
              openModal={openModal}
              className={"bg-white border z-10"}
              getResultContent={getResultContent}
              userStatus={userStatus}
              loggedInUser={loggedInUser}
              isCallWaitng={isCallWaitng}
              isAdminView={isAdminView}
            />
            {/* right-side */}
            <SectionDiagnosis
              refinementCall2Record={refinementCall2Record}
              allRenovationRecord={houseDetails}
              openModal={openModal}
              userStatus={userStatus}
              userSelectedData={userSelectedData}
              className={"bg-white shadow z-10"}
              getResultContent={getResultContent}
              loggedInUser={loggedInUser}
              isCallWaitng={isCallWaitng}
              downloadRecord={downloadRecord}
              isAdminView={isAdminView}
            />
          </div>
        </div>

        {/* packs buttons */}
        <main className="mx-[5%] lg:mx-[10%]">
          {/* CONTENT */}
          <div className="pb-8 pt-4">
            <Packs
              getResultContent={getResultContent}
              selectedPack={selectedPack}
              setSelectedPack={setSelectedPack}
              // method to change pack label
              onChange={onChange}
              // method for custom
              showAllRenovations={showAllRenovations}
              custom={custom}
              setCustom={setCustom}
              baseOfferRenovation={baseOfferRenovation}
              userStatus={userStatus}
            />
          </div>
          <div className="pb-8">
            <Subsides
              subsidies={subsidies}
              setSubsidies={setSubsidies}
              getResultContent={getResultContent}
              userStatus={userStatus}
              openModal={openModal}
            />
          </div>
          <div className="pb-14">
            <div className="flex justify-between flex-col-reverse xl:flex-row">
              {/* renovations options  */}
              <div className="w-full xl:w-[65%] lg:pr-3">
                <div className="w-full ">
                  <SectionOurRecomendation
                    selectedPack={selectedPack}
                    subsidies={subsidies}
                    selectCardHandler={selectCardHandler}
                    // state to setRenovationCOntent
                    setRenovationModalContent={setRenovationModalContent}
                    // method to open modal
                    openModal={openModal}
                    custom={custom}
                    // method to change pack label
                    showAllRenovations={showAllRenovations}
                    // method to change pack label
                    onChange={onChange}
                    // method to get value
                    getResultContent={getResultContent}
                    // state to get all renovations
                    resultPageContent={resultPageContent}
                    // state to show all values of renovations coming from backend
                    renovations={renovations}
                    epcA={epcA}
                    setActiveRenovationToolTip={setActiveRenovationToolTip}
                    isCallWaitng={isCallWaitng}
                  />
                </div>
                <div className="pt-3">
                  <SectionRenovationLoan
                    totalValue={totalValue}
                    getResultContent={getResultContent}
                  />
                </div>
              </div>
              {/* for responsive */}
              <div className="flex-grow xl:w-[25%] w-full">
                {/* <div className="hidden lg:block flex-grow"> */}
                {/* Fixed sidebar */}
                <div className="sticky top-8">
                  <SectionFixedSideBar
                    subsidies={subsidies}
                    houseDetails={houseDetails}
                    loggedInUser={loggedInUser}
                    futureEpc={futureEpc}
                    renovations={renovations}
                    openModal={openModal}
                    userStatus={userStatus}
                    getResultContent={getResultContent}
                    setTotalValue={setTotalValue}
                    userSelectedData={userSelectedData}
                    selectedInd={selectedInd}
                    refinementCall2Record={refinementCall2Record}
                    allRenovationRecord={houseDetails}
                    customStars={customStars}
                    customPackSelected={customPackSelected}
                    setRenovationModalContent={setRenovationModalContent}
                    setUserStatus={setUserStatus}
                    selectedUserCombination={selectedUserCombination}
                    setCustom={setCustom}
                    selectedPack={selectedPack}
                    isAdminView={isAdminView}
                  />
                </div>
              </div>
              {/* loan */}
              {/* <SectionRenovationLoan
                totalValue={totalValue}
                getResultContent={getResultContent}
              /> */}
              {/* result description */}
              {/* <SectionResultDescription
                // itemPerRow={3}
                getResultContent={getResultContent}
              /> */}
            </div>
            {/* SIDEBAR */}
            <div className="pt-8">
              <YourNextStep getResultContent={getResultContent} />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ListingStayDetailPage;
