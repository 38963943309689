import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
// import ButtonClose from "shared/ButtonClose/ButtonClose";
import {
  Slider,
  Checkbox,
  Radio,
  Button,
  Tooltip,
  Input,
  Space,
  Col,
  Select,
  InputNumber,
  Row,
} from "antd";
import {
  UpdateBuildingRefinementCall3ByService,
  UpdateBuildingRefinementCall2ByService,
  GetBuildingQuestResults,
  GetUserPacksData,
} from "../../services/ResultPage";
import { GetContentByService } from "../../services/homepage";
import { renovationPostProcessingForPackB } from "../../containers/result/constants";
import JSZip from "jszip";
import { saveAs } from "file-saver";
// import { updateRenovations } from "./constant";
import {
  ventillationValuesHandler,
  roofValuesHandler,
  heatingValuesToNumbersHandler,
  heatingNumbersToValuesHandler,
  wallValuesHandler,
  windowTypeHandler,
  // windowTypeValueHandler,
  mechanicalValuesHandler,
  updateRenovations,
} from "./constant";
import { Markup } from "interweave";
import { CloseOutlined } from "@ant-design/icons";

const RadioGroup = Radio.Group;
const CheckboxGroup = Checkbox.Group;

const Modal = (props: any) => {
  let {
    isOpenModal,
    closeModal,
    callTwo,
    userBuildingTemPId,
    setActiveModal,
    allRenovationRecord,
    setLoader,
    refinementCall2Record,
    setRefinementCall2Record,
    setHouseCallRecordUpdated,
    houseCallRecordUpdated,
    userData,
    // updateRenovations,
    setAllUserSelectedData,
    setEpcA,
    renovationsInvestmentPrice,
    defaultRenovations,
    setRenovations,
    userModalRenovations,
    houseDetails,
    setUserExistRenovations,
    openModal,
    setSelectedUserCombination,
    setSelectedPack,
    setFutureEpc,
    setCustomStars,
    setIsCallWaiting,
    setDownloadedRecord,
    getValue,
    getModalKeysHandler,
    setAllPacksCombinations,
    setRenovationLoader,
    renovationPackHandler,
    pythonLogicHandler,
    setGlazing,
    setAllCustomCombination
  } = props;
  // agc v2
  const windowAndDoorsv2 = (value: any) => {
    switch (value) {
      case "":
        return getValue && getValue(86)?.props?.content
          ? getValue(86).props.content
          : getValue(86);
      case "S":
        return getValue && getValue(87)?.props?.content
          ? getValue(87).props.content
          : getValue(87);
      case "ordinary_double":
        return (
          getValue &&
          `${
            getValue(88)?.props?.content
              ? getValue(88).props.content
              : getValue(88)
          } "<2000"`
        );
      case "D":
        return (
          getValue &&
          `${
            getValue(88)?.props?.content
              ? getValue(88).props.content
              : getValue(88)
          } "<2000"`
        );
      case "DR":
        return (
          getValue &&
          `${
            getValue(89)?.props?.content
              ? getValue(89).props.content
              : getValue(89)
          } ">2000"`
        );
      case "T":
        return (
          getValue &&
          `${
            getValue(90)?.props?.content
              ? getValue(90).props.content
              : getValue(90)
          }`
        );
      default:
        return (
          getValue &&
          `${
            getValue(88)?.props?.content
              ? getValue(88).props.content
              : getValue(88)
          } "<2000"`
        );
    }
  };
  // for agc v2
  const wallInsulationv2 = (construction_date: any) => {
    //    console.log("wallInsulationv2", construction_date);
    if (parseInt(construction_date) < 1960) {
      return "6";
    } else if (
      parseInt(construction_date) >= 1960 &&
      parseInt(construction_date) < 2006
    ) {
      return "7";
    } else if (parseInt(construction_date) >= 2006) {
      return "10";
    }
  };

  const roofInsulationv2 = (construction_date: any) => {
    //construction_date = 2009;
    if (parseInt(construction_date) < 1960) {
      return "6";
    } else if (
      parseInt(construction_date) >= 1960 &&
      parseInt(construction_date) < 1985
    ) {
      return "12";
    } else if (
      parseInt(construction_date) >= 1986 &&
      parseInt(construction_date) < 2006
    ) {
      return "18";
    } else if (parseInt(construction_date) >= 2006) {
      return "20";
    }
  };

  // const [sectionContent, setSectionContent]: any = useState([]);
  const [question2Option, setQuestion2Option]: any = useState("");
  const [question3Option, setQuestion3Option]: any = useState("");
  const [question3OptionA, setQuestion3OptionA]: any = useState("");
  const [question4Option, setQuestion4Option]: any = useState("");
  const [question4OptionA, setQuestion4OptionA]: any = useState("");

  // const buildingValues =
  const [buildingValues, setBuildingValues]: any = useState({
    window_type:
      // after refinement
      refinementCall2Record && Object.keys(refinementCall2Record).length > 0
        ? refinementCall2Record?.glazing
          ? // ? refinementCall2Record?.glazing === "hr_double_before_2000"
            refinementCall2Record?.glazing
          : ""
        : // default values
        allRenovationRecord && allRenovationRecord?.glazing !== null
        ? allRenovationRecord?.glazing
        : windowAndDoorsv2(allRenovationRecord?.glazing),
    //
    // window_sub_type:
    //   // after refinement
    //   refinementCall2Record &&
    //   refinementCall2Record.length !== 0 &&
    //   refinementCall2Record.glazing_sub_type !== null
    //     ? refinementCall2Record.glazing_sub_type
    //     : allRenovationRecord.glazing_sub_type !== null
    //     ? allRenovationRecord.glazing_sub_type
    //     : "",

    roof_insulation_key:
      // if user record exist in DB
      refinementCall2Record &&
      Object.keys(refinementCall2Record).length !== 0 &&
      refinementCall2Record?.roof_insulation_key !== ""
        ? refinementCall2Record?.roof_insulation_key
        : // : ""
        allRenovationRecord
        ? allRenovationRecord.roof_insulation !== "?" &&
          allRenovationRecord.roof_insulation === "Probable"
          ? "OUI"
          : allRenovationRecord?.roof_insulation &&
            allRenovationRecord.roof_insulation !== "?"
          ? allRenovationRecord?.roof_insulation
          : "NON"
        : "",
    //
    roof_insulation_value:
      // if user record exist in DB
      refinementCall2Record &&
      Object.keys(refinementCall2Record).length !== 0 &&
      refinementCall2Record?.roof_insulation_value !== ""
        ? refinementCall2Record?.roof_insulation_value
        : // : ""
        allRenovationRecord
        ? allRenovationRecord.roof_insulation !== "?" &&
          allRenovationRecord?.roof_insulation === "Probable"
          ? "6"
          : allRenovationRecord?.roof_insulation === "OUI" ||
            allRenovationRecord?.roof_insulation === "Yes, for the most part"
          ? roofInsulationv2(allRenovationRecord?.construction_date)
          : ""
        : "",
    // allRenovationRecord.roof_insulation === "NON"
    // ? roofInsulationv2(allRenovationRecord?.construction_date)
    //   : ""
    // : "",
    wall_insulation_key:
      // if user record exist in DB
      refinementCall2Record &&
      Object.keys(refinementCall2Record).length !== 0 &&
      refinementCall2Record?.wall_insulation_key !== ""
        ? refinementCall2Record?.wall_insulation_key
        : // : ""
        allRenovationRecord?.facade_insulation_exterior !== "?" &&
          (allRenovationRecord?.facade_insulation_exterior === "Probable" ||
            allRenovationRecord?.facade_insulation_exterior === "OUI" ||
            allRenovationRecord?.has_cavity === "OUI")
        ? "OUI"
        : "NON",
    wall_insulation_value:
      refinementCall2Record &&
      Object.keys(refinementCall2Record)?.length !== 0 &&
      refinementCall2Record?.wall_insulation_value !== ""
        ? refinementCall2Record?.wall_insulation_value
        : // : ""
        allRenovationRecord
        ? allRenovationRecord?.facade_insulation_exterior !== "?" &&
          allRenovationRecord?.facade_insulation_exterior === "Probable"
          ? "6"
          : allRenovationRecord?.facade_insulation_exterior === "OUI" ||
            allRenovationRecord?.has_cavity === "OUI"
          ? wallInsulationv2(allRenovationRecord?.construction_date)
          : ""
        : "",

    //

    floor_insulation_key:
      // if user record exist in DB
      refinementCall2Record &&
      Object.keys(refinementCall2Record).length !== 0 &&
      refinementCall2Record?.floor_insulation_key !== ""
        ? refinementCall2Record?.floor_insulation_key
        : // : ""
        allRenovationRecord
        ? allRenovationRecord?.floor_insulation_key
          ? allRenovationRecord?.floor_insulation_key
          : "NON"
        : "NON",
    floor_insulation_value:
      // if user record exist in DB
      refinementCall2Record &&
      Object.keys(refinementCall2Record).length !== 0 &&
      refinementCall2Record?.floor_insulation_value !== ""
        ? refinementCall2Record?.floor_insulation_value
        : // : ""
        allRenovationRecord
        ? allRenovationRecord.floor_insulation_value
          ? allRenovationRecord?.floor_insulation_value
          : ""
        : "",

    heating_type:
      refinementCall2Record &&
      Object.keys(refinementCall2Record).length !== 0 &&
      refinementCall2Record.heating_type !== undefined
        ? refinementCall2Record.heating_type
        : // default if user record not exits in DB
        allRenovationRecord &&
          allRenovationRecord.heating_type !== null &&
          allRenovationRecord.heating_type !== undefined
        ? allRenovationRecord.heating_type
        : "non-condesing2",
    // heating_sub_type:
    //   refinementCall2Record &&
    //   Object.keys(refinementCall2Record).length !== 0 &&
    //   refinementCall2Record?.heating_sub_type !== ""
    //     ? refinementCall2Record.heating_sub_type
    //     : allRenovationRecord &&
    //       allRenovationRecord.heating_sub_type !== null &&
    //       allRenovationRecord.heating_sub_type !== undefined
    //     ? allRenovationRecord.heating_sub_type
    //     : "non-condesing2",
    // mechanical_system_type:
    //   refinementCall2Record && refinementCall2Record.length !== 0
    //     ? refinementCall2Record.mechanical_system_type === ""
    //       ? "natural"
    //       : refinementCall2Record.mechanical_system_type
    //     : allRenovationRecord &&
    //       allRenovationRecord.mechanical_system_type !== null
    //     ? allRenovationRecord.mechanical_system_type === ""
    //       ? "natural"
    //       : allRenovationRecord.mechanical_system_type
    //     : "",
    // photovoltaics_attributes: "",
    // solar:
    //   refinementCall2Record &&
    //   refinementCall2Record?.solar?.number_panels !== undefined &&
    //   refinementCall2Record?.solar?.number_panels
    //     ? "YES"
    //     : "",
    ventilation_type:
      refinementCall2Record &&
      Object.keys(refinementCall2Record).length !== 0 &&
      refinementCall2Record?.ventilation_type !== ""
        ? refinementCall2Record.ventilation_type
        : allRenovationRecord?.ventilation_type !== null &&
          allRenovationRecord?.ventilation_type !== undefined
        ? allRenovationRecord?.ventilation_type
        : "1",
    drafts:
      refinementCall2Record &&
      Object.keys(refinementCall2Record).length !== 0 &&
      refinementCall2Record?.drafts !== ""
        ? refinementCall2Record.drafts
        : allRenovationRecord?.drafts !== null &&
          allRenovationRecord?.drafts !== undefined
        ? allRenovationRecord?.drafts
        : "3",
    humidity:
      refinementCall2Record &&
      Object.keys(refinementCall2Record).length !== 0 &&
      refinementCall2Record?.humidity !== ""
        ? refinementCall2Record.humidity
        : allRenovationRecord?.humidity !== null &&
          allRenovationRecord?.humidity !== undefined
        ? allRenovationRecord?.humidity
        : "1",

    // hot_water:
    //   refinementCall2Record &&
    //   Object.keys(refinementCall2Record).length !== 0 &&
    //   refinementCall2Record?.hot_water !== ""
    //     ? refinementCall2Record.hot_water
    //     : "",
    hot_water:
      refinementCall2Record &&
      Object.keys(refinementCall2Record).length !== 0 &&
      refinementCall2Record?.hot_water !== ""
        ? refinementCall2Record.hot_water
        : allRenovationRecord?.hot_water !== null &&
          allRenovationRecord?.hot_water !== undefined
        ? allRenovationRecord?.hot_water
        : "3",
    solar_panels: "1",
    number_panels:
      refinementCall2Record &&
      refinementCall2Record?.number_panels !== undefined
        ? refinementCall2Record?.number_panels
        : allRenovationRecord &&
          allRenovationRecord?.presents === "OUI" &&
          allRenovationRecord?.num_panels !== "" &&
          allRenovationRecord?.num_panels !== "?"
        ? allRenovationRecord?.num_panels
        : "",
    pv_panels:
      refinementCall2Record &&
      Object.keys(refinementCall2Record).length !== 0 &&
      refinementCall2Record?.pv_panels !== ""
        ? refinementCall2Record.pv_panels
        : allRenovationRecord
        ? allRenovationRecord?.presents === "OUI" &&
          allRenovationRecord?.num_panels !== "" &&
          allRenovationRecord?.num_panels !== "?"
          ? "OUI"
          : "NON"
        : "NON",

    // sub option new keys
    // roof_insulation_bonus: "",
    // wall_insulation_bonus: "",
    // wall_is_insulation: "",
    // insulation_floor_bonus: "",
    // floor_is_insulation: "",

    //
    // roof_unknown: "",
    // wall_unknown: "",
    // floor_unknown: "",
    building_id: allRenovationRecord?.id
      ? allRenovationRecord?.id
      : allRenovationRecord?.building_id,
  });

  // useEffect(() => {
  //   let cityId: any = { id: 197 };
  //   GetContentByService(cityId)
  //     .then((response) => {
  //       setSectionContent(response?.data?.data?.page_contents);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  // Method to return value through index.
  // const getValue = (index: number): any => {
  //   return sectionContent !== undefined &&
  //     sectionContent !== null &&
  //     sectionContent?.length !== 0 &&
  //     sectionContent[index]?.value ? (
  //     <Markup content={sectionContent[index]?.value} />
  //   ) : (
  //     ""
  //   );
  // };

  let userId = localStorage.getItem("user_temporary_id");
  const submitRenovationData = () => {
    setIsCallWaiting(true);
    setRenovationLoader(true);
    let refinementForm: any = {
      building_id: buildingValues?.building_id,
      userId: userId,
      refinement_form: {
        glazing: buildingValues?.window_type,
        roof_insulation_key: buildingValues?.roof_insulation_key,
        roof_insulation_value: buildingValues?.roof_insulation_value,
        wall_insulation_key: buildingValues?.wall_insulation_key,
        wall_insulation_value: buildingValues?.wall_insulation_value,
        floor_insulation_key: buildingValues?.floor_insulation_key,
        floor_insulation_value: buildingValues?.floor_insulation_value,
        ventilation_type: buildingValues?.ventilation_type,
        drafts: buildingValues?.drafts,
        humidity: buildingValues?.humidity,
        hot_water: buildingValues?.hot_water,
        solar_panels: buildingValues?.solar_panels,
        pv_panels: buildingValues?.pv_panels,
        number_panels: buildingValues?.number_panels,
        heating_type: buildingValues?.heating_type,
      },
    };
    setGlazing(buildingValues?.window_type);
    // refinementForm.userId = localStorage.getItem("user_temporary_id");

    // console.log("refinementForm", refinementForm);

    UpdateBuildingRefinementCall2ByService(refinementForm)
      .then((response: any) => {
        setIsCallWaiting(false);
        // setActiveModal("call2&3");
        // to update the state to get current data from response and to show inside form  using this state condition
        let duplicate = { ...houseCallRecordUpdated };
        duplicate.rightSide = "updated";
        setHouseCallRecordUpdated(duplicate);
        setRefinementCall2Record(response?.data?.data?.renovation_refinements);
        //
        // userRenovations
        // let data = {
        //   response: {
        //     data: [response.data.data],
        //   },
        // };

        // params 3 =>
        // 1 = to get keys on basis for updated options ,
        // 2 = left model object should selected from old state ,
        // 3 = to send updated object
        // console.log("payloadforquest-1", refinementForm?.refinement_form);
        let payloadforquest: any = {
          building_id: buildingValues?.building_id,
          userId: userId,
          ...getModalKeysHandler(
            "",
            {},
            {
              ...refinementForm?.refinement_form,
              glazing: refinementForm?.refinement_form?.glazing,
            }
          ),
        };
        // console.log("payloadforquest-3", payloadforquest);
        // to get packs data from api
        GetUserPacksData(payloadforquest)
          .then((response_packs: any) => {
            setAllCustomCombination(response_packs?.data)
            let pythonData = pythonLogicHandler(
              response_packs?.data,
              refinementForm?.refinement_form
            );
            console.log("response_packs?.data-3",response_packs?.data)
            // console.log("response_packs-pythonData",response_packs?.data,pythonData)
            if (
              refinementForm?.refinement_form &&
              Object.keys(refinementForm?.refinement_form).length > 0 &&
              (parseInt(refinementForm?.refinement_form?.ventilation_type) ===
                3 ||
                parseInt(refinementForm?.refinement_form?.ventilation_type) ===
                  4)
            ) {
              const filteredData = pythonData?.filter((item: any) => {
                return (
                  !item?.combo.includes("ventc+") &&
                  !item?.combo.includes("ventd")
                );
              });
              console.log(
                "renovationRefinements-1234",
                filteredData,
                response_packs?.data
              );
              setAllPacksCombinations(filteredData);
              setRenovationLoader(false);
              renovationPackHandler("b", filteredData);
            } else {
              setAllPacksCombinations(pythonData);
              setRenovationLoader(false);
              renovationPackHandler("b", pythonData);
            }
          })
          .catch((error) => {
            setRenovationLoader(false);
            console.log("error", error);
          });
      })
      .catch((err: any) => {
        setIsCallWaiting(false);
        setRenovationLoader(false);
      });
    closeModal();
  };

  // console.log("buildingValues-123", buildingValues);

  const [inputValue, setInputValue] = useState(1);

  const onChange = (newValue: number) => {
    setInputValue(newValue);
  };

  const onSearch = (value: string) => {
    //console.log("search:", value);
  };

  const [doubleWindowActive, setDoubleWindowActive] = useState(false);
  const [question8, setQuestion8] = useState("");
  // console.log("buildingValues?.number_panels", allRenovationRecord);
  return (
    <div>
      <Transition appear show={isOpenModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto result_common_model_container"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 w-full refinement_house_modal_one_container">
                <div className="inline-flex flex-col w-full lg:w-1/2 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex border-b border-[#d8d6d6] justify-center bg-whte flex-shrink-0 px-6 py-4  text-center">
                    <h3
                      className="text-[22px] font-bold  text-[#007DDF] "
                      id="headlessui-dialog-title-70"
                    >
                      {getValue(83)}
                    </h3>
                  </div>
                  <div className="px-8 py-3 w-full">
                    <span className="block  text-xs  text-[#455259] dark:text-neutral-400">
                      {getValue(84)}
                    </span>
                  </div>
                  {/* select-option */}
                  <div>
                    <div className="bg-[#F0F0F0] px-8 py-2">
                      <p className="text-xs text-[#455259] font-bold">
                        {getValue(85)}
                      </p>
                    </div>
                    <div className="px-8 py-6 flex flex-col">
                      <div className="w-full lg:w-[70%] pb-2">
                        <Select
                          className="w-full "
                          placeholder=""
                          value={
                            buildingValues?.window_type === "double" ||
                            buildingValues?.window_type === "ordinary_double" ||
                            buildingValues?.window_type ===
                              "hr_double_after_2000" ||
                            buildingValues?.window_type === "D"
                              ? "D"
                              : buildingValues?.window_type
                          }
                          onChange={(e: any) => {
                            console.log("e.target.value", e);
                            if (e === "double") {
                              setDoubleWindowActive(true);
                              let duplicate = { ...buildingValues };
                              duplicate.window_type = "D";
                              setBuildingValues(duplicate);
                            } else {
                              setDoubleWindowActive(false);
                              let duplicate = { ...buildingValues };
                              duplicate.window_type = e;
                              // if double is disabled then sub option should be empty
                              duplicate.window_sub_type = "";
                              setBuildingValues(duplicate);
                            }
                          }}
                          options={[
                            {
                              value: "S",
                              label: getValue(87),
                            },
                            // {
                            //   value: "D",
                            //   label: getValue(86),
                            // },
                            {
                              value: "D",
                              label: getValue(88),
                            },
                            {
                              value: "DR",
                              label: getValue(89),
                            },
                            {
                              value: "T",
                              label: getValue(90),
                            },
                          ]}
                        />
                      </div>
                      {/* {(buildingValues?.window_type === "D" ||
                        buildingValues?.window_type === "DR") && (
                        <div className=" w-full lg:w-[70%]">
                          <Select
                            className="w-full "
                            placeholder="Select Option"
                            onChange={(e: any) => {
                              let duplicate = { ...buildingValues };
                              duplicate.window_sub_type = e;
                              setBuildingValues(duplicate);
                            }}
                            value={
                              buildingValues?.window_type === "D" &&
                              buildingValues?.window_sub_type === undefined
                                ? "D"
                                : buildingValues?.window_sub_type
                            }
                            options={[
                              {
                                value: "D",
                                label: getValue(88),
                              },
                              {
                                value: "DR",
                                label: getValue(89),
                              },
                            ]}
                          />
                        </div>
                      )} */}
                    </div>
                  </div>
                  {/* slider-radio-button-input */}
                  <div className="slider-radio-button-right">
                    <div className="bg-[#F0F0F0] px-8 py-2">
                      <p className="text-xs text-[#455259] font-bold">
                        {getValue(320)}
                      </p>
                    </div>
                    {/* slider-radio-input */}
                    <div className="px-8 py-6 flex flex-col slider-input">
                      <div className=" w-full ">
                        <Select
                          className="w-full "
                          showSearch
                          placeholder=""
                          onChange={(e) => {
                            let duplicate = { ...buildingValues };
                            duplicate.roof_insulation_key = e;
                            if (
                              e === "NON" ||
                              e === "Yes, but only for a small part" ||
                              e === "i don't know"
                            ) {
                              duplicate.roof_insulation_bonus = "";
                              duplicate.roof_unknown = "";
                              duplicate.roof_insulation_value = "";
                            } else {
                              //console.log()
                              duplicate.roof_insulation_value = 3;
                            }
                            setBuildingValues(duplicate);
                            setQuestion2Option(e);
                          }}
                          value={
                            buildingValues?.roof_insulation_key === "" &&
                            buildingValues?.roof_insulation_value &&
                            parseInt(buildingValues?.roof_insulation_value) >= 3
                              ? "OUI"
                              : buildingValues?.roof_insulation_key === "" &&
                                buildingValues?.roof_insulation_value &&
                                parseInt(
                                  buildingValues?.roof_insulation_value
                                ) < 3
                              ? "NON"
                              : buildingValues?.roof_insulation_key
                          }
                          options={[
                            {
                              value: "OUI",
                              label: getValue(321),
                            },
                            {
                              value: "NON",
                              label: getValue(322),
                            },
                            {
                              value: "Yes, for the most part",
                              label: getValue(405),
                            },
                            {
                              value: "Yes, but only for a small part",
                              label: getValue(406),
                            },
                            // {
                            //   value: "i don't know",
                            //   label: getValue(399),
                            // },
                          ]}
                        />
                      </div>
                      {(question2Option === "OUI" ||
                        question2Option === "toitures/plafonds" ||
                        question2Option === "toitures/plafonds" ||
                        question2Option === "Yes, for the most part" ||
                        question2Option === "i don't know (sub-option)" ||
                        buildingValues?.roof_insulation_key ===
                          "Yes, for the most part" ||
                        buildingValues?.roof_insulation_key === "OUI" ||
                        // buildingValues?.roof_insulation_key !== "" ||
                        (buildingValues?.roof_insulation_value &&
                          parseInt(buildingValues?.roof_insulation_value) >=
                            3)) && (
                        <>
                          <div>
                            <div className="pl-1">
                              <p className="text-[#455259] text-xs font-bold pt-4">
                                {getValue(324)}
                              </p>
                              <div className="slider-input-right slider-marks-container">
                                <Row className="w-full flex flex-col justify-between items-center">
                                  <div className="w-full px-2 pt-2 flex justify-center input-value-container">
                                    <InputNumber
                                      className="slider-input-right-text !text-center "
                                      disabled={true}
                                      value={
                                        (buildingValues?.roof_insulation_key !==
                                          null &&
                                          buildingValues?.roof_insulation_key !==
                                            undefined &&
                                          buildingValues?.roof_insulation_value >=
                                            3 &&
                                          buildingValues?.roof_insulation_key ===
                                            "OUI") ||
                                        (buildingValues?.roof_insulation_key !==
                                          null &&
                                          buildingValues?.roof_insulation_key !==
                                            undefined &&
                                          buildingValues?.roof_insulation_value >=
                                            3 &&
                                          buildingValues?.roof_insulation_key ===
                                            "Yes, for the most part")
                                          ? buildingValues?.roof_insulation_value
                                          : 3
                                      }
                                    />
                                  </div>
                                  <div className="w-full">
                                    <Slider
                                      min={3}
                                      max={20}
                                      marks={{
                                        3: "3",
                                        20: "20",
                                      }}
                                      onChange={(e: any) => {
                                        let duplicate = { ...buildingValues };
                                        duplicate.roof_insulation_value = e;
                                        setBuildingValues(duplicate);
                                      }}
                                      value={
                                        buildingValues?.roof_insulation_value !==
                                          null &&
                                        buildingValues?.roof_insulation_value !==
                                          "" &&
                                        buildingValues?.roof_insulation_value !==
                                          undefined
                                          ? buildingValues?.roof_insulation_value
                                          : 3
                                      }
                                      className="w-full"
                                      disabled={
                                        (question2Option ===
                                          "toitures/plafonds" ||
                                          question2Option ===
                                            "i don't know (sub-option)" ||
                                          (buildingValues?.roof_unknown &&
                                            parseInt(
                                              buildingValues?.roof_unknown
                                            ) === 2)) &&
                                        true
                                      }
                                    />
                                  </div>
                                </Row>
                              </div>
                            </div>
                            {/*
                            <Checkbox
                              className="text-sm text-[#455259] check-box-radius"
                              onChange={(e) => {
                                if (e.target.checked === true) {
                                  let duplicate = { ...buildingValues };
                                  duplicate.roof_insulation_value = "OUI";
                                  // i don't know key for default option
                                  duplicate.roof_unknown = 2;
                                  setBuildingValues(duplicate);
                                  setQuestion2Option(
                                    "i don't know (sub-option)"
                                  );
                                } else {
                                  let duplicate = { ...buildingValues };
                                  // i don't know key for default option
                                  duplicate.roof_unknown = "";
                                  duplicate.roof_insulation_bonus = "";
                                  setBuildingValues(duplicate);
                                  setQuestion2Option("OUI");
                                }
                              }}
                              checked={
                                question2Option ===
                                  "i don't know (sub-option)" ||
                                (buildingValues?.roof_unknown &&
                                  parseInt(buildingValues?.roof_unknown) === 2)
                                  ? true
                                  : false
                              }
                            >
                              {getValue(325)}
                            </Checkbox>
                            {(question2Option === "i don't know (sub-option)" ||
                              (buildingValues?.roof_unknown &&
                                parseInt(buildingValues?.roof_unknown) ===
                                  2)) && (
                              <div>
                                <div className="pl-1">
                                  <p className="text-[#455259] text-xs font-bold pt-4">
                                    {getValue(375)}
                                  </p>
                                </div>
                                <div className="mt-2">
                                  <Checkbox
                                    className="text-sm text-[#455259] check-box-radius"
                                    onChange={(e: any) => {
                                      if (e.target.checked === true) {
                                        let duplicate = { ...buildingValues };
                                        duplicate.roof_insulation_bonus = "1";
                                        setBuildingValues(duplicate);
                                      } else {
                                        let duplicate = { ...buildingValues };
                                        duplicate.roof_insulation_bonus = "";
                                        setBuildingValues(duplicate);
                                      }
                                    }}
                                    checked={
                                      buildingValues?.roof_insulation_bonus ===
                                      "1"
                                        ? true
                                        : false
                                    }
                                  >
                                    {getValue(376)}
                                  </Checkbox>
                                </div>
                                <div className="mt-2">
                                  <Checkbox
                                    className="text-sm text-[#455259] check-box-radius"
                                    onChange={(e: any) => {
                                      if (e.target.checked === true) {
                                        let duplicate = { ...buildingValues };
                                        duplicate.roof_insulation_bonus = "0";
                                        setBuildingValues(duplicate);
                                      } else {
                                        let duplicate = { ...buildingValues };
                                        duplicate.roof_insulation_bonus = "";
                                        setBuildingValues(duplicate);
                                      }
                                    }}
                                    checked={
                                      buildingValues?.roof_insulation_bonus ===
                                      "0"
                                        ? true
                                        : false
                                    }
                                  >
                                    {getValue(377)}
                                  </Checkbox>
                                </div>
                              </div>
                            )}
                            */}
                          </div>
                          {/* )} */}
                        </>
                      )}
                    </div>
                  </div>
                  {/* slider-radio-button-input */}
                  <div className="slider-radio-button-right">
                    <div className="bg-[#F0F0F0] px-8 py-2">
                      <p className="text-xs text-[#455259] font-bold">
                        {getValue(326)}
                      </p>
                    </div>
                    {/* slider-radio-input */}
                    <div className="px-8 py-6 flex flex-col slider-input">
                      <div className=" w-full ">
                        <Select
                          className="w-full "
                          showSearch
                          placeholder=""
                          onChange={(e) => {
                            let duplicate = {
                              ...buildingValues,
                            };
                            duplicate.wall_insulation_key = e;
                            if (
                              e === "NON" ||
                              e === "Yes, but only for a small part" ||
                              e === "i don't know"
                            ) {
                              duplicate.wall_insulation_bonus = "";
                              duplicate.wall_is_insulation = "";
                              duplicate.wall_insulation_value = "";
                              duplicate.wall_unknown = "";
                            } else {
                              duplicate.wall_insulation_value = 3;
                            }
                            setBuildingValues(duplicate);
                            setQuestion3Option(e);
                          }}
                          value={
                            buildingValues?.wall_insulation_key === "" &&
                            buildingValues?.wall_insulation_value &&
                            parseInt(buildingValues?.wall_insulation_value) >= 3
                              ? "OUI"
                              : buildingValues?.wall_insulation_key === "" &&
                                buildingValues?.wall_insulation_value &&
                                parseInt(
                                  buildingValues?.wall_insulation_value
                                ) < 3
                              ? "NON"
                              : buildingValues?.wall_insulation_key
                            // buildingValues?.wall_insulation_key
                          }
                          options={[
                            {
                              value: "OUI",
                              label: getValue(327),
                            },
                            {
                              value: "NON",
                              label: getValue(328),
                            },
                            {
                              value: "Yes, for the most part",
                              label: getValue(407),
                            },
                            {
                              value: "Yes, but only for a small part",
                              label: getValue(408),
                            },
                            // {
                            //   value: "i don't know",
                            //   label: getValue(400),
                            // },
                          ]}
                        />
                      </div>
                      {question3Option === "OUI" ||
                      question3Option === "toitures/plafonds" ||
                      question3Option === "Yes, for the most part" ||
                      question3Option === "i don't know (sub-option)" ||
                      buildingValues?.wall_insulation_key ===
                        "Yes, for the most part" ||
                      buildingValues?.wall_insulation_key === "OUI" ||
                      (buildingValues?.wall_insulation_value &&
                        parseInt(buildingValues?.wall_insulation_value)) >=
                        3 ? (
                        <>
                          <div>
                            <div className="pl-1">
                              <p className="text-[#455259] text-xs font-bold pt-4">
                                {getValue(330)}
                              </p>
                              <div className="slider-input-right slider-marks-container">
                                <Row className="w-full flex flex-col justify-between items-center">
                                  <div className="w-full px-2 pt-2 flex justify-center input-value-container">
                                    <InputNumber
                                      className="slider-input-right-text !text-center "
                                      value={
                                        (buildingValues?.wall_insulation_key !==
                                          null &&
                                          buildingValues?.wall_insulation_key !==
                                            undefined &&
                                          buildingValues?.wall_insulation_value >=
                                            3 &&
                                          buildingValues?.wall_insulation_key ===
                                            "OUI") ||
                                        (buildingValues?.wall_insulation_key !==
                                          null &&
                                          buildingValues?.wall_insulation_key !==
                                            undefined &&
                                          buildingValues?.wall_insulation_value >=
                                            3 &&
                                          buildingValues?.wall_insulation_key ===
                                            "Yes, for the most part")
                                          ? buildingValues?.wall_insulation_value
                                          : 3
                                      }
                                      disabled={true}
                                    />
                                  </div>
                                  <div className="w-full">
                                    <Slider
                                      min={3}
                                      max={10}
                                      marks={{
                                        3: "3",
                                        10: "10",
                                      }}
                                      onChange={(e: any) => {
                                        let duplicate = {
                                          ...buildingValues,
                                        };
                                        duplicate.wall_insulation_value = e;
                                        setBuildingValues(duplicate);
                                      }}
                                      value={
                                        buildingValues?.wall_insulation_value !==
                                          null &&
                                        buildingValues?.wall_insulation_value !==
                                          "" &&
                                        buildingValues?.wall_insulation_value !==
                                          undefined
                                          ? buildingValues?.wall_insulation_value
                                          : 3
                                      }
                                      className="w-full"
                                      disabled={
                                        (question3Option ===
                                          "toitures/plafonds" ||
                                          question3Option ===
                                            "i don't know (sub-option)" ||
                                          (buildingValues?.wall_unknown &&
                                            parseInt(
                                              buildingValues?.wall_unknown
                                            ) === 2)) &&
                                        true
                                      }
                                      defaultValue={
                                        buildingValues?.wall_insulation_value
                                      }
                                    />
                                  </div>
                                </Row>
                              </div>
                            </div>
                            {/*
                            <Checkbox
                              className="textsm text-[#455259] check-box-radius"
                              onChange={(e) => {
                                if (e.target.checked === true) {
                                  let duplicate = { ...buildingValues };
                                  duplicate.wall_insulation_value = "OUI";
                                  // i don't know key for default option
                                  duplicate.wall_unknown = 2;
                                  setBuildingValues(duplicate);
                                  setQuestion3Option(
                                    "i don't know (sub-option)"
                                  );
                                  setQuestion3OptionA("");
                                } else {
                                  let duplicate = { ...buildingValues };
                                  // i don't know key for default option
                                  duplicate.wall_unknown = "";
                                  duplicate.wall_insulation_bonus = "";
                                  setBuildingValues(duplicate);
                                  setQuestion3Option("OUI");
                                }
                              }}
                              checked={
                                question3Option ===
                                  "i don't know (sub-option)" ||
                                (buildingValues?.wall_unknown &&
                                  parseInt(buildingValues?.wall_unknown) === 2)
                                  ? true
                                  : false
                              }
                            >
                              {getValue(331)}
                            </Checkbox>

                            {(question3Option === "i don't know (sub-option)" ||
                              (buildingValues?.wall_unknown &&
                                parseInt(buildingValues?.wall_unknown) ===
                                  2)) && (
                              <div>
                                <div className="pl-1">
                                  <p className="text-[#455259] text-xs font-bold pt-4">
                                    {getValue(378)}
                                  </p>
                                </div>
                                <div className="mt-2">
                                  <Checkbox
                                    className="text-sm text-[#455259] check-box-radius"
                                    onChange={(e: any) => {
                                      if (e.target.checked === true) {
                                        let duplicate = { ...buildingValues };
                                        duplicate.wall_insulation_bonus = "1";
                                        setBuildingValues(duplicate);
                                        setQuestion3OptionA("option1");
                                      } else {
                                        let duplicate = { ...buildingValues };
                                        duplicate.wall_insulation_bonus = "";
                                        duplicate.wall_is_insulation = "";
                                        setBuildingValues(duplicate);
                                        setQuestion3OptionA("");
                                      }
                                    }}
                                    checked={
                                      buildingValues?.wall_insulation_bonus ===
                                      "1"
                                        ? true
                                        : false
                                    }
                                  >
                                    {getValue(379)}
                                  </Checkbox>
                                </div>
                                <div className="mt-2">
                                  <Checkbox
                                    className="text-sm text-[#455259] check-box-radius"
                                    onChange={(e: any) => {
                                      if (e.target.checked === true) {
                                        let duplicate = { ...buildingValues };
                                        duplicate.wall_insulation_bonus = "0";
                                        duplicate.wall_is_insulation = "";
                                        setBuildingValues(duplicate);
                                        setQuestion3OptionA("option2");
                                      } else {
                                        let duplicate = { ...buildingValues };
                                        duplicate.wall_insulation_bonus = "";
                                        setBuildingValues(duplicate);
                                        setQuestion3OptionA("");
                                      }
                                    }}
                                    checked={
                                      buildingValues?.wall_insulation_bonus ===
                                      "0"
                                        ? true
                                        : false
                                    }
                                  >
                                    {getValue(380)}
                                  </Checkbox>
                                </div>
                                <div className="mt-2">
                                  <Checkbox
                                    className="text-sm text-[#455259] check-box-radius"
                                    onChange={(e: any) => {
                                      if (e.target.checked === true) {
                                        let duplicate = { ...buildingValues };
                                        duplicate.wall_insulation_bonus = "2";
                                        duplicate.wall_is_insulation = "";
                                        setBuildingValues(duplicate);
                                        setQuestion3OptionA("option3");
                                      } else {
                                        let duplicate = { ...buildingValues };
                                        duplicate.wall_insulation_bonus = "";
                                        setBuildingValues(duplicate);
                                        setQuestion3OptionA("");
                                      }
                                    }}
                                    checked={
                                      buildingValues?.wall_insulation_bonus ===
                                      "2"
                                        ? true
                                        : false
                                    }
                                  >
                                    {getValue(381)}
                                  </Checkbox>
                                </div>
                                {(question3OptionA === "option1" ||
                                  (buildingValues?.wall_insulation_bonus &&
                                    parseInt(
                                      buildingValues?.wall_insulation_bonus
                                    ) === 1)) && (
                                  <div>
                                    <div className="pl-1">
                                      <p className="text-[#455259] text-xs font-bold pt-4">
                                        {getValue(382)}
                                      </p>
                                    </div>
                                    <div className="mt-2">
                                      <Checkbox
                                        className="text-sm text-[#455259] check-box-radius"
                                        onChange={(e: any) => {
                                          if (e.target.checked === true) {
                                            let duplicate = {
                                              ...buildingValues,
                                            };
                                            duplicate.wall_is_insulation = "1";
                                            setBuildingValues(duplicate);
                                          } else {
                                            let duplicate = {
                                              ...buildingValues,
                                            };
                                            duplicate.wall_is_insulation = "";
                                            setBuildingValues(duplicate);
                                          }
                                        }}
                                        checked={
                                          buildingValues?.wall_is_insulation ===
                                          "1"
                                            ? true
                                            : false
                                        }
                                      >
                                        {getValue(383)}
                                      </Checkbox>
                                    </div>
                                    <div className="mt-2">
                                      <Checkbox
                                        className="text-sm text-[#455259] check-box-radius"
                                        onChange={(e: any) => {
                                          if (e.target.checked === true) {
                                            let duplicate = {
                                              ...buildingValues,
                                            };
                                            duplicate.wall_is_insulation = "2";
                                            setBuildingValues(duplicate);
                                          } else {
                                            let duplicate = {
                                              ...buildingValues,
                                            };
                                            duplicate.wall_is_insulation = "";
                                            setBuildingValues(duplicate);
                                          }
                                        }}
                                        checked={
                                          buildingValues?.wall_is_insulation ===
                                          "2"
                                            ? true
                                            : false
                                        }
                                      >
                                        {getValue(384)}
                                      </Checkbox>
                                    </div>
                                    <div className="mt-2">
                                      <Checkbox
                                        className="text-sm text-[#455259] check-box-radius"
                                        onChange={(e: any) => {
                                          if (e.target.checked === true) {
                                            let duplicate = {
                                              ...buildingValues,
                                            };
                                            duplicate.wall_is_insulation = "3";
                                            setBuildingValues(duplicate);
                                          } else {
                                            let duplicate = {
                                              ...buildingValues,
                                            };
                                            duplicate.wall_is_insulation = "";
                                            setBuildingValues(duplicate);
                                          }
                                        }}
                                        checked={
                                          buildingValues?.wall_is_insulation ===
                                          "3"
                                            ? true
                                            : false
                                        }
                                      >
                                        {getValue(385)}
                                      </Checkbox>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                            */}
                          </div>
                          {/* )} */}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* slider-radio-button-input */}
                  <div className="slider-radio-button-right">
                    {/* <div className="bg-[#F0F0F0] px-8 py-2">
                      <p className="text-xs text-[#455259] font-bold">
                        {getValue(332)}
                      </p>
                    </div> */}
                    {/* slider-radio-input */}
                    {/* py-6  */}
                    <div className="px-8 flex flex-col slider-input">
                      {/* <div className=" w-full ">
                        <Select
                          className="w-full "
                          showSearch
                          placeholder=""
                          onChange={(e) => {
                            let duplicate = {
                              ...buildingValues,
                            };
                            duplicate.floor_insulation_key = e;
                            if (
                              e === "NON" ||
                              e === "Yes, but only for a small part" ||
                              e === "i don't know"
                            ) {
                              duplicate.insulation_floor_bonus = "";
                              duplicate.floor_is_insulation = "";
                              duplicate.floor_insulation_value = "";
                              duplicate.floor_unknown = "";
                            } else {
                              duplicate.floor_insulation_value = 3;
                            }
                            setBuildingValues(duplicate);
                            setQuestion4Option(e);
                          }}
                          value={
                            buildingValues?.floor_insulation_key === "" &&
                            buildingValues?.floor_insulation_value &&
                            parseInt(buildingValues?.floor_insulation_value) >=
                              3
                              ? "OUI"
                              : buildingValues?.floor_insulation_key === "" &&
                                buildingValues?.floor_insulation_value &&
                                parseInt(
                                  buildingValues?.floor_insulation_value
                                ) < 3
                              ? "NON"
                              : buildingValues?.floor_insulation_key
                          }
                          options={[
                            {
                              value: "OUI",
                              label: getValue(333),
                            },
                            {
                              value: "NON",
                              label: getValue(334),
                            },
                            {
                              value: "Yes, for the most part",
                              label: getValue(409),
                            },
                            {
                              value: "Yes, but only for a small part",
                              label: getValue(410),
                            },
                          ]}
                        />
                      </div> */}
                      {/* {question4Option === "OUI" ||
                      question4Option === "toitures/plafonds" ||
                      question4Option === "i don't know (sub-option)" ||
                      buildingValues?.floor_insulation_key === "OUI" ||
                      buildingValues?.floor_insulation_key ===
                        "Yes, for the most part" ||
                      (buildingValues?.floor_insulation_value &&
                        parseInt(buildingValues?.floor_insulation_value) >=
                          3) ? (
                        <>
                          <div>
                            <div className="pl-1">
                              <p className="text-[#455259] text-xs font-bold pt-4">
                                {getValue(336)}
                              </p>
                              <div className="slider-input-right slider-marks-container">
                                <Row className="w-full flex flex-col justify-between items-center">
                                  <div className="w-full px-2 pt-2 flex justify-center input-value-container">
                                    <InputNumber
                                      className="slider-input-right-text !text-center "
                                      value={
                                        (buildingValues?.floor_insulation_key !==
                                          null &&
                                          buildingValues?.floor_insulation_key !==
                                            undefined &&
                                          buildingValues?.floor_insulation_value >=
                                            3 &&
                                          buildingValues?.floor_insulation_key ===
                                            "OUI") ||
                                        (buildingValues?.floor_insulation_key !==
                                          null &&
                                          buildingValues?.floor_insulation_key !==
                                            undefined &&
                                          buildingValues?.floor_insulation_value >=
                                            3 &&
                                          buildingValues?.floor_insulation_key ===
                                            "Yes, for the most part")
                                          ? buildingValues?.floor_insulation_value
                                          : 3
                                      }
                                      disabled={true}
                                    />
                                  </div>
                                  <div className="w-full">
                                    <Slider
                                      min={3}
                                      max={10}
                                      marks={{
                                        3: "3",
                                        10: "10",
                                      }}
                                      className="w-full"
                                      disabled={
                                        (question4Option ===
                                          "toitures/plafonds" ||
                                          question4Option ===
                                            "i don't know (sub-option)" ||
                                          (buildingValues?.floor_unknown &&
                                            parseInt(
                                              buildingValues?.floor_unknown
                                            ) === 2)) &&
                                        true
                                      }
                                      onChange={(e: any) => {
                                        let duplicate = { ...buildingValues };
                                        duplicate.floor_insulation_value = e;
                                        setBuildingValues(duplicate);
                                      }}
                                      value={
                                        buildingValues?.floor_insulation_value !==
                                          null &&
                                        buildingValues?.floor_insulation_value !==
                                          undefined &&
                                        buildingValues?.floor_insulation_value !==
                                          "OUI" &&
                                        buildingValues?.floor_insulation_value !==
                                          "Yes, for the most part"
                                          ? buildingValues?.floor_insulation_value
                                          : 3
                                      }
                                    />
                                  </div>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )} */}
                    </div>
                  </div>
                  {/* radio-button */}
                  <div>
                    <div className="bg-[#F0F0F0] px-8 py-2">
                      <p className="text-xs text-[#455259] font-bold">
                        {getValue(121)}
                      </p>
                    </div>
                    <RadioGroup
                      value={buildingValues?.ventilation_type}
                      onChange={(e: any) => {
                        let duplicate = { ...buildingValues };
                        duplicate.ventilation_type = e.target.value;
                        setBuildingValues(duplicate);
                      }}
                      defaultValue="1"
                    >
                      <div className="px-8 py-6 flex flex-col items-start radio-group-aligment">
                        <Radio className="text-sm text-[#455259] " value="1">
                          {getValue(123)}
                        </Radio>
                        <Radio
                          className="text-sm text-[#455259] pt-2 "
                          value="2"
                        >
                          {getValue(124)}
                        </Radio>
                        <Radio
                          className="text-sm text-[#455259] pt-2 "
                          value="3"
                        >
                          {getValue(125)}
                        </Radio>
                        <Radio
                          className="text-sm text-[#455259] pt-2 flex "
                          value="4"
                        >
                          {getValue(126)}
                        </Radio>
                      </div>
                    </RadioGroup>
                  </div>
                  {/* radio-button */}
                  <div>
                    <div className="bg-[#F0F0F0] px-8 py-2">
                      <p className="text-xs text-[#455259] font-bold">
                        {getValue(338)}
                      </p>
                    </div>
                    <RadioGroup
                      onChange={(e) => {
                        let duplicate = { ...buildingValues };
                        duplicate.drafts = e.target.value;
                        setBuildingValues(duplicate);
                      }}
                      value={buildingValues?.drafts}
                      // defaultValue="1"
                    >
                      <div className="px-8 py-6 flex flex-col items-start radio-group-aligment">
                        <Radio className="textsm text-[#455259] " value="1">
                          {getValue(339)}
                        </Radio>
                        <Radio
                          className="textsm text-[#455259] pt-2 "
                          value="2"
                        >
                          {getValue(340)}
                        </Radio>
                        <Radio
                          className="textsm text-[#455259] pt-2 "
                          value="3"
                        >
                          {getValue(341)}
                        </Radio>
                        <Radio className="textsm text-[#455259] pt-2" value="4">
                          {getValue(342)}
                        </Radio>
                        <Radio className="textsm text-[#455259] pt-2" value="5">
                          {getValue(343)}
                        </Radio>
                      </div>
                    </RadioGroup>
                  </div>
                  {/* radio-button */}
                  <div>
                    <div className="bg-[#F0F0F0] px-8 py-2">
                      <p className="text-xs text-[#455259] font-bold">
                        {getValue(344)}
                      </p>
                    </div>
                    <RadioGroup
                      onChange={(e) => {
                        let duplicate = { ...buildingValues };
                        duplicate.humidity = e.target.value;
                        setBuildingValues(duplicate);
                      }}
                      value={buildingValues?.humidity}
                    >
                      <div className="px-8 py-6 flex flex-col items-start radio-group-aligment">
                        <Radio className="textsm text-[#455259] " value="1">
                          {getValue(345)}
                        </Radio>
                        <Radio
                          className="textsm text-[#455259] pt-2 "
                          value="2"
                        >
                          {getValue(346)}
                        </Radio>
                        <Radio
                          className="textsm text-[#455259] pt-2 "
                          value="3"
                        >
                          {getValue(347)}
                        </Radio>
                        <Radio className="textsm text-[#455259] pt-2" value="4">
                          {getValue(348)}
                        </Radio>
                        <Radio className="textsm text-[#455259] pt-2" value="5">
                          {getValue(349)}
                        </Radio>
                      </div>
                    </RadioGroup>
                  </div>
                  {/* select-option */}
                  <div>
                    <div className="bg-[#F0F0F0] px-8 py-2">
                      <p className="text-xs text-[#455259] font-bold">
                        {getValue(113)}
                      </p>
                    </div>
                    <div className="px-8 py-6 flex flex-col">
                      <div className="w-full pb-2">
                        <Select
                          className="w-full "
                          showSearch
                          placeholder=""
                          onChange={(e) => {
                            let duplicate = { ...buildingValues };
                            duplicate.heating_type = e;
                            setBuildingValues(duplicate);
                            setQuestion8(e);
                          }}
                          value={buildingValues?.heating_type}
                          options={[
                            // {
                            //   value: "electric-heating",
                            //   label: getValue(359),
                            // },
                            //  non-condesing sub type
                            // {
                            //   value: "non-condesing",
                            //   label: getValue(361),
                            // },
                            {
                              value: "non-condesing1",
                              label: getValue(369),
                            },
                            {
                              value: "non-condesing2",
                              label: getValue(370),
                            },
                            // condesing substype
                            // {
                            //   value: "condesing",
                            //   label: getValue(362),
                            // },
                            {
                              value: "condesing1",
                              label: getValue(371),
                            },
                            {
                              value: "condesing2",
                              label: getValue(372),
                            },
                            // heatpump
                            {
                              value: "heatpump",
                              label: getValue(363),
                            },
                          ]}
                        />
                        {/* <div className={"pt-3"}>
                          {(question8 === "stove" ||
                            buildingValues?.heating_type === "stove") && (
                            <Select
                              className="w-full"
                              showSearch
                              placeholder=""
                              onChange={(e) => {
                                let duplicate = { ...buildingValues };
                                duplicate.heating_sub_type = e;
                                setBuildingValues(duplicate);
                              }}
                              defaultValue={buildingValues?.heating_sub_type}
                              options={[
                                {
                                  value: "stove1",
                                  label: getValue(365),
                                },
                                {
                                  value: "stove2",
                                  label: getValue(366),
                                },
                                {
                                  value: "stove3",
                                  label: getValue(367),
                                },
                                {
                                  value: "stove4",
                                  label: getValue(368),
                                },
                              ]}
                            />
                          )}
                          {(question8 === "non-condesing" ||
                            buildingValues?.heating_type ===
                              "non-condesing") && (
                            <Select
                              className="w-full"
                              showSearch
                              placeholder=""
                              onChange={(e) => {
                                let duplicate = { ...buildingValues };
                                duplicate.heating_sub_type = e;
                                setBuildingValues(duplicate);
                              }}
                              defaultValue={buildingValues?.heating_sub_type}
                              options={[
                                {
                                  value: "non-condesing1",
                                  label: getValue(369),
                                },
                                {
                                  value: "non-condesing2",
                                  label: getValue(370),
                                },
                              ]}
                            />
                          )}
                          {(question8 === "condesing" ||
                            buildingValues?.heating_type === "condesing") && (
                            <Select
                              className="w-full"
                              showSearch
                              placeholder=""
                              onChange={(e) => {
                                let duplicate = { ...buildingValues };
                                duplicate.heating_sub_type = e;
                                setBuildingValues(duplicate);
                              }}
                              defaultValue={buildingValues?.heating_sub_type}
                              options={[
                                {
                                  value: "condesing1",
                                  label: getValue(371),
                                },
                                {
                                  value: "condesing2",
                                  label: getValue(372),
                                },
                              ]}
                            />
                          )}
                          {(question8 === "heatpump-central" ||
                            buildingValues?.heating_type ===
                              "heatpump-central") && (
                            <Select
                              className="w-full"
                              showSearch
                              placeholder=""
                              onChange={(e) => {
                                let duplicate = { ...buildingValues };
                                duplicate.heating_sub_type = e;
                                setBuildingValues(duplicate);
                              }}
                              defaultValue={buildingValues?.heating_sub_type}
                              options={[
                                {
                                  value: "heatpump1",
                                  label: getValue(373),
                                },
                                {
                                  value: "heatpump2",
                                  label: getValue(374),
                                },
                              ]}
                            />
                          )}
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* select-option */}
                  <div>
                    <div className="bg-[#F0F0F0] px-8 py-2">
                      <p className="text-xs text-[#455259] font-bold">
                        {getValue(350)}
                      </p>
                    </div>
                    <div className="px-8 py-6 flex flex-col">
                      <div className="w-full lg:w-[70%] pb-2">
                        <Select
                          className="w-full "
                          value={buildingValues?.hot_water}
                          onChange={(e: any) => {
                            let duplicate = { ...buildingValues };
                            duplicate.hot_water = e;
                            setBuildingValues(duplicate);
                          }}
                          placeholder=""
                          defaultValue="1"
                          options={[
                            {
                              value: "1",
                              label: getValue(351),
                            },
                            {
                              value: "2",
                              label: getValue(352),
                            },
                            {
                              value: "3",
                              label: getValue(353),
                            },
                            {
                              value: "4",
                              label: getValue(354),
                            },
                            {
                              value: "5",
                              label: getValue(355),
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="slider-radio-button-right">
                    <div className="bg-[#F0F0F0] px-8 py-2">
                      <p className="text-xs text-[#455259] font-bold">
                        {getValue(402)}
                      </p>
                    </div>
                    <div className="px-8 py-6 flex flex-col">
                      <RadioGroup
                        value={
                          buildingValues?.solar_panels
                            ? buildingValues?.solar_panels
                            : "NO"
                        }
                        onChange={(e: any) => {
                          let duplicate = { ...buildingValues };
                          duplicate.solar_panels = e.target.value;
                          setBuildingValues(duplicate);
                        }}
                      >
                        <div>
                          <Radio className="textsm text-[#455259] " value="NO">
                            {getValue(403)}
                          </Radio>
                          <Radio
                            className="textsm text-[#455259] py-2"
                            value="YES"
                          >
                            {getValue(404)}
                          </Radio>
                        </div>
                      </RadioGroup>
                    </div>
                  </div> */}

                  {/* slider-radio-button */}
                  <div className="slider-radio-button-right">
                    <div className="bg-[#F0F0F0] px-8 py-2">
                      <p className="text-xs text-[#455259] font-bold">
                        {getValue(127)}
                      </p>
                    </div>
                    <div className="px-8 py-6 flex flex-col">
                      <RadioGroup
                        value={
                          // buildingValues?.pv_panels !== "" ||
                          buildingValues?.pv_panels === "OUI" ||
                          buildingValues?.pv_panels === "1"
                            ? // ||
                              // parseInt(buildingValues?.number_panels) > 0
                              "OUI"
                            : "NON"
                        }
                        onChange={(e: any) => {
                          console.log("e-123", e);
                          let duplicate = { ...buildingValues };
                          duplicate.pv_panels = e.target.value;
                          if (e.target.value === "NON") {
                            duplicate.number_panels = 0;
                          } else {
                            duplicate.number_panels = 1;
                          }
                          setBuildingValues(duplicate);
                        }}
                      >
                        <div>
                          <Radio className="textsm text-[#455259] " value="NON">
                            {getValue(129)}
                          </Radio>
                          <Radio
                            className="textsm text-[#455259] py-2"
                            value="OUI"
                          >
                            {getValue(130)}
                          </Radio>
                        </div>
                      </RadioGroup>
                      {(buildingValues?.number_panels > 0 ||
                        buildingValues?.pv_panels === "OUI" ||
                        buildingValues?.pv_panels === "1") && (
                        <div>
                          <p className="text-[#455259] text-xs font-bold pt-4">
                            {getValue(131)}
                          </p>
                          <div className="slider-input-right slider-marks-container">
                            <Row className="w-full flex flex-col justify-between items-center">
                              <div className="w-full px-2 pt-2 flex justify-center input-value-container input-disabled">
                                <InputNumber
                                  className="slider-input-right-text !text-center "
                                  value={
                                    (buildingValues?.pv_panels === "OUI" ||
                                      buildingValues?.pv_panels === "1") &&
                                    parseInt(buildingValues?.number_panels) >
                                      1 &&
                                    buildingValues?.number_panels !== ""
                                      ? buildingValues?.number_panels
                                      : 1
                                  }
                                  disabled
                                />
                              </div>
                              <div className="w-full">
                                <Slider
                                  max={35}
                                  min={1}
                                  value={buildingValues?.number_panels}
                                  onChange={(e: any) => {
                                    let duplicate = { ...buildingValues };
                                    duplicate.number_panels =
                                      parseInt(e) < 0
                                        ? -1 * parseInt(e)
                                        : parseInt(e);
                                    setBuildingValues(duplicate);
                                  }}
                                  // marks={{
                                  //   1: getValue(356),
                                  //   100: getValue(357),
                                  // }}
                                  className="w-full"
                                />
                                <div className="flex justify-between w-100">
                                  <span className="text-[#000] opacity-[0.8]">
                                    {getValue(356)}
                                  </span>
                                  <span className="text-[#000] opacity-[0.8]">
                                    {getValue(357)}
                                  </span>
                                </div>
                              </div>
                            </Row>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="relative bg-white border border-[#F0F0F0] flex justify-between items-center py-2 px-8">
                    <h3
                      className="text-base m-0 text-[#989898] underline cursor-pointer"
                      id="headlessui-dialog-title-70"
                      onClick={closeModal}
                    >
                      {getValue(398)}
                    </h3>
                    <span className="bg-[#007DDF] rounded py-2.5 px-7">
                      <button
                        onClick={submitRenovationData}
                        className="text-white text-base "
                      >
                        {getValue(133)}
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
export default Modal;
