import React, { FC, useEffect, useState } from "react";
import Avatar from "../../shared/Avatar/Avatar";
import Heating from "../../assets/images/resultPage2/heating.svg";
import Roof from "../../assets/images/resultPage2/roof.svg";
import Solar from "../../assets/images/resultPage2/solar_panels.svg";
import Ventillation from "../../assets/images/resultPage2/ventilation.svg";
import Wall from "../../assets/images/resultPage2/wall.svg";
import Window from "../../assets/images/resultPage2/window.svg";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
import GreenDot from "../../assets/images/resultPage2/dots/green.svg";
import OrangeDot from "../../assets/images/resultPage2/dots/orange.svg";
import RedDot from "../../assets/images/resultPage2/dots/red.svg";
import EPC_A from "../../assets/images/resultPage2/epcScale/a2.png";
import Star from "../../assets/images/resultPage2/star.png";
import StarGrey from "../../assets/images/resultPage2/star_grey.svg";
// import EPC_APlus from 'images/resultPage/epcScale/a+.svg'
import { currentComfort } from "../../utils/renovations";
import EPC_B from "../../assets/images/resultPage2/epcScale/b.png";
import EPC_C from "../../assets/images/resultPage2/epcScale/c.png";
import EPC_D from "../../assets/images/resultPage2/epcScale/d.png";
import EPC_E from "../../assets/images/resultPage2/epcScale/e.png";
import EPC_F from "../../assets/images/resultPage2/epcScale/f.png";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import Info from "../../assets/images/home/info.png";
import MoreOptionModal from "../../shared/Modal/sectionDiagnoseOption";

const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

export interface SectionHeroProps {
  className?: string;
  getResultContent?: any;
  userSelectedData?: any;
  openModal?: any;
  allRenovationRecord?: any;
  userStatus?: any;
  loggedInUser?: any;
  refinementCall2Record?: any;
  isCallWaitng?: any;
  downloadRecord?: any;
  isAdminView?: any;
}

const SectionDiagnosis: FC<SectionHeroProps> = ({
  className,
  getResultContent,
  userSelectedData,
  openModal,
  allRenovationRecord,
  userStatus,
  loggedInUser,
  refinementCall2Record,
  isCallWaitng,
  downloadRecord,
  isAdminView,
}) => {
  const [visible, setVisible] = useState(false);
  const windowAndDoors = (value: any) => {
    switch (value) {
      case "":
        return getResultContent && getResultContent(86)?.props?.content
          ? getResultContent(86).props.content
          : getResultContent(86);
      case "single":
        return getResultContent && getResultContent(87)?.props?.content
          ? getResultContent(87).props.content
          : getResultContent(87);
      case "ordinary_double":
        return (
          getResultContent &&
          `${
            getResultContent(88)?.props?.content
              ? getResultContent(88).props.content
              : getResultContent(88)
          } "<2000"`
        );
      case "hr_double_before_2000":
        return (
          getResultContent &&
          `${
            getResultContent(88)?.props?.content
              ? getResultContent(88).props.content
              : getResultContent(88)
          } "<2000"`
        );
      case "hr_double_after_2000":
        return (
          getResultContent &&
          `${
            getResultContent(89)?.props?.content
              ? getResultContent(89).props.content
              : getResultContent(89)
          } ">2000"`
        );
      case "triple":
        return (
          getResultContent &&
          `${
            getResultContent(90)?.props?.content
              ? getResultContent(90).props.content
              : getResultContent(90)
          }`
        );
      default:
        return "";
    }
  };

  const wallInsulation = (value: any) => {
    switch (value) {
      case 0:
        return getResultContent && getResultContent(92)?.props?.content
          ? getResultContent(92).props.content
          : getResultContent(92);
      case 1:
        return getResultContent && getResultContent(93)?.props?.content
          ? getResultContent(93).props.content
          : getResultContent(93);
      case 2:
        return getResultContent && getResultContent(94)?.props?.content
          ? getResultContent(94).props.content
          : getResultContent(94);
      case 3:
        return getResultContent && getResultContent(95)?.props?.content
          ? getResultContent(95).props.content
          : getResultContent(95);
      case 4:
        return getResultContent && getResultContent(96)?.props?.content
          ? getResultContent(96).props.content
          : getResultContent(96);
      case 5:
        return getResultContent && getResultContent(97)?.props?.content
          ? getResultContent(97).props.content
          : getResultContent(97);
      case 6:
        return getResultContent && getResultContent(98)?.props?.content
          ? getResultContent(98).props.content
          : getResultContent(98);
      case 7:
        return getResultContent && getResultContent(99)?.props?.content
          ? getResultContent(99).props.content
          : getResultContent(99);
      case 8:
        return getResultContent && getResultContent(100)?.props?.content
          ? getResultContent(100).props.content
          : getResultContent(100);
      default:
        return "";
    }
  };

  const RoofInsulation = (value: any) => {
    switch (value) {
      case "":
        return getResultContent && getResultContent(102)?.props?.content
          ? getResultContent(102).props.content
          : getResultContent(102);
      case 1:
        return getResultContent && getResultContent(103)?.props?.content
          ? getResultContent(103).props.content
          : getResultContent(103);
      case 2:
        return getResultContent && getResultContent(104)?.props?.content
          ? getResultContent(104).props.content
          : getResultContent(104);
      case 3:
        return getResultContent && getResultContent(105)?.props?.content
          ? getResultContent(105).props.content
          : getResultContent(105);
      case 4:
        return getResultContent && getResultContent(106)?.props?.content
          ? getResultContent(106).props.content
          : getResultContent(106);
      case 5:
        return getResultContent && getResultContent(107)?.props?.content
          ? getResultContent(107).props.content
          : getResultContent(107);
      case 6:
        return getResultContent && getResultContent(108)?.props?.content
          ? getResultContent(108).props.content
          : getResultContent(108);
      case 7:
        return getResultContent && getResultContent(109)?.props?.content
          ? getResultContent(109).props.content
          : getResultContent(109);
      case 8:
        return getResultContent && getResultContent(110)?.props?.content
          ? getResultContent(110).props.content
          : getResultContent(110);
      case 9:
        return getResultContent && getResultContent(111)?.props?.content
          ? getResultContent(111).props.content
          : getResultContent(111);
      case 10:
        return getResultContent && getResultContent(112)?.props?.content
          ? getResultContent(112).props.content
          : getResultContent(112);
      default:
        return "";
    }
  };

  const heatingValuesToNumbersHandler = (
    heating_type: any,
    subtype: any,
    fuel: any
  ) => {
    if (heating_type === "boiler" && subtype === "standard" && fuel === "oil") {
      return getResultContent && getResultContent(115);
    } else if (
      heating_type === "boiler" &&
      subtype === "standard" &&
      fuel === "gas"
    ) {
      return getResultContent && getResultContent(116)?.props?.content
        ? getResultContent(116).props.content
        : getResultContent(116);
    } else if (
      heating_type === "electric_heater" &&
      subtype === "standard" &&
      fuel === "electricity"
    ) {
      return getResultContent && getResultContent(117)?.props?.content
        ? getResultContent(117).props.content
        : getResultContent(117);
    } else if (
      heating_type === "boiler" &&
      subtype === "condensing" &&
      fuel === "gas"
    ) {
      return getResultContent && getResultContent(118)?.props?.content
        ? getResultContent(118).props.content
        : getResultContent(118);
    } else if (
      heating_type === "heat_pump" &&
      subtype === "air_water" &&
      fuel === "electricity"
    ) {
      return getResultContent && getResultContent(119)?.props?.content
        ? getResultContent(119).props.content
        : getResultContent(119);
    } else if (
      heating_type === "heat_pump" &&
      subtype === "ground_water" &&
      fuel === "electricity"
    ) {
      return getResultContent && getResultContent(120)?.props?.content
        ? getResultContent(120).props.content
        : getResultContent(120);
    } else {
      return getResultContent && getResultContent(114)?.props?.content
        ? getResultContent(114).props.content
        : getResultContent(114);
    }
  };

  const mechanicalSystemHandler = (value: any) => {
    switch (value) {
      case "":
        return getResultContent && getResultContent(123)?.props?.content
          ? getResultContent(123).props.content
          : getResultContent(123);
      case "system_c":
        return getResultContent && getResultContent(124)?.props?.content
          ? getResultContent(124).props.content
          : getResultContent(124);
      case "system_cplus":
        return getResultContent && getResultContent(125)?.props?.content
          ? getResultContent(125).props.content
          : getResultContent(125);
      case "system_d":
        return getResultContent && getResultContent(126)?.props?.content
          ? getResultContent(126).props.content
          : getResultContent(126);
      default:
        return "";
    }
  };

  const windowAndDoorsColor = (value: any) => {
    switch (value) {
      case "":
        return RedDot;
      case "single":
        return RedDot;
      case "ordinary_double":
        return OrangeDot;
      case "hr_double_before_2000":
        return OrangeDot;
      case "hr_double_after_2000":
        return GreenDot;
      case "triple":
        return GreenDot;
      default:
        return RedDot;
    }
  };

  const wallInsulationColor = (response: any) => {
    // cavity wall
    let cavity = response?.wall?.u_value;
    if (cavity !== "" && cavity !== undefined) {
      if (cavity === "") {
        return RedDot;
      } else if (
        cavity === 0.65 ||
        cavity === 0.55 ||
        cavity === 0.4 ||
        cavity === 0.3
      ) {
        return OrangeDot;
      } else if (cavity === 0.2) {
        return GreenDot;
      }
    } else {
      if (response?.u_value_wall < 0.6 && response?.has_cavity === 1) {
        return GreenDot;
      } else if (
        response?.u_value_wall < 0.65 &&
        response?.u_value_wall >= 0.6 &&
        response?.has_cavity === 1
      ) {
        return OrangeDot;
      } else if (
        response?.u_value_wall < 0.7 &&
        response?.u_value_wall >= 0.65 &&
        response?.has_cavity === 1
      ) {
        return OrangeDot;
      } else if (
        response?.u_value_wall < 1.65 &&
        response?.u_value_wall >= 0.7 &&
        response?.has_cavity === 1
      ) {
        return RedDot;
      } else if (response?.u_value_wall >= 1.65 && response?.has_cavity === 1) {
        return RedDot;
      } else if (response?.u_value_wall < 0.25 && response?.has_cavity === 0) {
        return GreenDot;
      } else if (
        response?.u_value_wall < 0.35 &&
        response?.u_value_wall >= 0.25 &&
        response?.has_cavity === 0
      ) {
        return OrangeDot;
      } else if (
        response?.u_value_wall < 0.45 &&
        response?.u_value_wall >= 0.35 &&
        response?.has_cavity === 0
      ) {
        return OrangeDot;
      } else if (
        response?.u_value_wall < 2.45 &&
        response?.u_value_wall >= 0.45 &&
        response?.has_cavity === 0
      ) {
        return RedDot;
      } else if (response?.u_value_wall >= 2.45 && response?.has_cavity === 0) {
        return RedDot;
      } else {
        return RedDot;
      }

      // cavity wall
      // if (
      //   response?.wall_insulation_year !== null &&
      //   parseInt(response?.wall_insulation_year) === 0
      // ) {
      //   return RedDot;
      // } else if (
      //   (response?.wall_insulation_year !== null &&
      //     response?.wall_insulation_year > 1960 &&
      //     response?.wall_insulation_year <= 1985) ||
      //   (response?.wall_insulation_year !== null &&
      //     response?.wall_insulation_year > 1985 &&
      //     response?.wall_insulation_year <= 2006)
      // ) {
      //   return OrangeDot;
      // } else if (response?.wall_insulation_year > 2006) {
      //   return GreenDot;
      // } else {
      //   return RedDot;
      // }
    }
  };

  const RoofInsulationColor = (response: any) => {
    let roofValue = response?.roof;
    if (roofValue !== "" && roofValue !== undefined) {
      if (roofValue === "") {
        return RedDot;
      } else if (
        roofValue.u_value === 0.8 ||
        (roofValue.u_value === 0.4 && roofValue?.roof_type === "exterior")
      ) {
        return OrangeDot;
      } else if (
        roofValue.u_value === 0.4 ||
        (roofValue.u_value === 0.2 && roofValue?.roof_type === "exterior")
      ) {
        return OrangeDot;
      } else if (
        roofValue.u_value === 0.2 ||
        (roofValue.u_value === 0.15 && roofValue?.roof_type === "exterior")
      ) {
        return GreenDot;
      }
    } else {
      // roof conditions for call zero
      if (response?.u_value_roof < 0.25) {
        return GreenDot;
      } else if (
        (response?.u_value_roof < 0.45 && response?.u_value_roof >= 0.25) ||
        (response?.u_value_roof < 0.85 && response?.u_value_roof >= 0.45)
      ) {
        return OrangeDot;
      }
      //  else if (
      //   response?.u_value_roof < 0.85 &&
      //   response?.u_value_roof >= 0.45
      // ) {
      //   return OrangeDot;
      // }
      else if (
        response?.u_value_roof < 2.85 &&
        response?.u_value_roof >= 0.85
      ) {
        return RedDot;
      } else {
        return RedDot;
      }
    }
  };

  const heatingValuesToNumbersHandlerColor = (
    heating_type: any,
    subtype: any,
    fuel: any
  ) => {
    if (heating_type === "boiler" && subtype === "standard" && fuel === "oil") {
      return RedDot;
    } else if (
      heating_type === "boiler" &&
      subtype === "standard" &&
      fuel === "gas"
    ) {
      return RedDot;
    } else if (
      heating_type === "electric_heater" &&
      subtype === "standard" &&
      fuel === "electricity"
    ) {
      return OrangeDot;
    } else if (
      heating_type === "boiler" &&
      subtype === "condensing" &&
      fuel === "gas"
    ) {
      return GreenDot;
    } else if (
      heating_type === "heat_pump" &&
      subtype === "air_water" &&
      fuel === "electricity"
    ) {
      return GreenDot;
    } else if (
      heating_type === "heat_pump" &&
      subtype === "ground_water" &&
      fuel === "electricity"
    ) {
      return GreenDot;
    } else {
      return RedDot;
    }
  };

  const mechanicalSystemHandlerColor = (value: any) => {
    switch (value) {
      case "":
        return RedDot;
      case "system_c":
        return OrangeDot;
      case "system_cplus":
        return GreenDot;
      case "system_d":
        return GreenDot;
      default:
        return RedDot;
    }
  };

  const Amenities_demos = [
    {
      id: 1,
      key: getResultContent(9),
      icon: Window,
      dotIcon:
        refinementCall2Record && Object.keys(refinementCall2Record).length > 0
          ? refinementCall2Record.window_type === "hr_double_before_2000"
            ? windowAndDoorsColor("ordinary_double")
            : windowAndDoorsColor(refinementCall2Record.window_type)
          : // default values
          allRenovationRecord && allRenovationRecord.window_type !== null
          ? windowAndDoorsColor(allRenovationRecord.window_type)
          : "",

      value:
        refinementCall2Record && Object.keys(refinementCall2Record).length > 0
          ? refinementCall2Record.window_type === "hr_double_before_2000"
            ? windowAndDoors("ordinary_double")
            : windowAndDoors(refinementCall2Record.window_type)
          : // default values
          allRenovationRecord && allRenovationRecord.window_type !== null
          ? windowAndDoors(allRenovationRecord.window_type)
          : "",
    },
    {
      id: 2,
      key: getResultContent(13),
      icon: Wall,
      dotIcon:
        refinementCall2Record && Object.keys(refinementCall2Record).length > 0
          ? wallInsulationColor(refinementCall2Record)
          : // if record not exist in DB
            wallInsulationColor(allRenovationRecord),
      value:
        refinementCall2Record && Object.keys(refinementCall2Record).length > 0
          ? refinementCall2Record?.wall?.u_value === 1.6 &&
            refinementCall2Record?.wall?.has_cavity === null
            ? wallInsulation(1)
            : refinementCall2Record?.wall?.u_value === 0.65 &&
              refinementCall2Record?.wall?.has_cavity === true
            ? wallInsulation(2)
            : refinementCall2Record?.wall?.u_value === 0.55 &&
              refinementCall2Record?.wall?.has_cavity === true
            ? wallInsulation(3)
            : refinementCall2Record?.wall?.u_value === 0.3 &&
              refinementCall2Record?.wall?.has_cavity === true
            ? wallInsulation(4)
            : refinementCall2Record?.wall?.u_value === 2.04 &&
              refinementCall2Record?.wall?.has_cavity === null
            ? wallInsulation(5)
            : refinementCall2Record?.wall?.u_value === 0.4 &&
              refinementCall2Record?.wall?.has_cavity === false
            ? wallInsulation(6)
            : refinementCall2Record?.wall?.u_value === 0.3 &&
              refinementCall2Record?.wall?.has_cavity === false
            ? wallInsulation(7)
            : refinementCall2Record?.wall?.u_value === 0.2 &&
              refinementCall2Record?.wall?.has_cavity === false
            ? wallInsulation(8)
            : wallInsulation(0)
          : allRenovationRecord
          ? allRenovationRecord?.u_value_wall < 0.6 &&
            allRenovationRecord?.has_cavity === 1
            ? wallInsulation(4)
            : allRenovationRecord?.u_value_wall < 0.65 &&
              allRenovationRecord?.u_value_wall >= 0.6 &&
              allRenovationRecord?.has_cavity === 1
            ? wallInsulation(3)
            : allRenovationRecord?.u_value_wall < 0.7 &&
              allRenovationRecord?.u_value_wall >= 0.65 &&
              allRenovationRecord?.has_cavity === 1
            ? wallInsulation(2)
            : allRenovationRecord?.u_value_wall < 1.65 &&
              allRenovationRecord?.u_value_wall >= 0.7 &&
              allRenovationRecord?.has_cavity === 1
            ? wallInsulation(1)
            : allRenovationRecord?.u_value_wall >= 1.65 &&
              allRenovationRecord?.has_cavity === 1
            ? wallInsulation(0)
            : allRenovationRecord?.u_value_wall < 0.25 &&
              allRenovationRecord?.has_cavity === 0
            ? wallInsulation(8)
            : allRenovationRecord?.u_value_wall < 0.35 &&
              allRenovationRecord?.u_value_wall >= 0.25 &&
              allRenovationRecord?.has_cavity === 0
            ? wallInsulation(7)
            : allRenovationRecord?.u_value_wall < 0.45 &&
              allRenovationRecord?.u_value_wall >= 0.35 &&
              allRenovationRecord?.has_cavity === 0
            ? wallInsulation(6)
            : allRenovationRecord?.u_value_wall < 2.45 &&
              allRenovationRecord?.u_value_wall >= 0.45 &&
              allRenovationRecord?.has_cavity === 0
            ? wallInsulation(5)
            : allRenovationRecord?.u_value_wall >= 2.45 &&
              allRenovationRecord?.has_cavity === 0
            ? wallInsulation(0)
            : ""
          : "",
    },
    {
      id: 3,
      key: getResultContent(10),
      icon: Roof,
      dotIcon:
        refinementCall2Record &&
        refinementCall2Record !== undefined &&
        Object.keys(refinementCall2Record).length > 0
          ? RoofInsulationColor(refinementCall2Record)
          : // if record not exist in DB
            RoofInsulationColor(allRenovationRecord),

      value:
        // if user record exist in DB
        refinementCall2Record && refinementCall2Record.roof
          ? refinementCall2Record.roof.u_value > 0.8
            ? RoofInsulation(1)
            : refinementCall2Record.roof.u_value > 0.4 ||
              refinementCall2Record.roof.u_value === 0.8
            ? RoofInsulation(2)
            : refinementCall2Record.roof.u_value > 0.2 ||
              refinementCall2Record.roof.u_value === 0.4
            ? RoofInsulation(3)
            : refinementCall2Record.roof.u_value > 0.15 ||
              refinementCall2Record.roof.u_value === 0.2
            ? RoofInsulation(4)
            : RoofInsulation(10)
          : // if record not exist in DB
          allRenovationRecord
          ? allRenovationRecord.u_value_roof < 0.25
            ? RoofInsulation(7)
            : allRenovationRecord.u_value_roof < 0.45 &&
              allRenovationRecord.u_value_roof >= 0.25
            ? RoofInsulation(6)
            : allRenovationRecord.u_value_roof < 0.85 &&
              allRenovationRecord.u_value_roof >= 0.45
            ? RoofInsulation(5)
            : allRenovationRecord.u_value_roof < 2.85 &&
              allRenovationRecord.u_value_roof >= 0.85
            ? RoofInsulation(1)
            : RoofInsulation(1)
          : "",
    },
    {
      id: 4,
      key: getResultContent(14),
      icon: Heating,
      dotIcon:
        refinementCall2Record && Object.keys(refinementCall2Record).length > 0
          ? heatingValuesToNumbersHandlerColor(
              refinementCall2Record?.heating?.heating_type,
              refinementCall2Record?.heating?.subtype,
              refinementCall2Record?.heating?.fuel
            )
          : // default if user record not exits in DB
            heatingValuesToNumbersHandlerColor(
              allRenovationRecord?.heating_type,
              allRenovationRecord?.heating_sub_type,
              allRenovationRecord?.heating_fuel
            ),
      value:
        refinementCall2Record && Object.keys(refinementCall2Record).length > 0
          ? heatingValuesToNumbersHandler(
              refinementCall2Record?.heating?.heating_type,
              refinementCall2Record?.heating?.subtype,
              refinementCall2Record?.heating?.fuel
            )
          : // default if user record not exits in DB
            heatingValuesToNumbersHandler(
              allRenovationRecord?.heating_type,
              allRenovationRecord?.heating_sub_type,
              allRenovationRecord?.heating_fuel
            ),
    },
    {
      id: 5,
      key: getResultContent(11),
      icon: Ventillation,
      // store data of user
      dotIcon:
        refinementCall2Record && refinementCall2Record?.mechanical_system_type
          ? mechanicalSystemHandlerColor(
              refinementCall2Record?.mechanical_system_type
            )
          : // default if user record not exits in DB
          allRenovationRecord?.mechanical_system_type !== null
          ? mechanicalSystemHandlerColor(
              allRenovationRecord?.mechanical_system_type
            )
          : "",
      value:
        // store data of user
        refinementCall2Record && refinementCall2Record?.mechanical_system_type
          ? mechanicalSystemHandler(
              refinementCall2Record?.mechanical_system_type
            )
          : // default if user record not exits in DB
          allRenovationRecord?.mechanical_system_type !== null
          ? mechanicalSystemHandler(allRenovationRecord?.mechanical_system_type)
          : "",
    },
    {
      id: 6,
      key: getResultContent(16),
      icon: Solar,
      value:
        refinementCall2Record !== undefined &&
        refinementCall2Record?.solar?.number_panels !== "" &&
        refinementCall2Record?.solar?.number_panels !== undefined
          ? "Yes, " + refinementCall2Record?.solar?.number_panels + " panneaux"
          : "Nee",
      dotIcon:
        refinementCall2Record == undefined ||
        refinementCall2Record?.solar?.number_panels == "" ||
        refinementCall2Record?.solar?.number_panels == undefined
          ? RedDot
          : GreenDot,
      // show: 1,
    },
  ];

  const currentEpcHandler = (epc_label: any) => {
    switch (epc_label) {
      case "1":
        return EPC_A;
      case "2":
        return EPC_B;
      case "3":
        return EPC_C;
      case "4":
        return EPC_D;
      case "5":
        return EPC_E;
      case "6":
        return EPC_F;
      default:
        return EPC_F;
    }
  };
  /* index for bad:18 , medium:19 , good:20 */
  const currentEpcStatus = (epc_label: any) => {
    switch (epc_label) {
      case "1":
        return getResultContent(154);
      // return getResultContent(20);
      case "2":
        return getResultContent(155);
      // return getResultContent(20);
      case "3":
        return getResultContent(156);
      // return getResultContent(19);
      case "4":
        return getResultContent(157);
      // return getResultContent(18);
      case "5":
        return getResultContent(158);
      // return getResultContent(18);
      case "6":
        return getResultContent(158);
      default:
        return getResultContent(158);
    }
  };

  const activeStars = (value: number) => {
    let allStars = [];
    for (let i = 1; i < 6; i++) {
      if (i <= value) {
        allStars?.push({
          id: i,
          show: "active",
        });
      } else {
        allStars?.push({
          id: i,
          show: "inActive",
        });
      }
    }
    return (
      <>
        <div className="flex items-center ">
          {allStars?.map((single: any, i: any) => (
            <React.Fragment key={i}>
              {single.show === "active" ? (
                <img className="h-6 w-6" src={Star} alt="" />
              ) : (
                <img className="h-6 w-6" src={StarGrey} alt="" />
              )}
            </React.Fragment>
          ))}
        </div>
      </>
    );
  };

  const getSavings = (): any => {
    let total: any = 0;

    let key = currentComfort(
      refinementCall2Record && Object.keys(refinementCall2Record).length > 0
        ? refinementCall2Record
        : allRenovationRecord,
      total
    );
    if (key) {
      if (key <= 20) {
        return activeStars(1);
      } else if (key <= 40) {
        return activeStars(2);
      } else if (key <= 60) {
        return activeStars(3);
      } else if (key <= 80) {
        return activeStars(4);
      } else if (key <= 100) {
        return activeStars(5);
      }
    } else {
      return activeStars(1);
    }
  };
  const downloadRefinementRecord = (key: any) => {
    if (
      downloadRecord &&
      Object.keys(downloadRecord)?.length > 0 &&
      key === 1
    ) {
      var zip = new JSZip();
      zip.file("Call2-Response.txt", JSON.stringify(downloadRecord));
      zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, "houseRefinement-response");
      });
    }
  };

  return (
    <>
      {/* model */}
      <MoreOptionModal
        getResultContent={getResultContent}
        visible={visible}
        setVisible={setVisible}
      />
      {/*  */}
      <div
        className={`w-full lg:w-1/2 listingSection__wrap diagnosis-container h-auto section_width border border-[#F0F0F0] rounded-[10px] lg:mx-2 my-0 my-0 p-0 ${className}`}
      >
        <div className="flex justify-between px-4 py-4 ">
          <div className="flex flex-col lg:flex-row items-center w-full">
            <div className="w-full lg:w-1/2">
              <div className="flex items-center">
                <img
                  src={currentEpcHandler(userSelectedData[0]?.epc_label)}
                  alt=""
                />
              </div>
              <div className="flex items-center pt-2 pl-1">
                <img
                  src={Info}
                  alt="Info"
                  className="h-[15px] pl-1 cursor-pointer"
                  onClick={() => setVisible(true)}
                />
                <span className="text-[14px] text-[#007DDF] px-2 underline">
                  {getResultContent && getResultContent(294)}
                </span>
              </div>
            </div>
            <div className="w-full lg:w-1/2 px-4 text-left m-0 flex flex-col">
              <span className="text-xs text-black font-normal">
                {getResultContent(17)}{" "}
                {currentEpcStatus(userSelectedData[0]?.epc_label)}
                <br />
                <b className="text-black font-medium ">
                  {getResultContent(21)}
                </b>
              </span>
              {/* <span className="">{getResultContent(21)}</span> */}
            </div>
          </div>
        </div>
        <div className="border border-[#F0F0F0]"></div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 w-full p-4">
          {Amenities_demos?.map((item, i) => (
            <React.Fragment key={i}>
              {/* {item?.show === 1 && ( */}
              <div key={i} className="flex items-center space-x-3">
                <img
                  src={item?.dotIcon ? item?.dotIcon : RedDot}
                  alt=""
                  className="rounded h-5 w-5"
                  onClick={() => downloadRefinementRecord(item?.id)}
                />
                <Avatar
                  sizeClass="h-8 w-8"
                  radius="rounded"
                  imgUrl={item?.icon ? item?.icon : ""}
                />
                {/* <div>
                <img src={item?.icon ? item?.icon : ""} />
              </div> */}
                <div className="flex flex-col">
                  <span className="text-sm font-bold text-[#455259]">
                    {item?.key}
                  </span>
                  <span className="text-xs font-normal text-[#455259] py-1">
                    {item?.value}
                  </span>
                </div>
              </div>
              {/* )} */}
            </React.Fragment>
          ))}
        </div>
        {/* footer */}
        <div className="">
          <div className="flex flex-col lg:flex-row justify-between p-4 lg:p-8 items-center w-full bg-[#F4FAFF] border-b-r-10 border-b-l-10">
            <div className="text-sm  mb-2 lg:mb-0">
              <span>{getResultContent && getResultContent(78)}</span>
              {getSavings()}
              {/* {getSavings(parseInt(currentValue))} */}
            </div>
            <div className="flex justify-end  ">
              {isCallWaitng ? (
                <button className="outline-primary-button ">
                  <Spin indicator={antIcon} />
                </button>
              ) : (
                (userStatus?.data?.status < 3 ||
                  userStatus?.data == null ||
                  userStatus?.data?.status == undefined) &&
                !isAdminView && (
                  <button
                    className={
                      "outline-primary-button hover:bg-[#007DDF] hover:text-[#fff]  border border-[#007DDF] rounded text-[#007DDF] font-normal text-base py-2 px-4"
                    }
                    onClick={() => {
                      openModal("refine-information2");
                    }}
                  >
                    {getResultContent(22)}
                  </button>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionDiagnosis;
