import React, { FC, useContext } from "react";
// import images
import Home from "../../../assets/images/advantages/home.svg";
import Stronger from "../../../assets/images/advantages/stronger.svg";
import Hulp from "../../../assets/images/advantages/hulp.svg";
import Samen from "../../../assets/images/advantages/samen.svg";
import Process from "../../../assets/images/advantages/process.svg";
import City from "../../../assets/images/advantages/city.svg";
import { getValue } from "../../../utils/getValue";
import { GlobalContext } from "../../../context/globalContext/globalContext";
// import components
interface AdvantagesSideNavInterface {
  activeTab?: any;
  setActiveTab?: any;
}

const AdvantagesSideNav: FC<AdvantagesSideNavInterface> = ({
  activeTab,
  setActiveTab,
}) => {
  // global state
  const { state }: any = useContext(GlobalContext);

  const SideMap = [
    {
      id: 1,
      images: Home,
      title: getValue(state, 1, 154)?.props?.content,
    },
    {
      id: 2,
      images: Stronger,
      title: getValue(state, 1, 155)?.props?.content,
    },
    {
      id: 3,
      images: Hulp,
      title: getValue(state, 1, 156)?.props?.content,
    },
    {
      id: 4,
      images: Samen,
      title: getValue(state, 1, 157)?.props?.content,
    },
    {
      id: 5,
      images: Process,
      title: getValue(state, 1, 158)?.props?.content,
    },
    {
      id: 6,
      images: City,
      title: getValue(state, 1, 159)?.props?.content,
    },
  ];

  return (
    <div className="boxes-advantages">
      <div className="space-x-3 lg:space-x-0 flex  lg:flex-col flex-row justify-between w-full py-4 lg:py-0">
        {SideMap.map((single) => (
          <div
            onClick={() => setActiveTab(single.id)}
            className={` ${
              single.id === activeTab
                ? "shadow-lg bg-white  lg:text-[#435159]"
                : "text-[#c2c6c8]  hover:text-[#435159]"
            }hover:bg-white hover:rounded-xl hover:shadow-lg advantages-sidenav  md:min-w-[30%] lg:w-[80%]  flex items-center justify-start lg:justify-start  cursor-pointer rounded-xl px-8 py-4 lg:px-4  lg:my-4`}
          >
            <img
              src={single.images}
              alt="images"
              className="h-[24px] lg:h-[80px]"
            />
            <p
              className="text-xs lg:text-base px-1 lg:px-4"
              dangerouslySetInnerHTML={{
                __html: single.title,
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
export default AdvantagesSideNav;
