import React, { FC, useContext, useEffect, useState } from "react";
import FaqSideNav from "../../components/faq/sideNav/index";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { getValue } from "../../utils/getValue";
import CarryOut from "../../components/faq/selectFaq/carryOut";
import { GetAPIService } from "../../context/services";
interface FaqInterface {}

const Faq: FC<FaqInterface> = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [allFaqs, setAllFaqs] = useState([]);
  const [selectedCtegoryFaqs, setSelectedCtegoryFaqs] = useState([]);
  const [innerWidth, setInnerWidth]: any = useState("");
  // handle for Faqs data
  const faqsDataHandler = (value: any) => {
    const selectedRecords = allFaqs?.filter(
      (single: any) => single?.category === value
    );
    setSelectedCtegoryFaqs([...selectedRecords]);
  };

  useEffect(() => {
    faqsDataHandler(1);
    // eslint-disable-next-line
  }, [allFaqs]);

  // Api call for faqs
  useEffect(() => {
    GetAPIService("/faqs/city/37")
      .then((response) => {
        setAllFaqs(response?.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setInnerWidth(window?.visualViewport?.width);
  }, [window]);

  // global state
  const { state }: any = useContext(GlobalContext);

  return (
    <div
      style={{
        width: innerWidth ? `${innerWidth}px` : `100%`,
      }}
    >
      <div className="bg-faq h-[250px]">
        <div className="h-full text-center flex justify-center items-center">
          <p
            className="text-[40px] font-semibold text-black"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 5, 6)?.props?.content,
            }}
          />
        </div>
      </div>
      <div className="mx-[5%] lg:mx-[10%] flex flex-col lg:flex-row py-4 ">
        <div className="lg:w-[35%]">
          <FaqSideNav
            faqsDataHandler={faqsDataHandler}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
        <div className="lg:w-[70%]">
          <CarryOut currentFaqs={selectedCtegoryFaqs} />
        </div>
      </div>
    </div>
  );
};
export default Faq;
