import {
  getbuilding_type,
  get_construction_date,
  windowAndDoorsv2,
  wallInsulationv2,
  roofInsulationv2,
} from "../../utils/buildingDetailsHandler";

import {
  heatingValuesToNumbersHandler,
  heatingNumbersToValuesHandler,
  // wallValuesHandler,
  // windowTypeHandler,
  // windowTypeValueHandler,
  // mechanicalValuesHandler,
  // updateRenovations,
} from "../../shared/Modal/constant";
// -------------------  Modal keys for below questions ------------------------- //
// left side modal of result page keys to send for left modal questions
export const leftModalKeysHandler = (allRenovationRecord: any) => {
  const buildingValues: any = {
    building_type:
      allRenovationRecord && allRenovationRecord?.building_type !== undefined
        ? allRenovationRecord?.building_type
        : allRenovationRecord?.builiding_type !== null
        ? allRenovationRecord?.builiding_type
        : "",
    construction_date:
      allRenovationRecord?.construction_date !== null
        ? get_construction_date(allRenovationRecord?.construction_date)
        : 0,
    floors:
      allRenovationRecord?.num_floors !== null
        ? (Number(allRenovationRecord?.num_floors) === 1 && "1") ||
          (Number(allRenovationRecord?.num_floors) < 3 && "2") ||
          (Number(allRenovationRecord?.num_floors) >= 3 && "3")
        : 0,
    residents:
      allRenovationRecord?.num_hob !== undefined
        ? (Number(allRenovationRecord?.num_hob) === 1 && 1) ||
          (Number(allRenovationRecord?.num_hob) === 2 && 2) ||
          (Number(allRenovationRecord?.num_hob) === 3 && 3) ||
          (Number(allRenovationRecord?.num_hob) === 4 && 4) ||
          (Number(allRenovationRecord?.num_hob) >= 5 && 5)
        : // default
        allRenovationRecord?.num_hob !== null
        ? (Number(allRenovationRecord?.num_hob) === 1 && 1) ||
          (Number(allRenovationRecord?.num_hob) === 2 && 2) ||
          (Number(allRenovationRecord?.num_hob) === 3 && 3) ||
          (Number(allRenovationRecord?.num_hob) === 4 && 4) ||
          (Number(allRenovationRecord?.num_hob) >= 5 && 5)
        : 0,
    has_attic:
      allRenovationRecord?.has_attic !== null &&
      allRenovationRecord?.has_attic === 1
        ? allRenovationRecord?.has_attic
        : 0,
    energyConsumer:
      allRenovationRecord?.energyConsumer !== null &&
      allRenovationRecord?.energyConsumer !== undefined
        ? allRenovationRecord?.energyConsumer
        : [],
    cave:
      allRenovationRecord?.cave !== null &&
      (allRenovationRecord?.cave === "OUI" || allRenovationRecord?.cave === 1)
        ? 1
        : 0,
    facades:
      allRenovationRecord?.num_facades !== null
        ? (getbuilding_type(Number(allRenovationRecord?.num_facades)) === 2 &&
            "2") ||
          (getbuilding_type(Number(allRenovationRecord?.num_facades)) === 3 &&
            "3") ||
          (getbuilding_type(Number(allRenovationRecord?.num_facades)) === 4 &&
            "4")
        : 0,
    building_id: allRenovationRecord?.id
      ? allRenovationRecord?.id
      : allRenovationRecord?.building_id,
  };
  return buildingValues;
};

// right side modal of result page keys to send for right modal questions
export const rightModalKeysHandler = (
  allRenovationRecord: any,
  refinementCall2Record: any
) => {
  //console.log("refinementCall2Record-insidefunction", refinementCall2Record);
  const buildingValues: any = {
    window_type:
      refinementCall2Record &&
      refinementCall2Record !== null &&
      refinementCall2Record !== undefined &&
      Object.keys(refinementCall2Record)?.length !== 0 &&
      refinementCall2Record?.glazing
        ? refinementCall2Record?.glazing
        : allRenovationRecord &&
          allRenovationRecord?.glazing !== null &&
          allRenovationRecord?.glazing !== "?"
        ? allRenovationRecord?.glazing
        : "",

    //
    roof_insulation_key:
      // if user record exist in DB
      refinementCall2Record &&
      Object.keys(refinementCall2Record)?.length !== 0 &&
      refinementCall2Record?.roof_insulation_key !== ""
        ? refinementCall2Record?.roof_insulation_key
        : // : ""
        allRenovationRecord
        ? allRenovationRecord?.roof_insulation !== "?" &&
          allRenovationRecord.roof_insulation === "Probable"
          ? "OUI"
          : allRenovationRecord?.roof_insulation
          ? allRenovationRecord?.roof_insulation
          : "NON"
        : "",

    roof_insulation_value:
      // if user record exist in DB
      refinementCall2Record &&
      Object.keys(refinementCall2Record)?.length !== 0 &&
      refinementCall2Record?.roof_insulation_value !== ""
        ? refinementCall2Record?.roof_insulation_value
        : // : ""
        allRenovationRecord
        ? allRenovationRecord?.roof_insulation !== "?" &&
          allRenovationRecord?.roof_insulation === "Probable"
          ? "6"
          : allRenovationRecord?.roof_insulation === "OUI" ||
            allRenovationRecord?.roof_insulation === "Yes, for the most part"
          ? roofInsulationv2(allRenovationRecord?.construction_date)
          : "NON"
        : "NON",

    wall_insulation_key:
      // if user record exist in DB
      refinementCall2Record &&
      Object.keys(refinementCall2Record)?.length !== 0 &&
      refinementCall2Record?.wall_insulation_key !== ""
        ? refinementCall2Record?.wall_insulation_key
        : // : ""
        allRenovationRecord?.facade_insulation_exterior !== "?" &&
          (allRenovationRecord?.facade_insulation_exterior === "Probable" ||
            allRenovationRecord?.facade_insulation_exterior === "OUI" ||
            allRenovationRecord?.has_cavity === "OUI")
        ? "OUI"
        : "NON",
    wall_insulation_value:
      refinementCall2Record &&
      Object.keys(refinementCall2Record)?.length !== 0 &&
      refinementCall2Record?.wall_insulation_value !== ""
        ? refinementCall2Record?.wall_insulation_value
        : // : ""
        allRenovationRecord
        ? allRenovationRecord?.facade_insulation_exterior !== "?" &&
          allRenovationRecord?.facade_insulation_exterior === "Probable"
          ? "6"
          : allRenovationRecord?.facade_insulation_exterior === "OUI" ||
            allRenovationRecord?.has_cavity === "OUI"
          ? wallInsulationv2(allRenovationRecord?.construction_date)
          : ""
        : "",
    floor_insulation_key:
      // if user record exist in DB
      refinementCall2Record && Object.keys(refinementCall2Record)?.length !== 0
        ? refinementCall2Record?.floor_insulation_key !== ""
          ? refinementCall2Record?.floor_insulation_key
          : allRenovationRecord.floor_insulation_key
          ? allRenovationRecord?.floor_insulation_key
          : "NON"
        : "NON",
    floor_insulation_value:
      // if user record exist in DB
      refinementCall2Record && Object.keys(refinementCall2Record).length !== 0
        ? refinementCall2Record?.floor_insulation_value !== ""
          ? refinementCall2Record?.floor_insulation_value
          : ""
        : allRenovationRecord
        ? allRenovationRecord.floor_insulation_value
          ? allRenovationRecord?.floor_insulation_value
          : ""
        : "",

    heating_type:
      refinementCall2Record &&
      Object.keys(refinementCall2Record)?.length !== 0 &&
      refinementCall2Record.heating_type !== undefined
        ? refinementCall2Record.heating_type
        : // default if user record not exits in DB
        allRenovationRecord &&
          allRenovationRecord.heating_type !== null &&
          allRenovationRecord.heating_type !== undefined
        ? allRenovationRecord.heating_type
        : "non-condesing2",

    ventilation_type:
      refinementCall2Record &&
      Object.keys(refinementCall2Record).length !== 0 &&
      refinementCall2Record?.ventilation_type !== ""
        ? refinementCall2Record.ventilation_type
        : allRenovationRecord?.ventilation_type !== null &&
          allRenovationRecord?.ventilation_type !== undefined
        ? allRenovationRecord?.ventilation_type
        : "1",
    drafts:
      refinementCall2Record &&
      Object.keys(refinementCall2Record).length !== 0 &&
      refinementCall2Record?.drafts !== ""
        ? refinementCall2Record.drafts
        : allRenovationRecord?.drafts !== null &&
          allRenovationRecord?.drafts !== undefined
        ? allRenovationRecord?.drafts
        : "3",
    humidity:
      refinementCall2Record &&
      Object.keys(refinementCall2Record).length !== 0 &&
      refinementCall2Record?.humidity !== ""
        ? refinementCall2Record.humidity
        : allRenovationRecord?.humidity !== null &&
          allRenovationRecord?.humidity !== undefined
        ? allRenovationRecord?.humidity
        : "1",
    heating:
      refinementCall2Record &&
      Object.keys(refinementCall2Record).length !== 0 &&
      refinementCall2Record?.heating !== ""
        ? refinementCall2Record.heating
        : "",
    hot_water:
      refinementCall2Record &&
      Object.keys(refinementCall2Record).length !== 0 &&
      refinementCall2Record?.hot_water !== ""
        ? refinementCall2Record.hot_water
        : allRenovationRecord?.hot_water !== null &&
          allRenovationRecord?.hot_water !== undefined
        ? allRenovationRecord?.hot_water
        : "3",

    number_panels:
      refinementCall2Record &&
      refinementCall2Record?.number_panels !== undefined
        ? refinementCall2Record?.number_panels
        : allRenovationRecord?.presents === "OUI" &&
          allRenovationRecord?.num_panels !== "" &&
          allRenovationRecord?.num_panels !== "?"
        ? allRenovationRecord?.num_panels
        : 0,

    pv_panels:
      refinementCall2Record &&
      Object.keys(refinementCall2Record).length !== 0 &&
      refinementCall2Record?.pv_panels !== ""
        ? refinementCall2Record.pv_panels
        : allRenovationRecord
        ? allRenovationRecord?.presents === "OUI" &&
          allRenovationRecord?.num_panels !== "" &&
          allRenovationRecord?.num_panels !== "?"
          ? "OUI"
          : "NON"
        : "NON",

    building_id: allRenovationRecord?.id
      ? allRenovationRecord?.id
      : allRenovationRecord?.building_id,
  };
  console.log(
    "refinementCall2Record-insidefunction-buildingValues",
    buildingValues
  );
  return buildingValues;
};
// ----------------------- ends here --------------------- //

// --------------------- All Questions handler starts from here ----------------------- //
// left side modal questions
//Construction_Year left side modal - (construction_date)
const Q5_Construction_Year = (value: any) => {
  let answere = 0;
  // if i don't know is selected.
  // if (parseInt(value) === 0) {
  //   answere = 123;
  // }
  // 1
  if (parseInt(value) < 1918) {
    answere = 9;
  }
  // 2
  else if (parseInt(value) >= 1918 && parseInt(value) <= 1945) {
    answere = 10;
  }
  // 3
  else if (parseInt(value) >= 1946 && parseInt(value) <= 1970) {
    answere = 11;
  }
  // 4
  else if (parseInt(value) >= 1971 && parseInt(value) <= 1984) {
    answere = 12;
  }
  //   5
  else if (parseInt(value) >= 1985 && parseInt(value) <= 1995) {
    answere = 13;
  }
  //   6
  else if (parseInt(value) >= 1996 && parseInt(value) <= 2025) {
    answere = 14;
  }
  // return
  return answere;
  // return {
  //   question_id: 5,
  //   answer_id: answere,
  // };
};

//facades left side modal - (facades)
const Q7_Facades = (value: any) => {
  let answere = 0;
  // 1
  if (parseInt(value) === 2) {
    answere = 18;
  }
  // 2
  else if (parseInt(value) === 3) {
    answere = 19;
  }
  // 3
  else if (parseInt(value) === 4) {
    answere = 20;
  }
  return answere;
  // return {
  //   question_id: 7,
  //   answer_id: answere,
  // };
};

//floors left side modal - (floors)
const Q8_Floors = (value: any) => {
  let answere = 0;
  // 1
  if (parseInt(value) === 1) {
    answere = 21;
  }
  // 2
  else if (parseInt(value) === 2) {
    answere = 22;
  }
  // 3
  else if (parseInt(value) === 3) {
    answere = 23;
  }
  // return
  return answere;
  // return {
  //   question_id: 8,
  //   answer_id: answere,
  // };
};

// right side modal questions
// vitrage right side 1st select option
const Q11_Vitrage = (value: any) => {
  let answere = 0;
  //   single
  if (value === "S" || value === "Simple vitrage") {
    answere = 26;
  }
  //   double
  //   else if (value === "Double") {
  //     answere = 0;
  //   }
  //   triple
  else if (value === "T" || value === "Triple vitrage") {
    answere = 29;
  }
  //  Double - option 1
  else if (value === "D") {
    answere = 27;
  }
  // else if (
  //   ((value === "double" ||
  //     value === "ordinary_double" ||
  //     value === "hr_double_after_2000" ||
  //     value === "D") &&
  //     subOption === "D") ||
  //   value === "double" ||
  //   value === "ordinary_double" ||
  //   value === "hr_double_after_2000" ||
  //   value === "D"
  // ) {
  //   answere = 27;
  // }
  //  Double - option 2
  else if (value === "DR") {
    answere = 29;
    // answere = 28;
  }
  // if window from db is null
  else {
    answere = 27;
  }
  return answere;
};

// Roof right side option (roof_insulation_key)
const Q12_Roof = (value: any) => {
  let answere = 0;
  console.log("value", value);
  //   OUI
  if (value === "OUI" || parseInt(value) >= 3) {
    answere = 30;
  }
  //   NON
  else if (value === "NON") {
    answere = 31;
  }
  //   OUI
  if (value === "Yes, for the most part") {
    answere = 30;
  }
  //   NON
  else if (value === "Yes, but only for a small part") {
    answere = 31;
  }
  //   i don't know
  else if (value === "i don't know") {
    answere = 32;
  }
  //   NON
  else {
    answere = 31;
  }
  // return
  return answere;
  // return {
  //   question_id: 12,
  //   answer_id: answere,
  // };
};

// Roof right side sub option (roof_insulation_value)
const Q13_Roof_SubOptions = (value: any) => {
  let answere = 0;
  // 3 - 6
  if (parseInt(value) >= 3 && parseInt(value) <= 7) {
    answere = 34;
  }
  // 8 - 12 cm
  else if (parseInt(value) >= 8 && parseInt(value) <= 13) {
    answere = 35;
  }
  // 14 - 18 cm
  else if (parseInt(value) >= 14 && parseInt(value) <= 19) {
    answere = 36;
  }
  // 20 cm +
  else if (parseInt(value) == 20) {
    answere = 37;
  } else {
    answere = 34;
  }
  // return
  return answere;
  // return {
  //   question_id: 13,
  //   answer_id: answere,
  // };
};

// Roof right side sub option 2 (roof_insulation_bonus)
const Q14_Roof_SubOptions = (value: any) => {
  let answere = 0;
  // 3 - 6
  if (parseInt(value) === 1) {
    answere = 39;
  }
  // 8 - 12 cm
  else if (parseInt(value) === 0) {
    answere = 40;
  }
  // return
  return answere;
  // return {
  //   question_id: 14,
  //   answer_id: answere,
  // };
};

// wall right side option (wall_insulation_value)
const Q16_Wall = (value: any) => {
  let answere = 0;
  //   OUI
  if (value === "OUI" || parseInt(value) >= 3) {
    answere = 42;
  }
  //   NON
  else if (value === "NON") {
    answere = 43;
  }
  //   OUI
  if (value === "Yes, for the most part") {
    answere = 42;
  }
  //   NON
  else if (value === "Yes, but only for a small part") {
    answere = 43;
  }
  //   i don't know
  else if (value === "i don't know") {
    answere = 44;
  }
  //   empty or non
  else {
    answere = 43;
  }
  // return
  return answere;
  // return {
  //   question_id: 16,
  //   answer_id: answere,
  // };
};

// Wall right side sub option (wall_insulation_value)
const Q17_Wall_SubOptions = (value: any) => {
  let answere = 0;
  // 3 - 6
  if (parseInt(value) >= 3 && parseInt(value) <= 6) {
    answere = 46;
  }
  // 7 - 9 cm
  else if (parseInt(value) >= 7 && parseInt(value) <= 9) {
    answere = 47;
  }
  // 10 cm +
  else if (parseInt(value) === 10) {
    answere = 48;
  } else {
    answere = 46;
  }
  // return
  return answere;
  // return {
  //   question_id: 17,
  //   answer_id: answere,
  // };
};

// Wall right side sub option 2 (wall_insulation_bonus)
const Q18_Wall_SubOptions = (value: any) => {
  let answere = 0;
  // 1
  if (parseInt(value) === 1) {
    answere = 50;
  }
  // Non
  else if (parseInt(value) === 0) {
    answere = 51;
  }
  // Je ne sais pas
  else if (value === 2) {
    answere = 52;
  }
  // return
  return answere;
  // return {
  //   question_id: 18,
  //   answer_id: answere,
  // };
};

// Wall right side sub option 3 (wall_is_insulation)
const Q19_Wall_SubOptions = (value: any) => {
  let answere = 0;
  // 3 - 6
  if (parseInt(value) === 1) {
    answere = 53;
  }
  // Non
  else if (parseInt(value) === 2) {
    answere = 54;
  }
  // Je ne sais pas
  else if (parseInt(value) === 3) {
    answere = 55;
  }
  // return
  return answere;
  // return {
  //   question_id: 19,
  //   answer_id: answere,
  // };
};

// floor right side option (insulation_floor_value)
const Q21_Floor = (value: any) => {
  //console.log("Q21_Floor", value);
  let answere = 0;
  //   OUI
  if (value === "OUI" || parseInt(value) >= 3) {
    answere = 56;
  }
  //   NON
  else if (value === "NON") {
    answere = 59;
  }
  //   OUI
  if (value === "Yes, for the most part") {
    answere = 56;
  }
  //   NON
  else if (value === "Yes, but only for a small part") {
    answere = 59;
  }
  //   i don't know
  else if (value === "don't know") {
    answere = 60;
  }
  // return
  return answere;
  // return {
  //   question_id: 21,
  //   answer_id: answere,
  // };
};

// floor right side sub option (insulation_floor_value)
const Q22_Floor_SubOptions = (value: any) => {
  let answere = 0;
  // 2 - 5
  if (parseInt(value) >= 2 && parseInt(value) <= 5) {
    answere = 62;
  }
  // 6 - 9 cm
  else if (parseInt(value) >= 6 && parseInt(value) <= 9) {
    answere = 63;
  }
  // 10 cm +
  else if (parseInt(value) === 10) {
    answere = 64;
  } else {
    answere = 62;
  }
  // return
  return answere;
  // return {
  //   question_id: 22,
  //   answer_id: answere,
  // };
};

// floor right side sub option 2 (insulation_floor_bonus)
const Q23_Floor_SubOptions = (value: any) => {
  let answere = 0;
  // 3 - 6
  if (parseInt(value) === 1) {
    answere = 66;
  }
  // Non
  else if (parseInt(value) === 0) {
    answere = 67;
  }
  // Je ne sais pas
  else if (parseInt(value) === 2) {
    answere = 68;
  }
  // return
  return answere;
  // return {
  //   question_id: 23,
  //   answer_id: answere,
  // };
};

// floor right side sub option 4 (floor_is_insulation)
const Q24_Floor_SubOptions = (value: any) => {
  let answere = 0;
  // 3 - 6
  if (parseInt(value) === 1) {
    answere = 69;
  }
  // Non
  else if (parseInt(value) === 2) {
    answere = 70;
  }
  // return
  return answere;
  // return {
  //   question_id: 24,
  //   answer_id: answere,
  // };
};

// Ventilation Type right side option 4 (ventilation_type)
const Q26_Ventilation = (value: any) => {
  let answere = 0;
  // 1
  if (parseInt(value) === 1) {
    answere = 73;
  }
  // 2
  else if (parseInt(value) === 2) {
    answere = 74;
  }
  // 3
  else if (parseInt(value) === 3) {
    answere = 75;
  }
  // 4
  else if (parseInt(value) === 4) {
    answere = 76;
  }
  // return
  return answere;
  // return {
  //   question_id: 26,
  //   answer_id: answere,
  // };
};

// drafts right side option 4 (drafts)
const Q27_Drafts = (value: any) => {
  let answere = 0;
  // 1
  if (parseInt(value) === 1) {
    answere = 77;
  }
  // 2
  else if (parseInt(value) === 2) {
    answere = 78;
  }
  // 3
  else if (parseInt(value) === 3) {
    answere = 79;
  }
  // 4
  else if (parseInt(value) === 4) {
    answere = 80;
  }
  // 5
  else if (parseInt(value) === 5) {
    answere = 81;
  }
  // return
  return answere;
  // return {
  //   question_id: 27,
  //   answer_id: answere,
  // };
};

// humidity right side option 4 (humidity)
const Q28_Humidity = (value: any) => {
  let answere = 0;
  // 1
  if (parseInt(value) === 1) {
    answere = 82;
  }
  // 2
  else if (parseInt(value) === 2) {
    answere = 83;
  }
  // 3
  else if (parseInt(value) === 3) {
    answere = 84;
  }
  // 4
  else if (parseInt(value) === 4) {
    answere = 85;
  }
  // 5
  else if (parseInt(value) === 5) {
    answere = 86;
  }
  // return
  return answere;
  // return {
  //   question_id: 28,
  //   answer_id: answere,
  // };
};

// Q29_Heating right side option 4 (heating and heating_sub_type)
const Q29_Heating = (value: any) => {
  let answere = 0;
  // 1
  if (value === "electric-heating") {
    answere = 87;
  }
  // 2
  else if (value === "heatpump") {
    answere = 97;
  }
  // 3  - poele and suboptions
  else if (value === "stove") {
    answere = 88;
  }
  // 3 - poele and suboptions
  else if (value === "stove") {
    answere = 88;
  }
  // 3 - poele and suboptions
  else if (value === "stove") {
    answere = 89;
  }
  // 3 - poele and suboptions
  else if (value === "stove") {
    answere = 90;
  }
  // 4 - non-condesing and suboptions
  else if (value === "non-condesing1") {
    answere = 91;
  }
  // 4 - non-condesing and suboptions
  else if (value === "non-condesing2") {
    answere = 92;
  }
  // 5 - condesing and suboptions
  else if (value === "condesing1") {
    answere = 93;
  }
  // 5 - condesing and suboptions
  else if (value === "condesing2") {
    answere = 94;
  }
  // 6 - heatpump-central and suboptions
  // else if (value === "heatpump-central" && subOption === "heatpump1") {
  //   answere = 95;
  // }
  // // 6 - heatpump-central and suboptions
  // else if (value === "heatpump-central" && subOption === "heatpump2") {
  //   answere = 96;
  // }
  // default 92 if not record selected
  else {
    answere = 92;
  }
  // return
  return answere;
};

// Bathroom water system right side option (hot_water)
const Q32_Water_System = (value: any) => {
  let answere = 0;
  // 1
  if (parseInt(value) === 1) {
    answere = 105;
  }
  // 2
  else if (parseInt(value) === 2) {
    answere = 106;
  }
  // 3
  else if (parseInt(value) === 3) {
    answere = 107;
  }
  // 4
  else if (parseInt(value) === 4) {
    answere = 108;
  }
  // 5
  else if (parseInt(value) === 5) {
    answere = 109;
  }
  // default
  else {
    answere = 107;
  }
  // return
  return answere;
  // return {
  //   question_id: 32,
  //   answer_id: answere,
  // };
};

// L'habitation possède-t-elle des panneaux solaires pour la production d'eau chaude? right side (solar_panels)
const Q33_Solar_System = (value: any) => {
  let answere = 0;
  // 1
  if (parseInt(value) === 1) {
    answere = 110;
  }
  // 2
  else if (parseInt(value) === 0) {
    answere = 111;
  }
  // return
  return answere;
  // return {
  //   question_id: 33,
  //   answer_id: answere,
  // };
};

// house have photovoltaic panels to produce electricity? right side (pv_panels)
const Q34_PV_Panels = (value: any) => {
  //console.log("pv_panels", value);
  let answere = 0;
  // 1
  if (parseInt(value) === 1 || value === "OUI") {
    answere = 112;
  }
  // 2
  else if (
    parseInt(value) === 0 ||
    value === "NON" ||
    value === "?" ||
    value === null
  ) {
    //console.log("raw");
    answere = 113;
  } else {
    answere = 0;
  }
  // return
  return answere;
};

// house have photovoltaic panels to produce electricity? right side (number_panels)
const Q36_Number_Of_Panels = (value: any) => {
  console.log("value-solar", value);
  let answere = 0;
  // 1
  if (parseInt(value) >= 1 && parseInt(value) <= 12) {
    answere = 119;
  }
  // 2
  else if (parseInt(value) >= 13 && parseInt(value) <= 20) {
    answere = 120;
  }
  // 3
  else if (parseInt(value) >= 21 && parseInt(value) <= 30) {
    answere = 121;
  }
  // 4
  else if (parseInt(value) > 30) {
    answere = 122;
  }
  // return
  return answere;
};

// house have photovoltaic panels to produce electricity? right side (number_panels)
const Q36_Number_Of_Panels_Calculations = (defaultHouseData: any) => {
  console.log("value-solar11", defaultHouseData);
  let answere = 0;
  let roof_area: any = parseInt(defaultHouseData?.surfsol) * 1.4;
  let solarPanels: any = Math.round((roof_area * 40) / 100 / 1.65);
  // 1
  if (parseInt(solarPanels) >= 1 && parseInt(solarPanels) <= 12) {
    answere = 119;
  }
  // 2
  else if (parseInt(solarPanels) >= 13 && parseInt(solarPanels) <= 20) {
    answere = 120;
  }
  // 3
  else if (parseInt(solarPanels) >= 21 && parseInt(solarPanels) <= 30) {
    answere = 121;
  }
  // 4
  else if (parseInt(solarPanels) > 30) {
    answere = 122;
  }
  // return
  return answere;
};

/* right side all questions with answeres on basis of selection* */
export const allLeftModalQuestionsHandler = (state: any) => {
  const data = {
    q5: Q5_Construction_Year(state?.construction_date),
    q6: 15,
    q7: Q7_Facades(state?.facades),
    q8: Q8_Floors(state?.floors),
    q9: 0,
    // q9: 24,
  };
  return data;
};

/* right side all questions with answeres on basis of selection* */
export const allRightModalQuestionsHandler = (
  state: any,
  showDefault: any,
  defaultHouseData: any
) => {
  const data = {
    q11: Q11_Vitrage(state?.window_type),
    q12: Q12_Roof(state?.roof_insulation_key),
    q13:
      state?.roof_insulation_key === "NON" ||
      state?.roof_insulation_key === "Yes, but only for a small part"
        ? 0
        : Q13_Roof_SubOptions(state?.roof_insulation_value),
    // q14: Q14_Roof_SubOptions(state?.roof_insulation_bonus),
    q16: Q16_Wall(state?.wall_insulation_key),
    q17:
      state?.wall_insulation_key === "NON" ||
      state?.wall_insulation_key === "Yes, but only for a small part"
        ? 0
        : Q17_Wall_SubOptions(state?.wall_insulation_value),
    // q18: Q18_Wall_SubOptions(state?.wall_insulation_bonus),
    // q19: Q19_Wall_SubOptions(state?.wall_is_insulation),
    // q21: 59,
    // commented --- waiting for alain logics to be updated
    q21: 59,
    q22: 0,
    // q21:
    //   showDefault === "default" ? 59 : Q21_Floor(state?.floor_insulation_key),
    // q22:
    //   showDefault === "default"
    //     ? 0
    //     : state?.floor_insulation_key === "NON" ||
    //       state?.floor_insulation_key === "Yes, but only for a small part"
    //     ? 0
    //     : Q22_Floor_SubOptions(state?.floor_insulation_value),
    // q23: Q23_Floor_SubOptions(state?.insulation_floor_bonus),
    // q24: Q24_Floor_SubOptions(state?.floor_is_insulation),
    // q25: "71,72",
    q26: Q26_Ventilation(state?.ventilation_type),
    q27: Q27_Drafts(state?.drafts),
    q28: Q28_Humidity(state?.humidity),
    q29: Q29_Heating(state?.heating_type),
    q30: 99,
    q32: Q32_Water_System(state?.hot_water),
    // q33: Q33_Solar_System(state?.solar_panels),
    q33: 111,
    q34:
      showDefault === "default"
        ? Q34_PV_Panels(defaultHouseData?.presents)
        : Q34_PV_Panels(state?.pv_panels),
    q35: 114,
    q36:
      showDefault === "default"
        ? defaultHouseData?.presents === "OUI" && state?.number_panels !== "?"
          ? Q36_Number_Of_Panels(state?.number_panels)
          : Q36_Number_Of_Panels_Calculations(defaultHouseData)
        : state?.pv_panels === "NON"
        ? // || state?.number_panels === 0
          Q36_Number_Of_Panels_Calculations(defaultHouseData)
        : Q36_Number_Of_Panels(state?.number_panels),
    // q37: "124,125,126",
  };
  return data;
};
