import React, { FC } from "react";
import TheProcess from "../../components/process/process";

interface ProcessInterface {}

const Process: FC<ProcessInterface> = () => {
  return (
    <div>
      <TheProcess />
    </div>
  );
};
export default Process;
