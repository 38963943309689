import React, { FC, useContext } from "react";
// import images
import Wall from "../../../assets/images/renovates/wall.jpg";
import WallDesign1 from "../../../assets/images/renovates/walldesign1.png";
import WallDesign2 from "../../../assets/images/renovates/walldesign2.png";
// import components
import { GlobalContext } from "../../../context/globalContext/globalContext";
import InputTabs from "../../../shared/autoComplete";
import { getValue } from "../../../utils/getValue";

interface WallInsulationInterface {}

const WallInsulation: FC<WallInsulationInterface> = () => {
  // global state
  const { state }: any = useContext(GlobalContext);
  console.log("getValue", getValue(state, 3, 0));
  return (
    <div>
      <div>
        <img src={Wall} alt="WallInsulation" />
      </div>
      <p
        className="text-black text-[20px] py-4"
        dangerouslySetInnerHTML={{
          __html: getValue(state, 3, 21)?.props?.content,
        }}
      ></p>
      <p
        className="text-[#6B7280] text-base py-4 "
        dangerouslySetInnerHTML={{
          __html: getValue(state, 3, 22)?.props?.content,
        }}
      />
      <div className="flex flex-col lg:flex-row py-8 space-x-5">
        {/* left */}
        <div className="w-full lg:w-[50%]">
          <p
            className="text-[#6B7280] text-lg py-2 font-semibold"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 3, 23)?.props?.content,
            }}
          />
          <p
            className="text-[#6B7280] text-base font-normal py-2"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 3, 24)?.props?.content,
            }}
          />
        </div>
        {/* right */}
        <div className="w-full lg:w-[50%]  flex flex-col lg:flex-row  items-center lg:items-start lg:justify-between">
          <div>
            <img src={WallDesign1} alt="WallDesign1" className="h-40" />
            <p
              className="text-[#6B7280] text-base font-normal text-center py-4"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 3, 26)?.props?.content,
              }}
            />
          </div>
          <div>
            <img src={WallDesign2} alt="WallDesign2" className="h-40" />
            <p
              className="text-[#6B7280] text-base font-normal text-center py-4"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 3, 74)?.props?.content,
              }}
            />
          </div>
        </div>
      </div>
      <div className="bg-[#007DDF] rounded-[20px] p-[40px] mb-14">
        <p
          className="text-white text-xl font-medium pb-6"
          dangerouslySetInnerHTML={{
            __html: getValue(state, 3, 79)?.props?.content,
          }}
        />
        <InputTabs showAutocomplete="renovation" />
      </div>
    </div>
  );
};
export default WallInsulation;
