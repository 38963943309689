import React, { FC, useContext, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { GlobalContext } from "../../context/globalContext/globalContext";
import AnimateHeight from "react-animate-height";
// import { ScreenSize } from "../../utils/windowDimension";
// import_components
import SelectorLanguage from "../../shared/languageSelector";
import InputHeader from "../autoComplete";
// import_Images
import Renocity from "../../assets/logo/renoCity.svg";
import Reno from "../../assets/logo/homeIcon.svg";
import Hamburger from "../../assets/logo/hamburger.png";
import HorizontalLines from "../../assets/logo/horizontal-lines.png";
import Arrow from "../../assets/images/header/arrow.png";
import { getValue } from "../../utils/getValue";

interface HeaderInterface {}

const Header: FC<HeaderInterface> = () => {
  // global_states
  const { state, dispatch }: any = useContext(GlobalContext);
  const Location = useLocation();
  // const screenSize = ScreenSize();
  const [height, setHeight]: any = useState(0);
  const Navigate = useNavigate();
  // show hide button handler
  const buttonHandler = () => {
    if (
      Location?.pathname === "/cookie/nl" ||
      Location?.pathname === "/cookie/fr" ||
      Location?.pathname === "/privacy/nl" ||
      Location?.pathname === "/privacy/fr"
    ) {
      return false;
    } else {
      return true;
    }
  };
  // show hide button handler
  const inputHandler = () => {
    if (
      Location?.pathname === "/" ||
      Location?.pathname === "/fr" ||
      Location?.pathname === "/nl" ||
      Location?.pathname === "/home" ||
      Location?.pathname === "/renovates" ||
      Location?.pathname === "/who-we-are" ||
      Location?.pathname === "/faq" ||
      Location?.pathname === "/contact-us" ||
      Location?.pathname === "/benefits" ||
      Location?.pathname === "/process"
    ) {
      return true;
    } else {
      return false;
    }
  };
  // gradient text show for cookieand privacy
  const gradientHandler = () => {
    if (
      Location?.pathname === "/cookie/nl" ||
      Location?.pathname === "/cookie/fr" ||
      Location?.pathname === "/privacy/nl" ||
      Location?.pathname === "/privacy/fr"
    ) {
      return true;
    } else {
      return false;
    }
  };
  // gradient text show for result page
  const resultGradientHandler = () => {
    if (Location?.pathname?.includes("/listing-renovate-detail")) {
      return true;
    } else {
      return false;
    }
  };
  // gradient text show for home
  const gradientHomeHandler = () => {
    if (
      Location?.pathname === "/" ||
      Location?.pathname === "/home" ||
      Location?.pathname === "/renovates" ||
      Location?.pathname === "/contact-us" ||
      Location?.pathname === "/faq" ||
      Location?.pathname === "/benefits" ||
      Location?.pathname === "/who-we-are" ||
      Location?.pathname === "/process"
    ) {
      return true;
    } else {
      return false;
    }
  };
  //language button show for cookie and policy page
  const languageButtonHandler = () => {
    if (
      // Location?.pathname === "/home" ||
      // Location?.pathname === "/renovates" ||
      // Location?.pathname === "/benefits" ||
      // Location?.pathname === "/who-we-are" ||
      // Location?.pathname === "/contact-us" ||
      // Location?.pathname === "/faq" ||
      // Location?.pathname === "/process" ||
      Location?.pathname === "/cookie/nl" ||
      Location?.pathname === "/cookie/fr" ||
      Location?.pathname === "/privacy/nl" ||
      Location?.pathname === "/privacy/fr"
    ) {
      return true;
    } else {
      return false;
    }
  };
  //language button show for cookie and policy page
  const paddingHandler = () => {
    if (
      Location?.pathname === "/" ||
      Location?.pathname === "/home" ||
      Location?.pathname === "/renovates" ||
      Location?.pathname === "/benefits" ||
      Location?.pathname === "/who-we-are" ||
      Location?.pathname === "/contact-us" ||
      Location?.pathname === "/faq" ||
      Location?.pathname === "/process" ||
      Location?.pathname?.includes("listing-renovate-detail")
    ) {
      return "py-4";
    } else {
      return "";
    }
  };
  //hamburger to show/hide for specific page
  const hamburgerHandler = () => {
    if (
      Location?.pathname === "/" ||
      Location?.pathname === "/home" ||
      Location?.pathname === "/renovates" ||
      Location?.pathname === "/benefits" ||
      Location?.pathname === "/who-we-are" ||
      Location?.pathname === "/contact-us" ||
      Location?.pathname === "/faq" ||
      Location?.pathname === "/process"
    ) {
      return true;
    } else {
      return false;
    }
  };
  // goBackHandler to last page
  const goBackHandler = () => {
    if (
      state?.previousPage[state?.previousPage.length - 1]?.includes("cookie")
    ) {
      // remove from array
      state?.previousPage.splice(-1);
      dispatch({
        type: "SET_PREVIOUS_PAGE",
        payload: [...state?.previousPage],
      });
      // navigate to previous page
      Navigate(`/cookie/${state?.language?.language?.toLowerCase()}`);
    } else if (
      state?.previousPage[state?.previousPage.length - 1]?.includes("privacy")
    ) {
      // remove from array
      state?.previousPage.splice(-1);
      dispatch({
        type: "SET_PREVIOUS_PAGE",
        payload: [...state?.previousPage],
      });
      // navigate to previous page
      Navigate(`/privacy/${state?.language?.language?.toLowerCase()}`);
    } else {
      Navigate(
        state?.previousPage?.length === 0
          ? "/"
          : state?.previousPage[state?.previousPage.length - 1]
      );
    }
  };
  return (
    <div className="contents">
      {/* container_div */}
      <div className={`mx-0 lg:mx-[10%] ${paddingHandler()}`}>
        {/* header_div */}
        <div className="py-1 px-6 md:px-[60px] lg:px-3">
          {/* header_image_text_Nl_div */}
          <div className="flex flex-row items-center justify-between">
            <div className="hidden lg:block lg:w-[55%] xl:w-[72%]">
              <div className=" flex items-center justify-between ">
                <div className="w-fit cursor-pointer ">
                  <div>
                    <img
                      src={Renocity}
                      alt="renoCity"
                      className="h-[34px]"
                      onClick={() => {
                        Navigate("/");
                        dispatch({
                          type: "SET_PREVIOUS_PAGE",
                          payload: [],
                        });
                      }}
                    />
                  </div>
                  {/* <p className="text-[#6B7180] text-[8px] md:text-[9px] 2xl:text-[10px] pr-4 opacity-100 text-end">
                    {state?.language?.landingPageTitle2}
                  </p> */}
                </div>
                {inputHandler() === true && (
                  <div className="hidden lg:block lg:px-[7px] xl:px-5 w-[70%]">
                    <InputHeader />
                  </div>
                )}
              </div>
            </div>
            <div className="block lg:hidden">
              <img
                src={Reno}
                alt="Reno"
                className="h-[39px]"
                onClick={() => Navigate("/")}
              />
            </div>
            {/* NL component_for_desktop */}
            <div className="hidden lg:block">
              <div className="flex items-center justify-end ">
                {languageButtonHandler() === true && (
                  <div className="">
                    <SelectorLanguage />
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center py-3 lg:py-0 ">
              {buttonHandler() === true && (
                <div className="pr-2.5">
                  <button
                    className="border border-[#007DDF] text-sm lg:text-base font-normal rounded-[4px] text-white bg-[#007DDF] px-[14px] py-2 lg:px-7 lg:py-2.5"
                    onClick={() =>
                      dispatch({
                        type: "SET_BOOK_AN_AUDIT_MODAL",
                        payload: true,
                      })
                    }
                  >
                    {getValue(state, 0, 151)?.props?.content}
                  </button>
                </div>
              )}

              {/* hamburger_for_mobile */}
              {hamburgerHandler() === true && (
                <div className="block  lg:hidden border-[2px] border-[#F0F0F0] rounded-[5px] p-1 shadow-sm">
                  <div
                    aria-expanded={height !== 0}
                    aria-controls="example-panel"
                    onClick={() => {
                      setHeight(height === 0 ? "auto" : 0);
                    }}
                  >
                    <img src={Hamburger} alt="hamburger" className="h-[22px]" />
                  </div>
                </div>
              )}
              <div className="block lg:hidden absolute top-[65px] left-0 right-0 bg-[#007DDF] w-full mt-[10px] z-10">
                <AnimateHeight
                  id="example-panel"
                  duration={500}
                  height={height} // see props documentation below
                >
                  <div className="p-7">
                    <div className="space-y-5">
                      <p
                        className={`text-sm text-white font-medium cursor-pointer ${
                          Location?.pathname === "/renovates" && "font-[700]"
                        }`}
                        onClick={() => {
                          Navigate("/renovates");
                          setHeight(height === 0 ? 0 : 0);
                        }}
                      >
                        {state?.homePageData?.pages?.length > 0 &&
                          state?.homePageData?.pages[3]?.title}
                      </p>
                      <p
                        className={`text-sm text-white font-medium cursor-pointer ${
                          Location?.pathname === "/benefits" && "font-[700]"
                        }`}
                        onClick={() => {
                          setHeight(height === 0 ? 0 : 0);
                          Navigate("/benefits");
                        }}
                      >
                        {state?.homePageData?.pages?.length > 0 &&
                          state?.homePageData?.pages[1]?.title}
                      </p>
                      <p
                        className={`text-sm text-white font-medium cursor-pointer ${
                          Location?.pathname === "/process" && "font-[700]"
                        }`}
                        onClick={() => {
                          setHeight(height === 0 ? 0 : 0);
                          Navigate("/process");
                        }}
                      >
                        {state?.homePageData?.pages?.length > 0 &&
                          state?.homePageData?.pages[2]?.title}
                      </p>
                      <p
                        className={`text-sm text-white font-medium cursor-pointer ${
                          Location?.pathname === "/who-we-are" && "font-[700]"
                        }`}
                        onClick={() => {
                          setHeight(height === 0 ? 0 : 0);
                          Navigate("/who-we-are");
                        }}
                      >
                        {state?.homePageData?.pages?.length > 0 &&
                          state?.homePageData?.pages[4]?.title}
                      </p>
                      <p
                        className={`text-sm text-white font-medium cursor-pointer ${
                          Location?.pathname === "/faq" && "font-[700]"
                        }`}
                        onClick={() => {
                          setHeight(height === 0 ? 0 : 0);
                          Navigate("/faq");
                        }}
                      >
                        {state?.homePageData?.pages?.length > 0 &&
                          state?.homePageData?.pages[5]?.title}
                      </p>
                      <p
                        className={`text-sm text-white font-medium cursor-pointer ${
                          Location?.pathname === "/contact-us" && "font-[700]"
                        }`}
                        onClick={() => {
                          setHeight(height === 0 ? 0 : 0);
                          Navigate("/contact-us");
                        }}
                      >
                        {state?.homePageData?.pages?.length > 0 &&
                          state?.homePageData?.pages[6]?.title}
                      </p>
                    </div>
                    <div className="pt-4">
                      <InputHeader />
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div>
                      <img
                        className="cursor-pointer"
                        onClick={() => {
                          setHeight(height === 0 ? "auto" : 0);
                        }}
                        src={HorizontalLines}
                        alt="HorizontalLines"
                      />
                    </div>
                  </div>
                </AnimateHeight>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* linear_gradient_div */}
      {gradientHandler() === true && (
        <div
          // onClick={() => goBackHandler()}
          className="w-full bg-gradient-to-r from-[#A3ED5E] to-[#0480DB] sticky top-0"
        >
          <div className="mx-0 lg:mx-[8%] px-4 py-2 md:px-2">
            <div className="flex items-center cursor-pointer w-fit">
              {/* <div>
                  <img src={Arrow} alt="arrow" className="h-5" />
                </div>
                <p className="text-lg text-white font-medium pl-2">
                  {state?.language?.headerGradientText}
                </p> */}
            </div>
          </div>
        </div>
      )}
      {/* result page header gradient */}
      {resultGradientHandler() === true && (
        <div
          onClick={() => Navigate("/")}
          className="w-full bg-gradient-to-r from-[#A3ED5E] to-[#0480DB] "
        >
          <div className="mx-[5%] lg:mx-[10%] px-4 py-2 md:px-2">
            <div className="flex items-center cursor-pointer w-fit">
              <div>
                <img src={Arrow} alt="arrow" className="h-5" />
              </div>
              <p className="text-sm text-white font-medium pl-2">
                {/* {state?.language?.headerGradientText} */}
                {getValue(state, 0, 152)?.props?.content}
              </p>
            </div>
          </div>
        </div>
      )}
      {gradientHomeHandler() === true && (
        <div className="w-full bg-gradient-to-r from-[#A3ED5E] to-[#0480DB] hidden lg:block">
          <div className="mx-0 lg:mx-[16%] px-4 py-2 md:px-2">
            <div className="flex items-center justify-between">
              <p
                className={`text-sm text-white font-medium cursor-pointer ${
                  Location?.pathname === "/renovates" && "font-[700]"
                }`}
                onClick={() => Navigate("/renovates")}
              >
                {state?.homePageData?.pages?.length > 0 &&
                  state?.homePageData?.pages[3]?.title}
              </p>
              <p
                className={`text-sm text-white font-medium cursor-pointer ${
                  Location?.pathname === "/benefits" && "font-[700]"
                }`}
                onClick={() => Navigate("/benefits")}
              >
                {state?.homePageData?.pages?.length > 0 &&
                  state?.homePageData?.pages[1]?.title}
              </p>
              <p
                className={`text-sm text-white font-medium cursor-pointer ${
                  Location?.pathname === "/process" && "font-[700]"
                }`}
                onClick={() => Navigate("/process")}
              >
                {state?.homePageData?.pages?.length > 0 &&
                  state?.homePageData?.pages[2]?.title}
              </p>
              <p
                className={`text-sm text-white font-medium cursor-pointer ${
                  Location?.pathname === "/who-we-are" && "font-[700]"
                }`}
                onClick={() => Navigate("/who-we-are")}
              >
                {state?.homePageData?.pages?.length > 0 &&
                  state?.homePageData?.pages[4]?.title}
              </p>
              <p
                className={`text-sm text-white font-medium cursor-pointer ${
                  Location?.pathname === "/faq" && "font-[700]"
                }`}
                onClick={() => Navigate("/faq")}
              >
                {state?.homePageData?.pages?.length > 0 &&
                  state?.homePageData?.pages[5]?.title}
              </p>
              <p
                className={`text-sm text-white font-medium cursor-pointer ${
                  Location?.pathname === "/contact-us" && "font-[700]"
                }`}
                onClick={() => Navigate("/contact-us")}
              >
                {state?.homePageData?.pages?.length > 0 &&
                  state?.homePageData?.pages[6]?.title}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Header;
