import React, { FC, Fragment } from "react";
import Avatar from "../../shared/Avatar/Avatar";
import informationImg from "../../assets/images/resultPage2/renovations_images/information.png";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
// import SecondaryButton from "shared/Button/ButtonSecondary";
import Boiler from "../../assets/images/resultPage2/renovations_images/boiler_gas.png";
import WindowDouble from "../../assets/images/resultPage2/renovations_images/window_double.png";
import Glazing from "../../assets/images/resultPage2/renovations/WM_38_RW_A_01.gif";
import SolarPanel from "../../assets/images/resultPage2/renovations/solar_panels@2x.png";
import HeatPump from "../../assets/images/resultPage2/renovations/heatpump@2x.png";
import Roof_Attic from "../../assets/images/resultPage2/renovations/roof_attic@2x.png";
import Roof_Exterior from "../../assets/images/resultPage2/renovations_images/roof_exterior.png";
import Roof_Interior from "../../assets/images/resultPage2/renovations_images/roof_interior.png";
import VentilationC from "../../assets/images/resultPage2/renovations/ventilation_c@2x.png";
import VentilationD from "../../assets/images/resultPage2/renovations_images/ventilation_d_2.png";
import Wall_Cavity from "../../assets/images/resultPage2/renovations_images/wall_cavity.png";
import Wall_Exterior from "../../assets/images/resultPage2/renovations_images/wall_exterior.png";
import SelectedImage from "../../assets/images/resultPage2/banner-selected.svg";
import { pricesDotHandler } from "../../containers/result/constants";
import { Spin } from "antd";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

export interface SectionHeroProps {
  className?: string;
  getResultContent?: any;
  resultPageContent?: any;
  subsidies?: number;
  renovations?: any;
  onChange?: any;
  showAllRenovations?: any;
  custom?: any;
  openModal?: any;
  setRenovationModalContent?: any;
  selectCardHandler?: any;
  epcA?: any;
  setActiveRenovationToolTip?: any;
  selectedPack?: any;
  isCallWaitng?: any;
  subtest?: any;
  selectedPackRenovations?: any;
  selectedPackData?: any;
  renovationLoader?: any;
}

const SectionOurRecomendation: FC<SectionHeroProps> = ({
  className,
  getResultContent,
  custom,
  renovations,
  subsidies = 1,
  onChange,
  showAllRenovations,
  openModal,
  setRenovationModalContent,
  selectCardHandler,
  setActiveRenovationToolTip,
  epcA,
  selectedPack,
  isCallWaitng,
  subtest,
  selectedPackRenovations,
  selectedPackData,
  renovationLoader,
}) => {
  const getNames = (key: any) => {
    //console.log("key", key);
    switch (key) {
      case "window":
        return getResultContent(230);
      case "wall":
        return getResultContent(29);
      case "roof":
        return getResultContent(33);
      case "ventc+":
        return getResultContent(34);
      case "ventd":
        return getResultContent(35);
      case "condgas":
        return getResultContent(274);
      case "heatpump":
        return getResultContent(36);
      case "glazing":
        return getResultContent(27);
      case "solar":
        return getResultContent(30);
      default:
        return "";
    }
  };

  const getRenovationPrices = (key: any) => {
    // let investment_cost: any = subtest && JSON.parse(subtest);
    switch (key) {
      case "window":
        return selectedPackData !== undefined && selectedPackData !== null
          ? selectedPackData?.window_cost
          : 0;
      case "glazing":
        return selectedPackData !== undefined && selectedPackData !== null
          ? selectedPackData?.glazing_cost
          : 0;
      case "wall":
        return selectedPackData !== undefined && selectedPackData !== null
          ? selectedPackData?.wall_cost
          : 0;
      case "roof":
        return selectedPackData !== undefined && selectedPackData !== null
          ? selectedPackData?.roof_cost
          : 0;
      case "ventc+":
        return selectedPackData !== undefined && selectedPackData !== null
          ? selectedPackData?.ventc_cost
          : 0;
      case "ventd":
        return selectedPackData !== undefined && selectedPackData !== null
          ? selectedPackData?.ventd_cost
          : 0;
      case "condgas":
        return selectedPackData !== undefined && selectedPackData !== null
          ? selectedPackData?.condgas_cost
          : 0;
      case "heatpump":
        return selectedPackData !== undefined && selectedPackData !== null
          ? selectedPackData?.heatpump_cost
          : 0;
      case "solar":
        return selectedPackData !== undefined && selectedPackData !== null
          ? selectedPackData?.solar_cost
          : 0;
      default:
        return "";
    }
  };

  const getRenovationSubsidy = (key: any) => {
    // let investment_cost: any = subtest && JSON.parse(subtest);
    switch (key) {
      case "window":
        return selectedPackData?.window_subsidy;
      case "wall":
        return selectedPackData?.wall_subsidy;
      case "roof":
        return selectedPackData?.roof_subsidy;
      case "ventc+":
        return selectedPackData?.ventc_subsidy;
      case "ventd":
        return selectedPackData?.ventd_subsidy;
      case "condgas":
        return selectedPackData?.condgas_subsidy;
      case "heatpump":
        return selectedPackData?.heatpump_subsidy;
      case "glazing":
        return selectedPackData?.glazing_subsidy;
      case "solar":
        return selectedPackData?.solar_subsidy;
      default:
        return "";
    }
  };

  const getImages = (key: any) => {
    switch (key) {
      case "window":
        return WindowDouble;
      case "wall":
        return Wall_Exterior;
      case "roof":
        return Roof_Exterior;
      case "ventc+":
        return VentilationC;
      case "ventd":
        return VentilationD;
      case "condgas":
        return Boiler;
      case "heatpump":
        return HeatPump;
      case "glazing":
        return Glazing;
      case "solar":
        return SolarPanel;
      default:
        return "";
    }
  };

  const activeDots = (value: number) => {
    let allDots = [];
    for (let i = 1; i < 6; i++) {
      if (i <= value) {
        allDots?.push({
          id: i,
          show: "active",
        });
      } else {
        allDots?.push({
          id: i,
          show: "inActive",
        });
      }
    }
    return (
      <>
        {allDots?.map((single: any, i: any) => (
          <div className="flex justify-between my-1 mx-[3px]" key={i}>
            {single?.show === "active" ? (
              <span className="bg-[#75CE82] rounded-full w-2.5 h-2.5"></span>
            ) : (
              <span className="bg-[#E3E7EB] rounded-full w-2.5 h-2.5"></span>
            )}
          </div>
        ))}
      </>
    );
  };

  const getComfort = (key: any): any => {
    switch (key) {
      case "solar":
        return activeDots(0);
      case "window":
        return activeDots(4);
      case "glazing":
        return activeDots(4);
      case "wall":
        return activeDots(2);
      case "roof":
        return activeDots(3);
      case "ventc+":
        return activeDots(2);
      case "ventd":
        return activeDots(4);
      case "condgas":
        return activeDots(1);
      case "heatpump":
        return activeDots(1);
      default:
        return activeDots(0);
    }
  };

  const getComplexity = (key: any): any => {
    switch (key) {
      case "solar":
        return activeDots(1);
      case "window":
        return activeDots(3);
      case "glazing":
        return activeDots(1);
      case "wall":
        return activeDots(4);
      case "roof":
        return activeDots(4);
      case "ventc+":
        return activeDots(4);
      case "ventd":
        return activeDots(5);
      case "condgas":
        return activeDots(1);
      case "heatpump":
        return activeDots(2);
      default:
        return activeDots(0);
    }
  };

  const getSavings = (key: any): any => {
    switch (key) {
      case "solar":
        return activeDots(5);
      case "window":
        return activeDots(2);
      case "glazing":
        return activeDots(2);
      case "wall":
        return activeDots(3);
      case "roof":
        return activeDots(4);
      case "ventc+":
        return activeDots(1);
      case "ventd":
        return activeDots(3);
      case "condgas":
        return activeDots(4);
      case "heatpump":
        return activeDots(4);
      default:
        return activeDots(0);
    }
  };

  const renovationPricesHandler = (value: any) => {
    let price = pricesDotHandler(value);
    return price;
  };

  // activeimageHandler to show active image
  const activeImageHandler = (renovation: string) => {
    if (selectedPackData?.combo?.includes(renovation)) {
      return 1;
    } else {
      return 0;
    }
  };

  // const mapData = selectedPackRenovations !== undefined &&
  // selectedPackRenovations !== null &&
  // selectedPackRenovations &&
  // Object.keys(selectedPackRenovations)?.length !== 0 ? (
  //   selectedPackRenovations &&
  //   selectedPackRenovations?.combo
  //     ?.split?.("_") :

  console.log("selectedPackRenovations123", selectedPackRenovations);

  return (
    <div className="!space-y-6 xl:mt-0 mt-10 w-full border border-[#E3E7EB] mr-10 rounded-xl p-5">
      {/* 1 */}
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          {/* a */}
          {selectedPack === "packA" && (
            <h2 className="font-poppins text-[18px] text-black flex items-center font-bold">
              {getResultContent(23)}
            </h2>
          )}
          {/* b */}
          {selectedPack === "packB" && (
            <h2 className="font-poppins text-[18px] text-black flex items-center font-bold">
              {getResultContent(237)}
            </h2>
          )}
          {/* c */}
          {selectedPack === "customPack" && (
            <h2 className="font-poppins text-[18px] text-black flex items-center font-bold">
              {getResultContent(238)}
            </h2>
          )}
          {selectedPack === "baseOffer" && (
            <h2 className="font-poppins text-[18px] text-black flex items-center font-bold">
              {getResultContent(238)}
            </h2>
          )}
          <span
            className="px-3 flex justify-center cursor-pointer"
            onClick={() => {
              openModal("pack-info");
            }}
          >
            <InfoCircleOutlined className="text-[#007DDF]" />
          </span>
        </div>
        {isCallWaitng && (
          <div className="flex">
            <Spin indicator={antIcon} />
          </div>
        )}
      </div>
      {/*<div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>*/}
      {!renovationLoader ? (
        selectedPackRenovations !== undefined &&
        selectedPackRenovations !== null &&
        selectedPackRenovations &&
        selectedPackRenovations?.combo !== "" &&
        selectedPackRenovations?.combo !== undefined &&
        Object.keys(selectedPackRenovations)?.length !== 0 ? (
          selectedPackRenovations &&
          selectedPackRenovations?.combo
            ?.split?.("_")
            ?.map((renovations: any, index: any) => (
              <Fragment key={index}>
                {/* for desktop */}
                <div className={`border flex border-[#E3E7EB] rounded-xl`}>
                  <div className="absolute w-[95px] z-10 rounded-tl-xl">
                    {/* {renovations?.active === 1 ? ( */}
                    {activeImageHandler(renovations) === 1 ? (
                      <img src={SelectedImage} alt="" />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="w-full flex lg:flex-row flex-col">
                    {/* left side  */}
                    <Avatar
                      sizeClass="md:h-40 w-1/2 md:w-40"
                      imgUrl={getImages(renovations)}
                    />
                    {/* right side */}
                    <div className="w-full pl-4 p-5 ">
                      {/* header */}
                      <div className="w-full flex lg:flex-row flex-col justify-between">
                        {/* <h6 className="font-poppins md:text-[22px] text-[18px] text-black flex items-center font-bold"> */}
                        <h6 className="font-poppins md:text-[22px] text-[18px] text-black flex  font-bold renovation-title">
                          {getNames(renovations)}
                          {/* {getNames(renovations?.renovation)} */}
                          <span
                            className="px-3 flex justify-center cursor-pointer pt-2"
                            onClick={() => {
                              setActiveRenovationToolTip(renovations);
                              setRenovationModalContent(renovations);
                              openModal("renovation-information");
                            }}
                          >
                            <InfoCircleOutlined className="text-[#007DDF] text-[16px]" />
                          </span>
                        </h6>
                        <div className="flex flex-col lg:px-2 pt-2 lg:pt-0">
                          <div className="font-poppins md:text-[22px] text-[18px] text-black flex lg:self-end font-bold">
                            {/* {renovationPricesHandler(
                          parseInt(renovations?.investment_cost)
                        )} */}
                            {renovationPricesHandler(
                              getRenovationPrices(renovations)
                            )}
                            €
                          </div>
                          <div className="flex lg:justify-end font-poppins text-[14px] text-[#A1A7AB] justify-end text-right">
                            {getResultContent(41)}
                          </div>
                        </div>
                      </div>
                      {/* <div>
                        <span className="flex lg:justify-end font-poppins text-[14px] text-[#A1A7AB]">
                          {getResultContent(41)}
                        </span>
                      </div> */}
                      {/* content */}
                      <div className="flex justify-start lg:mt-[-10px]">
                        {/* left side */}
                        {/* {subsidies == 1 && ( */}
                        <div className="flex pt-4 lg:pt-0 lg:w-auto w-full">
                          <h6 className="m-0 font-poppins text-[16px] text-[#455259] lg:w-auto w-[50%]">
                            {getResultContent(37)}
                          </h6>
                          <span className="font-poppins text-[16px] text-[#455259] font-bold px-2">
                            jsq à -
                            {renovationPricesHandler(
                              getRenovationSubsidy(renovations)
                            )}{" "}
                            €
                          </span>
                        </div>
                        {/* )} */}
                      </div>
                      <div className="flex lg:flex-row flex-wrap justify-between flex-col pt-4 md:pt-8 ">
                        {/* left side */}
                        <div className="">
                          <div className="flex items-center pt-4 md:pt-0">
                            <h6 className="font-poppins text-[14px] text-[#455259] lg:w-auto w-[50%]">
                              {getResultContent(38)}
                            </h6>
                            <div className="flex items-center">
                              <div className="flex items-center pl-1">
                                {getSavings(renovations)}
                                {/* {getSavings(renovations?.renovation)} */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="border border-[#E3E7EB] h-6 hidden lg:block "></div>
                        <div className="flex pt-4 md:pt-0">
                          <h6 className="font-poppins text-[14px] text-[#455259] lg:w-auto w-[50%]">
                            {getResultContent(39)}
                          </h6>
                          <div className="flex items-center pl-1">
                            {getComplexity(renovations)}
                            {/* {getComplexity(renovations?.renovation)} */}
                          </div>
                        </div>
                        <div className="border border-[#E3E7EB] h-6 hidden lg:block"></div>
                        <div className="flex pt-4 md:pt-0">
                          <h6 className="font-poppins text-[14px] text-[#455259] lg:w-auto w-[50%]">
                            {getResultContent(40)}
                          </h6>
                          <div className="flex items-center">
                            <div className="flex items-center pl-1">
                              {getComfort(renovations)}
                              {/* {getComfort(renovations?.renovation)} */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {selectedPack === "customPack" && (
                        <React.Fragment>
                          {activeImageHandler(renovations) === 1 ? (
                            <div className="flex lg:justify-end justify-start pt-3">
                              <button
                                className={
                                  "bg-[#007DDF] text-white border border-[#007DDF2B] rounded-[4px] px-2 py-1 text-[16px]"
                                }
                                onClick={() => {
                                  //  sending 0 to deselect it
                                  selectCardHandler(renovations, 0);
                                }}
                              >
                                {getResultContent(280)}
                              </button>
                            </div>
                          ) : (
                            <div className="flex lg:justify-end justify-start pt-3">
                              <button
                                className={
                                  "bg-white text-[#007DDF] border border-[#007DDF2B] rounded-[4px] px-2 py-1 text-[16px]"
                                }
                                onClick={() => {
                                  //  sending 1 to activate it
                                  selectCardHandler(renovations, 1);
                                }}
                              >
                                {getResultContent(279)}
                              </button>
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
                {/* for mobile */}
                {/*<div*/}
                {/*  className={`block lg:hidden`}*/}
                {/*  key={index}*/}
                {/*>*/}
                {/*  <div className="absolute w-[95px] z-10 rounded-tl-xl">*/}
                {/*    {renovations?.active === 1 ? (*/}
                {/*      <img src={SelectedImage} alt="" />*/}
                {/*    ) : (*/}
                {/*      ""*/}
                {/*    )}*/}
                {/*  </div>*/}
                {/*  <div className="w-full flex p-5">*/}
                {/*    /!* left side  *!/*/}
                {/*    <Avatar*/}
                {/*      sizeClass="h-32 w-32"*/}
                {/*      radius="rounded"*/}
                {/*      imgUrl={getImages(renovations?.renovation)}*/}
                {/*    />*/}
                {/*    /!* right side *!/*/}
                {/*    <div className="w-full pl-2">*/}
                {/*      /!* header *!/*/}
                {/*      <div className="w-full">*/}
                {/*        <h6 className="font-poppins text-[22px] text-black flex items-center font-bold">*/}
                {/*          {getNames(renovations?.renovation)}*/}
                {/*          <span*/}
                {/*            className="px-3 cursor-pointer"*/}
                {/*            onClick={() => {*/}
                {/*              setActiveRenovationToolTip(renovations?.renovation);*/}
                {/*              setRenovationModalContent(renovations);*/}
                {/*              openModal("renovation-information");*/}
                {/*            }}*/}
                {/*          >*/}
                {/*            <Avatar*/}
                {/*              imgUrl={informationImg}*/}
                {/*              sizeClass="h-5 w-5"*/}
                {/*              radius="rounded-full"*/}
                {/*            />*/}
                {/*          </span>*/}
                {/*        </h6>*/}
                {/*        <div className="flex items-center justify-between text-sm">*/}
                {/*          <span className="text-sm text-gray-400">*/}
                {/*            {getResultContent(41)}*/}
                {/*          </span>*/}
                {/*          <span className="text-lg text-blue-500 font-medium fs_22 text-right">*/}
                {/*            {renovationPricesHandler(*/}
                {/*              parseInt(renovations?.investment_cost)*/}
                {/*            )}*/}
                {/*            €*/}
                {/*          </span>*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*      /!* content *!/*/}
                {/*      <div className="flex flex-row ">*/}
                {/*        /!* left side *!/*/}
                {/*        <div className="py-2">*/}
                {/*          <div className="flex">*/}
                {/*            <h6 className="text-sm m-0">*/}
                {/*              {getResultContent(37)} :*/}
                {/*            </h6>*/}
                {/*            <span className="text-sm px-2">*/}
                {/*              {renovationPricesHandler(*/}
                {/*                parseInt(renovations.total_subsidy)*/}
                {/*              )}*/}
                {/*              €*/}
                {/*            </span>*/}
                {/*          </div>*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*      <div className="py-4">*/}
                {/*        /!* left side *!/*/}
                {/*        <div className="py-2">*/}
                {/*          <div className="flex items-center ">*/}
                {/*            <h6 className="text-sm m-0">*/}
                {/*              {getResultContent(38)} :*/}
                {/*            </h6>*/}
                {/*            <div className="flex items-center px-2">*/}
                {/*              <div className="flex items-center pl-2">*/}
                {/*                {getSavings(renovations?.renovation)}*/}
                {/*              </div>*/}
                {/*            </div>*/}
                {/*          </div>*/}
                {/*        </div>*/}
                {/*        <div className="py-2">*/}
                {/*          <div className="flex">*/}
                {/*            <h6 className="text-sm m-0">*/}
                {/*              {getResultContent(39)} :*/}
                {/*            </h6>*/}
                {/*            <div className="flex items-center px-2">*/}
                {/*              {getComplexity(renovations?.renovation)}*/}
                {/*            </div>*/}
                {/*          </div>*/}
                {/*        </div>*/}
                {/*        <div className="py-2">*/}
                {/*          <div className="flex">*/}
                {/*            <h6 className="text-sm m-0">*/}
                {/*              {getResultContent(40)} :*/}
                {/*            </h6>*/}
                {/*            <div className="flex items-center px-2">*/}
                {/*              <div className="flex items-center pl-2">*/}
                {/*                {getComfort(renovations?.renovation)}*/}
                {/*              </div>*/}
                {/*            </div>*/}
                {/*          </div>*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*      {custom && (*/}
                {/*        <React.Fragment>*/}
                {/*          {renovations?.active === 1 ? (*/}
                {/*            <div className="flex justify-end px-4">*/}
                {/*              <button*/}
                {/*                className={"outline-secondary-button"}*/}
                {/*                onClick={() =>*/}
                {/*                  selectCardHandler(renovations, index, 0)*/}
                {/*                }*/}
                {/*              >*/}
                {/*                {getResultContent(280)}*/}
                {/*              </button>*/}
                {/*            </div>*/}
                {/*          ) : (*/}
                {/*            <div className="flex justify-end px-4">*/}
                {/*              <button*/}
                {/*                className={"outline-primary-button"}*/}
                {/*                onClick={() =>*/}
                {/*                  selectCardHandler(renovations, index, 1)*/}
                {/*                }*/}
                {/*              >*/}
                {/*                {getResultContent(279)}*/}
                {/*              </button>*/}
                {/*            </div>*/}
                {/*          )}*/}
                {/*        </React.Fragment>*/}
                {/*      )}*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </Fragment>
            ))
        ) : (
          <p className="py-2 text-center">{getResultContent(292)}</p>
        )
      ) : (
        <div className="flex justify-center">
          <Spin />
        </div>
      )}
      {/* 5 */}
      {/*<div className="w-full border-b border-neutral-100 dark:border-neutral-700" />*/}
    </div>
  );
};

export default SectionOurRecomendation;
