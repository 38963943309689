import CheckImage from "../../assets/images/result/resultPage/check_3.png";
import { Button } from "antd";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const Modal = (props: any) => {
  let { getResultContent, closeModal, loader } = props;
  const navigate = useNavigate();
  return (
    <div className="absolute w-full z-40 top-0">
      <div className="h-full fixed w-full background_rgba flex justify-center refinement_house_modal_one_container">
        <div className="overflow-y-scroll py-8 w-full flex justify-center bg-[rgba(0,0,0,0.6)]">
          <div className="inline-flex h-fit flex-col justify-end w-full lg:w-3/4 text-left align-middle transition-all transform rounded-2xl bg-white dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl">
            <div className="px-8 py-8 flex flex-col items-center">
              <div className="px-4 text-center">
                <p className="text-center text-lg text-black-500">
                  {getResultContent && getResultContent(231)}
                </p>
              </div>
              {loader ? (
                <div className="pt-4">
                  <Spin indicator={antIcon} />
                </div>
              ) : (
                <div className="pt-4">
                  <img src={CheckImage} alt="" />
                </div>
              )}
            </div>
            {/* text-div-after-added */}
            <div className="py-8"></div>
            {/* line */}
            <>
              <div className="border border-[#F0F0F0]"></div>
              <div className="px-6 py-5 flex justify-between text-center items-center">
                <div>
                  <p
                    className="text-base font-normal text-[#989898] underline cursor-pointer"
                    onClick={() => navigate("/home")}
                  >
                    {!loader && getResultContent && getResultContent(422)}
                  </p>
                </div>
                {!loader && (
                  <button
                    onClick={closeModal}
                    className="text-sm font-normal text-white py-2.5 px-7 bg-[#007DDF] rounded border-[#007DDF] "
                  >
                    {getResultContent && getResultContent(423)}
                  </button>
                )}
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Modal;
