import React, { FC, useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
// import Packs from "components/Packs/Packs";
import {
  GetContentByService,
  GetUserPacksData,
} from "../../services/ResultPage";
import {
  GetBuildingInfo,
  GenerateTempIdAgainsUserService,
  GenerateTempIdAgainsBuildingService,
  getbuildinginfouser,
  GetPacksData,
} from "../../services/ResultPage";
//
import {
  leftModalKeysHandler,
  rightModalKeysHandler,
  allLeftModalQuestionsHandler,
  allRightModalQuestionsHandler,
} from "./questions";
import SectionHouse from "../../components/result-sub/sectionHouse";
import SectionDiagnosis from "../../components/result-sub/sectionDianosis";
import Packs from "../../components/result-sub/packs";
import Subsides from "../../components/result-sub/SubSides";
import SectionOurRecomendation from "../../components/result-sub/sectionOurRecomendation";
import SectionFixedSideBar from "../../components/result-sub/sectionFixedSideBar";
import YourNextStep from "../../components/result-sub/youNextStep";
// import ResultBanner from "../../components/result/resultBanner";
// import ChooseRenovationComponent from "../../components/result/chooseRenovation";
// import OurReacommended from "../../components/result/ourRecommended";
// import SectionHouse from "components/SectionHouse/SectionHouse";
// import SectionDiagnosis from "components/SectionOurDiagnosis/SectionOurDiagnosis";
// import SectionOurRecomendation from "components/SectionOurRecomendation/SectionOurRecomendation";
// import SectionResultDescription from "components/SectionResultDescription/SectionResultDescription";
// import SectionFixedSideBar from "components/SectionFixedSideBar/SectionFixedSideBar";
import SectionRenovationLoan from "../../components/result-sub/SectionRenovationLoan";
import Modal from "../../shared/Modal/index";
// import SectionRenovationSteps from "components/SectionRenovationSteps/SectionRenovationSteps";
import { GetUserStatus } from "../../utils/renovations";
import { defaultRenovations } from "../../utils/renovations";
// import { withRouter } from "react-router-dom";
import cookie from "react-cookies";
import jwt_decode from "jwt-decode";
// import ResultHeader from "shared/Header/ResultHeader";
import { Helmet } from "react-helmet";
import {
  renovationPostProcessing,
  renovationPostProcessingForPackB,
  renovationsInvestmentPrice,
  customSolarRenovationPostProcessing,
  existRenovationHandler,
  solarPriceHandler,
} from "./constants";
import CallZeroModal from "../../shared/Modal/CallZeroModal";
import { Markup } from "interweave";
import Call2And3Modal from "../../shared/Modal/Call2And3Modal";
import { useParams } from "react-router-dom";
import JsonData from "./withsubsidy.json";

export interface ListingStayDetailPageProps {
  // isPreviewMode?: boolean;
  // history?: any;
  // match?: any;
}

const ListingStayDetailPage = ({}: // isPreviewMode,
// history,
// match,
any) => {
  const params = useParams();
  let match = {
    params: {
      id: params?.id,
    },
  };
  const [loader, setLoader] = useState(true);
  const [glazing, setGlazing] = useState("");

  const [isCallWaitng, setIsCallWaiting] = useState(false);
  const [isGenerateTempId, setIsGenerateTempId] = useState(false);
  const [houseCallRecordUpdated, setHouseCallRecordUpdated] = useState({
    leftSide: "",
    rightSide: "",
  });
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [activeModal, setActiveModal] = useState("callZero");
  const [renovationModalContent, setRenovationModalContent] = useState({});
  const [resultPageContent, setResultPageContent]: any = useState([]);
  // state to store userData
  const [userData, setUserData] = useState();
  // state to store data of houseDetails of left and right side of section1
  const [houseDetails, setHouseDetails]: any = useState();
  // state to store userCurrentStatus
  const [userStatus, setUserStatus]: any = useState([]);
  // state to store api repose data
  const [allRenovationRecord, setAllRenovationsRecord]: any = useState();
  // state to show all renovations records (pack label a, b and custom)
  const [renovations, setRenovations]: any = useState([]);
  const [userSelectedData, setAllUserSelectedData]: any = useState([]);
  const [futureEpc, setFutureEpc] = useState("1");
  const [custom, setCustom] = useState(false);
  const [packLabel, setPackLabel] = useState(false);
  // state to add epc a through index
  const [epcA, setEpcA] = useState([]);
  // state to store user login info
  const [loggedInUser, setLoggedInUser]: any = useState();
  const [callTwoTemplate, setCallTwoTemplate] = useState({});
  const [callThreeTemplate, setCallThreeTemplate] = useState({});
  const [userBuildingId, setUserBuildingId] = useState({});
  // state to show active tool tip record
  const [activeRenovationToolTip, setActiveRenovationToolTip] = useState();
  // state to store data after refinement of left and right side modal
  const [refinementCall3Record, setRefinementCall3Record]: any = useState({});
  const [refinementCall2Record, setRefinementCall2Record]: any = useState({});
  // states for model
  const [userModalRenovations, setUserRenovationsModal] = useState([]);
  // state to store data for call 2 and 3
  const [userExistRenovations, setUserExistRenovations] = useState([]);
  // state to store total value for loan
  const [totalValue, setTotalValue] = useState();
  const [selectedInd, setSelectedInd] = useState(0);
  // state to show five stars if custom is selected
  const [customStars, setCustomStars]: any = useState(0);
  const [customPackSelected, setCustomPackSelected]: any = useState();
  // state to show selectedPack
  const [selectedPack, setSelectedPack] = useState("packB");
  // state to save selectedrecords to ssave it with user
  const [selectedUserCombination, setSelectedUserCombination]: any = useState();
  // state to store data after refinement
  const [downloadRecord, setDownloadedRecord]: any = useState();
  const [allCustomCombination, setAllCustomCombination] = useState([]);
  const [isAdminView, setIsAdminView]: any = useState(false);

  const [epc_value_current, setepc_value_current]: any = useState(false);
  const [epc_value_current_epc, setepc_value_current_epc]: any =
    useState(false);
  const [currenSituationDataBuild, setcurrenSituationDataBuild]: any =
    useState(false);
  const [subsidies, setSubsidies]: any = useState(1);
  let subtest: any = localStorage.getItem("test_cost_data");

  // (based on old data logics for v2 needs to be updated again)
  const baseOfferRenovation = () => {};

  // method to change pack label using switch button - (based on old data logics for v2 needs to be updated again)
  const onChange = (checked: any) => {};

  // Method to return result page value through index. )(no need to update for this new version)
  const getResultContent = (index: number): any => {
    return resultPageContent?.length !== 0 &&
      resultPageContent[index]?.value ? (
      <Markup content={resultPageContent[index]?.value} />
    ) : (
      ""
    );
  };

  // v2 renovations pattern
  const sortOrder = [
    "window",
    "glazing",
    "wall",
    "roof",
    "heatpump",
    "condgas",
    "ventd",
    "ventc+",
    "solar",
  ];

  // method to show all renovations (based on old data logics for v2 needs to be updated again)
  const showAllRenovations = (value: any) => {};

  // model for v2 as well.
  const openModal = (activeModal: any) => {
    setIsOpenModal(true);
    setActiveModal(activeModal);
  };

  // model for v2 as well.
  const closeModal = (e: any) => {
    if (e == false) {
    } else {
      setIsOpenModal(false);
      setActiveModal("");
    }
  };

  // v2 function to sort combination
  const sortedRenovation = (renovations: any) => {
    // method to sort array
    let sortedArray: any = [];
    const orderForIndexVals = sortOrder.slice(0).reverse();
    return (sortedArray = renovations.sort((a: any, b: any) => {
      const aIndex = -orderForIndexVals.indexOf(a);
      const bIndex = -orderForIndexVals.indexOf(b);
      return aIndex - bIndex;
    }));
  };

  // address handler v2
  // useEffect(() => {
  //   if (activeModal === "call2&3") {
  //     const timer = setTimeout(() => {
  //       setActiveModal("");
  //     }, 3000);
  //     return () => clearTimeout(timer);
  //   }
  // }, [activeModal]);

  // v2 handler and state to show renovations
  const [selectedPackRenovations, setSelectedRenovationPack]: any = useState();
  const [selectedPackData, setSelectedPackData]: any = useState({});
  const [allPacksCombination, setAllPacksCombinations] = useState([]);
  const [renovationLoader, setRenovationLoader] = useState(true);
  const [defaultData, setDefaultData] = useState(false);

  // handler to get keys of both modal left and right side for question to get their answeres
  const getModalKeysHandler = (
    key: any,
    refinementCall3: any,
    refinementCall2: any
  ) => {
    let leftKeys = {};
    let rightKeys = {};
    // if for default time to get keys
    if (key === "default") {
      // leftkeys
      leftKeys = leftModalKeysHandler(
        refinementCall3Record &&
          Object.keys(refinementCall3Record)?.length !== 0 &&
          refinementCall3Record !== null &&
          refinementCall3Record !== undefined
          ? refinementCall3Record
          : houseDetails
      );
      // rightkeys
      rightKeys = rightModalKeysHandler(
        houseDetails,
        refinementCall2Record
        // getResultContent
      );
    }
    // else for after data in model for api.
    else {
      // leftkeys
      leftKeys = leftModalKeysHandler(
        refinementCall3 &&
          Object.keys(refinementCall3)?.length !== 0 &&
          refinementCall3 !== null &&
          refinementCall3 !== undefined
          ? refinementCall3
          : houseDetails
      );
      // rightkeys
      rightKeys = rightModalKeysHandler(
        houseDetails,
        refinementCall2
        // getResultContent
      );
    }

    // allRightModalQuestionsHandler has 4 params (rightKeys, key = default , q34key & default => only for default option)
    if (
      leftKeys &&
      Object.keys(leftKeys).length > 0 &&
      rightKeys &&
      Object.keys(rightKeys).length > 0
    ) {
      const data = {
        ...allLeftModalQuestionsHandler(leftKeys),
        ...allRightModalQuestionsHandler(rightKeys, key, houseDetails),
      };
      return data;
    }
  };

  // handler to find combination for custom pack (default)
  const comboHandler = (allData: any) => {
    let combo = "";
    if (allData?.find((item: any) => item?.combo.includes("window"))) {
      combo = combo !== "" ? combo + "_window" : "window";
    }
    if (allData?.find((item: any) => item?.combo.includes("window"))) {
      combo = combo !== "" ? combo + "_glazing" : "glazing";
    }
    if (allData?.find((item: any) => item?.combo.includes("wall"))) {
      combo = combo !== "" ? combo + "_wall" : "wall";
    }
    if (allData?.find((item: any) => item?.combo.includes("roof"))) {
      combo = combo !== "" ? combo + "_roof" : "roof";
    }
    if (allData?.find((item: any) => item?.combo.includes("ventd"))) {
      combo = combo !== "" ? combo + "_ventd" : "ventd";
    }
    if (allData?.find((item: any) => item?.combo.includes("ventc+"))) {
      combo = combo !== "" ? combo + "_ventc+" : "ventc+";
    }
    if (allData?.find((item: any) => item?.combo.includes("heatpump"))) {
      combo = combo !== "" ? combo + "_heatpump" : "heatpump";
    }
    if (allData?.find((item: any) => item?.combo.includes("condgas"))) {
      combo = combo !== "" ? combo + "_condgas" : "condgas";
    }
    if (allData?.find((item: any) => item?.combo.includes("solar"))) {
      combo = combo !== "" ? combo + "_solar" : "solar";
    }
    return combo;
  };

  // handler to return all renovations with glazing for custom pack to check at select and deselect time
  const getAllGlazingRenovations = (allRenovations: any) => {
    let data: any = [];
    // map loop to create window data duplicate for glazing records
    [...allRenovations]?.map((item) => {
      if (item.combo?.includes("window")) {
        let windowCombination = item.combo?.replace("window", "glazing");
        data.push({ ...item, combo: windowCombination });
      }
    });
    return data;
  };

  // api call handler
  const getRenovationsByAPI = () => {
    // payload for packs data
    if (houseDetails) {
      let payloadforpacks: any = {
        building_id: houseDetails?.id,
        ...getModalKeysHandler("default", {}, {}),
      };
      // to get packs data from api
      GetPacksData(payloadforpacks)
        .then((response_packs: any) => {
          let pythonData = pythonLogicHandler(
            response_packs?.data,
            houseDetails
          );
          console.log("response_packs?.data-1", response_packs?.data);
          setAllCustomCombination(response_packs?.data);
          setAllPacksCombinations(pythonData);
          // setAllPacksCombinations(response_packs?.data);
          setRenovationLoader(false);
          renovationPackHandler("b", pythonData);
          setLoader(false);
        })
        .catch((error) => {
          setRenovationLoader(false);
          console.log("error", error);
          setLoader(false);
        });
    }
  };

  console.log("houseDetails-1-2", houseDetails?.glazing, refinementCall2Record);

  const pythonLogicHandler = (data: any, refinementKeys: any) => {
    let selectedRecords: any = [];
    // to get current situation epc of building
    const currenSituationData: any = data.find(
      (item: any) => item?.combo === "current_situation"
    );
    // else to return as it as the same array if we don't have "S" in glazing.
    if (refinementKeys?.glazing === "S") {
      data?.map((item: any) => {
        if (item?.combo?.includes("window")) {
          selectedRecords?.push(item);
        }
      });
      return [currenSituationData, ...selectedRecords];
    }
    // else to return as it as the same array if we don't have "S" in glazing.
    else {
      return data;
    }
  };

  // pack handler - process.env.REACT_APP_FRONTEND_URL + "/agc/v2/getbuildingresults",
  const renovationPackHandler = (pack: any, allCombination: any) => {
    const allPacks = allCombination;
    // to get current situation epc of building
    const currenSituationData: any = allCombination.filter(
      (item: any) => item?.combo === "current_situation"
    );
    // packa <= 85
    // console.log("allCombination-aa", allPacks);
    if (pack === "a") {
      // less then and equla 85
      const filteredData: any = allPacks.filter(
        (item: any) =>
          parseInt(
            item?.result?.ecolabel_value
              ? item?.result?.ecolabel_value
              : item?.ecolabel_value
          ) <= 85 &&
          // parseInt(item?.result?.ecolabel_value
          // ? item?.result?.ecolabel_value
          // : item?.ecolabel_value) > 0
          //&&
          item.combo !== "current_situation"
      );
      console.log("allCombination-aabb", filteredData, allPacks);
      // sort in ascending form to get lowest value data
      const lowestPack: any = filteredData.sort(
        (a: any, b: any) =>
          (parseInt(a?.result?.total_investment_cost) &&
            parseInt(a?.result?.total_subsidy_cost)) -
          (parseInt(b?.result?.total_investment_cost) &&
            parseInt(b?.result?.total_subsidy_cost))
      );

      if (
        currenSituationData?.length > 0 &&
        parseInt(
          currenSituationData[0]?.result?.ecolabel_value
            ? currenSituationData[0]?.result?.ecolabel_value
            : currenSituationData[0]?.ecolabel_value
        ) <= 85
        // &&
        // parseInt(currenSituationData[0]?.result?.ecolabel_value) <= 170
      ) {
        setSelectedRenovationPack([]);
        // state to save data to show prices,subsidy,e.t.c
        setSelectedPackData([]);
      } else {
        // to check if pack have window in lowest then we have to replace "window -> glazing"
        let lowestCombination = {
          ...lowestPack[0],
          combo: lowestPack[0]?.combo?.includes("window")
            ? lowestPack[0]?.combo?.replace("window", "glazing")
            : lowestPack[0]?.combo,
        };
        console.log("allCombination-123", lowestCombination);
        setSelectedRenovationPack(lowestCombination);
        // state to save data to show prices,subsidy,e.t.c
        setSelectedPackData(lowestCombination);
      }
      setSelectedPack("packA");
    }
    // packb > 100 & <= 200
    else if (pack === "b") {
      console.log("allPacks-packb", allPacks);
      const filteredData: any = allPacks.filter(
        (item: any) =>
          parseInt(
            item?.result?.ecolabel_value
              ? item?.result?.ecolabel_value
              : item?.ecolabel_value
          ) > 85 &&
          parseInt(
            item?.result?.ecolabel_value
              ? item?.result?.ecolabel_value
              : item?.ecolabel_value
          ) <= 170 &&
          item.combo !== "current_situation"
      );
      // sort in ascending form to get lowest value data
      const lowestPack: any = filteredData.sort(
        (a: any, b: any) =>
          parseInt(a?.result?.total_investment_cost) -
          parseInt(a?.result?.total_subsidy_cost) -
          (parseInt(b?.result?.total_investment_cost) -
            parseInt(b?.result?.total_subsidy_cost))
      );
      // to get current situation epc of building
      // const currenSituationData: any = allPacks.filter(
      //   (item: any) => item?.combo === "current_situation"
      // );
      if (
        currenSituationData?.length > 0 &&
        parseInt(
          currenSituationData[0]?.result?.ecolabel_value
            ? currenSituationData[0]?.result?.ecolabel_value
            : currenSituationData[0]?.ecolabel_value
        ) > 85 &&
        parseInt(
          currenSituationData[0]?.result?.ecolabel_value
            ? currenSituationData[0]?.result?.ecolabel_value
            : currenSituationData[0]?.ecolabel_value
        ) <= 170
      ) {
        setSelectedRenovationPack([]);
        // state to save data to show prices,subsidy,e.t.c
        setSelectedPackData([]);
      } else {
        // to check if pack have window in lowest then we have to replace "window -> glazing"
        let lowestCombination = {
          ...lowestPack[0],
          combo: lowestPack[0]?.combo?.includes("window")
            ? lowestPack[0]?.combo?.replace("window", "glazing")
            : lowestPack[0]?.combo,
        };
        console.log("allCombination-123-b", lowestCombination);
        setSelectedRenovationPack(lowestCombination);
        // state to save data to show prices,subsidy,e.t.c
        setSelectedPackData(lowestCombination);
      }
      setSelectedPack("packB");
    }
    // custom pack
    else if (pack === "custom") {
      // to get current situation epc of building
      // const currenSituationData: any = allPacks.filter(
      //   (item: any) => item?.combo === "current_situation"
      // );
      //first to check if current situation is a so we have to pick empty array // less then 85 logic for custom to show pack a by default
      const filteredData: any =
        currenSituationData?.length > 0 &&
        parseInt(
          currenSituationData[0]?.result?.ecolabel_value
            ? currenSituationData[0]?.result?.ecolabel_value
            : currenSituationData[0]?.ecolabel_value
        ) <= 85
          ? []
          : allPacks.filter(
              (item: any) =>
                parseInt(
                  item?.result?.ecolabel_value
                    ? item?.result?.ecolabel_value
                    : item?.ecolabel_value
                ) <= 85 && item.combo !== "current_situation"
            );
      //first to check if current situation is b so we have to pick empty array // less then 170 logic for custom to show pack b if pack a does not exist
      const filteredDataPackB: any =
        currenSituationData?.length > 0 &&
        parseInt(
          currenSituationData[0]?.result?.ecolabel_value
            ? currenSituationData[0]?.result?.ecolabel_value
            : currenSituationData[0]?.ecolabel_value
        ) > 85 &&
        parseInt(
          currenSituationData[0]?.result?.ecolabel_value
            ? currenSituationData[0]?.result?.ecolabel_value
            : currenSituationData[0]?.ecolabel_value
        ) <= 170
          ? []
          : allPacks.filter(
              (item: any) =>
                parseInt(
                  item?.result?.ecolabel_value
                    ? item?.result?.ecolabel_value
                    : item?.ecolabel_value
                ) > 85 &&
                parseInt(
                  item?.result?.ecolabel_value
                    ? item?.result?.ecolabel_value
                    : item?.ecolabel_value
                ) <= 170 &&
                item.combo !== "current_situation"
            );
      // if pack a exist pick pack a else pack b
      const selectedPack =
        filteredData?.length > 0 ? filteredData : filteredDataPackB;
      // sort in ascending form to get lowest value data
      const lowestPack: any = selectedPack.sort(
        (a: any, b: any) =>
          parseInt(a?.result?.total_investment_cost) -
          parseInt(a?.result?.total_subsidy_cost) -
          (parseInt(b?.result?.total_investment_cost) -
            parseInt(b?.result?.total_subsidy_cost))
      );
      // if there is no pack then we have to show prices for that we have to pick it from current_situation
      if (lowestPack?.length === 0) {
        // state to save data to show prices,subsidy,e.t.c
        setSelectedPackData(currenSituationData[0]);
      } else {
        // to check if pack have window in lowest then we have to replace "window -> glazing"
        let lowestCombination = {
          ...lowestPack[0],
          combo: lowestPack[0]?.combo?.includes("window")
            ? lowestPack[0]?.combo?.replace("window", "glazing")
            : lowestPack[0]?.combo,
        };
        // state to save data to show prices,subsidy,e.t.c
        setSelectedPackData(lowestCombination);
      }
      // creating a combo to find the custom records
      const customCombo = comboHandler(allPacks);
      setSelectedRenovationPack({ combo: customCombo });
    }
    // to get current situation epc of building
    // const currenSituationData: any = allPacks.filter(
    //   (item: any) => item?.combo === "current_situation"
    // );
    setcurrenSituationDataBuild(currenSituationData[0]);
    setepc_value_current(
      currenSituationData[0]?.result?.ecolabel_value
        ? currenSituationData[0]?.result?.ecolabel_value
        : currenSituationData[0]?.ecolabel_value
    );
    setepc_value_current_epc(
      currenSituationData[0]?.result?.ecolabel_value
        ? currenSituationData[0]?.result?.ecolabel_value
        : currenSituationData[0]?.ecolabel_value
    );
  };

  // api call to get building details data
  useEffect(() => {
    setRefinementCall2Record(null);
    setRefinementCall3Record(null);
    setActiveModal("callZero");
    let userId = localStorage.getItem("user_temporary_id");
    // token is commented because they are not using any login functionality
    let userRecord: any = "";
    // result page content api call
    let resultId: any = { id: 193 };
    // api call to get content
    GetContentByService(resultId)
      .then((response) => {
        setResultPageContent(response?.data?.data?.page_contents);
      })
      .catch((error) => {
        console.log("error");
      });
    // api call to get all renovations list of records
    let linkId = {
      link_id: match?.params?.id?.replace(/\-/g, "/"),
    };
    // api call to get only renovation house record
    GetBuildingInfo(linkId)
      .then((houseRenovations: any) => {
        setHouseDetails(houseRenovations?.data);

        // to show users renovations if login else show all logout renovations
        let payload: any = {
          building_id: houseRenovations?.data?.id,
          userId: userId,
        };

        if (userId) {
          setLoader(true);
          setUserData(userRecord);
          // setActiveModal("callZero");
          // api call for building details to get data if save
          getbuildinginfouser(payload)
            .then((renovations: any) => {
              // setActiveModal("");
              if (
                renovations?.data === null ||
                renovations?.data === undefined ||
                renovations?.data?.updated_current_situation === "" ||
                renovations?.data?.updated_current_situation === null ||
                renovations?.data?.updated_current_situation === undefined
              ) {
                setDefaultData(true);
                setLoader(false);
              } else {
                // setLoader(false);
                // modal questions
                let allQuestions: any = renovations?.data
                  ?.updated_current_situation
                  ? JSON.parse(renovations?.data?.updated_current_situation)
                  : {};
                // left side model data after saving in database
                let buildingRefinements: any = renovations?.data
                  ?.building_refinements
                  ? JSON.parse(renovations?.data?.building_refinements)
                  : {};
                // right side model data after saving in database
                let renovationRefinements: any = renovations?.data
                  ?.renovation_refinements
                  ? JSON.parse(renovations?.data?.renovation_refinements)
                  : {};
                // for modals to show data if stored in database
                if (
                  renovations?.data !== "no data yet" &&
                  buildingRefinements &&
                  Object.keys(buildingRefinements).length > 0
                ) {
                  setRefinementCall3Record(buildingRefinements);
                }
                if (
                  renovationRefinements &&
                  Object.keys(renovationRefinements).length > 0
                ) {
                  setRefinementCall2Record(renovationRefinements);
                }
                // api call to get packs
                let payloadforquest: any = {
                  building_id: houseRenovations?.data?.id,
                  userId: userId,
                  ...allQuestions,
                };
                // to get packs data from api
                GetUserPacksData(payloadforquest)
                  .then((response_packs: any) => {
                    setAllCustomCombination(response_packs?.data);
                    let pythonData = pythonLogicHandler(
                      response_packs?.data,
                      renovationRefinements
                    );
                    console.log(
                      "response_packs?.data-11",
                      response_packs?.data,
                      pythonData
                    );
                    // ventc and vent d logics 3 and 4
                    if (
                      renovationRefinements &&
                      Object.keys(renovationRefinements).length > 0 &&
                      (parseInt(renovationRefinements?.ventilation_type) ===
                        3 ||
                        parseInt(renovationRefinements?.ventilation_type) === 4)
                    ) {
                      const filteredData = pythonData?.filter((item: any) => {
                        return (
                          !item?.combo.includes("ventc+") &&
                          !item?.combo.includes("ventd")
                        );
                      });
                      setAllPacksCombinations(filteredData);
                      setRenovationLoader(false);
                      renovationPackHandler("b", filteredData);
                      setLoader(false);
                    } else {
                      setAllPacksCombinations(pythonData);
                      setRenovationLoader(false);
                      renovationPackHandler("b", pythonData);
                      setLoader(false);
                    }
                  })
                  .catch((error) => {
                    setLoader(false);
                    setRenovationLoader(false);
                    console.log("error", error);
                  });
              }
            })
            .catch((error) => {
              console.log(
                "🚀 ~ file: Results.tsx ~ line 654 ~ .then ~ error",
                error
              );
              // setActiveModal("");
              setLoader(false);
            });
        } else {
          // setActiveModal("");
          setDefaultData(true);
          localStorage.setItem("user_temporary_id", uuidv4());
        }
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: Results.tsx ~ line 671 ~ useEffect ~ error",
          error
        );
      });
  }, []);
  // }, [cookie.load("token")]);

  useEffect(() => {
    if (defaultData === true) {
      getRenovationsByAPI();
    }
  }, [defaultData === true]);

  // recursive logic
  const recursiveLogicHandler = (renovation: any, key: any) => {
    // vent c and vent d
    if (
      renovation !== undefined &&
      renovation !== null &&
      renovation.includes("current_situation")
    ) {
      renovation = renovation.replace("current_situation", ``);
    }
    // vent c and vent d
    if (
      renovation !== undefined &&
      renovation !== null &&
      renovation.includes("ventc+") &&
      key === "ventd"
    ) {
      renovation = renovation?.includes("_ventc")
        ? renovation.replace("_ventc+", ``)
        : renovation.replace("ventc+", ``);
    }
    if (
      renovation !== undefined &&
      renovation !== null &&
      renovation.includes("ventd") &&
      key === "ventc+"
    ) {
      renovation = renovation?.includes("_ventd")
        ? renovation.replace("_ventd", ``)
        : renovation.replace("ventd", ``);
    }
    // condgas and heatpump
    if (
      renovation !== undefined &&
      renovation !== null &&
      renovation.includes("heatpump") &&
      key === "condgas"
    ) {
      renovation = renovation?.includes("_heatpump")
        ? renovation.replace("_heatpump", ``)
        : renovation.replace("heatpump", ``);
    }
    if (
      renovation !== undefined &&
      renovation !== null &&
      renovation.includes("condgas") &&
      key === "heatpump"
    ) {
      renovation = renovation?.includes("_condgas")
        ? renovation.replace("_condgas", ``)
        : renovation.replace("condgas", ``);
    }
    // window and glazing
    if (
      renovation !== undefined &&
      renovation !== null &&
      renovation.includes("window") &&
      key === "glazing"
    ) {
      renovation = renovation?.includes("_window")
        ? renovation.replace("_window", ``)
        : renovation.replace("window", ``);
    }
    if (
      renovation !== undefined &&
      renovation !== null &&
      renovation.includes("glazing") &&
      key === "window"
    ) {
      renovation = renovation?.includes("_glazing")
        ? renovation.replace("_glazing", ``)
        : renovation.replace("glazing", ``);
    }
    return renovation;
  };

  // method to select Card (based on old data logics for v2 needs to be updated again)
  const selectCardHandler = (renovation: any, key: any) => {
    const allPacks = allCustomCombination;
    // const allPacks = allPacksCombination;
    // to unselect renovation
    if (
      selectedPackData?.combo !== undefined &&
      selectedPackData?.combo !== null &&
      selectedPackData?.combo?.includes(renovation)
    ) {
      let selectedCombination = selectedPackData?.combo?.includes(
        `_${renovation}`
      )
        ? selectedPackData?.combo?.replace(`_${renovation}`, "")
        : selectedPackData?.combo?.replace(`${renovation}`, "");
      // to sort data and to create a combo
      let combination = selectedCombination?.includes("_")
        ? selectedCombination?.split("_")
        : [selectedCombination];
      const sortedData = sortedRenovation(combination);
      // creating a combo to search selected data
      let newCombo = "";
      sortedData?.map((item: any) => {
        if (item !== "") {
          newCombo = newCombo === "" ? item : newCombo + "_" + item;
        }
      });
      // to combine array with glazing if window exist
      let combinedRenovations = [
        ...allPacks,
        ...getAllGlazingRenovations(allPacks),
      ];

      //  filtering data to get selected record
      let finalSelectedRecord: any = combinedRenovations?.filter(
        (item: any) => item.combo === newCombo
      );
      if (finalSelectedRecord?.length !== 0) {
        // state to save data to show prices,subsidy,e.t.c
        setSelectedPackData(finalSelectedRecord[0]);
      } else {
        // state to save data if no record is found so we can show the renoavtion values only
        setSelectedPackData({
          window_cost: selectedPackData?.window_cost,
          glazing_cost: selectedPackData?.glazing_cost,
          wall_cost: selectedPackData?.wall_cost,
          roof_cost: selectedPackData?.roof_cost,
          heatpump_cost: selectedPackData?.heatpump_cost,
          condgas_cost: selectedPackData?.condgas_cost,
          ventc_cost: selectedPackData?.ventc_cost,
          ventd_cost: selectedPackData?.ventd_cost,
          solar_cost: selectedPackData?.solar_cost,
          wall_subsidy: selectedPackData?.wall_subsidy,
          roof_subsidy: selectedPackData?.roof_subsidy,
          ventc_subsidy: selectedPackData?.ventc_subsidy,
          ventd_subsidy: selectedPackData?.ventd_subsidy,
          heatpump_subsidy: selectedPackData?.heatpump_subsidy,
          condgas_subsidy: selectedPackData?.condgas_subsidy,
          solar_subsidy: selectedPackData?.solar_subsidy,
          window_subsidy: selectedPackData?.window_subsidy,
          glazing_subsidy: selectedPackData?.glazing_subsidy,
        });
      }
    }
    // to select data renovation
    else {
      // to check recursive logic
      const recursiveCombo = recursiveLogicHandler(
        selectedPackData?.combo,
        renovation
      );
      let selectedCombination =
        recursiveCombo !== null &&
        recursiveCombo !== undefined &&
        recursiveCombo?.includes(`_`)
          ? recursiveCombo + `_${renovation}`
          : recursiveCombo !== undefined &&
            recursiveCombo !== null &&
            recursiveCombo !== ""
          ? recursiveCombo + `_${renovation}`
          : `${renovation}`;
      // to sort data and to create a combo
      let combination =
        selectedCombination !== null &&
        selectedCombination !== undefined &&
        selectedCombination?.includes("_")
          ? selectedCombination?.split("_")
          : [selectedCombination];
      const sortedData = sortedRenovation(combination);
      // creating a combo to search selected data
      let newCombo = "";
      sortedData?.map((item: any) => {
        if (item !== "") {
          newCombo = newCombo === "" ? item : newCombo + "_" + item;
        }
      });
      // to combine array with glazing if window exist
      let combinedRenovations = [
        ...allPacks,
        ...getAllGlazingRenovations(allPacks),
      ];

      // to search combination now
      let finalSelectedRecord: any = combinedRenovations?.filter(
        (item: any) => item.combo === newCombo
      );
      // state to save data to show prices,subsidy,e.t.c
      setSelectedPackData(finalSelectedRecord[0]);
    }
  };

  return (
    <div className="result_page_main_container">
      <Helmet>
        <link
          rel="stylesheet"
          href="https://js.arcgis.com/4.21/esri/themes/light/main.css"
        />
      </Helmet>
      {/* Call2And3Modal */}
      {activeModal === "call2&3" && (
        <Call2And3Modal getValue={getResultContent} />
      )}
      {/* CallZeroModal */}
      {activeModal === "callZero" && (
        <div>
          <CallZeroModal
            loader={loader}
            getResultContent={getResultContent}
            closeModal={closeModal}
          />
        </div>
      )}

      {/* Header */}
      {/* <ResultHeader
        openModal={openModal}
        closeModal={closeModal}
        getResultContent={getResultContent}
        userStatus={userStatus}
        setLoggedInUser={setLoggedInUser}
        setRefinementCall2Record={setRefinementCall2Record}
        setRefinementCall3Record={setRefinementCall3Record}
        setFutureEpc={setFutureEpc}
        setPackLabel={setPackLabel}
        setLoader={setLoader}
      /> */}
      <div
        className={`nc-ListingStayDetailPage`}
        data-nc-id="ListingStayDetailPage"
      >
        <Modal
          setIsOpenModal={setIsOpenModal}
          openModal={openModal}
          setActiveModal={setActiveModal}
          activeRenovationToolTip={activeRenovationToolTip}
          activeModal={activeModal}
          closeModal={closeModal}
          isOpenModal={isOpenModal}
          callTwo={callTwoTemplate}
          callThree={callThreeTemplate}
          userBuildingTemPId={userBuildingId}
          getValue={getResultContent}
          allRenovationRecord={allRenovationRecord}
          renovationModalContent={renovationModalContent}
          userData={userData}
          setUserStatus={setUserStatus}
          setLoader={setLoader}
          houseCallRecordUpdated={houseCallRecordUpdated}
          setHouseCallRecordUpdated={setHouseCallRecordUpdated}
          // state to pass user data if exits
          refinementCall3Record={refinementCall3Record}
          setRefinementCall2Record={setRefinementCall2Record}
          refinementCall2Record={refinementCall2Record}
          setRefinementCall3Record={setRefinementCall3Record}
          // state for left and right side model of section 1
          houseDetails={houseDetails}
          // states for method
          setAllUserSelectedData={setAllUserSelectedData}
          setEpcA={setEpcA}
          renovationsInvestmentPrice={renovationsInvestmentPrice}
          defaultRenovations={defaultRenovations}
          setRenovations={setRenovations}
          userModalRenovations={userModalRenovations}
          //
          setFutureEpc={setFutureEpc}
          setPackLabel={setPackLabel}
          //
          setUserExistRenovations={setUserExistRenovations}
          getResultContent={getResultContent}
          //
          setSelectedUserCombination={setSelectedUserCombination}
          //
          setSelectedPack={setSelectedPack}
          setCustomStars={setCustomStars}
          setIsCallWaiting={setIsCallWaiting}
          setDownloadedRecord={setDownloadedRecord}
          // function to get keys from left and right model
          getModalKeysHandler={getModalKeysHandler}
          setAllPacksCombinations={setAllPacksCombinations}
          setRenovationLoader={setRenovationLoader}
          renovationPackHandler={renovationPackHandler}
          pythonLogicHandler={pythonLogicHandler}
          setGlazing={setGlazing}
          glazing={glazing}
          setAllCustomCombination={setAllCustomCombination}
        />
        {/* Steps */}
        {/* <SectionRenovationSteps
          userStatus={userStatus}
          getResultContent={getResultContent}
        /> */}
        {/* <ResultBanner /> */}
        {/* <ChooseRenovationComponent />
        <OurReacommended /> */}
        {/* SectionHouse 2 house data*/}
        <div className="mx-[5%] lg:mx-[10%]">
          <div className="w-full">
            {/* heading-div */}
            <div className="flex flex-col lg:flex-row items-center py-8">
              <p className="text-[38px] lg:text-[38px] font-semibold text-[#222222] ">
                {getResultContent(2)}
              </p>
              <p className="text-lg lg:text-[22px] font-bold text-[#007DDF] pl-[14px] pt-1">
                {houseDetails?.street +
                  " " +
                  houseDetails?.street_num +
                  ", 7100 La Louvière"}
              </p>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row">
            {/* left-side */}
            <SectionHouse
              allRenovationRecord={houseDetails}
              refinementCall3Record={refinementCall3Record}
              openModal={openModal}
              className={"bg-white border z-10"}
              getResultContent={getResultContent}
              userStatus={userStatus}
              loggedInUser={loggedInUser}
              isCallWaitng={isCallWaitng}
              isAdminView={isAdminView}
            />
            {/* right-side */}
            <SectionDiagnosis
              refinementCall2Record={refinementCall2Record}
              allRenovationRecord={houseDetails}
              openModal={openModal}
              userStatus={userStatus}
              userSelectedData={userSelectedData}
              className={"bg-white shadow z-10"}
              getResultContent={getResultContent}
              loggedInUser={loggedInUser}
              isCallWaitng={isCallWaitng}
              downloadRecord={downloadRecord}
              isAdminView={isAdminView}
              epc_value={epc_value_current_epc}
              renovationLoader={renovationLoader}
            />
          </div>
        </div>

        {/* packs buttons */}
        <main className="mx-[5%] lg:mx-[10%]">
          {/* CONTENT */}
          <div className="pb-8 pt-4">
            <Packs
              getResultContent={getResultContent}
              selectedPack={selectedPack}
              setSelectedPack={setSelectedPack}
              // method to change pack label
              onChange={onChange}
              // method for custom
              showAllRenovations={showAllRenovations}
              custom={custom}
              setCustom={setCustom}
              baseOfferRenovation={baseOfferRenovation}
              userStatus={userStatus}
              // renovationPackHandler
              renovationPackHandler={renovationPackHandler}
              allPacksCombination={allPacksCombination}
            />
          </div>
          <div className="pb-8">
            <Subsides
              subsidies={subsidies}
              setSubsidies={setSubsidies}
              getResultContent={getResultContent}
              userStatus={userStatus}
              openModal={openModal}
            />
          </div>
          <div className="pb-14">
            <div className="flex justify-between flex-col-reverse xl:flex-row">
              {/* renovations options  */}
              <div className="w-full xl:w-[65%] lg:pr-3">
                <div className="w-full ">
                  <SectionOurRecomendation
                    selectedPack={selectedPack}
                    subsidies={subsidies}
                    selectCardHandler={selectCardHandler}
                    // state to setRenovationCOntent
                    setRenovationModalContent={setRenovationModalContent}
                    // method to open modal
                    openModal={openModal}
                    custom={custom}
                    // method to change pack label
                    showAllRenovations={showAllRenovations}
                    // method to change pack label
                    onChange={onChange}
                    // method to get value
                    getResultContent={getResultContent}
                    // state to get all renovations
                    resultPageContent={resultPageContent}
                    // state to show all values of renovations coming from backend
                    renovations={renovations}
                    epcA={epcA}
                    setActiveRenovationToolTip={setActiveRenovationToolTip}
                    isCallWaitng={isCallWaitng}
                    subtest={subtest}
                    selectedPackRenovations={selectedPackRenovations}
                    selectedPackData={selectedPackData}
                    renovationLoader={renovationLoader}
                  />
                </div>
                <div className="pt-3">
                  <SectionRenovationLoan
                    totalValue={totalValue}
                    getResultContent={getResultContent}
                    setRenovationModalContent={setRenovationModalContent}
                    openModal={openModal}
                    // renovationLoader={renovationLoader}
                    renovationLoader={activeModal}
                  />
                </div>
              </div>
              {/* for responsive */}
              <div className="flex-grow xl:w-[25%] w-full">
                {/* <div className="hidden lg:block flex-grow"> */}
                {/* Fixed sidebar */}
                <div className="sticky top-8">
                  <SectionFixedSideBar
                    subsidies={subsidies}
                    houseDetails={houseDetails}
                    loggedInUser={loggedInUser}
                    futureEpc={futureEpc}
                    renovations={renovations}
                    openModal={openModal}
                    userStatus={userStatus}
                    getResultContent={getResultContent}
                    setTotalValue={setTotalValue}
                    userSelectedData={userSelectedData}
                    selectedInd={selectedInd}
                    refinementCall2Record={refinementCall2Record}
                    allRenovationRecord={houseDetails}
                    customStars={customStars}
                    customPackSelected={customPackSelected}
                    setRenovationModalContent={setRenovationModalContent}
                    setUserStatus={setUserStatus}
                    selectedUserCombination={selectedUserCombination}
                    setCustom={setCustom}
                    selectedPack={selectedPack}
                    isAdminView={isAdminView}
                    subtest={subtest}
                    selectedPackData={selectedPackData}
                    selectedPackRenovations={selectedPackRenovations}
                    currenSituationDataEpc={epc_value_current}
                    currenSituationData={currenSituationDataBuild}
                    epc_value_current_epc={epc_value_current_epc}
                  />
                </div>
              </div>
              {/* loan */}
              {/* <SectionRenovationLoan
                totalValue={totalValue}
                getResultContent={getResultContent}
              /> */}
              {/* result description */}
              {/* <SectionResultDescription
                // itemPerRow={3}
                getResultContent={getResultContent}
              /> */}
            </div>
            {/* SIDEBAR */}
            <div className="pt-8">
              <YourNextStep getResultContent={getResultContent} />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ListingStayDetailPage;
