import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
// import ButtonClose from "shared/ButtonClose/ButtonClose";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
// import Avatar from "shared/Avatar/Avatar";

const Modal = (props: any) => {
  let {
    isOpenModal,
    closeModal,
    getResultContent,
    renovationModalContent,
  } = props;
  return (
    <Transition appear show={isOpenModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block py-8 w-full">
              <div className="inline-flex flex-col w-full lg:w-1/2 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                <div className="relative flex justify-center bg-neutral-100 flex-shrink-0 px-6 pb-4 border-b border-neutral-200 text-center">
                  <h3
                    className="text-md m-0 text-gray-600 pt-4"
                    id="headlessui-dialog-title-70"
                  >
                    {renovationModalContent === "subsidy"
                      ? getResultContent && getResultContent(264)
                      : renovationModalContent === "less_then_10_cost_modal"
                      ? getResultContent && getResultContent(413)
                      : renovationModalContent === "city"
                      ? getResultContent && getResultContent(420)
                      : renovationModalContent === "budget"
                      ? getResultContent && getResultContent(418)
                      : getResultContent && getResultContent(265)}
                  </h3>
                  <span className="absolute right-4 pt-3 text-black text-lg font-bold">
                    <CloseOutlined onClick={closeModal} />
                  </span>
                </div>
                <div className="px-8 py-8 flex flex-col items-center">
                  {/* right side */}
                  <div className="px-4 text-center">
                    <span className="text-center font-medium text-sm text-gray-500">
                      {/* subsidy */}
                      {renovationModalContent === "subsidy"
                        ? getResultContent && getResultContent(52)
                        : renovationModalContent === "less_then_10_cost_modal"
                        ? getResultContent && getResultContent(412)
                        : renovationModalContent === "city"
                        ? getResultContent && getResultContent(421)
                        : renovationModalContent === "budget"
                        ? getResultContent && getResultContent(419)
                        : getResultContent && getResultContent(53)}
                      {/* total */}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
export default Modal;
