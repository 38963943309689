import React, { FC, useContext } from "react";
// import images
import RoofPannel from "../../../assets/images/renovates/roofpannel.jpg";
// import components
import { GlobalContext } from "../../../context/globalContext/globalContext";
import InputTabs from "../../../shared/autoComplete";
import { getValue } from "../../../utils/getValue";

interface SolarPannelRenovationsInterface {}

const SolarPannelRenovations: FC<SolarPannelRenovationsInterface> = () => {
  const { state }: any = useContext(GlobalContext);
  console.log("getValue", getValue(state, 3, 0));
  return (
    <div>
      <div>
        <img src={RoofPannel} alt="RoofPannel" />
      </div>
      <p
        className="text-black text-[20px] py-4"
        dangerouslySetInnerHTML={{
          __html: getValue(state, 3, 3)?.props?.content,
        }}
      ></p>
      <p className="text-[#6B7280] text-base py-4 ">
        {getValue(state, 3, 4)?.props?.content}
      </p>
      <div className="  py-8">
        <div className="w-full lg:w-[60%]">
          <p
            className="text-[#6B7280] font-semibold text-lg py-2"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 3, 5)?.props?.content,
            }}
          />

          <p
            className="text-[#6B7280] text-base font-normal py-2"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 3, 6)?.props?.content,
            }}
          />
        </div>
      </div>
      <div className="bg-[#007DDF] rounded-[20px] p-[40px] mb-14">
        <p
          className="text-white text-xl font-medium pb-6"
          dangerouslySetInnerHTML={{
            __html: getValue(state, 3, 49)?.props?.content,
          }}
        />
        <InputTabs showAutocomplete="renovation" />
      </div>
    </div>
  );
};
export default SolarPannelRenovations;
