import React, { FC, useEffect, useState } from "react";
import Avatar from "../../shared/Avatar/Avatar";
import Heating from "../../assets/images/resultPage2/heating.svg";
import Roof from "../../assets/images/resultPage2/roof.svg";
import Solar from "../../assets/images/resultPage2/solar_panels.svg";
import Ventillation from "../../assets/images/resultPage2/ventilation.svg";
import Wall from "../../assets/images/resultPage2/wall.svg";
import Window from "../../assets/images/resultPage2/window.svg";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
import GreenDot from "../../assets/images/resultPage2/dots/green.svg";
import OrangeDot from "../../assets/images/resultPage2/dots/orange.svg";
import RedDot from "../../assets/images/resultPage2/dots/red.svg";
import Star from "../../assets/images/resultPage2/star.png";
import StarGrey from "../../assets/images/resultPage2/star_grey.svg";
//import EPC_APlus from "images/resultPage2/epcScale/a.png";
import { get_construction_date } from "../../utils/buildingDetailsHandler";
import { currentComfort, futureComfort } from "../../utils/renovations";
import EPC_APlusPlus from "../../assets/images/resultPage2/epcScale/aplusplus.svg";
import EPC_APlus from "../../assets/images/resultPage2/epcScale/aplus.svg";
import EPC_A from "../../assets/images/resultPage2/epcScale/a.svg";
import EPC_B from "../../assets/images/resultPage2/epcScale/b.svg";
import EPC_C from "../../assets/images/resultPage2/epcScale/c.svg";
import EPC_D from "../../assets/images/resultPage2/epcScale/d.svg";
import EPC_E from "../../assets/images/resultPage2/epcScale/e.svg";
import EPC_F from "../../assets/images/resultPage2/epcScale/f.svg";
import EPC_G from "../../assets/images/resultPage2/epcScale/g.svg";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import Info from "../../assets/images/home/info.png";
import MoreOptionModal from "../../shared/Modal/sectionDiagnoseOption";

const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;

export interface SectionHeroProps {
  className?: string;
  getResultContent?: any;
  userSelectedData?: any;
  openModal?: any;
  allRenovationRecord?: any;
  userStatus?: any;
  loggedInUser?: any;
  refinementCall2Record?: any;
  isCallWaitng?: any;
  downloadRecord?: any;
  isAdminView?: any;
  epc_value?: any;
  renovationLoader: any;
}

const SectionDiagnosis: FC<SectionHeroProps> = ({
  className,
  getResultContent,
  userSelectedData,
  openModal,
  allRenovationRecord,
  userStatus,
  loggedInUser,
  refinementCall2Record,
  isCallWaitng,
  downloadRecord,
  isAdminView,
  epc_value,
  renovationLoader,
}) => {
  const [visible, setVisible] = useState(false);
  const windowAndDoors = (value: any) => {
    switch (value) {
      case "":
        return getResultContent && getResultContent(86)?.props?.content
          ? getResultContent(86).props.content
          : getResultContent(86);
      case "single":
        return getResultContent && getResultContent(87)?.props?.content
          ? getResultContent(87).props.content
          : getResultContent(87);
      case "ordinary_double":
        return (
          getResultContent &&
          `${
            getResultContent(88)?.props?.content
              ? getResultContent(88).props.content
              : getResultContent(88)
          } "<2000"`
        );
      case "hr_double_before_2000":
        return (
          getResultContent &&
          `${
            getResultContent(88)?.props?.content
              ? getResultContent(88).props.content
              : getResultContent(88)
          } "<2000"`
        );
      case "hr_double_after_2000":
        return (
          getResultContent &&
          `${
            getResultContent(89)?.props?.content
              ? getResultContent(89).props.content
              : getResultContent(89)
          } ">2000"`
        );
      case "triple":
        return (
          getResultContent &&
          `${
            getResultContent(90)?.props?.content
              ? getResultContent(90).props.content
              : getResultContent(90)
          }`
        );
      default:
        return "";
    }
  };

  // agc v2
  const windowAndDoorsv2 = (value: any) => {
    switch (value) {
      case "":
        return getResultContent && getResultContent(86)?.props?.content
          ? getResultContent(86).props.content
          : getResultContent(86);
      case "S":
        return getResultContent && getResultContent(87)?.props?.content
          ? getResultContent(87).props.content
          : getResultContent(87);
      case "ordinary_double":
        return (
          getResultContent &&
          `${
            getResultContent(88)?.props?.content
              ? getResultContent(88).props.content
              : getResultContent(88)
          } "<2000"`
        );
      case "D":
        return (
          getResultContent &&
          `${
            getResultContent(88)?.props?.content
              ? getResultContent(88).props.content
              : getResultContent(88)
          } "<2000"`
        );
      case "DR":
        return (
          getResultContent &&
          `${
            getResultContent(89)?.props?.content
              ? getResultContent(89).props.content
              : getResultContent(89)
          } ">2000"`
        );
      case "T":
        return (
          getResultContent &&
          `${
            getResultContent(90)?.props?.content
              ? getResultContent(90).props.content
              : getResultContent(90)
          }`
        );
      default:
        return (
          getResultContent &&
          `${
            getResultContent(88)?.props?.content
              ? getResultContent(88).props.content
              : getResultContent(88)
          } "<2000"`
        );
    }
  };

  const wallInsulation = (value: any) => {
    switch (value) {
      case 0:
        return getResultContent && getResultContent(92)?.props?.content
          ? getResultContent(92).props.content
          : getResultContent(92);
      case 1:
        return getResultContent && getResultContent(93)?.props?.content
          ? getResultContent(93).props.content
          : getResultContent(93);
      case 2:
        return getResultContent && getResultContent(94)?.props?.content
          ? getResultContent(94).props.content
          : getResultContent(94);
      case 3:
        return getResultContent && getResultContent(95)?.props?.content
          ? getResultContent(95).props.content
          : getResultContent(95);
      case 4:
        return getResultContent && getResultContent(96)?.props?.content
          ? getResultContent(96).props.content
          : getResultContent(96);
      case 5:
        return getResultContent && getResultContent(97)?.props?.content
          ? getResultContent(97).props.content
          : getResultContent(97);
      case 6:
        return getResultContent && getResultContent(98)?.props?.content
          ? getResultContent(98).props.content
          : getResultContent(98);
      case 7:
        return getResultContent && getResultContent(99)?.props?.content
          ? getResultContent(99).props.content
          : getResultContent(99);
      case 8:
        return getResultContent && getResultContent(100)?.props?.content
          ? getResultContent(100).props.content
          : getResultContent(100);
      default:
        return "";
    }
  };

  // for agc v2
  const wallInsulationv2 = (key: any) => {
    let construction_date = get_construction_date(key);
    if (parseInt(construction_date) < 1960) {
      return "3-6 cm";
    } else if (
      parseInt(construction_date) >= 1960 &&
      parseInt(construction_date) < 2006
    ) {
      return "7-9 cm";
    } else if (parseInt(construction_date) >= 2006) {
      return "10 cm+";
    }
  };
  console.log(
    "allRenovationRecord?.roof_insulation",
    allRenovationRecord?.roof_insulation
  );
  const wallInsulationv2value = (value: any) => {
    let answere = "";

    if (parseInt(value) >= 3 && parseInt(value) <= 6) {
      answere = "3-6 cm";
    }
    // 7-9 cm
    else if (parseInt(value) >= 7 && parseInt(value) <= 9) {
      answere = "7-9 cm";
    }
    // 10 cm+
    else if (parseInt(value) >= 10) {
      answere = "10 cm+";
    }

    return answere;
  };
  const roofInsulationv2 = (key: any) => {
    //construction_date = 2009;
    let construction_date = get_construction_date(key);
    if (parseInt(construction_date) < 1960) {
      return "3-6 cm";
    } else if (
      parseInt(construction_date) >= 1960 &&
      parseInt(construction_date) < 1985
    ) {
      return "8-12 cm";
    } else if (
      parseInt(construction_date) >= 1986 &&
      parseInt(construction_date) < 2006
    ) {
      return "14-18 cm";
    } else if (parseInt(construction_date) >= 2006) {
      return "20 cm+";
    }
  };
  const roofInsulationv2value = (value: any) => {
    let answere = "";
    console.log("valueroof", value);

    if (parseInt(value) >= 3 && parseInt(value) <= 7) {
      answere = "3-7 cm";
    }
    // 8 - 12 cm
    else if (parseInt(value) >= 8 && parseInt(value) <= 13) {
      answere = "8-13 cm";
    }
    // 14 - 18 cm
    else if (parseInt(value) >= 14 && parseInt(value) <= 19) {
      answere = "14-19 cm";
    }
    // 20 cm +
    else if (parseInt(value) > 19) {
      answere = "20 cm+";
    }
    return answere;
  };
  const RoofInsulation = (value: any) => {
    switch (value) {
      case "":
        return getResultContent && getResultContent(102)?.props?.content
          ? getResultContent(102).props.content
          : getResultContent(102);
      case 1:
        return getResultContent && getResultContent(103)?.props?.content
          ? getResultContent(103).props.content
          : getResultContent(103);
      case 2:
        return getResultContent && getResultContent(104)?.props?.content
          ? getResultContent(104).props.content
          : getResultContent(104);
      case 3:
        return getResultContent && getResultContent(105)?.props?.content
          ? getResultContent(105).props.content
          : getResultContent(105);
      case 4:
        return getResultContent && getResultContent(106)?.props?.content
          ? getResultContent(106).props.content
          : getResultContent(106);
      case 5:
        return getResultContent && getResultContent(107)?.props?.content
          ? getResultContent(107).props.content
          : getResultContent(107);
      case 6:
        return getResultContent && getResultContent(108)?.props?.content
          ? getResultContent(108).props.content
          : getResultContent(108);
      case 7:
        return getResultContent && getResultContent(109)?.props?.content
          ? getResultContent(109).props.content
          : getResultContent(109);
      case 8:
        return getResultContent && getResultContent(110)?.props?.content
          ? getResultContent(110).props.content
          : getResultContent(110);
      case 9:
        return getResultContent && getResultContent(111)?.props?.content
          ? getResultContent(111).props.content
          : getResultContent(111);
      case 10:
        return getResultContent && getResultContent(112)?.props?.content
          ? getResultContent(112).props.content
          : getResultContent(112);
      default:
        return "";
    }
  };

  const heatingValuesToNumbersHandler = (
    heating_type: any,
    subtype: any,
    fuel: any
  ) => {
    if (heating_type === "boiler" && subtype === "standard" && fuel === "oil") {
      return getResultContent && getResultContent(115);
    } else if (
      heating_type === "boiler" &&
      subtype === "standard" &&
      fuel === "gas"
    ) {
      return getResultContent && getResultContent(116)?.props?.content
        ? getResultContent(116).props.content
        : getResultContent(116);
    } else if (
      heating_type === "electric_heater" &&
      subtype === "standard" &&
      fuel === "electricity"
    ) {
      return getResultContent && getResultContent(117)?.props?.content
        ? getResultContent(117).props.content
        : getResultContent(117);
    } else if (
      heating_type === "boiler" &&
      subtype === "condensing" &&
      fuel === "gas"
    ) {
      return getResultContent && getResultContent(118)?.props?.content
        ? getResultContent(118).props.content
        : getResultContent(118);
    } else if (
      heating_type === "heat_pump" &&
      subtype === "air_water" &&
      fuel === "electricity"
    ) {
      return getResultContent && getResultContent(119)?.props?.content
        ? getResultContent(119).props.content
        : getResultContent(119);
    } else if (
      heating_type === "heat_pump" &&
      subtype === "ground_water" &&
      fuel === "electricity"
    ) {
      return getResultContent && getResultContent(120)?.props?.content
        ? getResultContent(120).props.content
        : getResultContent(120);
    } else {
      return getResultContent && getResultContent(114)?.props?.content
        ? getResultContent(114).props.content
        : getResultContent(114);
    }
  };
  //agc v2 heating type
  const heatingValuesToNumbersHandlerv2 = (heating_type: any) => {
    if (heating_type === "non-condesing2") {
      return getResultContent(370);
    } else if (heating_type === "non-condesing1") {
      return getResultContent(369);
    } else if (heating_type === "electric-heating") {
      return getResultContent(359);
    } else if (heating_type === "condesing1") {
      return getResultContent(371);
    } else if (heating_type === "condesing2") {
      return getResultContent(372);
    } else if (heating_type === "heatpump") {
      return getResultContent(363);
    } else {
      return "non-condensing2";
    }
  };
  //
  const mechanicalSystemHandler = (value: any) => {
    switch (value) {
      case "":
        return getResultContent && getResultContent(123)?.props?.content
          ? getResultContent(123).props.content
          : getResultContent(123);
      case "system_c":
        return getResultContent && getResultContent(124)?.props?.content
          ? getResultContent(124).props.content
          : getResultContent(124);
      case "system_cplus":
        return getResultContent && getResultContent(125)?.props?.content
          ? getResultContent(125).props.content
          : getResultContent(125);
      case "system_d":
        return getResultContent && getResultContent(126)?.props?.content
          ? getResultContent(126).props.content
          : getResultContent(126);
      default:
        return "";
    }
  };

  const windowAndDoorsColor = (value: any) => {
    switch (value) {
      case "":
        return RedDot;
      case "single":
        return RedDot;
      case "ordinary_double":
        return OrangeDot;
      case "hr_double_before_2000":
        return OrangeDot;
      case "hr_double_after_2000":
        return GreenDot;
      case "triple":
        return GreenDot;
      default:
        return RedDot;
    }
  };
  // agcv v2
  const windowAndDoorsColorv2 = (value: any) => {
    // if any empty or unexpected value we need it to be double before 2000 = orange
    //console.log("valuesrer", value);
    switch (value) {
      case "":
        return OrangeDot;
      case "S":
        return RedDot;
      case "D":
        return OrangeDot;
      case "DR":
        return GreenDot;
      case "T":
        return GreenDot;
      default:
        return OrangeDot;
    }
  };
  const RoofInsulationColorv2 = (response: any) => {
    let roofValue = response?.roof_insulation;

    if (roofValue !== "" && roofValue !== undefined) {
      if (roofValue === "Probable") {
        return OrangeDot;
      } else if (roofValue === "OUI") {
        let roofcalculatedvalue = roofInsulationv2(
          allRenovationRecord?.construction_date
        );
        //console.log("roofcalculatedvalue", roofcalculatedvalue);
        if (roofcalculatedvalue === "3-6 cm") {
          return OrangeDot;
        } else if (roofcalculatedvalue === "8-12 cm") {
          return OrangeDot;
        } else if (roofcalculatedvalue === "14-18 cm") {
          return GreenDot;
        } else if (roofcalculatedvalue === "20 cm+") {
          return GreenDot;
        }
      } else {
        return RedDot;
      }
    }
  };
  const wallInsulationColorv2 = (response: any) => {
    let wallValue = response?.facade_insulation_exterior;

    if (wallValue !== "" && wallValue !== undefined) {
      if (wallValue === "Probable") {
        return OrangeDot;
      } else if (wallValue === "OUI") {
        let wallcalculatedvalue = wallInsulationv2(
          allRenovationRecord?.construction_date
        );
        if (wallcalculatedvalue === "3-6 cm") {
          return OrangeDot;
        } else if (wallcalculatedvalue === "7-9 cm") {
          return OrangeDot;
        } else if (wallcalculatedvalue === "10 cm+") {
          return GreenDot;
        }
      } else {
        return RedDot;
      }
    }
  };
  const wallInsulationColor = (response: any) => {
    // cavity wall
    let wallValue = response?.wall_insulation_key;

    if (wallValue !== "" && wallValue !== undefined) {
      if (wallValue === "Probable") {
        return OrangeDot;
      } else if (
        wallValue === "OUI" ||
        wallValue === "Yes, for the most part"
      ) {
        let wallcalculatedvalue = wallInsulationv2value(
          response?.wall_insulation_value
        );
        if (wallcalculatedvalue === "3-6 cm") {
          return OrangeDot;
        } else if (wallcalculatedvalue === "7-9 cm") {
          return OrangeDot;
        } else if (wallcalculatedvalue === "10 cm+") {
          return GreenDot;
        }
      } else {
        return RedDot;
      }
    }
  };

  const RoofInsulationColor = (response: any) => {
    let roofValue = response?.roof_insulation_key;

    if (roofValue !== "" && roofValue !== undefined) {
      if (roofValue === "Probable") {
        return OrangeDot;
      } else if (
        roofValue === "OUI" ||
        roofValue === "Yes, for the most part"
      ) {
        let roofcalculatedvalue = roofInsulationv2value(
          response?.roof_insulation_value
        );
        //console.log("roofcalculatedvalue", roofcalculatedvalue);
        if (roofcalculatedvalue === "3-7 cm") {
          return OrangeDot;
        } else if (roofcalculatedvalue === "8-13 cm") {
          return OrangeDot;
        } else if (roofcalculatedvalue === "14-19 cm") {
          return GreenDot;
        } else if (roofcalculatedvalue === "20 cm+") {
          return GreenDot;
        }
      } else {
        return RedDot;
      }
    }
  };

  const heatingValuesToNumbersHandlerColor = (
    heating_type: any,
    subtype: any,
    fuel: any
  ) => {
    if (heating_type === "boiler" && subtype === "standard" && fuel === "oil") {
      return RedDot;
    } else if (
      heating_type === "boiler" &&
      subtype === "standard" &&
      fuel === "gas"
    ) {
      return RedDot;
    } else if (
      heating_type === "electric_heater" &&
      subtype === "standard" &&
      fuel === "electricity"
    ) {
      return OrangeDot;
    } else if (
      heating_type === "boiler" &&
      subtype === "condensing" &&
      fuel === "gas"
    ) {
      return GreenDot;
    } else if (
      heating_type === "heat_pump" &&
      subtype === "air_water" &&
      fuel === "electricity"
    ) {
      return GreenDot;
    } else if (
      heating_type === "heat_pump" &&
      subtype === "ground_water" &&
      fuel === "electricity"
    ) {
      return GreenDot;
    } else {
      return RedDot;
    }
  };
  const heatingValuesToNumbersHandlerColorv2 = (heating_type: any) => {
    if (
      heating_type === "non-condesing2" ||
      heating_type === "non-condesing1"
    ) {
      return RedDot;
    } else if (
      heating_type === "electric-heating" ||
      heating_type === "condesing1"
    ) {
      return OrangeDot;
    } else if (heating_type === "condesing2" || heating_type === "heatpump") {
      return GreenDot;
    } else {
      return RedDot;
    }
  };
  const mechanicalSystemHandlerColor = (value: any) => {
    switch (value) {
      case "":
        return RedDot;
      case "system_c":
        return OrangeDot;
      case "system_cplus":
        return GreenDot;
      case "system_d":
        return GreenDot;
      default:
        return RedDot;
    }
  };
  const mechanicalSystemHandlerColorv2 = (value: any) => {
    switch (value) {
      case "":
        return RedDot;
      case "2":
        return OrangeDot;
      case "3":
        return GreenDot;
      case "4":
        return GreenDot;
      default:
        return RedDot;
    }
  };

  const mechanicalSystemHandlervalue2 = (value: any) => {
    switch (value) {
      case "1":
        return "NON";
      case "2":
        return "Système de ventilation C";
      case "3":
        return "Système de ventilation C+";
      case "4":
        return "Système de ventilation D";
      default:
        return "NON";
    }
  };

  console.log(
    " refinementCall2Record?.roof_insulation_value ",
    refinementCall2Record?.number_panels
  );

  const Amenities_demos = [
    {
      id: 1,
      key: getResultContent(9),
      icon: Window,
      dotIcon:
        refinementCall2Record && Object.keys(refinementCall2Record).length > 0
          ? refinementCall2Record.window_type === "hr_double_before_2000"
            ? windowAndDoorsColor("ordinary_double")
            : windowAndDoorsColorv2(refinementCall2Record?.glazing)
          : // default values
          allRenovationRecord && allRenovationRecord.glazing !== null
          ? windowAndDoorsColorv2(allRenovationRecord?.glazing)
          : windowAndDoorsColorv2(allRenovationRecord?.glazing),

      value:
        refinementCall2Record && Object.keys(refinementCall2Record).length > 0
          ? windowAndDoorsv2(refinementCall2Record?.glazing)
          : // default values
          allRenovationRecord && allRenovationRecord?.glazing !== null
          ? windowAndDoorsv2(allRenovationRecord?.glazing)
          : windowAndDoorsv2(allRenovationRecord?.glazing),
    },
    {
      id: 2,
      key: getResultContent(13),
      icon: Wall,
      dotIcon:
        refinementCall2Record && Object.keys(refinementCall2Record).length > 0
          ? wallInsulationColor(refinementCall2Record)
          : // if record not exist in DB
            wallInsulationColorv2(allRenovationRecord),
      value:
        refinementCall2Record &&
        Object.keys(refinementCall2Record)?.length !== 0 &&
        refinementCall2Record?.wall_insulation_key !== "" &&
        (refinementCall2Record?.wall_insulation_key === "OUI" ||
          refinementCall2Record?.wall_insulation_key ===
            "Yes, for the most part")
          ? refinementCall2Record?.wall_insulation_key === "OUI"
            ? refinementCall2Record?.wall_insulation_key +
              ", " +
              wallInsulationv2value(
                refinementCall2Record?.wall_insulation_value
              )
            : "Oui, pour la plus grande partie" +
              ", " +
              wallInsulationv2value(
                refinementCall2Record?.wall_insulation_value
              )
          : refinementCall2Record &&
            Object.keys(refinementCall2Record)?.length !== 0 &&
            refinementCall2Record?.wall_insulation_key !== "" &&
            refinementCall2Record?.wall_insulation_key !== "OUI"
          ? refinementCall2Record?.wall_insulation_key === "NON"
            ? "NON"
            : "Oui, mais seulement une petite partie"
          : allRenovationRecord
          ? allRenovationRecord?.facade_insulation_exterior === "Probable"
            ? "OUI, 3-6 cm"
            : allRenovationRecord?.facade_insulation_exterior === "OUI" ||
              allRenovationRecord?.cavity_insulation === "OUI"
            ? `OUI, ${wallInsulationv2(allRenovationRecord?.construction_date)}`
            : "NON"
          : "NON",
    },
    {
      id: 3,
      key: getResultContent(10),
      icon: Roof,
      dotIcon:
        refinementCall2Record &&
        refinementCall2Record !== undefined &&
        Object.keys(refinementCall2Record).length > 0
          ? RoofInsulationColor(refinementCall2Record)
          : // if record not exist in DB
            RoofInsulationColorv2(allRenovationRecord),

      value:
        // if user record exist in DB
        refinementCall2Record &&
        Object.keys(refinementCall2Record)?.length !== 0 &&
        refinementCall2Record?.roof_insulation_key !== "" &&
        (refinementCall2Record?.roof_insulation_key === "OUI" ||
          refinementCall2Record?.roof_insulation_key ===
            "Yes, for the most part")
          ? refinementCall2Record?.roof_insulation_key === "OUI"
            ? refinementCall2Record?.roof_insulation_key +
              " , " +
              roofInsulationv2value(
                refinementCall2Record?.roof_insulation_value
              )
            : "Oui, pour la plus grande partie" +
              ", " +
              roofInsulationv2value(
                refinementCall2Record?.roof_insulation_value
              )
          : refinementCall2Record &&
            Object.keys(refinementCall2Record)?.length !== 0 &&
            refinementCall2Record?.roof_insulation_key !== "" &&
            refinementCall2Record?.roof_insulation_key !== "OUI"
          ? refinementCall2Record?.roof_insulation_key === "NON"
            ? "NON"
            : "Oui, mais seulement une petite partie"
          : allRenovationRecord
          ? allRenovationRecord?.roof_insulation === "Probable"
            ? "OUI, 3-6 cm"
            : allRenovationRecord?.roof_insulation === "OUI" ||
              allRenovationRecord?.roof_insulation === "Yes, for the most part"
            ? `OUI, ${roofInsulationv2(allRenovationRecord?.construction_date)}`
            : "NON"
          : "NON",
      // allRenovationRecord.roof_insulation === "OUI"
      //   ? roofInsulationv2(allRenovationRecord?.construction_date)
      //   : ""
      // : "",
    },
    {
      id: 4,
      key: getResultContent(14),
      icon: Heating,
      dotIcon:
        refinementCall2Record && Object.keys(refinementCall2Record).length > 0
          ? heatingValuesToNumbersHandlerColorv2(
              refinementCall2Record?.heating_type
            )
          : // default if user record not exits in DB
            heatingValuesToNumbersHandlerColorv2(
              allRenovationRecord?.heating_type
            ),
      value:
        refinementCall2Record &&
        refinementCall2Record !== null &&
        refinementCall2Record !== undefined &&
        Object.keys(refinementCall2Record).length > 0
          ? heatingValuesToNumbersHandlerv2(refinementCall2Record?.heating_type)
          : // default if user record not exits in DB
          allRenovationRecord &&
            allRenovationRecord.heating_type !== null &&
            allRenovationRecord.heating_type !== undefined
          ? allRenovationRecord.heating_type
          : getResultContent(361),
      // heatingValuesToNumbersHandler(
      //   allRenovationRecord?.heating_type,
      //   allRenovationRecord?.heating_sub_type,
      //   allRenovationRecord?.heating_fuel
      // ),
    },
    {
      id: 5,
      key: getResultContent(11),
      icon: Ventillation,
      // store data of user
      dotIcon:
        refinementCall2Record && refinementCall2Record?.ventilation_type
          ? mechanicalSystemHandlerColorv2(
              refinementCall2Record?.ventilation_type
            )
          : // default if user record not exits in DB
          allRenovationRecord?.mechanical_system_type !== null
          ? mechanicalSystemHandlerColor(
              allRenovationRecord?.mechanical_system_type
            )
          : "",
      value:
        // store data of user
        refinementCall2Record && refinementCall2Record?.ventilation_type
          ? mechanicalSystemHandlervalue2(
              refinementCall2Record?.ventilation_type
            )
          : // default if user record not exits in DB

            "NON",
    },
    {
      id: 6,
      key: getResultContent(16),
      icon: Solar,
      value:
        refinementCall2Record &&
        Object.keys(refinementCall2Record).length > 0 &&
        refinementCall2Record !== null &&
        refinementCall2Record !== undefined &&
        refinementCall2Record?.number_panels !== "" &&
        refinementCall2Record?.number_panels !== undefined &&
        refinementCall2Record?.number_panels !== 0
          ? "OUI, " + refinementCall2Record?.number_panels + " panneaux"
          : allRenovationRecord
          ? allRenovationRecord?.num_panels !== "-" &&
            allRenovationRecord?.num_panels !== "" &&
            allRenovationRecord?.presents === "OUI"
            ? "OUI, " + allRenovationRecord.num_panels + " panneaux"
            : "NON"
          : "NON",
      dotIcon:
        refinementCall2Record && Object.keys(refinementCall2Record).length > 0
          ? refinementCall2Record?.pv_panels === "NON"
            ? RedDot
            : refinementCall2Record !== undefined ||
              refinementCall2Record?.number_panels !== "" ||
              refinementCall2Record?.number_panels !== undefined
            ? GreenDot
            : RedDot
          : allRenovationRecord?.presents !== "" &&
            allRenovationRecord?.presents === "OUI"
          ? GreenDot
          : RedDot,
      // refinementCall2Record === undefined ||
      // refinementCall2Record?.pv_panels === "NON" ||
      // refinementCall2Record?.pv_panels === undefined
      //   ? RedDot
      //   : refinementCall2Record !== undefined ||
      //     refinementCall2Record?.number_panels !== "" ||
      //     refinementCall2Record?.number_panels !== undefined
      //   ? GreenDot
      //   : allRenovationRecord?.presents !== "" &&
      //     allRenovationRecord?.presents === "OUI"
      //   ? GreenDot
      //   : RedDot,

      // show: 1,
    },
  ];

  const currentEpcHandler = (epc_label: any) => {
    let epc: any = parseInt(epc_label);
    if (epc <= 0) {
      return EPC_APlusPlus;
    } else if (epc <= 45) {
      return EPC_APlus;
    } else if (epc <= 85) {
      return EPC_A;
    } else if (epc <= 170) {
      return EPC_B;
    } else if (epc <= 255) {
      return EPC_C;
    } else if (epc <= 340) {
      return EPC_D;
    } else if (epc <= 425) {
      return EPC_E;
    } else if (epc <= 510) {
      return EPC_F;
    } else {
      return EPC_G;
    }
  };
  /* index for bad:18 , medium:19 , good:20 */
  const currentEpcStatus = (epc_label: any) => {
    // switch (epc_label) {
    //   case "1":
    //     return getResultContent(154);
    //   // return getResultContent(20);
    //   case "2":
    //     return getResultContent(155);
    //   // return getResultContent(20);
    //   case "3":
    //     return getResultContent(156);
    //   // return getResultContent(19);
    //   case "4":
    //     return getResultContent(157);
    //   // return getResultContent(18);
    //   case "5":
    //     return getResultContent(158);
    //   // return getResultContent(18);
    //   case "6":
    //     return getResultContent(158);
    //   default:
    //     return getResultContent(158);
    // }
    let epc: any = parseInt(epc_label);
    if (epc <= 0) {
      return getResultContent(154);
    } else if (epc <= 45) {
      return getResultContent(154);
    } else if (epc <= 85) {
      return getResultContent(154);
    } else if (epc <= 170) {
      return getResultContent(155);
    } else if (epc <= 255) {
      return getResultContent(156);
    } else if (epc <= 340) {
      return getResultContent(157);
    } else if (epc <= 425) {
      return getResultContent(158);
    } else if (epc <= 510) {
      return getResultContent(158);
    } else {
      return getResultContent(158);
    }
  };

  const activeStars = (value: number) => {
    let allStars = [];
    for (let i = 1; i < 6; i++) {
      if (i <= value) {
        allStars?.push({
          id: i,
          show: "active",
        });
      } else {
        allStars?.push({
          id: i,
          show: "inActive",
        });
      }
    }
    return (
      <>
        <div className="flex items-center ">
          {allStars?.map((single: any, i: any) => (
            <React.Fragment key={i}>
              {single.show === "active" ? (
                <img className="h-6 w-6" src={Star} alt="" />
              ) : (
                <img className="h-6 w-6" src={StarGrey} alt="" />
              )}
            </React.Fragment>
          ))}
        </div>
      </>
    );
  };

  const getSavings = (): any => {
    let total: any = 0;

    let key =
      refinementCall2Record && Object.keys(refinementCall2Record).length > 0
        ? futureComfort(refinementCall2Record, total)
        : currentComfort(allRenovationRecord, total);
    console.log("key-123", key);
    if (key) {
      if (key <= 20) {
        return activeStars(1);
      } else if (key <= 40) {
        return activeStars(2);
      } else if (key <= 60) {
        return activeStars(3);
      } else if (key <= 80) {
        return activeStars(4);
      } else if (key <= 100) {
        return activeStars(5);
      }
    } else {
      return activeStars(1);
    }
  };

  // download refinement
  const downloadRefinementRecord = (key: any) => {
    if (
      downloadRecord &&
      Object.keys(downloadRecord)?.length > 0 &&
      key === 1
    ) {
      var zip = new JSZip();
      zip.file("Call2-Response.txt", JSON.stringify(downloadRecord));
      zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, "houseRefinement-response");
      });
    }
  };

  return (
    <>
      {/* model */}
      <MoreOptionModal
        getResultContent={getResultContent}
        visible={visible}
        setVisible={setVisible}
      />
      {/*  */}
      <div
        className={`w-full lg:w-1/2 listingSection__wrap diagnosis-container h-auto section_width border border-[#F0F0F0] rounded-[10px] lg:mx-2 my-0 my-0 p-0 ${className}`}
      >
        <div className="flex justify-between px-4 py-4 ">
          <div className="flex flex-col lg:flex-row items-center w-full">
            <div className="w-full lg:w-1/2">
              <div className="flex items-center">
                {!renovationLoader ? (
                  <img src={currentEpcHandler(epc_value)} alt="" />
                ) : (
                  <Spin />
                )}
              </div>
              <div className="flex items-center pt-2 pl-1">
                <img
                  src={Info}
                  alt="Info"
                  className="h-[15px] pl-1 cursor-pointer"
                  onClick={() => setVisible(true)}
                />
                <span className="text-[14px] text-[#007DDF] px-2 underline">
                  {getResultContent && getResultContent(294)}
                </span>
              </div>
            </div>
            <div className="w-full lg:w-1/2 px-4 text-left m-0 flex flex-col">
              <span className="text-xs text-black font-normal">
                {console.log("currentResultContent", epc_value)}
                {getResultContent(17)} {currentEpcStatus(epc_value)}
                <br />
                <b className="text-black font-medium ">
                  {getResultContent(21)}
                </b>
              </span>
              {/* <span className="">{getResultContent(21)}</span> */}
            </div>
          </div>
        </div>
        <div className="border border-[#F0F0F0]"></div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 w-full p-4">
          {Amenities_demos?.map((item, i) => (
            <React.Fragment key={i}>
              {/* {item?.show === 1 && ( */}
              <div key={i} className="flex items-start space-x-3">
                <div className="flex items-center w-[25%]">
                  <div>
                    <img
                      src={item?.dotIcon ? item?.dotIcon : RedDot}
                      alt=""
                      className="rounded h-5 w-5"
                      onClick={() => downloadRefinementRecord(item?.id)}
                    />
                  </div>
                  <div>
                    <Avatar
                      sizeClass="h-8 w-8"
                      radius="rounded"
                      imgUrl={item?.icon ? item?.icon : ""}
                    />
                  </div>
                </div>
                {/* <div>
                <img src={item?.icon ? item?.icon : ""} />
              </div> */}
                <div className="flex flex-col w-[75%]">
                  <span className="text-sm font-bold text-[#455259]">
                    {item?.key}
                  </span>
                  <span className="text-xs font-normal text-[#455259] py-1">
                    {item?.value}
                  </span>
                </div>
              </div>
              {/* )} */}
            </React.Fragment>
          ))}
        </div>
        {/* footer */}
        <div className="">
          <div className="flex flex-col lg:flex-row justify-between p-4 lg:p-8 items-center w-full bg-[#F4FAFF] border-b-r-10 border-b-l-10">
            <div className="text-sm  mb-2 lg:mb-0">
              <span>{getResultContent && getResultContent(78)}</span>
              {getSavings()}
              {/* {getSavings(parseInt(currentValue))} */}
            </div>
            <div className="flex justify-end  ">
              {isCallWaitng ? (
                <button className="outline-primary-button ">
                  <Spin indicator={antIcon} />
                </button>
              ) : (
                (userStatus?.data?.status < 3 ||
                  userStatus?.data == null ||
                  userStatus?.data?.status == undefined) &&
                !isAdminView && (
                  <button
                    className={
                      "outline-primary-button hover:bg-[#007DDF] hover:text-[#fff]  border border-[#007DDF] rounded text-[#007DDF] font-normal text-base py-2 px-4"
                    }
                    onClick={() => {
                      openModal("refine-information2");
                    }}
                  >
                    {getResultContent(22)}
                  </button>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionDiagnosis;
