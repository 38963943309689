import React, { FC, useState } from "react";
import RenovatesSideNav from "../../components/renovates/sideNav";
import WindowsRenovations from "../../components/renovates/selectRenovates/windowsRenovations";
import SolarPannelRenovations from "../../components/renovates/selectRenovates/solarPannelRenovations";
import RoofInsulation from "../../components/renovates/selectRenovates/roofInsulation";
import WallInsulation from "../../components/renovates/selectRenovates/wallInsulation";
import Heating from "../../components/renovates/selectRenovates/heating";
import Ventilation from "../../components/renovates/selectRenovates/ventilation";

interface RenovatesInterface {}

const Renovates: FC<RenovatesInterface> = () => {
  const [activeTab, setActiveTab] = useState(1);

  const ActiveTabHandler = (active: number) => {
    switch (active) {
      case 1:
        return <WindowsRenovations />;
      case 2:
        return <SolarPannelRenovations />;
      case 3:
        return <RoofInsulation />;
      case 4:
        return <WallInsulation />;
      case 5:
        return <Heating />;
      case 6:
        return <Ventilation />;
      default:
        return <WindowsRenovations />;
    }
  };

  return (
    <div className="mx-[5%] lg:mx-[10%] flex flex-col lg:flex-row py-4 ">
      <div className="lg:w-[33%]">
        <RenovatesSideNav activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
      <div className="lg:w-[67%]">{ActiveTabHandler(activeTab)}</div>
    </div>
  );
};
export default Renovates;
