import React, { FC, useEffect, useContext } from "react";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
import Avatar from "../../shared/Avatar/Avatar";
import Savings from "../../assets/images/resultPage2/icon_color/savings_2.png";
import Star from "../../assets/images/resultPage2/star.png";
import StarGrey from "../../assets/images/resultPage2/star_grey.svg";
import Cloud from "../../assets/images/resultPage2/icon_color/cloud.png";
import HouseValue from "../../assets/images/resultPage2/icon_color/house_value.png";
import Renting from "../../assets/images/resultPage2/icon_color/savings.png";
import informationImg from "../../assets/images/resultPage2/information.png";
import { currentComfort, GetUserStatus } from "../../utils/renovations";
import { pricesDotHandler } from "../../containers/result/constants";
import { SetUserProfileStatusByService } from "../../services/ResultPage";
import { match } from "interweave";
import { InfoCircleOutlined } from "@ant-design/icons";
import { GlobalContext } from "../../context/globalContext/globalContext";
import { get_construction_date } from "../../utils/buildingDetailsHandler";

export interface SectionSliderNewCategoriesProps {
  className?: string;
  itemClassName?: string;
  openModal?: any;
  getResultContent?: any;
  renovations?: any;
  futureEpc?: any;
  loggedInUser?: any;
  userStatus?: any;
  houseDetails?: any;
  setTotalValue?: any;
  userSelectedData?: any;
  selectedInd?: any;
  refinementCall2Record?: any;
  allRenovationRecord?: any;
  customStars?: any;
  customPackSelected?: any;
  setRenovationModalContent?: any;
  setUserStatus: any;
  selectedUserCombination: any;
  setCustom: any;
  selectedPack: any;
  isAdminView: any;
  subsidies: number;
  subtest?: any;
  selectedPackData?: any;
  selectedPackRenovations?: any;
  currenSituationDataEpc?: any;
  currenSituationData?: any;
  epc_value_current_epc?: any;
}

const SectionSliderNewCategories: FC<SectionSliderNewCategoriesProps> = ({
  className = "",
  itemClassName = "",
  subsidies = 1,
  openModal,
  getResultContent,
  renovations,
  futureEpc,
  loggedInUser,
  userStatus,
  houseDetails,
  setTotalValue,
  userSelectedData,
  selectedInd,
  refinementCall2Record,
  allRenovationRecord,
  customStars,
  customPackSelected,
  setRenovationModalContent,
  setUserStatus,
  selectedUserCombination,
  setCustom,
  selectedPack,
  isAdminView,
  subtest,
  selectedPackData,
  selectedPackRenovations,
  currenSituationDataEpc,
  currenSituationData,
  epc_value_current_epc,
}) => {
  const { state, dispatch }: any = useContext(GlobalContext);

  //
  let totalBudgetValue: any;
  let totalSubsidyValue: any;

  const renovationPricesHandler = (value: any) => {
    let price = pricesDotHandler(value);
    return price;
  };

  const getRenovationPrices = (key: any) => {
    let investment_cost: any = subtest && JSON.parse(subtest);

    switch (key) {
      case "windowsDoubleAndDoors":
        return parseInt(investment_cost?.window_area) * 300 * 2;
      case "wallCav":
        return parseInt(investment_cost?.wall_area) * 30;
      case "wallExt":
        return parseInt(investment_cost?.wall_area) * 180;
      case "roofAttic":
        return parseInt(investment_cost?.roof_area) * 25;
      case "roofIn":
        return parseInt(investment_cost?.roof_area) * 70;
      case "roofExt":
        return parseInt(investment_cost?.roof_area) * 270;
      case "ventC+":
        // + 3* protected volume = height of house * floor area
        return 4500;
      // + 6* protected volume = height of house * floor area

      case "ventD":
        return 5500;
      case "condGas":
        return 4000;
      case "heatPump":
        // additional calculation based on heatpump production (kw)
        //500 € + 40€/m2(floor_area * num_floors)
        return 10000;
      case "glazing":
        return parseInt(investment_cost.window_area) * 300;
      case "solar":
        return 0;
      default:
        return "";
    }
  };

  // total budget
  const totalBudget = () => {
    let totalBudget: any = 0;
    renovations?.map((total: any) => {
      // if (total?.investment_cost !== null && total?.active === 1) {
      //   totalBudget = totalBudget + parseInt(total?.investment_cost);
      // }
      if (
        // total?.total_subsidy !== null &&
        // total?.total_subsidy &&
        total?.active === 1
      ) {
        // totalSubsidy = totalSubsidy + parseInt(total?.total_subsidy);
        totalBudget += getRenovationPrices(total?.renovation);
      }
    });
    console.log("totalBudgetValue", totalBudgetValue);
    totalBudgetValue = totalBudget;
    return renovationPricesHandler(parseInt(totalBudget));
  };

  const getRenovationSubsidy = (key: any) => {
    let investment_cost: any = subtest && JSON.parse(subtest);
    switch (key) {
      case "windowsDoubleAndDoors":
        return parseInt(investment_cost.window_area) * 390;
      case "wallCav":
        return parseInt(investment_cost.wall_area) * 132;
      case "wallExt":
        return parseInt(investment_cost.wall_area) * 132;
      case "roofAttic":
        return parseInt(investment_cost.roof_area) * 90;
      case "roofIn":
        return parseInt(investment_cost.roof_area) * 300;
      case "roofExt":
        return parseInt(investment_cost.roof_area) * 300;
      case "ventC+":
        return 1200;
      case "ventD":
        return 2400;
      case "condGas":
        return 0;
      case "heatPump":
        return 9000;
      case "glazing":
        return parseInt(investment_cost.window_area) * 390;
      case "solar":
        return 0;
      default:
        return "";
    }
  };

  const getActiveRenovationCount = (key: any) => {
    switch (key) {
      case "window":
        return 1;
      case "wall":
        return 1;

      case "roof":
        return 1;
      case "ventc+":
        return 1;
      case "ventd":
        return 1;
      case "condgas":
        return 1;
      case "heatpump":
        return 1;
      case "glazing":
        return 1;
      case "solar":
        return 0;
      default:
        return "";
    }
  };
  const activeRenonations = (renovation: string) => {
    if (selectedPackData?.combo?.includes(renovation)) {
      return 1;
    } else {
      return 0;
    }
  };
  // subsidy city based calculation
  const getRenovationSubsidyCity: any = () => {
    let subsidy_total_city: any = 0;
    // option 1 is seleceted
    if (subsidies === 1) {
      //total subsidy of selected renovations
      let subsidy_total_city: any =
        (parseFloat(getRenovationTotalSubsidy()) * 50) / 100;

      //if 50% of total subsidy is greater then 10k then return 10k

      if (parseInt(subsidy_total_city) > 10000) {
        subsidy_total_city = 10000;
      } else {
        subsidy_total_city = subsidy_total_city;
      }
      //agc-v2
      // if total - subsidy is less then 0 then we reduce city subsidy so overall total can come to 0'
      // we check if solar is selected then solar cost will be minus
      let overalltotal: any = 0;
      let solariselected = selectedPackData?.combo?.includes("solar");

      let solarcost = 0;

      if (solariselected) {
        solarcost = selectedPackData?.solar_cost;
        overalltotal =
          getRenovationBudget() -
          getRenovationTotalSubsidy() -
          solarcost -
          subsidy_total_city;
      } else {
        overalltotal =
          getRenovationBudget() -
          getRenovationTotalSubsidy() -
          subsidy_total_city;
      }

      //overalltotal = -4782;
      if (parseInt(overalltotal) < 0) {
        return subsidy_total_city - Math.abs(overalltotal);
      } else {
        return subsidy_total_city;
      }

      //option 2 is selected
    } else {
      //if total number of renovation is greater then 1 without solar
      //  roof_solar = 1
      //  roof_wall = 2
      //  roof_wall_solar = 2

      let totalWithoutSolar: any = 0;

      if (selectedPackRenovations?.combo?.includes("_")) {
        selectedPackRenovations?.combo?.split("_").map((item: any) => {
          if (activeRenonations(item) === 1) {
            totalWithoutSolar =
              totalWithoutSolar + getActiveRenovationCount(item);
          }
        });
      } else {
        totalWithoutSolar =
          totalWithoutSolar +
          getActiveRenovationCount(selectedPackRenovations?.combo);
      }

      if (parseInt(totalWithoutSolar) > 1) {
        subsidy_total_city = 10000;
      } else {
        subsidy_total_city = 0;
      }
      //agc-v2
      // if total - subsidy is less then 0 then we reduce city subsidy so overall total can come to 0'
      // let overalltotal: any =
      //   getRenovationBudget() -
      //   getRenovationTotalSubsidy() -
      //   subsidy_total_city;
      let overalltotal: any = 0;
      let solariselected = selectedPackData?.combo?.includes("solar");

      let solarcost = 0;

      if (solariselected) {
        solarcost = selectedPackData?.solar_cost;
        overalltotal =
          getRenovationBudget() -
          getRenovationTotalSubsidy() -
          solarcost -
          subsidy_total_city;
      } else {
        overalltotal =
          getRenovationBudget() -
          getRenovationTotalSubsidy() -
          subsidy_total_city;
      }

      //overalltotal = -4782;
      if (parseInt(overalltotal) < 0) {
        return subsidy_total_city - Math.abs(overalltotal);
      } else {
        return subsidy_total_city;
      }
    }

    // let investment_cost: any = subtest && JSON.parse(subtest);
    //return "20";
  };

  const getremaingcost: any = () => {
    let totalcost = (getRenovationBudget() * 10) / 100;

    let tebpercentcost =
      getRenovationBudget() -
      getRenovationTotalSubsidy() -
      getRenovationSubsidyCity();

    if (tebpercentcost < totalcost) {
      return 1;
    } else {
      return 0;
    }
  };
  const totalSubsidy = () => {
    let totalSubsidy: any = 0;
    renovations?.map((total: any) => {
      if (
        // total?.total_subsidy !== null &&
        // total?.total_subsidy &&
        total?.active === 1
      ) {
        // totalSubsidy = totalSubsidy + parseInt(total?.total_subsidy);
        totalSubsidy += getRenovationSubsidy(total?.renovation);
      }
    });

    totalSubsidyValue = totalSubsidy;
    // return renovationPricesHandler(parseInt(totalSubsidy));
    return totalSubsidy;
  };

  //   total yearly savings
  const totalYearlySavings = () => {
    // console.log("housdetailraw", houseDetails);
    console.log("currenSituationDataEpc", currenSituationDataEpc);

    // console.log("houseDetails?.surfsol", houseDetails?.surfsol);
    // console.log("houseDetails?.num_floors", houseDetails?.num_floors);
    let totalYearlySavings: any = 0;

    // for current default buildings we only assuem gas condensing
    if (
      isNaN(
        selectedPackData?.result?.ecolabel_value
          ? selectedPackData?.result?.ecolabel_value
          : selectedPackData?.result?.ecolabel_now_value
      )
    ) {
      totalYearlySavings = 0;
    } else {
      totalYearlySavings =
        (currenSituationDataEpc - selectedPackData?.result?.ecolabel_value
          ? selectedPackData?.result?.ecolabel_value
          : selectedPackData?.result?.ecolabel_now_value) *
        0.093 *
        houseDetails?.surfsol *
        houseDetails?.num_floors;
    }

    totalYearlySavings = renovationPricesHandler(totalYearlySavings);
    return totalYearlySavings;
  };

  // agcv2 c02
  const totalc02savings = () => {
    let totalc02: any = 0;
    if (
      !isNaN(
        selectedPackData?.result?.ecolabel_value
          ? selectedPackData?.result?.ecolabel_value
          : selectedPackData?.result?.ecolabel_now_value
      )
    ) {
      totalc02 =
        parseInt(currenSituationData?.result?.co2) -
        parseInt(selectedPackData?.result?.co2);
    } else {
      totalc02 = 0;
    }
    return parseInt(totalc02);
  };
  //   total co2
  const totalCO2 = () => {
    let totalCo2: any = 0;
    renovations?.map((total: any) => {
      if (total?.co2_savings !== null && total?.active === 1) {
        totalCo2 = totalCo2 + parseInt(total?.co2_savings);
      }
    });
    totalCo2 = renovationPricesHandler(totalCo2);
    return totalCo2 ? totalCo2 : 0;
  };

  const currentEpcHandler = (epc_label: any) => {
    let epc: any =
      parseInt(epc_value_current_epc) > parseInt(epc_label)
        ? parseInt(epc_label)
        : parseInt(epc_value_current_epc);
    if (epc <= 0) {
      return "A++";
    } else if (epc <= 45) {
      return "A+";
    } else if (epc <= 85) {
      return "A";
    } else if (epc <= 170) {
      return "B";
    } else if (epc <= 255) {
      return "C";
    } else if (epc <= 340) {
      return "D";
    } else if (epc <= 425) {
      return "E";
    } else if (epc <= 510) {
      return "F";
    } else {
      return "G";
    }
  };

  const baseFutureEpcHandler = (epc_label: any) => {
    if (epc_label < 150) {
      return 5;
    } else if (epc_label < 200) {
      return 4;
    } else if (epc_label < 300) {
      return 3;
    } else if (epc_label < 400) {
      return 2;
    } else if (epc_label < 500) {
      return 1;
    } else if (epc_label < 600) {
      return 1;
    } else {
      return 1;
    }
  };

  const currentEpcClassHandler = (epc_label: any) => {
    let epc: any =
      parseInt(epc_value_current_epc) > parseInt(epc_label)
        ? parseInt(epc_label)
        : parseInt(epc_value_current_epc);
    if (epc <= 0) {
      return "#4fafee";
    } else if (epc <= 85) {
      return "#00b648";
    } else if (epc <= 170) {
      return "#45c247";
    } else if (epc <= 255) {
      return "#c0da00";
    } else if (epc <= 340) {
      return "#e4e500";
    } else if (epc <= 425) {
      return "#ffc200";
    } else if (epc <= 510) {
      return "#ff9500";
    } else {
      return "#fc0009";
    }
  };

  const updateUserStatus = () => {
    const payload = {
      userId: userStatus?.data.userId,
      userDataId: userStatus?.data.userDataId,
      status: 4,
      base_offer: JSON.stringify([selectedUserCombination]),
    };
    SetUserProfileStatusByService(payload)
      .then((profileResponse: any) => {
        setUserStatus(profileResponse);
        setCustom(false);
      })
      .catch((error: any) => {});
  };

  const modelHandler = (userStatus: any) => {
    /// console.log("testmodal", userStatus);
    switch (parseInt(userStatus)) {
      case 1:
        return loggedInUser
          ? openModal("book-an-audit")
          : openModal("book-an-audit");
      case 2:
        return loggedInUser ? openModal("audit-waiting") : openModal("login");
      case 3:
        return loggedInUser ? updateUserStatus() : openModal("login");
      case 4:
        return loggedInUser ? openModal("audit-waiting") : openModal("login");
      case 5:
        return loggedInUser ? openModal("") : openModal("login");
      default:
        return loggedInUser ? openModal("book-an-audit") : openModal("login");
    }
  };

  const buttonTextHandler = (userStatus: any) => {
    switch (parseInt(userStatus)) {
      case 1:
        return getResultContent(56);
      case 2:
        return getResultContent(249);
      case 3:
        return getResultContent(250);
      case 4:
        return getResultContent(249);
      case 5:
        return getResultContent(251);
      default:
        return getResultContent(56);
    }
  };

  const activeStars = (value: number) => {
    let allStars = [];
    for (let i = 1; i < 6; i++) {
      if (i <= value) {
        allStars?.push({
          id: i,
          show: "active",
        });
      } else {
        allStars?.push({
          id: i,
          show: "inActive",
        });
      }
    }

    console.log("valuenum", value);

    return (
      <>
        <div className="flex items-center px-4">
          {allStars?.map((single: any, i: any) => (
            <React.Fragment key={i}>
              {single.show === "active" ? (
                <img className="h-6 w-6" src={Star} alt="" />
              ) : (
                <img className="h-6 w-6" src={StarGrey} alt="" />
              )}
            </React.Fragment>
          ))}
        </div>
      </>
    );
  };

  const selectedRenovationsTotal = (
    total: any,
    selectedRenovationsValue: any
  ) => {
    selectedRenovationsValue?.map((item: any) => {
      if (item === "window") {
        return (total = total + 4);
      }
      if (item === "glazing") {
        return (total = total + 4);
      }
      if (item === "wall") {
        return (total = total + 2);
      }

      if (item === "roof") {
        return (total = total + 3);
      }
      if (item === "ventc+") {
        return (total = total + 2);
      }
      if (item === "ventd") {
        return (total = total + 4);
      }
      if (item === "condgas") {
        return (total = total + 1);
      }
      if (item === "heatpump") {
        return (total = total + 1);
      }
    });
    console.log("selectedd total", total);

    return total;
  };

  // for agc v2
  const wallInsulationv2 = (key: any) => {
    let construction_date = get_construction_date(key);
    if (parseInt(construction_date) < 1960) {
      return "3-6 cm";
    } else if (
      parseInt(construction_date) >= 1960 &&
      parseInt(construction_date) < 2006
    ) {
      return "7-9 cm";
    } else if (parseInt(construction_date) >= 2006) {
      return "10 cm+";
    }
  };

  const wallInsulationv2value = (value: any) => {
    let answere = "";

    if (parseInt(value) >= 3 && parseInt(value) <= 6) {
      answere = "3-6 cm";
    }
    // 7-9 cm
    else if (parseInt(value) >= 7 && parseInt(value) <= 9) {
      answere = "7-9 cm";
    }
    // 10 cm+
    else if (parseInt(value) >= 10) {
      answere = "10 cm+";
    }

    return answere;
  };

  const roofInsulationv2value = (value: any) => {
    let answere = "";

    if (parseInt(value) >= 3 && parseInt(value) <= 7) {
      answere = "3-7 cm";
    }
    // 8 - 12 cm
    else if (parseInt(value) >= 8 && parseInt(value) <= 13) {
      answere = "8-13 cm";
    }
    // 14 - 18 cm
    else if (parseInt(value) >= 14 && parseInt(value) <= 19) {
      answere = "14-19 cm";
    }
    // 20 cm +
    else if (parseInt(value) > 19) {
      answere = "20 cm+";
    }
    return answere;
  };

  const roofInsulationv2 = (key: any) => {
    //construction_date = 2009;
    let construction_date = get_construction_date(key);
    if (parseInt(construction_date) < 1960) {
      return "3-6 cm";
    } else if (
      parseInt(construction_date) >= 1960 &&
      parseInt(construction_date) < 1985
    ) {
      return "8-12 cm";
    } else if (
      parseInt(construction_date) >= 1986 &&
      parseInt(construction_date) < 2006
    ) {
      return "14-18 cm";
    } else if (parseInt(construction_date) >= 2006) {
      return "20 cm+";
    }
  };
  const unSelectedRenovationsTotal = (
    total: any,
    unSelectedRenovationsValue: any,
    response: any
  ) => {
    //console.log("responsedb", response);
    let window_check = 0;
    unSelectedRenovationsValue?.map((item: any) => {
      if ((item === "window" || item === "glazing") && window_check === 0) {
        //
        window_check = 1;

        // glazing , ordinary_double ,single
        if (response?.window_type === "S" || response?.glazing === "S") {
          total = total + 0;
        } else if (
          response?.window_type === "ordinary_double" ||
          response?.window_type === "hr_double_before_2000" ||
          response?.glazing === "D" ||
          response?.window_type === "D"
        ) {
          total = total + 2;
        } else if (
          response?.window_type === "triple" ||
          response?.window_type === "hr_double_after_2000" ||
          response?.glazing === "DR" ||
          response?.glazing === "T" ||
          response?.window_type === "DR" ||
          response?.window_type === "T"
        ) {
          // console.log("test99", response?.glazing);
          total = total + 4;
        }
      }

      // console.log("windtotal1", total);
      if (item === "wall") {
        let wallValue = response?.wall_insulation_key
          ? response?.wall_insulation_key
          : allRenovationRecord?.facade_insulation_exterior;
        if (wallValue !== "" && wallValue !== undefined) {
          if (wallValue === "Probable") {
            total = total + 1;
          } else if (wallValue === "OUI") {
            let wallcalculatedvalue = response?.wall_insulation_value
              ? wallInsulationv2value(response?.wall_insulation_value)
              : wallInsulationv2(allRenovationRecord?.construction_date);
            if (wallcalculatedvalue === "3-6 cm") {
              total = total + 1;
            } else if (wallcalculatedvalue === "7-9 cm") {
              total = total + 1;
            } else if (wallcalculatedvalue === "10 cm+") {
              total = total + 2;
            }
          } else {
            total = total + 0;
          }
        }
      }
      // roof
      if (item === "roof") {
        // roof
        let RoofValue = response?.roof_insulation_key
          ? response?.roof_insulation_key
          : allRenovationRecord?.roof_insulation;

        // if (RoofValue !== "" && RoofValue !== undefined) {
        if (RoofValue === "Probable") {
          total = total + 1;
        } else if (RoofValue === "OUI") {
          let roofcalculatedvalue = response?.roof_insulation_value
            ? roofInsulationv2value(response?.roof_insulation_value)
            : roofInsulationv2(allRenovationRecord?.construction_date);
          if (roofcalculatedvalue === "3-7 cm") {
            total = total + 1;
          } else if (roofcalculatedvalue === "8-13 cm") {
            total = total + 2;
          } else if (roofcalculatedvalue === "14-19 cm") {
            total = total + 3;
          } else if (roofcalculatedvalue === "20 cm+") {
            total = total + 3;
          }
        } else {
          total = total + 0;
        }
      }
      // }
      // console.log("rooftotal", total);

      // Ventillation
      if (item === "ventc+" && parseInt(response?.ventilation_type) === 3) {
        total = total + 2;
      }
      if (item === "ventd" && parseInt(response?.ventilation_type) === 4) {
        total = total + 4;
      }
      // heatPump & condGas
      console.log("response?.heating", response);
      if (
        item === "condGas" ||
        item === "heatPump" ||
        item === "heatpump" ||
        item === "condgas"
      ) {
        let heating = response?.heating;
        if (heating !== "" && heating !== undefined) {
          if (
            (heating?.heating_type === "boiler" &&
              heating?.subtype === "condensing") ||
            heating?.heating_type === "heat_pump"
          ) {
            total = total + 1;
          }
        } else {
          if (
            (response?.heating_type === "boiler" &&
              response?.heating_sub_type === "condensing") ||
            response?.heating_type === "heat_pump" ||
            response?.heating_type === "heatpump" ||
            response?.heating_type === "condesing2"
          ) {
            total = total + 1;
          } else {
            total = total + 0;
          }
        }
      }
    });
    //console.log("unselectedd total", total);

    return total;
  };

  // agc v2

  const FutureComfortCalculation = () => {
    let selectedRenovationsValue: any = [];
    let unSelectedRenovationsValue: any = [];
    const renovationsList = [
      "window",
      "glazing",
      "wall",
      "roof",
      "heatpump",
      "condgas",
      "ventd",
      "ventc+",
      "solar",
    ];
    let selecdtedtotal = 0;
    let unSelectedTotal = 0;

    renovationsList.map((item: any) => {
      if (selectedPackData?.combo?.includes(item)) {
        selectedRenovationsValue.push(item);
      } else {
        //UnselectedRenovationNames;
        unSelectedRenovationsValue.push(item);
      }
    });

    // selected renovations
    let selectedTotalValue = selectedRenovationsTotal(
      selecdtedtotal,
      selectedRenovationsValue
    );
    // unSelectedTotalValue
    let unSelectedTotalValue = unSelectedRenovationsTotal(
      unSelectedTotal,
      unSelectedRenovationsValue,
      refinementCall2Record && refinementCall2Record?.length !== 0
        ? refinementCall2Record
        : allRenovationRecord
    );

    console.log("selectedTotalValue", selectedTotalValue);
    console.log("unSelectedTotalValue", unSelectedTotalValue);

    let allSelectedValues: any =
      ((selectedTotalValue + unSelectedTotalValue) / 14) * 100;

    // 5 star logic for customStars && customPackSelected || Pack A selected || if Pack A is selected in BaseOffer

    // custom select and remove logic
    // else if (customStars === 0 && parseInt(allSelectedValues) > 0) {
    if (allSelectedValues <= 20) {
      return activeStars(1);
    } else if (allSelectedValues <= 40) {
      return activeStars(2);
    } else if (allSelectedValues <= 60) {
      return activeStars(3);
    } else if (allSelectedValues <= 80) {
      return activeStars(4);
    } else if (allSelectedValues <= 100 || allSelectedValues >= 100) {
      return activeStars(5);
    } else {
      return activeStars(1);
    }
  };

  const currentEPC = (number: any) => {
    console.log("number", number);
    if (number === "1") {
      return activeStars(5);
    } else if (number === "2") {
      return activeStars(4);
    } else if (number === "3") {
      return activeStars(3);
    } else if (number === "4") {
      return activeStars(2);
    } else if (number === "5") {
      return activeStars(1);
    } else if (number === "6") {
      return activeStars(1);
    }
  };

  /**
   * Find number of itteration for renting and saling value.
   * @param value is current ind and future ind
   * @returns itterations.
   */
  const findNumberOftterations = (value: any) => {
    const vlu: any = parseInt(value);
    if (vlu > 500) return 0;
    else if (vlu > 400) return 1;
    else if (vlu > 300) return 2;
    else if (vlu > 200) return 3;
    else if (vlu > 100) return 4;
    else if (vlu < 100) return 5;
    else return undefined;
  };
  /**
   * Calculate renting and saling value.
   * @param key is string (renting or sales)
   * @returns renting and saling value.
   */

  const caluculateSalesAndRenting = (key: any) => {
    const currentItteration: any = findNumberOftterations(
      currenSituationDataEpc && currenSituationDataEpc
    );
    const futureIteration: any = findNumberOftterations(
      selectedPackData?.result?.ecolabel_value
        ? selectedPackData?.result?.ecolabel_value
        : selectedPackData?.result?.ecolabel_now_value
    );
    let numberOfItteration =
      currentItteration -
      (futureIteration ? futureIteration : currentItteration);
    if (numberOfItteration < 0) {
      numberOfItteration = numberOfItteration * -1;
    }
    if (key === "sales") {
      // const salesValue: any =
      //   ((Math.round(userSelectedData[0]?.epc_ind) - selectedInd) / 100) * 2.3;
      return (numberOfItteration * 5.4).toFixed(1);
    } else if (key === "renting") {
      // const rentingValue: any =
      //   ((Math.round(userSelectedData[0]?.epc_ind) - selectedInd) / 100) * 3.2;
      return (numberOfItteration * 1.5).toFixed(1);
      // rentingValue >= 0 ? Math.round(rentingValue) : 0;
    }
  };

  /* index for bad:18 , medium:19 , good:20 */
  const currentEpcStatus = (epc_label: any) => {
    switch (epc_label) {
      case "1":
        return getResultContent(154);
      // return getResultContent(20);
      case "2":
        return getResultContent(155);
      // return getResultContent(20);
      case "3":
        return getResultContent(156);
      // return getResultContent(19);
      case "4":
        return getResultContent(157);
      // return getResultContent(18);
      case "5":
        return getResultContent(158);
      // return getResultContent(18);
      case "6":
        return getResultContent(158);
      default:
        return getResultContent(158);
    }
  };

  const getRenovationCost = (key: any, investment_cost: any) => {
    if (key === "window") {
      return (investment_cost =
        investment_cost + parseInt(selectedPackData?.window_cost));
    }
    if (key === "glazing") {
      return (investment_cost =
        investment_cost + parseInt(selectedPackData?.glazing_cost));
    }
    if (key === "wall") {
      return (investment_cost =
        investment_cost + parseInt(selectedPackData?.wall_cost));
    }
    if (key === "roof") {
      return (investment_cost =
        investment_cost + parseInt(selectedPackData?.roof_cost));
    }
    if (key === "ventc+") {
      return (investment_cost =
        investment_cost + parseInt(selectedPackData?.ventc_cost));
    }
    if (key === "ventd") {
      return (investment_cost =
        investment_cost + parseInt(selectedPackData?.ventd_cost));
    }
    if (key === "condgas") {
      return (investment_cost =
        investment_cost + parseInt(selectedPackData?.condgas_cost));
    }
    if (key === "heatpump") {
      return (investment_cost =
        investment_cost + parseInt(selectedPackData?.heatpump_cost));
    }
    if (key === "solar") {
      return (investment_cost =
        investment_cost + parseInt(selectedPackData?.solar_cost));
    }
    return investment_cost;
  };

  // total investmentcost
  const getRenovationBudget = () => {
    let investment_cost: any = 0;
    let array = selectedPackRenovations?.combo?.includes("_")
      ? selectedPackRenovations?.combo?.split("_")
      : [selectedPackRenovations?.combo];
    // calculating budget
    array?.map((key: any) => {
      // function call function here
      if (selectedPackData?.combo?.includes(key) && key === "window") {
        investment_cost =
          investment_cost + parseInt(selectedPackData?.window_cost);
      }
      if (selectedPackData?.combo?.includes(key) && key === "glazing") {
        investment_cost =
          investment_cost + parseInt(selectedPackData?.glazing_cost);
      }
      if (selectedPackData?.combo?.includes(key) && key === "wall") {
        investment_cost =
          investment_cost + parseInt(selectedPackData?.wall_cost);
      }
      if (selectedPackData?.combo?.includes(key) && key === "roof") {
        investment_cost =
          investment_cost + parseInt(selectedPackData?.roof_cost);
      }
      if (selectedPackData?.combo?.includes(key) && key === "ventc+") {
        investment_cost =
          investment_cost + parseInt(selectedPackData?.ventc_cost);
      }
      if (selectedPackData?.combo?.includes(key) && key === "ventd") {
        investment_cost =
          investment_cost + parseInt(selectedPackData?.ventd_cost);
      }
      if (selectedPackData?.combo?.includes(key) && key === "condgas") {
        investment_cost =
          investment_cost + parseInt(selectedPackData?.condgas_cost);
      }
      if (selectedPackData?.combo?.includes(key) && key === "heatpump") {
        investment_cost =
          investment_cost + parseInt(selectedPackData?.heatpump_cost);
      }
      if (selectedPackData?.combo?.includes(key) && key === "solar") {
        investment_cost =
          investment_cost + parseInt(selectedPackData?.solar_cost);
      }
    });
    return investment_cost;
  };
  // total subsidy cost
  const getRenovationTotalSubsidy = () => {
    let investment_cost: any = 0;
    let array = selectedPackRenovations?.combo?.includes("_")
      ? selectedPackRenovations?.combo?.split("_")
      : [selectedPackRenovations?.combo];
    // calculating budget
    array?.map((key: any) => {
      // function call function here
      if (selectedPackData?.combo?.includes(key) && key === "window") {
        investment_cost =
          investment_cost + parseInt(selectedPackData?.window_subsidy);
      }
      if (selectedPackData?.combo?.includes(key) && key === "glazing") {
        investment_cost =
          investment_cost + parseInt(selectedPackData?.glazing_subsidy);
      }
      if (selectedPackData?.combo?.includes(key) && key === "wall") {
        investment_cost =
          investment_cost + parseInt(selectedPackData?.wall_subsidy);
      }
      if (selectedPackData?.combo?.includes(key) && key === "roof") {
        investment_cost =
          investment_cost + parseInt(selectedPackData?.roof_subsidy);
      }
      if (selectedPackData?.combo?.includes(key) && key === "ventc+") {
        investment_cost =
          investment_cost + parseInt(selectedPackData?.ventc_subsidy);
      }
      if (selectedPackData?.combo?.includes(key) && key === "ventd") {
        investment_cost =
          investment_cost + parseInt(selectedPackData?.ventd_subsidy);
      }
      if (selectedPackData?.combo?.includes(key) && key === "condgas") {
        investment_cost =
          investment_cost + parseInt(selectedPackData?.condgas_subsidy);
      }
      if (selectedPackData?.combo?.includes(key) && key === "heatpump") {
        investment_cost =
          investment_cost + parseInt(selectedPackData?.heatpump_subsidy);
      }
      if (selectedPackData?.combo?.includes(key) && key === "solar") {
        investment_cost =
          investment_cost + parseInt(selectedPackData?.solar_subsidy);
      }
    });
    return investment_cost;
  };
  //   total = budget - subsidy
  const total = () => {
    //console.log("getRenovationSubsidyCity", getRenovationSubsidyCity());
    let total = getRenovationBudget();
    setTotalValue(total);
    let TotalValue = renovationPricesHandler(total);
    return TotalValue;
    // return totalBudget() - totalSubsidy();
  };
  total();

  return (
    <div className="shadow-xl bg-[#BCEACE] p-3 rounded-lg">
      {/* PRICE */}
      <div className="bg-white mb-2 rounded-t-lg p-5">
        <div className="flex justify-between items-center py-1 space-x-3 w-full font-poppins text-[14px] text-black">
          <span className="py-1">{getResultContent(42)}</span>
          {futureEpc !== null && futureEpc !== undefined && futureEpc && (
            <div className="flex px-4 border-black">
              <div
                style={{
                  backgroundColor:
                    selectedPack === "packA" &&
                    (selectedPackRenovations === undefined ||
                      selectedPackRenovations?.length === 0)
                      ? currentEpcClassHandler(50)
                      : selectedPack === "packB" &&
                        (selectedPackRenovations === undefined ||
                          selectedPackRenovations?.length === 0)
                      ? currentEpcClassHandler(86)
                      : currentEpcClassHandler(
                          selectedPackData?.result?.ecolabel_value
                            ? selectedPackData?.result?.ecolabel_value
                            : selectedPackData?.result?.ecolabel_now_value
                        ),
                }}
                className={`flex w-10 font-bold text-xl text-white text-center leading-none p-0 justify-center items-center pl-1`}
              >
                {/* current EPC = 400 , future epc === 800*/}
                {selectedPack === "packA" &&
                (selectedPackRenovations === undefined ||
                  selectedPackRenovations?.length === 0)
                  ? currentEpcHandler(50)
                  : selectedPack === "packB" &&
                    (selectedPackRenovations === undefined ||
                      selectedPackRenovations?.length === 0)
                  ? currentEpcHandler(86)
                  : currentEpcHandler(
                      selectedPackData?.result?.ecolabel_value
                        ? selectedPackData?.result?.ecolabel_value
                        : selectedPackData?.result?.ecolabel_now_value
                    )}
              </div>
              <div
                style={{
                  borderLeftColor:
                    selectedPack === "packA" &&
                    (selectedPackRenovations === undefined ||
                      selectedPackRenovations?.length === 0)
                      ? currentEpcClassHandler(50)
                      : selectedPack === "packB" &&
                        (selectedPackRenovations === undefined ||
                          selectedPackRenovations?.length === 0)
                      ? currentEpcClassHandler(86)
                      : currentEpcClassHandler(
                          selectedPackData?.result?.ecolabel_value
                            ? selectedPackData?.result?.ecolabel_value
                            : selectedPackData?.result?.ecolabel_now_value
                        ),
                }}
                className={`w-0 h-0 border-t-[15px] border-t-transparent border-l-[15px] border-b-[15px] border-b-transparent`}
              ></div>
            </div>
          )}
        </div>
        {/* <div className="flex mt-0 pt-2 justify-between items-center space-x-3 w-full font-poppins text-[14px] text-black">
          <span className="py-1">Wat je momenteel teveel betaalt</span>
          <span className="px-4 font-poppins text-[21px] font-bold text-[#007DDF]">
            -476€
          </span>
        </div> */}
        <div className="flex mt-0 pt-2 pb-6 justify-between items-center space-x-3 w-full font-poppins text-[14px] text-black">
          <span className="py-1">{getResultContent(43)}</span>
          {FutureComfortCalculation()}
        </div>
      </div>
      {/* SUM */}
      <div className="flex flex-col bg-white space-y-4 mt-0 p-4 border-b-r-10 border-b-l-10 rounded-b-lg">
        <div className="flex justify-between">
          <div className="flex items-center py-1 w-1/2">
            <Avatar sizeClass="w-[40px]" radius="" imgUrl={Renting} />
            <div className="flex flex-col font-poppins text-[14px] text-black">
              <span className="font-semibold">
                + {caluculateSalesAndRenting("renting")} %
              </span>
              <span className="py-1">{getResultContent(45)}</span>
            </div>
          </div>
          <div className="flex items-center py-1 w-1/2">
            <Avatar sizeClass="w-[40px]" radius="" imgUrl={HouseValue} />
            <div className="flex flex-col font-poppins text-[14px] text-black">
              <span className="font-semibold">
                + {caluculateSalesAndRenting("sales")} %
              </span>
              <span className="py-1">{getResultContent(46)}</span>
            </div>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex items-center py-1 w-1/2">
            <Avatar sizeClass="w-[40px]" radius="" imgUrl={Savings} />
            <div className="flex flex-col font-poppins text-[14px] text-black">
              {/* <span className="font-semibold">{totalYearlySavings()} €</span> */}
              <span className="font-semibold">
                <span className="font-semibold">
                  {Math.abs(totalYearlySavings())} €
                </span>
              </span>
              <span className="py-1">{getResultContent(47)}</span>
            </div>
          </div>
          <div className="flex items-center py-1 w-1/2">
            <Avatar sizeClass="w-[40px]" radius="" imgUrl={Cloud} />
            <div className="flex flex-col font-poppins text-[14px] text-black">
              <span className="font-semibold">
                {/* {selectedUserCombination?.co2_savings
                  ? parseInt(selectedUserCombination?.co2_savings)
                  : 0}{" "} */}
                {totalc02savings()} kg
              </span>
              {/* <span className="font-semibold">{totalCO2()} kg</span> */}
              <span className="py-1">{getResultContent(48)}</span>
            </div>
          </div>
        </div>
        <div className="border-b border-[#E3E7EB]"></div>
        <div className="font-poppins text-[14px] text-black">
          <span>{getResultContent(49)}</span>
        </div>
        <div className="flex justify-between font-poppins text-[16px] text-black">
          <div className="flex w-[53%]">
            <div className="">{getResultContent(50)}</div>
            <div
              className="px-3 pt-1 flex justify-center cursor-pointer w-[5%]"
              onClick={() => {
                openModal("total-modal");
                setRenovationModalContent("budget");
              }}
            >
              <InfoCircleOutlined className="text-[#007DDF]" />
            </div>
          </div>
          <div className="">
            {selectedPackData?.total_investment_cost !== undefined &&
            selectedPackData?.total_investment_cost !== null
              ? renovationPricesHandler(
                  getRenovationBudget()
                  // parseInt(selectedPackData?.total_investment_cost)
                )
              : 0}{" "}
            €
          </div>
          {/* <span>{totalBudget()} €</span> */}
        </div>
        <div className="flex justify-between font-poppins text-[16px] text-black">
          {/* <div className="flex items-center"> */}
          <div className="flex w-[53%] result-subsidie-regional">
            <div>{getResultContent(51)}</div>
            <div
              className="px-3 pt-1 flex justify-center cursor-pointer"
              onClick={() => {
                openModal("total-modal");
                setRenovationModalContent("subsidy");
              }}
            >
              <InfoCircleOutlined className="text-[#007DDF]" />
            </div>
          </div>
          <div className="">
            jsq à -
            {selectedPackData?.total_subsidy_cost !== undefined &&
            selectedPackData?.total_subsidy_cost !== null
              ? renovationPricesHandler(getRenovationTotalSubsidy())
              : 0}{" "}
            €
          </div>
        </div>
        <div className="flex justify-between font-poppins text-[16px] text-black">
          {/* <div className="flex items-center">           */}
          <div className="flex w-[53%]">
            <div className="">
              {subsidies == 1 ? getResultContent(298) : getResultContent(299)}
            </div>
            <div
              className="px-3 pt-1 flex justify-center cursor-pointer"
              onClick={() => {
                openModal("total-modal");
                setRenovationModalContent("city");
              }}
            >
              <InfoCircleOutlined className="text-[#007DDF]" />
            </div>
          </div>
          <span>
            jsq à -{renovationPricesHandler(getRenovationSubsidyCity())} €
          </span>
        </div>
        <div className="flex font-poppins text-[16px] text-blue-500 justify-between">
          {/* <div className="flex items-center"> */}
          <div className="flex w-[60%]">
            <div className=" pr-2">{getResultContent(54)}</div>
            <div
              className="pt-1 flex justify-center cursor-pointer"
              onClick={() => {
                openModal("total-modal");
                setRenovationModalContent("total");
              }}
            >
              <InfoCircleOutlined className="text-[#007DDF] text-[16px]" />
            </div>
          </div>
          <span className="">
            {selectedPackData?.total_investment_cost !== undefined &&
            selectedPackData?.total_investment_cost !== null &&
            selectedPackData?.total_subsidy_cost !== undefined &&
            selectedPackData?.total_subsidy_cost !== null
              ? renovationPricesHandler(
                  getRenovationBudget() -
                    getRenovationTotalSubsidy() -
                    getRenovationSubsidyCity()
                )
              : 0}{" "}
            €
          </span>
        </div>

        {getremaingcost() === 1 ? (
          <div className="flex font-poppins text-[16px] text-red-500 justify-between">
            <div className="flex items-center">
              <div className="pr-2 ">{getResultContent(411)}</div>
              <div
                className="px-3 flex justify-center cursor-pointer"
                onClick={() => {
                  openModal("total-modal");
                  setRenovationModalContent("less_then_10_cost_modal");
                }}
              >
                <InfoCircleOutlined className="text-[#007DDF] text-[16px]" />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {userStatus?.data?.status == 5
          ? !isAdminView && (
              <a
                href={userStatus?.data?.fileUrl}
                target="_blank"
                download="offer.pdf"
                className="w-full"
              >
                <button
                  className={`bg-[#007DDF] text-white border border-[#007DDF2B]
                 rounded-[4px] px-[14px] py-2 lg:px-7 lg:py-2.5 text-[16px] w-full font-poppins`}
                >
                  {buttonTextHandler(userStatus?.data?.status)}
                </button>
              </a>
            )
          : !isAdminView && (
              <button
                className={`bg-[#007DDF] text-white border border-[#007DDF2B]
                 rounded-[4px] px-[14px] py-2 lg:px-7 lg:py-2.5 text-[16px] font-poppins`}
                onClick={() => {
                  dispatch({
                    type: "SET_BOOK_AN_AUDIT_MODAL",
                    payload: true,
                  });
                }}
              >
                {buttonTextHandler(1)}
              </button>
            )}

        <span className="text-center font-poppins text-[14px]">
          {getResultContent(79)}
        </span>
      </div>
    </div>
  );
};

export default SectionSliderNewCategories;
