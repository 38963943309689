import React, { FC } from "react";
import WhoWeAreComponent from "../../components/whoWeAre/whoWeAre";

interface WhoWeAreInterface {}

const WhoWeAre: FC<WhoWeAreInterface> = () => {
  return (
    <div>
      <WhoWeAreComponent />
    </div>
  );
};
export default WhoWeAre;
