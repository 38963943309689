import React, { FC, useContext } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
// import images
import Logo from "../../assets/images/home/logoempty.svg";
import TextLogo from "../../assets/images/home/E.png";
import RoundMap from "../../assets/images/home/map.jpeg";
import { getValue } from "../../utils/getValue";
interface ProcessInterface {}

const Process: FC<ProcessInterface> = () => {
  //  global state
  const { state }: any = useContext(GlobalContext);

  return (
    <div>
      <div className="bg-img">
        {/* content_div */}
        <div className="mx-0 lg:mx-[10%] lg:flex justify-end py-20 px-4">
          {/* left_side_div  */}
          <div className="bg-white rounded-[10px] flex  sm:flex-col lg:flex-row justify-center w-full lg:w-[226px] shadow-2xl mb-6 lg:mb-0 mr-0 lg:mr-9  p-5 ">
            <div className="flex justify-center items-center">
              <img src={Logo} alt="logo" className="h-[261px]" />
            </div>
          </div>
          {/* right_side_div  */}
          <div className="bg-white rounded-[10px] shadow-2xl w-full  lg:w-[612px] px-8 py-6">
            <h2 className="text-[#222222] text-2xl lg:text-[38px] text-right  font-semibold leading-9 lg:leading-[50px]">
              {getValue(state, 0, 17)?.props?.content}
            </h2>
            <p
              className="text-sm text-[#455259] font-normal text-right py-4"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 0, 18)?.props?.content,
              }}
            />
            {/* <p
              className="text-sm text-[#455259] font-normal text-right pb-4"
              dangerouslySetInnerHTML={{
                __html: state?.language?.procesText2,
              }}
            />
            <p
              className="text-sm text-[#455259] font-normal text-right pb-4"
              dangerouslySetInnerHTML={{
                __html: state?.language?.procesText3,
              }}
            /> */}
          </div>
        </div>
      </div>
      <div className="mx-0 lg:mx-[10%] px-4 relative flex items-center justify-center py-16 louvier-box   md:-mt-[230px] lg:-mt-[460px]">
        <div className="bg-white w-full lg:w-[925px] px-8 py-6 rounded-lg shadow-xl lg:flex lg:items-center relative">
          {/* left div */}
          <div className="w-full lg:w-[70%]">
            <h2 className="text-sm font-bold text-[#007DDF] uppercase pb-1.5">
              {state?.language?.procesTitle2}
            </h2>
            <h2 className="text-2xl lg:text-[38px] font-semibold text-[#222222] leading-9  lg:leading-[50px] ">
              {getValue(state, 0, 32)?.props?.content}
            </h2>
            <p
              className="text-sm text-[#455259] font-normal  py-3"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 0, 33)?.props?.content,
              }}
            />

            {/* <p className="text-sm text-[#455259] font-normal  ">
              {state?.language?.procesText5}
            </p>
            <p className="text-sm text-[#455259] font-normal  pb-16">
              {state?.language?.procesText6}
            </p> 
            <div className="flex items-center py-4">
              <img src={TextLogo} alt="textLogo" className="h-[38px]" />
              <p className="text-sm text-black font-bold pl-[14px]">
                {" "}
                Gemiddeld EPC in Sint-Anna
              </p>
            </div>
            <div className="flex items-center pb-4">
              <img src={TextLogo} alt="textLogo" className="h-[38px]" />
              <p className="text-sm text-black font-bold pl-[14px]">
                {" "}
                Gemiddeld EPC in Hof Somergem
              </p>
            </div>
            */}
          </div>
          {/* right div */}
          <div className="flex justify-center items-center relative lg:absolute right-0 lg:mr-[-100px]">
            <img
              src={RoundMap}
              alt="RoundMap"
              className="h-[230px] md:h-[250px] lg:h-[320px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Process;
