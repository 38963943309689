import React, { FC, useContext, useState } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
import AnimateHeight from "react-animate-height";
// import images
import Setting from "../../assets/images/home/settings.svg";
import Dollar from "../../assets/images/home/dollar.svg";
import Hands from "../../assets/images/home/hands.svg";
import Star from "../../assets/images/home/star.svg";
import Heart from "../../assets/images/home/heart.svg";
import Happy from "../../assets/images/home/happy.svg";
import { getValue } from "../../utils/getValue";
interface PositivePointInterface {}

const PositivePoint: FC<PositivePointInterface> = () => {
  //  global state
  const { state }: any = useContext(GlobalContext);
  const [active, setActive]: any = useState({
    box1: 0,
    box2: 0,
    box3: 0,
    box4: 0,
    box5: 0,
    box6: 0,
  });
  return (
    <div className="mx-[5%] lg:mx-[10%] py-20">
      <div className="w-full md:flex">
        <div>
          <div className="bg-image ">
            <div className="flex items-center justify-center h-full py-3 md:py-0">
              <p
                className="text-[#222222] text-[20px] text-center md:text-left md:text-[32px]  lg:text-[38px] font-semibold"
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 0, 7)?.props?.content,
                }}
              ></p>
            </div>
          </div>
        </div>
        {/* boxes_div */}
        {/* grid grid-cols-1 md:grid-cols-2 */}
        <div className=" flex flex-col lg:flex-row w-full lg:w-[70%]">
          {/* left */}
          <div className="w-full lg:w-6/12">
            <div
              aria-expanded={active?.box1 !== 0}
              aria-controls="example-panel"
              onMouseEnter={() => {
                // setActive({ ...active, box1: "auto" });
                setActive({
                  box1: "auto",
                  box2: 0,
                  box3: 0,
                  box4: 0,
                  box5: 0,
                  box6: 0,
                });
              }}
              onMouseLeave={() => {
                setActive({
                  box1: 0,
                  box2: 0,
                  box3: 0,
                  box4: 0,
                  box5: 0,
                  box6: 0,
                });
                // setActive({ ...active, box1: 0 });
              }}
              className={`${
                active?.box1 === 0 ? "bg-white " : "bg-[#0480DB] "
              } rounded-[10px] shadow-positive-points px-3 py-3 mx-1 mt-[3px] h-fit relative`}
            >
              <div className={`relative`}>
                <div className="relative">
                  <div className={`absolute flex items-center w-full`}>
                    <img
                      src={Setting}
                      alt="setting"
                      className="h-[50px] md:h-[70px] "
                    />
                  </div>
                </div>
                <div className="sticky z-50">
                  <p
                    className={`${
                      active?.box1 === 0 ? "text-[#222222]" : "text-white "
                    }  text-xs   lg:text-sm 2xl:text-lg  font-semibold  p-5  `}
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 0, 9)?.props?.content,
                    }}
                  />

                  <AnimateHeight
                    id="example-panel"
                    duration={500}
                    height={active?.box1}
                  >
                    <p className="text-xs flex pl-5 items-center  text-white">
                      {getValue(state, 0, 45)?.props?.content}
                    </p>
                  </AnimateHeight>
                </div>
              </div>
            </div>

            <div
              aria-expanded={active?.box3 !== 0}
              aria-controls="example-panel"
              onMouseEnter={() => {
                setActive({
                  box1: 0,
                  box2: 0,
                  box3: "auto",
                  box4: 0,
                  box5: 0,
                  box6: 0,
                });
                // setActive({ ...active, box3: "auto" });
              }}
              onMouseLeave={() => {
                setActive({
                  box1: 0,
                  box2: 0,
                  box3: 0,
                  box4: 0,
                  box5: 0,
                  box6: 0,
                });
                // setActive({ ...active, box3: 0 });
              }}
              className={`${
                active?.box3 === 0 ? "bg-white " : "bg-[#0480DB] "
              } rounded-[10px] shadow-positive-points px-3 pt-3 mx-1 mt-[3px] h-fit relative`}
            >
              <div className={`relative`}>
                <div className={`relative flex items-center`}>
                  <div className="absolute z-0  w-full h-full">
                    <img
                      src={Hands}
                      alt="Hands"
                      className="h-[50px] md:h-[70px]"
                    />
                  </div>
                </div>
                <div className="sticky z-50">
                  <p
                    className={`${
                      active?.box3 === 0 ? "text-[#222222]" : "text-white "
                    }  text-xs lg:text-sm 2xl:text-lg  font-semibold  p-5  `}
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 0, 11)?.props?.content,
                    }}
                  />
                  <AnimateHeight
                    id="example-panel"
                    duration={500}
                    height={active?.box3}
                  >
                    <p
                      className="text-xs pl-5 pb-3 flex items-center  text-white"
                      dangerouslySetInnerHTML={{
                        __html: getValue(state, 0, 47)?.props?.content,
                      }}
                    ></p>
                  </AnimateHeight>
                </div>
              </div>
            </div>

            <div
              aria-expanded={active?.box5 !== 0}
              aria-controls="example-panel"
              onMouseEnter={() => {
                setActive({
                  box1: 0,
                  box2: 0,
                  box3: 0,
                  box4: 0,
                  box5: "auto",
                  box6: 0,
                });
                // setActive({ ...active, box5: "auto" });
              }}
              onMouseLeave={() => {
                setActive({
                  box1: 0,
                  box2: 0,
                  box3: 0,
                  box4: 0,
                  box5: 0,
                  box6: 0,
                });
                // setActive({ ...active, box5: 0 });
              }}
              className={`${
                active?.box5 === 0 ? "bg-white " : "bg-[#0480DB]"
              } rounded-[10px] shadow-positive-points px-3 pt-3 mx-1 mt-[3px] h-fit relative`}
            >
              <div className={`relative flex items-center`}>
                <div className="absolute z-0  w-full h-full">
                  <img
                    src={Heart}
                    alt="Heart"
                    className="h-[50px] md:h-[70px]"
                  />
                </div>
                <div className="sticky z-50">
                  <p
                    className={`${
                      active?.box5 === 0 ? "text-[#222222]" : "text-white "
                    }  text-xs lg:text-sm 2xl:text-lg  font-semibold  p-5  `}
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 0, 15)?.props?.content,
                    }}
                  />
                  <AnimateHeight
                    id="example-panel"
                    duration={500}
                    height={active?.box5}
                  >
                    <p
                      className="text-xs pl-5 flex items-center  text-white pb-3"
                      dangerouslySetInnerHTML={{
                        __html: getValue(state, 0, 49)?.props?.content,
                      }}
                    ></p>
                  </AnimateHeight>
                </div>
              </div>
            </div>
          </div>
          {/* right */}
          <div className="w-full lg:w-6/12">
            <div
              aria-expanded={active.box2 !== 0}
              aria-controls="example-panel"
              onMouseEnter={() => {
                setActive({
                  box1: 0,
                  box2: "auto",
                  box3: 0,
                  box4: 0,
                  box5: 0,
                  box6: 0,
                });
                // setActive({ ...active, box2: "auto" });
              }}
              onMouseLeave={() => {
                setActive({
                  box1: 0,
                  box2: 0,
                  box3: 0,
                  box4: 0,
                  box5: 0,
                  box6: 0,
                });
                // setActive({ ...active, box2: 0 });
              }}
              className={`${
                active?.box2 === 0 ? "" : "bg-[#0480DB] "
              } rounded-[10px] shadow-positive-points px-3 pt-3 mx-1 mt-[3px] h-fit relative`}
            >
              <div className={`relative flex items-center`}>
                <div className="absolute z-0  w-full h-full">
                  <img
                    src={Dollar}
                    alt="Dollar"
                    className="h-[50px] md:h-[70px] "
                  />
                </div>
                <div className="sticky z-50">
                  <p
                    className={`${
                      active?.box2 === 0 ? "text-[#222222]" : "text-white "
                    }  text-xs lg:text-sm 2xl:text-lg  font-semibold  p-5  `}
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 0, 10)?.props?.content,
                    }}
                  />
                  <AnimateHeight
                    id="example-panel"
                    duration={500}
                    height={active?.box2}
                  >
                    <p
                      className="text-xs pl-5 flex items-center pb-3 text-white"
                      dangerouslySetInnerHTML={{
                        __html: getValue(state, 0, 46)?.props?.content,
                      }}
                    ></p>
                  </AnimateHeight>
                </div>
              </div>
            </div>

            <div
              aria-expanded={active?.box4 !== 0}
              aria-controls="example-panel"
              onMouseEnter={() => {
                setActive({
                  box1: 0,
                  box2: 0,
                  box3: 0,
                  box4: "auto",
                  box5: 0,
                  box6: 0,
                });
                // setActive({ ...active, box4: "auto" });
              }}
              onMouseLeave={() => {
                setActive({
                  box1: 0,
                  box2: 0,
                  box3: 0,
                  box4: 0,
                  box5: 0,
                  box6: 0,
                });
                // setActive({ ...active, box4: 0 });
              }}
              className={`${
                active?.box4 === 0 ? "bg-white " : "bg-[#0480DB] "
              } rounded-[10px] shadow-positive-points px-3 pt-3 mx-1 mt-[3px] h-fit relative`}
            >
              <div className={`relative flex items-center`}>
                <div className="absolute z-0 w-full h-full">
                  <img src={Star} alt="Star" className="h-[50px] md:h-[70px]" />
                </div>
                <div className="sticky z-50">
                  <p
                    className={`${
                      active?.box4 === 0 ? "text-[#222222]" : "text-white "
                    }  text-xs lg:text-sm 2xl:text-lg  font-semibold  p-5  `}
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 0, 14)?.props?.content,
                    }}
                  />
                  <AnimateHeight
                    id="example-panel"
                    duration={500}
                    height={active?.box4}
                  >
                    <p
                      className="text-xs pl-5 flex items-center pb-3 text-white"
                      dangerouslySetInnerHTML={{
                        __html: getValue(state, 0, 48)?.props?.content,
                      }}
                    ></p>
                  </AnimateHeight>
                </div>
              </div>
            </div>

            <div
              aria-expanded={active?.box6 !== 0}
              aria-controls="example-panel"
              onMouseEnter={() => {
                setActive({
                  box1: 0,
                  box2: 0,
                  box3: 0,
                  box4: 0,
                  box5: 0,
                  box6: "auto",
                });
                // setActive({ ...active, box6: "auto" });
              }}
              onMouseLeave={() => {
                setActive({
                  box1: 0,
                  box2: 0,
                  box3: 0,
                  box4: 0,
                  box5: 0,
                  box6: 0,
                });
                // setActive({ ...active, box6: 0 });
              }}
              className={`${
                active?.box6 === 0 ? "bg-white " : "bg-[#0480DB] "
              } rounded-[10px] shadow-positive-points px-3 pt-3 mx-1 mt-[3px] h-fit relative`}
            >
              <div className={`relative flex items-center`}>
                <div className="absolute z-0 w-full h-full">
                  <img
                    src={Happy}
                    alt="Happy"
                    className="h-[50px] md:h-[70px]"
                  />
                </div>
                <div className="sticky z-50">
                  <p
                    className={`${
                      active?.box6 === 0 ? "text-[#222222]" : "text-white "
                    }  text-xs lg:text-sm 2xl:text-lg  font-semibold  p-5  `}
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 0, 16)?.props?.content,
                    }}
                  />
                  <AnimateHeight
                    id="example-panel"
                    duration={500}
                    height={active?.box6}
                  >
                    <p
                      className="text-xs pl-5 flex items-center pb-3 text-white"
                      dangerouslySetInnerHTML={{
                        __html: getValue(state, 0, 50)?.props?.content,
                      }}
                    ></p>
                  </AnimateHeight>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PositivePoint;
