import React, { FC, useContext, useState } from "react";
import { GlobalContext } from "../../context/globalContext/globalContext";
// import { Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
import { HubspotPostAPIService } from "../../context/services";
// components
import SelectorLanguage from "../../shared/languageSelector";
import ComingSoonModal from "./comingSoonModal";
// images
import Renocity from "../../assets/images/comingSoon/renoCity.svg";
// antd
import { notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

interface ComingSoonInterface {}

const ComingSoon: FC<ComingSoonInterface> = () => {
  // global state
  const { state }: any = useContext(GlobalContext);
  const [loader, setLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  const Navigation = useNavigate();
  // state to store user data from form
  const [createUser, setCreateUser] = useState({
    hs_lead_status: "",
    email: "",
    subscription_type: false,
    processing: false,
  });

  const [validateUser, setValidateUser]: any = useState({
    hs_lead_status: false,
    email: false,
    processing: false,
  });

  // method to check email
  const isValidEmail = (val: any) => {
    let regEmail =
      // eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
      return false;
      // return "Invalid Email Address";
    } else {
      return true;
    }
  };

  //onChange handler for updating state
  const onChangeHandler = (key: any, value: any) => {
    let duplicate: any = { ...createUser };
    duplicate[key] = value;
    setCreateUser(duplicate);
  };

  //onChange handler for updating validation state
  const onChangeValidationHandler = (key: any, value: any) => {
    let duplicate: any = { ...validateUser };
    duplicate[key] = value;
    setValidateUser(duplicate);
  };

  // notifaction modal
  const openNotification = (message: string, description: string) => {
    notification.open({
      message: message,
      description: description,
    });
  };

  // onSubmitHandler to add data in hubspot
  let duplicateUserValidation = { ...validateUser };
  const submitHandler = (e: any) => {
    e.preventDefault();
    setLoader(true);
    // ERROR
    if (
      createUser?.hs_lead_status === "" ||
      createUser?.email === "" ||
      // createUser?.processing === false ||
      isValidEmail(createUser?.email) === false
    ) {
      if (createUser?.hs_lead_status === "") {
        duplicateUserValidation.hs_lead_status = true;
      }
      if (createUser?.email === "") {
        duplicateUserValidation.email = true;
      }
      if (
        createUser?.email !== "" &&
        isValidEmail(createUser?.email) === false
      ) {
        duplicateUserValidation.email = "Invalid Email Address";
      }
      // if (createUser?.processing === false) {
      //   duplicateUserValidation.processing = true;
      // }
      setValidateUser(duplicateUserValidation);
      setLoader(false);
    }
    // success
    else {
      // payload
      const payload = {
        fields: [
          {
            objectTypeId: "0-1",
            name: "hs_lead_status",
            value: createUser?.hs_lead_status,
          },
          {
            objectTypeId: "0-1",
            name: "email",
            value: createUser?.email,
          },
          {
            objectTypeId: "0-1",
            name: "LEGAL_CONSENT.subscription_type_122867907",
            value: createUser?.subscription_type && "122867907",
          },
          // GDPR
          {
            objectTypeId: "0-1",
            name: "LEGAL_CONSENT.subscription_type_123974578",
            value: createUser?.processing === true ? "123974578" : false,
          },
        ],
        // legalConsentOptions: {
        //   consent: {
        //     consentToProcess: true,
        //     text: "I agree to allow agc-glass.eu to store and process my personal data.",
        //     communications:
        //       createUser?.subscription_type === true
        //         ? [
        //             {
        //               value: true,
        //               subscriptionTypeId: 122867907,
        //               text: "I agree to receive other communications from AGC Glass Europe.",
        //             },
        //           ]
        //         : [],
        //   },
        // },
      };
      // Hubspot API call
      HubspotPostAPIService(
        "25186371/9ba378c0-30c0-4927-b7e6-172adb58905c",
        payload
      )
        .then(() => {
          openNotification(
            "Success",
            state?.language?.comigSoonSucessNotification
          );
          setLoader(false);
        })
        .catch(() => {
          openNotification("Error", "");
          setLoader(false);
        });
    }
  };

  return (
    <div className="h-full">
      <ComingSoonModal visible={visible} setVisible={setVisible} />
      {/* container_div */}
      <div className="mx-0 lg:mx-[10%] px-5 pt-10">
        {/* header */}
        {/* desktop logo */}
        <div className="hidden lg:block w-full">
          <div className="flex justify-center absolute left-[0] right-[0] w-full">
            <div
              className="w-fit pt-5 cursor-pointer "
              onClick={() => Navigation("/")}
            >
              <div>
                <img src={Renocity} alt="renoCity" className="h-[50px]" />
              </div>
              <p className="text-[#6B7180] text-[10px] sm:text-[8px] md:text-[10px] lg:text-xs 2xl:text-[13px] pr-4 opacity-100 text-end">
                {state?.language?.comingSoonTitle1}
              </p>
            </div>
          </div>
        </div>
        <div className=" flex justify-end">
          {/* NL component_for_Desktop */}
          <div className="hidden lg:block">
            <SelectorLanguage />
          </div>
        </div>
        {/* mobile logo */}
        <div className="block lg:hidden">
          <div className="w-full flex  items-center justify-between">
            <div className="w-full">
              <div
                className="w-fit py-5 cursor-pointer"
                onClick={() => Navigation("/")}
              >
                <div>
                  <img
                    src={Renocity}
                    alt="renoCity"
                    className="h-[16px] md:h-[34px]"
                  />
                </div>
                <p className="text-[#6B7180] text-[6px] sm:text-[7px] md:text-[9px] opacity-100 text-end">
                  {state?.language?.comingSoonTitle1}
                </p>
              </div>
            </div>
            {/* NL component_for_Mobile */}
            <div>
              <SelectorLanguage />
            </div>
          </div>
        </div>
        {/* banner_div */}
        <div className="flex justify-center">
          <div className="w-11/12 lg:w-8/12">
            {/* heading_text_div */}
            <div>
              <h1 className="text-[#007DDF] text-center text-base font-semibold py-5">
                {state?.language?.comingSoonTitle2}
              </h1>
            </div>
            {/* text_div */}
            {/* <div className="py-2.5">
              <p className="text-[14px] text-center text-[#455259]">
                {state?.language?.comingSoonContent1}
              </p>
            </div> */}
          </div>
        </div>
        {/* Form_div */}
        <form
          onSubmit={(e) => submitHandler(e)}
          className="py-[20px] flex justify-center"
        >
          <div className="rounded-[10px] box-shadow1 w-full border-[#007DDF2B] border bg-white px-5 pt-8 lg:pt-12 flex justify-center">
            <div className="w-full lg:w-10/12 flex flex-col items-center justify-center">
              {/* Title */}
              <div className="pb-6 ">
                <p className="text-[#007DDF] text-xs md:text-base text-center font-semibold">
                  {state?.language?.comingSoonFormTitle}
                </p>
              </div>
              <div className="w-full 2xl:w-9/12">
                {/* boxes_div */}
                <div className="flex flex-col lg:flex-row justify-between  lg:px-0 ">
                  {/* box */}
                  <div
                    onClick={() => {
                      onChangeHandler("hs_lead_status", "NEW");
                      onChangeValidationHandler("hs_lead_status", false);
                    }}
                    className={`border rounded-[4px] border-[#007DDF2B] w-full lg:w-2/7 h-[initial] ${
                      createUser?.hs_lead_status === "NEW" &&
                      "bg-[#007DDF] text-white"
                    } hover:bg-[#007DDF] text-[#007DDF] hover:text-[#fff] cursor-pointer`}
                  >
                    <p className="py-2.5 px-7 lg:px-5 text-center  h-full flex items-center justify-center">
                      {" "}
                      {state?.language?.comingSoonBox1}
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      onChangeHandler("hs_lead_status", "OPEN");
                      onChangeValidationHandler("hs_lead_status", false);
                    }}
                    className={`border rounded-[4px] border-[#007DDF2B] ${
                      createUser?.hs_lead_status === "OPEN" &&
                      "bg-[#007DDF] text-white"
                    } w-full lg:w-2/7 my-4 lg:my-0 lg:mx-4 h-[initial] hover:bg-[#007DDF] text-[#007DDF] hover:text-[#fff] cursor-pointer`}
                  >
                    <p className="py-2.5 px-7 lg:px-5 text-center h-full flex items-center justify-center">
                      {" "}
                      {state?.language?.comingSoonBox2}
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      onChangeHandler("hs_lead_status", "IN_PROGRESS");
                      onChangeValidationHandler("hs_lead_status", false);
                    }}
                    className={`border rounded-[4px] border-[#007DDF2B] ${
                      createUser?.hs_lead_status === "IN_PROGRESS" &&
                      "bg-[#007DDF] text-white"
                    } w-full lg:w-2/7 h-[initial] hover:bg-[#007DDF] text-[#007DDF] hover:text-[#fff] cursor-pointer`}
                  >
                    <p className="py-2.5 px-7 lg:px-5 text-center h-full flex items-center justify-center">
                      {" "}
                      {state?.language?.comingSoonBox3}
                    </p>
                  </div>
                </div>
                {validateUser?.hs_lead_status && (
                  <p className="text-red-500 text-[12px] pt-1">
                    {state?.language?.commingSoonValidationStatus}
                  </p>
                )}
                {/* form */}
                <div className="flex flex-col items-center w-full  lg:px-0 ">
                  {/* email_div */}
                  <div className="w-full py-7">
                    <label
                      className=" text-black text-base pb-1"
                      placeholder="email"
                    >
                      {state?.language?.comingSoonEmail}
                    </label>
                    <div className="py-1">
                      <input
                        className=" border outline-none border-[#97979748] rounded-[4px] text-black w-full py-[9px] pl-5 "
                        type=""
                        placeholder={state?.language?.comingSoonPlaceholher}
                        onChange={(e) => {
                          onChangeHandler("email", e.target.value);
                          onChangeValidationHandler("email", false);
                        }}
                      />
                    </div>
                    {validateUser?.email === true && (
                      <p className="text-red-500 text-[12px]">
                        {state?.language?.commingSoonValidationEmail}
                      </p>
                    )}
                    {validateUser?.email === "Invalid Email Address" && (
                      <p className="text-red-500 text-[12px]">
                        {state?.language?.commingSoonValidationEmail2}
                      </p>
                    )}
                  </div>
                  {/* check_boxes */}
                  {/* <div className="w-full">
                    <div>
                      <Checkbox
                        onChange={(e) =>
                          onChangeHandler("subscription_type", e.target.checked)
                        }
                        className="pb-6"
                      >
                        <p className="text-[#000000] text-xs font-normal font-poppin">
                          {state?.language?.comingSoonCheckbox1}
                        </p>
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        onChange={(e) => {
                          onChangeHandler("processing", e.target.checked);
                          onChangeValidationHandler("processing", false);
                        }}
                      >
                        <p className="text-[#000000] text-xs font-normal font-poppin">
                          <span
                            onClick={() => setVisible(true)}
                            dangerouslySetInnerHTML={{
                              __html: state?.language?.comingSoonCheckbox2Link,
                            }}
                          ></span>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: state?.language?.comingSoonCheckbox2,
                            }}
                          ></span>
                        </p>
                      </Checkbox>
                    </div>
                  </div> */}
                  <div className="w-full">
                    <p className="text-[14px]  text-[#455259] ">
                      {state?.language?.comingSoonFormText1}
                      <span
                        onClick={() => {
                          Navigation(
                            `/privacy/${state?.language?.language.toLowerCase()}`
                          );
                          window.scroll({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                        className="text-[#007DDF] underline cursor-pointer"
                      >
                        {state?.language?.comingSoonFormText2}
                      </span>{" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: state?.language?.comingSoonFormText3,
                        }}
                      ></span>
                    </p>
                  </div>
                </div>
                {/* {validateUser?.processing && (
                  <p className="text-red-500 text-[12px] pt-1">
                    {state?.language?.commingSoonValidationGDPR}
                  </p>
                )} */}
              </div>
              {/* border_bottom_div */}
              <div className="border-b border-b-[#F0F0F0] w-full flex pt-10 "></div>
              {/* button_div */}
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="border border-[#007DDF] rounded bg-[#007DDF] my-[25px] text-white text-base py-2.5 px-7"
                >
                  {!loader ? (
                    state?.language?.comingSoonButton
                  ) : (
                    <LoadingOutlined style={{ fontSize: 24 }} spin />
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ComingSoon;
