import React, { FC, useContext } from "react";
// import images
import RoofInstallation from "../../../assets/images/renovates/installation.jpg";
import RoofDesign1 from "../../../assets/images/renovates/roofdesign1.png";
import RoofDesign2 from "../../../assets/images/renovates/roofdesign2.png";
import RoofDesign3 from "../../../assets/images/renovates/roofdesign3.png";
// import components
import { GlobalContext } from "../../../context/globalContext/globalContext";
import InputTabs from "../../../shared/autoComplete";
import { getValue } from "../../../utils/getValue";

interface RoofInsulationInterface {}

const RoofInsulation: FC<RoofInsulationInterface> = () => {
  const { state }: any = useContext(GlobalContext);
  console.log("getValue", getValue(state, 3, 0));
  return (
    <div>
      <div>
        <img src={RoofInstallation} alt="RoofInsulation" />
      </div>
      <p
        className="text-black text-[20px] py-4"
        dangerouslySetInnerHTML={{
          __html: getValue(state, 3, 10)?.props?.content,
        }}
      ></p>
      <p
        className="text-[#6B7280] text-base py-4 "
        dangerouslySetInnerHTML={{
          __html: getValue(state, 3, 11)?.props?.content,
        }}
      />
      <div className="flex flex-col lg:flex-row py-8 space-x-5">
        {/* left */}
        <div className="w-full lg:w-[50%]">
          <p
            className="text-[#6B7280] text-lg py-2 font-semibold"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 3, 12)?.props?.content,
            }}
          />
          <p
            className="text-[#6B7280] text-base font-normal py-2"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 3, 13)?.props?.content,
            }}
          />
        </div>

        {/* right */}
        <div className="w-full lg:w-[50%] ">
          <div className=" flex flex-col lg:flex-row items-center lg:justify-between">
            <div>
              <img src={RoofDesign1} alt="RoofDesign1" className="h-40" />
              <p
                className="text-[#6B7280] text-base font-normal text-center py-4"
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 3, 15)?.props?.content,
                }}
              />
            </div>
            <div>
              <img src={RoofDesign2} alt="RoofDesign2" className="h-40" />
              <p
                className="text-[#6B7280] text-base font-normal text-center py-4"
                dangerouslySetInnerHTML={{
                  __html: getValue(state, 3, 19)?.props?.content,
                }}
              />
            </div>
          </div>
          <div className="flex flex-col items-center lg:items-start">
            <img src={RoofDesign3} alt="RoofDesign3" className="h-40" />
            <p
              className="text-[#6B7280] text-base font-normal  py-4"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 3, 139)?.props?.content,
              }}
            />
          </div>
        </div>
      </div>
      <div className="bg-[#007DDF] rounded-[20px] p-[40px] mb-14">
        <p
          className="text-white text-xl font-medium pb-6"
          dangerouslySetInnerHTML={{
            __html: getValue(state, 3, 62)?.props?.content,
          }}
        />
        <InputTabs showAutocomplete="renovation" />
      </div>
    </div>
  );
};
export default RoofInsulation;
