import React, { FC } from "react";
import { Button } from "antd";
// import HandImg from "../../images/hands/hand_3.png";

export interface SectionHeroProps {
  className?: string;
  getResultContent: any;
  selectedPack: any;
  setSelectedPack: any;
  onChange: any;
  showAllRenovations: any;
  custom: any;
  setCustom: any;
  baseOfferRenovation: any;
  userStatus: any;
  renovationPackHandler?: any;
  allPacksCombination?: any;
}

const SectionOurRecomendation: FC<SectionHeroProps> = ({
  className,
  getResultContent,
  selectedPack,
  setSelectedPack,
  onChange,
  showAllRenovations,
  custom,
  setCustom,
  baseOfferRenovation,
  userStatus,
  renovationPackHandler,
  allPacksCombination,
}) => {
  return (
    <div className="listingSection__wrap packs_container !space-y-6 shadow-[0_0_20px_#0000001A] border border-[#F0F0F0] bg-gradient-to-r from-[#BCEACE] to-[#98CBEF] py-10 md:px-36 rounded-md px-10">
      <div className="flex justify-center">
        <p className="text-[22px] font_semibold m-0">
          {" "}
          {getResultContent && getResultContent(291)}
        </p>
      </div>
      <div className="flex justify-center flex-wrap">
        {userStatus?.data &&
        userStatus?.data &&
        userStatus?.data?.status &&
        parseInt(userStatus?.data?.status) > 4 ? (
          ""
        ) : (
          <>
            <div className="px-2 py-2">
              <button
                className={` hover:bg-[#007DDF] hover:text-[#fff] 
                  ${
                    selectedPack === "packA"
                      ? "bg-[#007DDF] text-white border border-[#007DDF]"
                      : "bg-white text-[#007DDF] border border-[#007DDF2C]"
                  }
                 rounded-[4px] px-[14px] py-2 lg:px-7 lg:py-2.5 text-[16px] `}
                onClick={() => {
                  onChange(false);
                  setSelectedPack("packA");
                  setCustom(false);
                  renovationPackHandler("a", allPacksCombination);
                }}
              >
                {getResultContent && getResultContent(24)}
              </button>
            </div>
            <div className="px-2 py-2">
              <button
                className={` hover:bg-[#007DDF] hover:text-[#fff] 
                  ${
                    selectedPack === "packB"
                      ? "bg-[#007DDF] text-white border border-[#007DDF]"
                      : "bg-white text-[#007DDF] border border-[#007DDF2C]"
                  }
                 rounded-[4px] px-[14px] py-2 lg:px-7 lg:py-2.5 text-[16px]`}
                onClick={() => {
                  onChange(true);
                  setSelectedPack("packB");
                  setCustom(false);
                  renovationPackHandler("b", allPacksCombination);
                }}
              >
                {" "}
                {getResultContent && getResultContent(25)}
              </button>
            </div>
            <div className="px-2 py-2">
              <button
                className={` hover:bg-[#007DDF] hover:text-[#fff] 
                  ${
                    selectedPack === "customPack"
                      ? "bg-[#007DDF] text-white border border-[#007DDF]"
                      : "bg-white text-[#007DDF] border border-[#007DDF2C]"
                  }
                 rounded-[4px] px-[14px] py-2 lg:px-7 lg:py-2.5 text-[16px]`}
                onClick={() => {
                  showAllRenovations(true);
                  setSelectedPack("customPack");
                  renovationPackHandler("custom", allPacksCombination);
                }}
              >
                {" "}
                {getResultContent && getResultContent(26)}
              </button>
            </div>
          </>
        )}
        {userStatus?.data?.status && parseInt(userStatus?.data?.status) > 3 && (
          <div className="px-2">
            <Button
              size={"large"}
              className={ 
                selectedPack === "baseOffer"
                  ? "primary_button"
                  : "secondary_button"
              }
              onClick={() => {
                setSelectedPack("baseOffer");
                baseOfferRenovation();
              }}
            >
              Base for offer
            </Button>
          </div>
        )}
      </div>
      <div className="flex justify-center">
        {/* a */}
        {selectedPack === "packA" && (
          <p className="text-center text-[14px]">
            {getResultContent && getResultContent(234)}
          </p>
        )}
        {/* b */}
        {selectedPack === "packB" && (
          <p className="text-center text-[14px]">
            {getResultContent && getResultContent(235)}
          </p>
        )}
        {/* custom */}
        {selectedPack === "customPack" && (
          <p className="text-center text-[14px]">
            {getResultContent && getResultContent(236)}
          </p>
        )}
        {/* baseOffer */}
        {selectedPack === "baseOffer" && (
          <p className="text-center text-[14px]">
            {getResultContent && getResultContent(236)}
          </p>
        )}
      </div>
      {/* <div className="absolute hand_image">
        <img src={HandImg} className="" />
      </div> */}
    </div>
  );
};

export default SectionOurRecomendation;
