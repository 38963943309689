import React, { FC, useContext } from "react";
// import images
import TempControll from "../../../assets/images/renovates/tempcontroll.jpg";
import TempDesign1 from "../../../assets/images/renovates/tempdesign1.jpg";
import TempDesign2 from "../../../assets/images/renovates/tempdesign2.jpg";
// import components
import { GlobalContext } from "../../../context/globalContext/globalContext";
import InputTabs from "../../../shared/autoComplete";
import { getValue } from "../../../utils/getValue";

interface HeatingInterface {}

const Heating: FC<HeatingInterface> = () => {
  // global state
  const { state }: any = useContext(GlobalContext);
  console.log("getValue", getValue(state, 3, 0));
  return (
    <div>
      <div>
        <img src={TempControll} alt="Tempcontroll" />
      </div>
      <p
        className="text-black text-[20px] py-4"
        dangerouslySetInnerHTML={{
          __html: getValue(state, 3, 91)?.props?.content,
        }}
      ></p>
      <p
        className="text-[#6B7280] text-base py-4 "
        dangerouslySetInnerHTML={{
          __html: getValue(state, 3, 92)?.props?.content,
        }}
      />
      <div className="flex flex-col lg:flex-row py-8 space-x-5">
        {/* left */}
        <div className="w-full lg:w-[50%]">
          <p
            className="text-[#6B7280] text-lg py-2 font-semibold"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 3, 93)?.props?.content,
            }}
          />
          <p
            className="text-[#6B7280] text-base font-normal py-2"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 3, 94)?.props?.content,
            }}
          />
        </div>
        {/* right */}
        <div className="w-full lg:w-[50%]  flex flex-col lg:flex-row items-center lg:items-start lg:justify-between">
          <div>
            <img src={TempDesign1} alt="TempDesign1" className="h-40" />
            <p
              className="text-[#6B7280] text-base font-normal text-center py-4"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 3, 96)?.props?.content,
              }}
            />
          </div>
          <div>
            <img src={TempDesign2} alt="TempDesign2" className="h-40" />
            <p
              className="text-[#6B7280] text-base font-normal text-center py-4"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 3, 98)?.props?.content,
              }}
            />
          </div>
        </div>
      </div>
      <div className="bg-[#007DDF] rounded-[20px] p-[40px] mb-14">
        <p
          className="text-white text-xl font-medium pb-6"
          dangerouslySetInnerHTML={{
            __html: getValue(state, 3, 103)?.props?.content,
          }}
        />
        <InputTabs showAutocomplete="renovation" />
      </div>
    </div>
  );
};
export default Heating;
