import React, { FC, useContext } from "react";
// import images
import EnergyBanner from "../../../assets/images/advantages/LowerEnergyBanner.png";
import Check from "../../../assets/images/advantages/check.svg";

// import components
import { GlobalContext } from "../../../context/globalContext/globalContext";
import { getValue } from "../../../utils/getValue";
import InputTabs from "../../../shared/autoComplete";

interface LowerEnergyInterface {}

const LowerEnergy: FC<LowerEnergyInterface> = () => {
  const { state }: any = useContext(GlobalContext);
  console.log("getValue", getValue(state, 1, 17)?.props?.content);
  return (
    <div>
      <div>
        <img src={EnergyBanner} alt="EnergyBanner" />
      </div>
      <p
        className="text-black text-[20px] py-4"
        dangerouslySetInnerHTML={{
          __html: getValue(state, 1, 1)?.props?.content,
        }}
      ></p>

      <div className="flex flex-col lg:flex-row items-start justify-between space-x-0 lg:space-x-3 py-8">
        <div className="w-full lg:w-2/6">
          <div className="flex ">
            <img src={Check} alt="check" className="h-[30px]" />
            <p
              className="text-[#6B7280] text-lg font-semibold px-2"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 1, 2)?.props?.content,
              }}
            />
          </div>
          <p
            className="text-[#6B7280] text-base font-light py-4"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 5)?.props?.content,
            }}
          />
        </div>
        <div className="w-full lg:w-2/6">
          <div className="flex ">
            <img src={Check} alt="check" className="h-[30px]" />
            <p
              className="text-[#6B7280] text-lg font-semibold px-2"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 1, 3)?.props?.content,
              }}
            />
          </div>
          <p
            className="text-[#6B7280] text-base font-light py-4"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 8)?.props?.content,
            }}
          />
        </div>
        <div className="w-full lg:w-2/6">
          <div className="flex ">
            <img src={Check} alt="check" className="h-[30px]" />
            <p
              className="text-[#6B7280] text-lg font-semibold px-2"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 1, 4)?.props?.content,
              }}
            />
          </div>
          <p
            className="text-[#6B7280] text-base font-light py-4"
            dangerouslySetInnerHTML={{
              __html: getValue(state, 1, 9)?.props?.content,
            }}
          />
        </div>
      </div>

      <div className="bg-[#007DDF] rounded-[20px] p-[40px] mb-14">
        <p
          className="text-white text-xl font-medium pb-6"
          dangerouslySetInnerHTML={{
            __html: getValue(state, 1, 10)?.props?.content,
          }}
        />
        <InputTabs showAutocomplete="renovation" />
      </div>
    </div>
  );
};
export default LowerEnergy;
