import React, { FC, useContext } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import Images

// import components
import { GlobalContext } from "../../context/globalContext/globalContext";
import { getValue } from "../../utils/getValue";
interface YourSidetInterface {}

const YourSide: FC<YourSidetInterface> = () => {
  //  global state
  const { state }: any = useContext(GlobalContext);

  const responsive = {
    desktop: {
      breakpoint: { max: 1023, min: 768 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 768, min: 430 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 430, min: 0 },
      items: 1,
    },
  };

  return (
    <div>
      <div className="line-img ">
        {/* home_div   */}
        <div className="mx-[5%] lg:mx-[10%] lg:pt-24 xl:pt-0">
          <div className="py-9  flex items-center justify-center">
            <h2
              className=" text-[24px] lg:text-[38px] text-[#222222] text-center font-semibold w-full lg:w-3/6"
              dangerouslySetInnerHTML={{
                __html: getValue(state, 0, 12)?.props?.content,
              }}
            ></h2>
          </div>
          {/* boxes_div */}
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            // autoPlay={this.props.deviceType !== "mobile" ? true : false}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
            // deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px block lg:hidden"
          >
            <div className="boxes-main-div-responsive relative bgimg1 h-[212px] mx-2 shadow-sm rounded-2xl ">
              <div className="rounded-2xl h-full">
                <div className="overlay">
                  <p
                    className="text-sm text-white text-center font-bold p-2 "
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 0, 21)?.props?.content,
                    }}
                  />
                  <p
                    className="text-white text-center"
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 0, 22)?.props?.content,
                    }}
                  />
                </div>
                <p
                  className="text-sm text-white text-center font-bold p-2 "
                  dangerouslySetInnerHTML={{
                    __html: getValue(state, 0, 21)?.props?.content,
                  }}
                />
              </div>
              <div className="bg-white rounded-tr-[55px] rounded-br-[55px] w-[80px] ">
                <p className="text-[#222222] text-center text-[32px] font-semibold p-5">
                  {state?.language?.yourSideBox1Text2}
                </p>
              </div>
            </div>
            <div className="boxes-main-div-responsive relative bgimg2  h-[212px] mx-2 shadow-sm rounded-2xl  ">
              <div className="bg-[rgba(0,0,0,0.2)] rounded-2xl h-full">
                <div className="overlay">
                  <p
                    className="text-sm text-white text-center font-bold p-2 "
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 0, 23)?.props?.content,
                    }}
                  />
                  <p
                    className="text-white text-center"
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 0, 25)?.props?.content,
                    }}
                  />
                </div>
                <p
                  className="text-sm text-white text-center font-bold p-2 "
                  dangerouslySetInnerHTML={{
                    __html: getValue(state, 0, 23)?.props?.content,
                  }}
                />
              </div>
              <div className="bg-white rounded-tr-[55px] rounded-br-[55px] w-[80px]">
                <p className="text-[#222222] text-center text-[32px] font-semibold p-5">
                  {state?.language?.yourSideBox1Text4}
                </p>
              </div>
            </div>
            <div className="boxes-main-div-responsive relative bgimg3  h-[212px] mx-2 shadow-sm rounded-2xl ">
              <div className="bg-[rgba(0,0,0,0.2)] rounded-2xl h-full">
                <div className="overlay">
                  <p
                    className="text-sm text-white text-center font-bold p-2 "
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 0, 26)?.props?.content,
                    }}
                  />

                  <p
                    className="text-white text-center"
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 0, 28)?.props?.content,
                    }}
                  />
                </div>
                <p
                  className="text-sm text-white text-center font-bold p-2 "
                  dangerouslySetInnerHTML={{
                    __html: getValue(state, 0, 26)?.props?.content,
                  }}
                />
              </div>
              <div className="bg-white rounded-tr-[55px] rounded-br-[55px] w-[80px]">
                <p className="text-[#222222] text-center text-[32px] font-semibold p-5">
                  {state?.language?.yourSideBox1Text6}
                </p>
              </div>
            </div>
            <div className="boxes-main-div-responsive relative bgimg4  h-[212px] mx-2 shadow-sm rounded-2xl ">
              <div className="bg-[rgba(0,0,0,0.2)] rounded-2xl h-full">
                <div className="overlay">
                  <p
                    className="text-sm text-white text-center font-bold p-2 "
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 0, 29)?.props?.content,
                    }}
                  />
                  <p
                    className="text-white text-center"
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 0, 31)?.props?.content,
                    }}
                  />
                </div>
                <p
                  className="text-sm text-white text-center font-bold p-2 "
                  dangerouslySetInnerHTML={{
                    __html: getValue(state, 0, 29)?.props?.content,
                  }}
                />
              </div>
              <div className="bg-white rounded-tr-[55px] rounded-br-[55px] w-[80px]">
                <p className="text-[#222222] text-center text-[32px] font-semibold p-5">
                  {state?.language?.yourSideBox1Text9}
                </p>
              </div>
            </div>
            <div className="boxes-main-div-responsive relative bgimg5 h-[212px] mx-2 shadow-sm rounded-2xl ">
              <div className="bg-[rgba(0,0,0,0.2)] rounded-2xl h-full">
                <div className="overlay">
                  <p
                    className="text-sm text-white text-center font-bold p-2 "
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 0, 70)?.props?.content,
                    }}
                  />
                  <p
                    className="text-white text-center"
                    dangerouslySetInnerHTML={{
                      __html: getValue(state, 0, 71)?.props?.content,
                    }}
                  />
                </div>
                <p
                  className="text-sm text-white text-center font-bold p-2 "
                  dangerouslySetInnerHTML={{
                    __html: getValue(state, 0, 70)?.props?.content,
                  }}
                />
              </div>
              <div className="bg-white rounded-tr-[55px] rounded-br-[55px] w-[80px]">
                <p className="text-[#222222] text-center text-[32px] font-semibold p-5">
                  {state?.language?.yourSideBox1Text12}
                </p>
              </div>
            </div>
          </Carousel>
          <div className="block lg:hidden py-4">
            <div className="flex flex-col items-center justify-center space-y-2">
              <div className="bgline-mobile w-full ">
                <p
                  className="text-white text-xs font-bold text-center py-3 px-5 "
                  dangerouslySetInnerHTML={{
                    __html: getValue(state, 0, 72)?.props?.content,
                  }}
                />
              </div>
              <div className="bgline-mobile w-full ">
                <p
                  className="text-white text-xs font-bold text-center py-3 px-5 "
                  dangerouslySetInnerHTML={{
                    __html: getValue(state, 0, 73)?.props?.content,
                  }}
                />
              </div>
              <div className="bgline-mobile w-full ">
                <p
                  className="text-white text-xs font-bold text-center py-3 px-5 "
                  dangerouslySetInnerHTML={{
                    __html: getValue(state, 0, 74)?.props?.content,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="hidden lg:block">
            <div className=" flex justify-center space-x-2">
              <div>
                <div className="flex w-full space-x-2">
                  <div className=" boxes-main-div relative bgimg1  lg:h-[200px] lg:w-[195px] xl:h-[212px] xl:w-[210px]  shadow-lg rounded-2xl ">
                    <div className="bg-[rgba(0,0,0,0.2)] rounded-2xl h-full">
                      <div className="overlay opacity-[0.28]">
                        <p
                          className="text-sm text-white text-left font-bold px-[10px] py-[12px] "
                          dangerouslySetInnerHTML={{
                            __html: getValue(state, 0, 21)?.props?.content,
                          }}
                        />
                        <div className="flex justify-end">
                          <div className="w-[75%] px-1 pb-1">
                            <p
                              className="text-white text-left font-normal text-[10px]"
                              dangerouslySetInnerHTML={{
                                __html: getValue(state, 0, 22)?.props?.content,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <p
                        className="text-sm text-white text-left font-bold px-[10px] py-[12px] "
                        dangerouslySetInnerHTML={{
                          __html: getValue(state, 0, 21)?.props?.content,
                        }}
                      />
                      <div className="bg-white rounded-tr-[55px] rounded-br-[55px] w-[80px] absolute z-50 top-[60px] number-box py-4 pl-1 pr-2">
                        <p className="text-[#222222] text-center text-[32px] font-semibold ">
                          {state?.language?.yourSideBox1Text2}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" boxes-main-div relative bgimg2 lg:h-[200px] lg:w-[195px] xl:h-[212px] xl:w-[210px]  shadow-lg rounded-2xl mt-8 ">
                    <div className="bg-[rgba(0,0,0,0.2)] rounded-2xl h-full">
                      <div className="overlay opacity-[0.28]">
                        <p
                          className="text-sm text-white text-left font-bold px-[10px] py-[12px]"
                          dangerouslySetInnerHTML={{
                            __html: getValue(state, 0, 23)?.props?.content,
                          }}
                        />
                        <div className="flex justify-end">
                          <div className="w-[75%] px-1 pb-1">
                            <p
                              className="text-white text-left font-normal text-[10px]"
                              dangerouslySetInnerHTML={{
                                __html: getValue(state, 0, 25)?.props?.content,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <p
                        className="text-sm text-white text-left font-bold px-[10px] py-[12px]"
                        dangerouslySetInnerHTML={{
                          __html: getValue(state, 0, 23)?.props?.content,
                        }}
                      />

                      <div className="bg-white rounded-tr-[55px] rounded-br-[55px] w-[80px]  absolute z-50 top-[60px] number-box py-4 pl-1 pr-2">
                        <p className="text-[#222222] text-center text-[32px] font-semibold ">
                          {state?.language?.yourSideBox1Text4}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" boxes-main-div relative bgimg3 lg:h-[200px] lg:w-[195px] xl:h-[212px] xl:w-[210px] shadow-lg rounded-2xl ">
                    <div className="bg-[rgba(0,0,0,0.2)] rounded-2xl h-full">
                      <div className="overlay opacity-[0.28]">
                        <p
                          className="text-sm text-white text-left font-bold px-[10px] py-[12px] "
                          dangerouslySetInnerHTML={{
                            __html: getValue(state, 0, 26)?.props?.content,
                          }}
                        />
                        <div className="flex justify-end">
                          <div className="w-[75%] px-1 pb-1">
                            <p
                              className="text-white text-left font-normal text-[10px]"
                              dangerouslySetInnerHTML={{
                                __html: getValue(state, 0, 28)?.props?.content,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <p
                        className="text-sm text-white text-left font-bold px-[10px] py-[12px]"
                        dangerouslySetInnerHTML={{
                          __html: getValue(state, 0, 26)?.props?.content,
                        }}
                      />

                      <div className="bg-white rounded-tr-[55px] rounded-br-[55px] w-[80px]  absolute z-50 top-[60px] number-box py-4 pl-1 pr-2">
                        <p className="text-[#222222] text-center text-[32px] font-semibold ">
                          {state?.language?.yourSideBox1Text6}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-6">
                  <div className="bgline1 lg:flex items-center justify-start lg:justify-center px-6 h-[42px] ">
                    <p
                      className="text-white text-xs font-bold text-center  "
                      dangerouslySetInnerHTML={{
                        __html: getValue(state, 0, 72)?.props?.content,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="lg:h-[200px] lg:w-[195px] xl:h-[212px] xl:w-[210px]">
                  <div className="boxes-main-div relative bgimg4 w-full h-full  shadow-lg rounded-2xl mt-8">
                    <div className="bg-[rgba(0,0,0,0.2)] rounded-2xl h-full">
                      <div className="overlay opacity-[0.28]">
                        <p
                          className="text-sm text-white text-left font-bold px-[10px] py-[12px]"
                          dangerouslySetInnerHTML={{
                            __html: getValue(state, 0, 29)?.props?.content,
                          }}
                        />
                        <div className="flex justify-end">
                          <div className="w-[75%] px-1 pb-1">
                            <p
                              className="text-white text-left font-normal text-[10px]"
                              dangerouslySetInnerHTML={{
                                __html: getValue(state, 0, 31)?.props?.content,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <p
                          className="text-sm text-white text-left font-bold px-[10px] py-[12px]"
                          dangerouslySetInnerHTML={{
                            __html: getValue(state, 0, 29)?.props?.content,
                          }}
                        />
                      </div>
                    </div>
                    <div className="bg-white rounded-tr-[55px] rounded-br-[55px] w-[80px]  absolute z-50 top-[60px] number-box py-4 pl-1 pr-2">
                      <p className="text-[#222222] text-center text-[32px] font-semibold">
                        {state?.language?.yourSideBox1Text9}
                      </p>
                    </div>
                  </div>
                  <div className="pt-6">
                    {" "}
                    <div className="bgline2 lg:flex items-center justify-start lg:justify-center px-6 h-[42px] ">
                      <p
                        className="text-white text-xs font-bold text-center "
                        dangerouslySetInnerHTML={{
                          __html: getValue(state, 0, 73)?.props?.content,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="lg:h-[200px] lg:w-[195px] xl:h-[212px] xl:w-[210px] ">
                  <div className="boxes-main-div relative bgimg5 w-full h-full shadow-lg rounded-2xl ">
                    <div className="bg-[rgba(0,0,0,0.2)] rounded-2xl h-full">
                      <div className="overlay opacity-[0.28]">
                        <p
                          className="text-sm text-white text-left font-bold px-[10px] py-[12px]"
                          dangerouslySetInnerHTML={{
                            __html: getValue(state, 0, 70)?.props?.content,
                          }}
                        />
                        <div className="flex justify-end">
                          <div className="w-[75%] px-1 pb-1">
                            <p
                              className="text-white text-left font-normal text-[10px]"
                              dangerouslySetInnerHTML={{
                                __html: getValue(state, 0, 71)?.props?.content,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <p
                        className="text-sm text-white text-left font-bold px-[10px] py-[12px]"
                        dangerouslySetInnerHTML={{
                          __html: getValue(state, 0, 70)?.props?.content,
                        }}
                      />
                    </div>
                    <div className="bg-white rounded-tr-[55px] rounded-br-[55px] w-[80px]  absolute z-50 top-[60px] number-box py-4 pl-1 pr-2">
                      <p className="text-[#222222] text-center text-[32px] font-semibold ">
                        {state?.language?.yourSideBox1Text12}
                      </p>
                    </div>
                  </div>
                  <div className="pt-14">
                    <div className="bgline3 lg:flex items-center justify-start lg:justify-center px-6 h-[42px]">
                      <p
                        className="text-white text-xs font-bold text-center "
                        dangerouslySetInnerHTML={{
                          __html: getValue(state, 0, 74)?.props?.content,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default YourSide;
