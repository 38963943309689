import React, { FC, useEffect } from "react";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
import Splash from "../../assets/images/result/resultPage/manny-becerra-NgdhrwAx0J8-unsplash.jpg";
import { Helmet } from "react-helmet";
import { loadModules } from "esri-loader";
import { Spin } from "antd";
import { get_construction_date } from "../../utils/buildingDetailsHandler";
import { LoadingOutlined } from "@ant-design/icons";
import GSMap from "../../containers/resultSub/gsMap";
const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;
export interface SectionHeroProps {
  className?: string;
  getResultContent?: any;
  openModal?: any;
  allRenovationRecord?: any;
  userStatus?: any;
  loggedInUser?: any;
  refinementCall3Record?: any;
  isCallWaitng?: any;
  isAdminView?: any;
}

const SectionHouse: FC<SectionHeroProps> = ({
  className,
  getResultContent,
  openModal,
  allRenovationRecord,
  userStatus,
  loggedInUser,
  refinementCall3Record,
  isCallWaitng,
  isAdminView,
}) => {
  const getBuildingName = (value: any) => {
    switch (value) {
      case "terraced_building":
        return getResultContent(139);
      case "detached_building":
        return getResultContent(137);
      case "semi_detached_building":
        return getResultContent(138);
      default:
        return "";
    }
  };

  // get building type agc-v2
  const getbuilding_type = (value: any) => {
    let total_facade: any = parseInt(value) + 1;
    // console.log("total_facade", total_facade);

    switch (total_facade) {
      case 2:
        return getResultContent(139);
      case 4:
        return getResultContent(137);
      case 3:
        return getResultContent(138);
      default:
        return "";
    }
  };

  //console.log("refinementCall3Record", refinementCall3Record);
  console.log(
    "allRenovationRecord?.cave",
    refinementCall3Record,
    allRenovationRecord?.cave
  );
  const house_detail = [
    {
      key: getResultContent ? getResultContent(4) : "",
      icon: "la-key",
      value:
        refinementCall3Record &&
        Object.keys(refinementCall3Record)?.length !== 0 &&
        refinementCall3Record !== undefined &&
        refinementCall3Record?.construction_date !== null
          ? parseInt(refinementCall3Record?.construction_date)
          : allRenovationRecord?.construction_date !== null
          ? get_construction_date(allRenovationRecord?.construction_date)
          : 0,
      show: allRenovationRecord?.construction_date !== null ? 1 : 0,
    },
    {
      key: getResultContent ? getResultContent(7) : "",
      icon: "la-smoking",
      value:
        refinementCall3Record &&
        Object.keys(refinementCall3Record)?.length !== 0 &&
        refinementCall3Record !== undefined &&
        refinementCall3Record?.num_hob !== null
          ? Number(refinementCall3Record?.num_hob)
          : allRenovationRecord?.num_hob !== null
          ? allRenovationRecord?.num_hob
          : 0,
      show: allRenovationRecord?.num_hob !== null ? 1 : 0,
    },
    {
      key: getResultContent ? getResultContent(6) : "",
      icon: "la-shower",
      value:
        refinementCall3Record &&
        Object.keys(refinementCall3Record)?.length !== 0 &&
        refinementCall3Record !== undefined &&
        refinementCall3Record?.num_floors !== null
          ? Math.round(allRenovationRecord?.surfsol)
          : allRenovationRecord?.surfsol !== null
          ? Math.round(allRenovationRecord?.surfsol)
          : 0,
      show: allRenovationRecord?.surfsol !== null ? 1 : 0,
    },
    {
      key: getResultContent ? getResultContent(3) : "",
      icon: "la-shower",
      value:
        refinementCall3Record &&
        Object.keys(refinementCall3Record)?.length !== 0 &&
        refinementCall3Record !== undefined &&
        refinementCall3Record?.num_facades !== null
          ? Math.round(refinementCall3Record?.num_facades)
          : allRenovationRecord?.num_facades !== null
          ? Math.round(allRenovationRecord?.num_facades)
          : 0,
      show: allRenovationRecord?.num_facades !== null ? 1 : 0,
    },
    {
      key: getResultContent ? getResultContent(5) : "",
      icon: "la-luggage-cart",
      value:
        refinementCall3Record &&
        Object.keys(refinementCall3Record)?.length !== 0 &&
        refinementCall3Record !== undefined &&
        refinementCall3Record?.num_floors !== null
          ? refinementCall3Record?.num_floors
          : allRenovationRecord?.num_floors,
      show: allRenovationRecord?.num_floors !== null ? 1 : 0,
    },
    {
      key: getResultContent ? getResultContent(15) : "",
      icon: "la-smoking",
      value:
        refinementCall3Record &&
        Object.keys(refinementCall3Record)?.length !== 0 &&
        refinementCall3Record !== undefined &&
        refinementCall3Record?.has_attic !== null &&
        refinementCall3Record?.has_attic === 1
          ? "OUI"
          : refinementCall3Record?.has_attic === 0
          ? "NON"
          : allRenovationRecord?.has_attic === undefined ||
            allRenovationRecord?.has_attic === null ||
            allRenovationRecord?.has_attic === 1
          ? "OUI"
          : "NON",
      show: allRenovationRecord?.has_attic !== null ? 1 : 0,
    },
    {
      key: getResultContent ? getResultContent(12) : "",
      icon: "la-smoking",
      value:
        refinementCall3Record &&
        Object.keys(refinementCall3Record)?.length !== 0 &&
        refinementCall3Record !== undefined &&
        refinementCall3Record?.cave !== null &&
        refinementCall3Record?.cave === 1
          ? "OUI"
          : refinementCall3Record?.cave === 0
          ? "NON"
          : allRenovationRecord?.cave !== null &&
            allRenovationRecord?.cave !== "?"
          ? allRenovationRecord?.cave
          : "NON",
      show:
        allRenovationRecord?.cave !== null && allRenovationRecord?.cave !== 0
          ? 1
          : 1,
    },
  ];

  console.log("allrenovationRecord", allRenovationRecord);

  return (
    <>
      <div
        className={`mb-4 lg:mb-0 shadow w-full lg:w-1/2 listingSection__wrap house_container_result section_width border border-[#F0F0F0] rounded-[10px] lg:mx-2 h-auto p-0 ${className} flex flex-col justify-between`}
      >
        <div className="flex flex-col lg:flex-row xl:flex-row justify-between">
          {/* left side */}
          <div className="house_map w-full lg:w-[45%]">
            <div className="py-[7px] px-1.5">
              {/* <img
                src={Splash}
                alt="splash"
                className="w-full lg:h-[318px] rounded"
              /> */}
              <GSMap
                lat={allRenovationRecord?.lat}
                lng={allRenovationRecord?.lng}
                address={
                  allRenovationRecord?.street +
                  " " +
                  allRenovationRecord?.street_num +
                  ", 7100 La Louvière"
                }
              />
              {/* <div id="viewDiv"></div> */}
            </div>
          </div>
          {/* right side */}
          <div className="grid grid-cols-1 lg:grid-cols-1 text-sm text-neutral-700 dark:text-neutral-300 w-full lg:w-[55%] px-3 lg:pl-6 lg:pr-2 py-3 ">
            {/* <div className="pt-4">
              <h4 className="font-medium text-2xl">
                {
                  addressHandler(allRenovationRecord?.formatted_address)
                    ?.firstAddress
                }
                <br />
                {
                  addressHandler(allRenovationRecord?.formatted_address)
                    ?.lastAddress
                }
              </h4>
            </div> */}
            {house_detail?.map((item, i) => (
              <React.Fragment key={i}>
                {item?.show === 1 && (
                  <div key={i} className={`flex py-2 space-x-3 `}>
                    {/* <Avatar sizeClass="h-10 w-10" radius="rounded-full" /> */}
                    <div className="flex">
                      <span className="font-bold text-black text-base ">
                        {item?.key}:
                        <span className="px-2 text-black text-base font-normal">
                          {item?.value}
                        </span>
                      </span>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        {/* footer */}
        <div className=" flex mt-0 w-full">
          <div className="flex justify-end mt-0 p-8 bg-[#F4FAFF] w-full border-b-r-10 border-b-l-10">
            {isCallWaitng ? (
              <button className="outline-primary-button ">
                <Spin indicator={antIcon} />
              </button>
            ) : (
              (userStatus?.data?.status < 3 ||
                userStatus?.data == null ||
                userStatus?.data?.status == undefined) &&
              !isAdminView && (
                <button
                  className={
                    "outline-primary-button hover:bg-[#007DDF] hover:text-[#fff] border border-[#007DDF] rounded hover:text-white text-[#007DDF] font-normal text-base py-2 px-4"
                  }
                  onClick={() => {
                    openModal("refine-information");
                  }}
                >
                  {getResultContent(22)}
                </button>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionHouse;
